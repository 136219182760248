<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
    <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
  <div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
      <div class="content">
          <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
          <div class="container-fluid" style="width: 100%; float: left; display: flex;">
            <div class="col-md-12">
              <div class="card margin-top-35">
                <div *ngIf="solicitudesSinResponsableUsuarioOnline.length > 0" class="card-body padding-0">
                  <div class="col-md-12 padding-0" style="margin-top:30px;">
                    <div class="col-md-12 table-responsive">
                      <table class="table table-tw-widget table-hover table-sm">
                        <thead>
                          <tr>
                            <th>Email (usuario)</th>
                            <th>Phone</th>
                            <th>Name</th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let solicitud of solicitudesSinResponsableUsuarioOnline; let index = index;">
                            <td> {{ solicitud.usuario }}</td>
                            <td> {{ solicitud.pass }}</td>
                            <td> {{ solicitud.nombre }}</td>
                            <td>
                              <button *ngIf="solicitud.boton_habilitar != 2" class="btn btn-dark btn-icon" (click)="solicitarDatosResponsable(solicitud.usuario, solicitud.nombre);">
                                <i class="mdi mdi-email-outline"></i>
                                Request info
                              </button>
                              <button *ngIf="solicitud.boton_habilitar == 2" class="btn btn-icon btn-danger" (click)="eliminarSolicitudUsuarioOnline(solicitud.usuario, solicitud.nombre)">
                                <i class="mdi mdi-delete"></i>
                                ¿Are you sure?
                              </button>
                            </td>
                            <td *ngIf="solicitud.boton_habilitar != 2">
                              <input *ngIf="solicitud.id != null" value={{solicitud.responsable}} type="text" placeholder="Responsable" class="form-control" [matAutocomplete]="autoCompleteResponsable" (keyup)="getResponsables($event,index)">
                              <input *ngIf="solicitud.id == null" type="text" placeholder="Ingresa el nombre del responsable" class="form-control" [matAutocomplete]="autoCompleteResponsable" (keyup)="getResponsables($event,index)">
                              <mat-autocomplete #autoCompleteResponsable="matAutocomplete" [displayWith]="displayResponsableCuenta">
                                <mat-option *ngFor="let responsable of responsables" [value]="responsable" class="fila-autocomplete" (click)="setIdUsuarioOnline(responsable.id_responsable,index,responsable.fullname)">
                                  {{ (responsable.fullname) ? responsable.fullname : '-' }}
                                </mat-option>
                              </mat-autocomplete>
                            </td>
                            <td *ngIf="solicitud.boton_habilitar == 2">
                              <button *ngIf="solicitud.boton_habilitar == 2" class="btn btn-icon btn-success" (click)="abrirPanelConfirmacion(index,0)">
                                <i class="mdi mdi-close-box"></i>
                                Nope, Nope, Nope
                              </button>
                            </td>
                            <td>
                              <button *ngIf="solicitud.boton_habilitar == 1" class="btn btn-dark" (click)="habilitarPerfilUsuarioOnline(solicitud.id,solicitud.nombre,solicitud.usuario);">
                                Enable user
                              </button>
                              <button *ngIf="solicitud.boton_habilitar == 0" class="btn btn-icon btn-dark" (click)="abrirPanelConfirmacion(index,2)">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div id="pendientes-0" class="centered row justify-content-md-center" style="background-color: #fafbfe !important;" *ngIf="solicitudesSinResponsableUsuarioOnline.length == 0 && loaded == true">
                  <div class="col-6">
                    <img style="width:100px;" src="assets/images/icons/partners-claping-hands.svg" alt="cool">
                  </div>
                  <div class="col-12">
                    <p>Hurray, everything is up to date!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
