import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public products: any[];
  public form: UntypedFormGroup;
  public loading: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private productService: ProductService
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.products = [];
    this.form = new UntypedFormGroup({
      sku: new UntypedFormControl('', [Validators.required]),
      qty: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl('', [Validators.required]),
      cost: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
      category: new UntypedFormControl('', [Validators.required]),
    });
    this.loading = false;
  }

  ngOnInit() {
    this.getProducts();
  }

  private getProducts(): void{
    this.productService.getProducts().subscribe(
      response => {
        if(!response.error){
          this.products = response.products;
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the products list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public addProduct(): void{
    if(this.form.valid){
      this.form.disable();
      this.productService.save(this.form.value).subscribe(
        response => {
          if(!response.error){
            this.products.push(response.product);
            this.products = this.products.sort((a,b) => {
              if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;

              return 0;
            });
            
            this.form.reset('');
            this.form.enable();
          } else{
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
            this.form.enable();
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to save the product.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          this.form.enable();
        }
      );
    }
  }

  public editarProducto(product): void{
    this.productService.edit(product).subscribe(
      response => {
        if(!response.error){
          //console.log(response);
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to edit the product.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
