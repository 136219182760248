import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PersonaService } from '../services/persona.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-merge-riders-manual',
  templateUrl: './merge-riders-manual.component.html',
  styleUrls: ['./merge-riders-manual.component.css']
})
export class MergeRidersManualComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public people: any[];
  public form: UntypedFormGroup;

  constructor(private router: Router, private authService: AuthService, private personaService: PersonaService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.people = [];
    this.form = new UntypedFormGroup({
      person1: new UntypedFormControl('', [Validators.required]),
      person2: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').hide();
  }

  public findPeople(e): void{
    const searchValue: string = e.target.value;
    if(searchValue.length < 3) return;

    this.personaService.getPersonasFiltradas(searchValue, this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.people = response.personas;
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It has not been possible to query the peopole, please try again.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public displayPerson(idPersona): string{
    const person = this.people.find(p => p.id_persona == idPersona);

    return person ? person.fullname : idPersona;
  }

  public filtroPersonas(): any[]{
    let people = this.people;
    if(this.form.value.person1) people = people.filter(p => p.id_persona != this.form.value.person1);
    if(this.form.value.person2) people = people.filter(p => p.id_persona != this.form.value.person2);

    return people;
  }

  public mergePeopleManual(): void{
    if(this.form.valid){
      this.form.disable();
      this.personaService.mergePeopleManual(this.form.value).subscribe(
        response => {
          console.log(response);
          if(!response.error){
            this.form.setValue({
              person1: '',
              person2: ''
            });
            $('form').removeClass('was-validated');
            this.people = [];
            this.form.enable();
            $.NotificationApp.send("Success", "The merge has been successfully.", 'bottom-right', '#0acf97', 'success');
          } else{
            this.form.enable();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          this.form.enable();
          console.log(error);
          $.NotificationApp.send("Error", 'It has not been possible to merge these peopole, please try again.', 'bottom-right', '#fa5c7c', 'error');
        }
      );
    }
  }
}
