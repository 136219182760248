import { Component, OnInit, Directive, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import {FormControl, Validators, Form,FormBuilder, FormGroup} from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service'
declare var jquery:any;
declare var $ : any;


@Component({
  selector: 'vincular-usuario-inscripciones',
  templateUrl: './vincular-usuario-inscripciones.component.html',
  styleUrls: ['./vincular-usuario-inscripciones.component.css']
})
export class VincularUsuarioInscripcionesComponent implements OnInit {


  public idConcurso: string | boolean;
  public nombreConcurso: string;
  public solicitudesSinResponsableUsuarioOnline: any;
  public responsables: any;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public loaded: boolean;
  public privilegios: number;

  constructor(
    private route: ActivatedRoute,
    private inscripcionesRemotasService: InscripcionesRemotasService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {
    this.idConcurso = '';
    this.token = '';
    this.loaded = false;
    this.privilegios = 0;
    this.idUsuario = '';
    this.sessionData = {};
    this.solicitudesSinResponsableUsuarioOnline = [];
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.responsables = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').show();
    this.getUsuariosSinResponsable();
  }

  public getUsuariosSinResponsable(){
    this.inscripcionesRemotasService.getUsuariosSinResponsable().subscribe(
      response => {
        this.solicitudesSinResponsableUsuarioOnline = response;
        this.loaded = true;
        $('#loader').hide();
        if(this.solicitudesSinResponsableUsuarioOnline.length == 0){
          $('#pendientes-0').fadeIn(1000);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * @param event (KeyUp) Regresa el valor del INPUT de responsables.
   * @param index Indice del array solicitudesSinResponsableUsuarioOnline[]
   *              En caso de que el evento regrese vacio o nulo, reseteara los campos
   *              del índice.
   *
   * Obtiene los registros de la tabla responsables donde el campo 'fullname' sea similar
   * a los valores capturados por el usuario.
   */
  public getResponsables(event: any,index){
    let nombre = event.target.value;
    let accion = 'filtrarResponsables';
    let correo = '';
    if(nombre.length > 3){
      this.inscripcionesRemotasService.getInformacionResponsables(nombre,correo,accion).subscribe(
        response => {
          this.responsables = response;
        },
        error => {
          console.log(error);
        }
      );
    }else{
      this.solicitudesSinResponsableUsuarioOnline[index].responsable = '';
      this.solicitudesSinResponsableUsuarioOnline[index].boton_habilitar = 0;
    }
  }

  /**
   * @param val Objeto con los valores del responsable.
   *
   * Regresa a la vista el nombre del responsable
   */
  public displayResponsableCuenta(val): string {
    return val ? val.fullname: val;
  }

  /**
   * @param id ID del responsable a vincular en la cuenta.
   * @param index Indice del array solicitudesSinResponsableUsuarioOnline[] donde se insertarán
   *              los datos temporalmente.
   * @param responsable Nombre del responsable para mostrarlo en la vista.
   *
   * Inserta los valores en los campos indicados del indice del array. Estos automáticamente se
   * actualizan en la vista (directiva ngFor).
   *
   * Vacia el array de responsables para que no se renderee automáticamente en la vista.
   */
  public setIdUsuarioOnline(id,index,responsable){
    this.solicitudesSinResponsableUsuarioOnline[index].id = id;
    this.solicitudesSinResponsableUsuarioOnline[index].responsable = responsable;
    this.solicitudesSinResponsableUsuarioOnline[index].boton_habilitar = 1;
    this.responsables = [];
  }

  /**
   * @param index Indice del arreglo solicitudesSinResponsableUsuarioOnline en el que se mostrarán las opciones.
   * @param accion Parametro que define opción visible:
   *                0 = Boton borrar (negro).
   *                1 = Boton habilitar usuario (visible cuando hay un responsable real capturado).
   *                2 = Botones confirmacion (rojo | verde) para borrar solicitud.
   */
  public abrirPanelConfirmacion(index, accion){
    this.solicitudesSinResponsableUsuarioOnline[index].boton_habilitar = accion;
  }

  /**
   * @param usuario (Correo) del cual se eliminará la solicitud
   * @param nombre Nombre capturado de quien realizo la solicitud
   *
   * Se eliminarán de la base de datos todas las coincidencias sin ID y que no estén activas
   */
  public eliminarSolicitudUsuarioOnline(usuario,nombre){
    let accion = 'eliminarSolicitud'
    this.inscripcionesRemotasService.accionesUsuarioOnline('',nombre,usuario,accion).subscribe(
      response => {
        if(!response.error){
          $.NotificationApp.send("Success", 'The request from '+nombre+' has been deleted. The payee is not notified :).', 'bottom-right', '#06d5a1', 'success');
          this.getUsuariosSinResponsable();
        };
      },
      error => {
        $.NotificationApp.send("Error", error , 'bottom-right', '#06d5a1', 'success');
        console.log(error);
      }
    );
  }

  /**
   * @param correo Correo electrónico al cual se contactará al cliente que solicito el acceso (Usuario).
   * @param nombre Nombre que ingreso el cliente al momento de hacer su solicitud.
   *
   * Envía al usuario un correo solicitando datos adicionales para poder detectar a que responsable
   * vincular su cuenta
   */
  public solicitarDatosResponsable(correo,nombre){
    let accion = 'contactarResponsable'
    this.inscripcionesRemotasService.getInformacionResponsables(nombre,correo,accion).subscribe(
      response => {
        $.NotificationApp.send("Email sent", nombre+' has been contacted to request more information regarding his request', 'bottom-right', '#06d5a1', 'success');
      },
      error => {
        $.NotificationApp.send("Error", error , 'bottom-right', '#06d5a1', 'success');
        console.log(error);
      }
    );
  }

  /**
   * @param id ID del responsable real que se vinculará con la cuenta del usuario online (users_responsables).
   * @param nombre Nombre que indico el cliente al momento de solicitar una cuenta de usuario online.
   * @param correo Correo electrónico de contacto con el cliente (usuario).
   *
   * Vincula un registro de usuario online (users_responsables) con un responsable real, posteriormente
   * activa la cuenta
   */
  public habilitarPerfilUsuarioOnline(id,nombre,correo){
    let accion = 'vincularUsuario'
    this.inscripcionesRemotasService.accionesUsuarioOnline(id,nombre,correo,accion).subscribe(
      response => {
        if(!response.error){
          $.NotificationApp.send("Success", 'The profile of '+nombre+' has been enabled. The payee was notified :).',  'bottom-right', '#06d5a1', 'success');
          this.getUsuariosSinResponsable();
        };
      },
      error => {
        $.NotificationApp.send("Error", error , 'bottom-right', '#06d5a1', 'success');
        console.log(error);
      }
    );
  }
}
