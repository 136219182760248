import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { ListaService } from '../services/lista.service';
import { AuthService } from '../services/auth.service';
import { EntrenadorService } from '../services/entrenador.service';
import { ConcursoService } from '../services/concurso.service';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-listas-entrenador',
  templateUrl: './listas-entrenador.component.html',
  styleUrls: ['./listas-entrenador.component.css']
})
export class ListasEntrenadorComponent implements OnInit {
  public idConcurso: string | boolean;
  public idEntrenador: number;
  public entrenadoresConcurso: any;
  public binomiosPorEntrenador: any;
  public adds_drops: any;
  public displayButtonAD: boolean;
  public trainerName: string;
  public trainerEmail: string;
  public date: string;
  public keys: any;
  public count: number;
  public testPrizes: any;
  public tituloReporte: string;
  public tituloReporteEN: string;
  public nombreConcurso: string;
  public logoClub: string;
  private idUsuario: string;
  public totalInscritos: number;
  private token: string;
  private sessionData: any;
  public header: string;
  public baseUrl: string;
  constructor(
    private authService: AuthService,
    private listaService: ListaService,
    private router: Router,
    private concursoService: ConcursoService,
    private entrenadorService: EntrenadorService
  ) {
    this.entrenadoresConcurso = [];
    this.binomiosPorEntrenador = [];
    this.adds_drops = [];
    this.count = 0;
    this.token = '';
    this.logoClub = '';
    this.tituloReporte = '';
    this.tituloReporteEN = '';
    this.sessionData = {};
    this.testPrizes = [];
    this.totalInscritos = 0;
    this.header = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.baseUrl = window.location.hostname;
    this.displayButtonAD = false;
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if(this.authService.validarConcurso()){
      this.idConcurso = this.authService.validarConcurso();
    } else{
      this.router.navigate(['']);
      return;
    }
    this.getEntrenadoresConcurso();
    this.getInscripciones();
    this.getConcurso();
  }

  public getConcurso(){
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.logoClub = response.concurso.logoClub;
          this.header = response.concurso.header;
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  private getEntrenadoresConcurso(){
    this.entrenadorService.getEntrenadores(this.idConcurso).subscribe(
      response => {
        this.entrenadoresConcurso = response.entrenadores;
      },
      error => {
        $.NotificationApp.send("ERROR!",  "There was an error, try again.",  'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getInscripciones(){
    this.listaService.getInscripciones(this.idConcurso).subscribe(
      response => {
        this.tituloReporte = 'Inscripciones por entrenador';
        this.tituloReporteEN = 'Entries';
        this.testPrizes = response.map(t => {
          t.panelOpenState = false;
          return t;
        });
        this.getTotalInscritos();
      },
      error => {
        console.log(error);
      }
    );
  }

  private getTotalInscritos(){
    this.totalInscritos = 0;
    this.testPrizes.forEach(
      fila => {
        this.totalInscritos += parseFloat(fila.inscritos);
      }
    );
  }

  public getListasEntrenador(){
    this.listaService.getListasEntrenador(this.idConcurso,this.idEntrenador).subscribe(
      response => {
        this.binomiosPorEntrenador = response.pruebas;
        this.adds_drops = response.adds_drops;
        let trainer = this.entrenadoresConcurso.find(x => x.idEntrenador == this.idEntrenador);
        this.trainerName = trainer.nombre;
        this.trainerEmail = trainer.email;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hr = today.getHours();
        var min = today.getMinutes();
        this.date = mm + '/' + dd + '/' + yyyy + ' '+ hr + ':' + min;
        if(Object.keys(this.adds_drops).length > 0){
          this.displayButtonAD = true;
        }else{
          this.displayButtonAD = false;
        }
        $.NotificationApp.send("SUCCESS!", 'the lists of rider/horse combinations of this trainer have been loaded.', 'bottom-right', '#06d5a1', 'success');;
      },
      error => {
        $.NotificationApp.send("ERROR!",  "There was an error, try again.",  'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public printAddsDrops(){
    $('#listas-entrenador').addClass('hidden-print');
    $('#adds-drops').removeClass('hidden-print');
    window.print();
  }

  public printTrainerList(){
    $('#listas-entrenador').removeClass('hidden-print');
    $('#adds-drops').addClass('hidden-print');
    window.print();
  }

  public jsonToArray(json): Array<string> {
    return Object.keys(json);
  }
}
