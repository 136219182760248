<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-8 mt-3">
                        <div class="card">
                            <div class="card-header text-white bg-primary text-xs-center p-2">
                                Ingate Users
                            </div>
                            <div class="card-body p-0">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr [formGroup]="form">
                                            <td><input type="email" class="form-control form-control-sm" formControlName="email"></td>
                                            <td class="table-action text-center">
                                                <a href="javascript: void(0);" class="action-icon" (click)="submit()"> <i class="mdi mdi-plus-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let email of emails; let index = index">
                                            <td>{{ email }}</td>
                                            <td class="table-action text-center">
                                                <a href="javascript: void(0);" class="action-icon" (click)="eliminarEmail(index)"> <i class="mdi mdi-close-circle-outline"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
