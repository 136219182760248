<div style="width:8.5in; height:11in; background-color: white; margin: 15px auto; padding: 1cm;" class="print-margin">
    <div style="width: 100%; display: flex; border-bottom: 10px solid black; margin-bottom: 15px;">
        <div style="text-align: center; flex: 1 1 0;">
            <img src="/assets/img/clubs/{{pase.siglas}}.png" style="width: 50%;"><!-- onerror="this.style.display='none'" -->
        </div>
        <div style="text-align: center; flex: 3 1 0; margin-top: auto;">
            <div style="background-color: black; color: white; font-size: 25px;">
                Pase de Salida
            </div>
        </div>
        <div style="flex: 1 1 0; margin: auto; padding: 0 10px; font-size: 20px; text-align: center;">
            <b>Folio: {{pase.folio}}</b>
        </div>
    </div>
    <div style="width: 100%; display: flex; border: 2px solid black;">
        <div style="flex: 1 1 0; border-right: 2px solid black; padding: 5px;">
            <label style="margin: 0"><b>Fecha:</b> {{pase.fecha | date: 'dd/MM/yyyy hh:mm a'}}</label>
        </div>
        <div style="flex: 1 1 0; padding: 5px;">
            <label style="margin: 0"><b>Club:</b> {{pase.club}}</label>
        </div>
    </div>
    <div style="width: 100%; display: flex; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black;">
        <div style="flex: 1 1 0; padding: 5px;">
            <label style="margin: 0"><b>Responsable:</b> {{pase.responsable}}</label>
        </div>
    </div>
    <div style="width: 100%; display: flex; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black;">
        <div style="flex: 1 1 0; padding: 5px;">
            <label><b>Caballos:</b></label>
            <ul style="columns: 3;list-style: none; padding: 0;">
                <li *ngFor="let caballo of pase.caballos" style="padding: 5px;"><mat-icon svgIcon="horse" style="width: 13px; height: 13px;"></mat-icon> {{ caballo }}</li>
            </ul>
        </div>
    </div>
    <div style="width: 100%; display: flex; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black;">
        <div style="flex: 1 1 0; padding: 5px;">
            <label><b>Comentarios:</b></label>
            <p>{{pase.comentarios}}</p>
        </div>
    </div>
    <div style="width: 100%; display: flex; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black; height: 4cm;">
        <div style="flex: 1 1 0; padding: 5px; text-align: center; margin-top: auto;">
            <label style="margin: 0"><b>Firma Oficina</b></label>
        </div>
        <div style="flex: 1 1 0; padding: 5px; text-align: center; margin-top: auto;">
            <label style="margin: 0"><b>Sello</b></label>
        </div>
    </div>
</div>