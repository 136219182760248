import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class UsuarioService extends Api {

    constructor(private http: HttpClient) {
        super();
    }

    public getUsers(): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}usuarios/`, {headers: headers});
    }

    public crear(usuario): Observable<any>{
        const params = JSON.stringify({usuario});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}usuarios/crear/`, params, {headers: headers});
    }

    public actualizar(usuario): Observable<any>{
        const params = JSON.stringify({usuario});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}usuarios/actualizar/`, params, {headers: headers});
    }
}
