<!--div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div-->
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
      <div class="content">
        <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <h4 class="page-title">
                        <b i18n="@@tituloResumenResponsables">Limpiar DB Caballos: FEI</b>
                    </h4>
                </div>
            </div>
        </div>
        <div class="row" style="width: 100%; float: left; display: flex;">
            <div class="card col-3">
                <table class="table-striped table">
                    <thead>
                        <th></th>
                        <th>FEI</th>
                        <th>Caballo</th>
                    </thead>    
                  <tbody>
                      <tr *ngFor="let caballo of feiDuplicados;let i = index" (click)="traerCaballosPorFEI(caballo.fei)">
                        <td>{{i+1}}</td>
                        <td>{{caballo.fei}}</td>
                        <td>{{caballo.fullname}}</td>
                      </tr>
                  </tbody>  
              </table>
            </div>
            <div class="card col-5" *ngIf="caballosPorFEI.length > 0">
                <table class="table-striped table" >
                    <thead>
                          <th>FEM</th>
                          <th>Caballo</th>
                          <th>Propietario</th>
                          <th></th>
                          <th></th>
                    </thead>    
                    <tbody>
                        <tr *ngFor="let caballo of caballosPorFEI">
                          <td>{{caballo.fem}}</td>
                          <td>{{caballo.fullname}}</td>
                          <td>{{caballo.propietario}}</td>
                          <td><button type="button" class="btn btn-outline-success btn-rounded" (click)="seleccionarCaballoAConservar(caballo.id_caballo, caballo.fullname, caballo.propietario)"><i class="mdi mdi-check"></i></button></td>
                          <td><button type="button" class="btn btn-outline-danger btn-rounded" (click)="seleccionarCaballoABorrar(caballo.id_caballo, caballo.fullname, caballo.propietario)"><i class="mdi mdi-close"></i></button></td>
                        </tr>
                    </tbody>  
                </table>
            </div>
            <div class="card col-4" *ngIf="this.nombreCaballoSeleccionado.length > 0">
                <div class="card-body">
                    <h4 class="header-title mb-3">Seleccion</h4>
                    <div class="inbox-widget">
                        <div class="inbox-item">
                            <p class="inbox-item-author">{{this.nombreCaballoSeleccionado}}</p>
                            <p class="inbox-item-text">{{this.propietarioCaballoSeleccionado}}</p>
                            <p class="inbox-item-date"> Mantener </p>
                        </div>
                        <div class="inbox-item">
                            <p class="inbox-item-author">{{this.nombreCaballoEliminado}}</p>
                            <p class="inbox-item-text">{{this.propietarioCaballoEliminado}}</p>
                            <p class="inbox-item-date">Borrar</p>
                        </div>    
                    </div> 
                    <div style="text-align: center">
                        <button type="button" class="btn btn-primary" *ngIf="this.caballoSeleccionado.length > 0 && this.caballoEliminado.length > 0" (click)="mergeCaballos(this.caballoEliminado, this.caballoSeleccionado)">
                            <i class="mdi mdi-call-merge mr-1"></i> 
                            <span>Fusionar</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
  </div>