<button class="btn btn-success" (click)="inputFile.click()">
  Import results (Pyramid CSV)
</button>
<input #inputFile class="d-none" type="file" (change)="onFileChange($any($event).target.files)" />

<div id="import-results-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="importResultsLabel" aria-hidden="true">
  <div class="modal-dialog modal-full-width">
      <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="importResultsLabel">Import results - Class {{ classNumber }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <table class="table table-sm mb-0">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Entry</th>
                  <th>Rider</th>
                  <th>Horse</th>
                  <th>Jumping Faults 1</th>
                  <th>Time Faults 1</th>
                  <th>Total Faults 1</th>
                  <th>Time 1</th>
                  <th>Jumping Faults 2</th>
                  <th>Time Faults 2</th>
                  <th>Total Faults 2</th>
                  <th>Time 2</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of rows" [ngClass]="{'text-danger': row?.errors}">
                  <td>{{ row?.rank }}</td>
                  <td>{{ row?.backNumber }}</td>
                  <td>{{ row?.riderName }}</td>
                  <td>{{ row?.horse }}</td>
                  <td>{{ row?.jumpFaultsR1 }}</td>
                  <td>{{ row?.timeFaultsR1 }}</td>
                  <td>{{ row?.totalFaultsR1 }}</td>
                  <td>{{ row?.timeR1 }}</td>
                  <td>{{ row?.jumpFaultsR2 }}</td>
                  <td>{{ row?.timeFaultsR2 }}</td>
                  <td>{{ row?.totalFaultsR2 }}</td>
                  <td>{{ row?.timeR2 }}</td>
                  <th>{{ row?.errors }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal" [disabled]="saving">Close</button>
              <button type="button" class="btn btn-success" [disabled]="saving || hasErrors" (click)="saveResults()">
                <span class="loader" *ngIf="saving"></span>
                Save
              </button>
          </div>
      </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
