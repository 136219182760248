import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
declare var $ :any;

@Component({
  selector: 'app-users-orders',
  templateUrl: './users-orders.component.html',
  styleUrls: ['./users-orders.component.css']
})
export class UsersOrdersComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public form: UntypedFormGroup;
  public emails: any[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private db: AngularFirestore,
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    this.emails = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getEmails();
  }

  private async getEmails(): Promise<void>{
    this.emails = await this.db.collection('srjt').doc('Orders').collection('orderUsers', ref => ref.where('show', '==', this.idConcurso)).get().toPromise().then(r => r.docs.map(d => d.data()));
    $('#loader').hide();
  }

  public submit(){
    if(this.form.valid){
      this.db.collection('srjt').doc('Orders').collection('orderUsers').doc(`${this.form.value.email}`).set({
        email: this.form.value.email,
        show: this.idConcurso
      }, { merge: true });
      this.form.setValue({
        email: ''
      });
      this.getEmails();
    }
  }

  public async eliminarEmail(email){
    await this.db.collection('srjt').doc('Orders').collection('orderUsers').doc(email).delete();
    this.getEmails();
  }
}
