import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {FormControl, Validators} from '@angular/forms';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'users-responsables',
  templateUrl: './inscripciones-remotas.component.html',
  styleUrls: ['./inscripciones-remotas.component.css']
})
export class InscripcionesRemotasComponent implements OnInit, AfterViewInit {
  public idConcurso: string;
  public dia: number;
  public mes: number;
  public anio: number;
  public fechahoy: string;
  public fechaSinFormato: Date;
  public idUsuario: string;
  public panelAgregar: boolean;
  public displayedColumns: Array<string>;
  public dataSource;
  public pruebas: Array<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private inscripcionesRemotasService: InscripcionesRemotasService,
  ) {
    this.route.params.subscribe(params => {
       this.idConcurso = params['idConcurso'];
       this.idUsuario = params['idUsuario'];
    });
    this.panelAgregar = false;
    this.displayedColumns = ['Responsable', 'Jinete', 'Caballo', 'Fechas', 'Pruebas'];
    this.dataSource = new MatTableDataSource();
    this.fechaSinFormato = new Date();
    this.dia = this.fechaSinFormato.getDate();
    this.mes = this.fechaSinFormato.getMonth() + 1;
    this.anio = this.fechaSinFormato.getFullYear();
    this.fechahoy = this.dia + ' / 0' + this.mes + ' / ' + this.anio;
  }

  ngOnInit() {
    this.getUsersResponsables();
  }

  ngAfterViewInit(){
    this.sort.sortChange.subscribe(
      () => {
        this.paginator.pageIndex = 0;
        const active = this.sort.active;
        const direction = this.sort.direction;
      }
    );

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getUsersResponsables(){
    this.inscripcionesRemotasService.getUsersResponsables(this.idConcurso).subscribe(
      response => {
        this.dataSource.data = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  public formatText(params) {
    let text:string;   
    text = JSON.stringify(params);
    if(text == '[]'){
      text = text.split('[]').join(' <p class="eliminar">No deberias verme</p>');
    }
    let padre = $('p.eliminar').parent();
    padre = $(padre).parent();
    padre = $(padre).parent();
    $(padre).css("display","none");
    text = text.split('":"').join(' : ');
    text = text.split('{"').join('PBA ');
    text = text.split('","').join(', PBA ');
    text = text.split('"}').join(' ');
    text = text.split(', ').join(' <br/> ');
    return text;
  }

  public scratched(params,params2){
    return params;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
