<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">Location Guide</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event)">
                                    <button *ngIf="locations.length > 0" class="btn btn-sm btn-success ml-3" (click)="save()"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Address 1</th>
                                            <th>Address 2</th>
                                            <th>Telephone</th>
                                            <th>Url</th>
                                            <th>Image</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let location of locations">
                                            <td>{{ location.name }}</td>
                                            <td>{{ location.description }}</td>
                                            <td>{{ location.address1 }}</td>
                                            <td>{{ location.address2 }}</td>
                                            <td>{{ location.telephone }}</td>
                                            <td>{{ location.url }}</td>
                                            <td>{{ location.image }}</td>
                                            <td>{{ location.categoria }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>