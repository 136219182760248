import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ResponsablesService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getResponsables(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}responsables/?c=${idConcurso}&usef`, { headers: headers });
  }

  public addResponsables(responsables, idConcurso): Observable<any> {
    responsables.idConcurso = idConcurso;
    const params = JSON.stringify(responsables);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/add/`, params, { headers: headers });
  }

  public updateResponsables(responsables): Observable<any> {
    const params = JSON.stringify(responsables);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/update/`, params, { headers: headers });
  }

  public deleteResponsables(responsables): Observable<any> {
    const params = JSON.stringify(responsables);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/delete/`, params, { headers: headers });
  }

  public getInfoResumen(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/resumen/`, params, { headers: headers });
  }

  public getResponsablesPorConcurso(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/porConcurso/`, params, { headers: headers });
  }

  public getResponsableById(idConcurso, idResponsable): Observable<any> {
    const params = JSON.stringify({ idConcurso, idResponsable });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/byId/`, params, { headers: headers });
  }

  public agregarCargo(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/agregarCargo/`, params, { headers: headers });
  }

  public eliminarCargo(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/eliminarCargo/`, params, { headers: headers });
  }

  public agregarPago(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/agregarPago/`, params, { headers: headers });
  }

  public agregarPagos(pagos): Observable<any> {
    const params = JSON.stringify({ pagos });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/agregarPagos/`, params, { headers: headers });
  }

  public anularPago(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/anularPago/`, params, { headers: headers });
  }

  public getConcursosTraspasos(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/concursosTraspasos/`, params, { headers: headers });
  }

  public guardarTraspaso(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/guardarTraspaso/`, params, { headers: headers });
  }

  public anularMulta(ibpc, motivo, idUsuario, idConcurso): Observable<any> {
    const params = JSON.stringify({ ibpc, motivo, idUsuario, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/anularMulta/`, params, { headers: headers });
  }

  public agregarConcepto(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/agregarConcepto/`, params, { headers: headers });
  }

  public eliminarConcepto(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/eliminarConcepto/`, params, { headers: headers });
  }

  public editarConcepto(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/editarConcepto/`, params, { headers: headers });
  }

  public getCuentaCaballo(idCaballo, idConcurso, idResponsable): Observable<any> {
    const params = JSON.stringify({ idCaballo, idConcurso, idResponsable });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/cuentaCaballo/`, params, { headers: headers });
  }

  public moverCuentaCaballo(idCaballo, idConcurso, idResponsable, idResponsableAnterior, idUsuario): Observable<any> {
    const params = JSON.stringify({ idCaballo, idConcurso, idResponsable, idResponsableAnterior, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/moverCuentaCaballo/`, params, { headers: headers });
  }

  public getPagosOnline(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/pagosOnline/`, params, { headers: headers });
  }

  public marcarPagoOnline(idComprobante, status): Observable<any> {
    const params = JSON.stringify({ idComprobante, status });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/marcarPagoOnline/`, params, { headers: headers });
  }

  public getResponsablesFiltrados(filtro, idConcurso): Observable<any> {
    const params = JSON.stringify({ filtro, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/query/`, params, { headers: headers });
  }

  public modificarReferenciaCargo(idCargo, notas): Observable<any> {
    const params = JSON.stringify({ idCargo, notas });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/modificarReferenciaCargo/`, params, { headers: headers });
  }

  public generarPaseDeSalida(idConcurso, idResponsable, idUsuario, caballos, comentarios): Observable<any> {
    const params = JSON.stringify({ idConcurso, idResponsable, idUsuario, caballos, comentarios });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/paseDeSalida/`, params, { headers: headers });
  }

  public getPase(idPase): Observable<any> {
    const params = JSON.stringify({ idPase });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}responsables/getPase/`, params, { headers: headers });
  }
}
