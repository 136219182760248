<div class="wrapper">
  <menu class="navbar-custom"></menu>
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div style="width: 100%; float: left; display: flex;">
      <div class="panel panel-default panel-binomios" style="margin-top:40px" [style.margin-left]="(panelAgregar) ? '5%': '5%'" [style.width]="(panelAgregar) ? '50%': '90%'">
        <div class="panel-heading">Inscripciones remotas ( {{fechahoy}} )</div>
        <mat-table #table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Responsable">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Responsable</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.responsable }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Jinete">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"> Jinete </mat-header-cell>
            <mat-cell *matCellDef="let row">{{ (row.jinete) ? row.jinete : '-' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Caballo">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"> Caballo </mat-header-cell>
            <mat-cell *matCellDef="let row">{{ (row.caballo) ? row.caballo : '-' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Fechas">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"> Fecha de inscripción</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ (row.fecha_inscripcion) ? row.fecha_inscripcion : '-' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Pruebas">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"> Pruebas </mat-header-cell>
            <mat-cell *matCellDef="let row" [style.text-decoration]="row.scratched == '1' ? 'line-through' : '' ">{{ (row.Prueba) ? row.Prueba : '-' }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
