<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content col-8 offset-2 mb-4">
            <!-- Intro -->
            <section>
                <h1>Introduction and Overview</h1>
                <p>
                    Thank you for choosing Equestrian Digital. Our Platform includes a variety of services such as
                    Office System, Online Entries, and Scoring (collectively, the "Services"). By using the Platform,
                    you enter into a binding contract with us. Please note that this overview does not fully summarize
                    the Agreement; you should read each section carefully. Important legal rights are highlighted in
                    bold, and it is crucial to review these sections thoroughly. The provisions in Section A apply
                    generally to the Platform, while Section B contains additional terms specific to Equestrian Digital
                    Software or Services.
                </p>
                <p>If you are acting on behalf of a company or client, you accept these terms for them, and "you" will
                    refer to you, your company, or your client.</p>
            </section>
            <section>
                <h1>Systems and Protections</h1>
                <p>
                    Equestrian Digital’s accounting systems are designed to safeguard our company and its users. Here’s
                    how we protect ourselves and you:
                </p>
                <ul>
                    <li>Data Security and Encryption</li>
                    <ul>
                        <li>Encryption: We use strong encryption protocols to ensure secure transmission of financial
                            data.</li>
                        <li>Data Backups: We perform weekly backups of all server data and back up our database four
                            times daily to protect against data loss and ensure continuity.</li>
                        <li>Access Controls: User permissions restrict access to sensitive financial information.</li>
                    </ul>
                    <li>Compliance and Legal Protections</li>
                    <ul>
                        <li>Legal Terms: Terms and conditions limit our liability regarding misuse or user errors.</li>
                    </ul>
                    <li>Financial Reporting and Reconciliation</li>
                    <ul>
                        <li>Accurate Reporting: Tools for precise financial reporting help avoid discrepancies.</li>
                    </ul>
                    <li>Support and Training</li>
                    <ul>
                        <li>Customer Support: Equestrian Digital offers comprehensive support to assist users with any
                            issues or questions during the use of the software. Our direct support team is available to
                            ensure you receive the assistance you need.</li>
                        <li>Training Resources: Tutorials, and guides help users effectively utilize our software and
                            minimize the risk of errors.</li>
                    </ul>
                    <li>Terms of Service and Limitations</li>
                    <ul>
                        <li>User Responsibility: Users are responsible for data accuracy, protecting Equestrian Digital
                            from liability.</li>
                        <li>Limitation of Liability: Our liability for financial loss is limited as described in the
                            Agreement.</li>
                    </ul>
                    <li>Insurance and Risk Management</li>
                    <ul>
                        <li>Business Insurance: We carry insurance for major risks, including data breaches and software
                            errors.</li>
                        <li>Risk Management Strategies: Proactive strategies are employed to mitigate potential issues.
                        </li>
                    </ul>
                </ul>

            </section>
            <section>
                <h1>Disclaimers</h1>
                <p>We do not provide any warranties about the Platform beyond those explicitly stated in this Agreement.
                    The Platform is provided "as-is," without implied warranties of merchantability, fitness for a
                    particular purpose, or non-infringement. We do not guarantee that the Platform will be error-free or
                    that data will be secure. Our liability is limited regarding data loss, accuracy, reliability, or
                    availability of the Platform.
                </p>
                <h2>Limitation of Liability</h2>
                <p>Our liability is limited when it comes to issues you may encounter with our Platform. </p>
                <p> Other than the rights and responsibilities described in this Agreement and as allowed by applicable
                    law, Equestrian Digital won't be responsible for any losses.</p>
                <p>
                    The total aggregate liability of Equestrian Digital and our third party providers, licensors,
                    distributors or suppliers arising out of or relating to this Agreement is limited to the greater of:
                    (1) the fees that you paid to use the relevant Service(s) in the 12 months before the breach or (2)
                    $100.</p>
                <p>
                    Equestrian Digital won't be responsible for the following:
                </p>
                <ol>
                    <li>Loss of data, profits, revenues, business opportunities, goodwill or anticipated savings;
                    </li>
                    <li>Indirect, incidental, or consequential loss;</li>
                    <li>Punitive damages; or</li>
                    <li>Damages relating to failures of telecommunications, the internet, electronic communications,
                        corruption, or security.</li>
                </ol>

                <p>
                    The above limitations apply even if the parties have been advised of the possibility of such
                    damages. This Agreement sets forth your exclusive remedy with respect to the Platform and its use.
                </p>

                <ol>
                    <li>Exclusions and Limitations:</li>
                    <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or
                        exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations
                        may not apply to you. In such jurisdictions, the company's liability will be limited to the greatest extent
                        permitted by  law.</p>
                    <li>Indemnification:</li>
                    <p>You agree to indemnify, defend, and hold harmless the company and its affiliates, officers,
                        directors, employees, and agents from any and all claims, damages, losses, liabilities, and expenses
                        (including reasonable attorneys' fees) arising from your use of the software, including any violation of
                        these terms or any third-party rights.
                    </p>
                </ol>

            </section>
            <section>
                <h1>Waiver</h1>
                <p>
                    If we waive some of our rights under this Agreement, it doesn't mean we waive our rights in other
                    circumstances.
                </p>
                <p>
                    Equestrian Digital's failure to act or enforce any of its rights does not constitute a waiver of any
                    of our rights.
                    Any waiver by Equestrian Digital of any of the provisions in the Agreement must be made in writing
                    and signed by a duly authorized officer of Equestrian Digital.</p>

                <h1>Assignment</h1>

                <p>You can't transfer this Agreement or your right to use the Platform to someone else without our
                    permission.</p>
                <p>
                    Equestrian Digital may assign or transfer this Agreement to any party at any time without notice to
                    you. You may not
                    assign your rights under this Agreement, by operation of law or otherwise, without our consent. Any
                    attempts to do so without our consent will be void.</p>

                <h1>Severability</h1>
                <p>If any provision of this Agreement is unlawful, void, or unenforceable for any reason, then that
                    provision will be severed and the remaining provisions will remain in full force and effect.</p>
            </section>
            <section>
                <h1>Contact Information</h1>
                <p>For any questions about the Platform or this Agreement, or if you need assistance with the software, please contact us at:
                    <a
                        href="mailto:support@equestrian.digital?subject=Support%20Request&body=Please%20describe%20your%20issue%20here.">ED
                        Support</a>
                </p>
            </section>
            <br><br><br>
        </div>
    </div>
</div>