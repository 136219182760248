import { AuthService } from '../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DivisionChartsService } from '../services/division-charts.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-division-charts',
  templateUrl: './division-charts.component.html',
  styleUrls: ['./division-charts.component.css']
})
export class DivisionChartsComponent implements OnInit {
  /*public entries: any = [];
  public classes: any = [];
  public division;
  public divisionCode;*/
  public divisionIds: any[];
  public showName: string;
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public idConcurso: string | boolean;
  public divisions: any[];

  constructor(private divisionChartsService: DivisionChartsService, private route: ActivatedRoute, private authService: AuthService, private router: Router) {
    this.divisionIds = this.route.snapshot.paramMap.get('id').split(',');
    this.showName = this.authService.getNombreConcurso();
    this.divisions = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso() && this.privilegios >= 200) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
   // this.getResults();
  }

  public async getResults(){
    const response = await this.divisionChartsService.getResults(this.divisionIds).toPromise().then(r => r, e => ({ error: true, message: e }));
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.divisions = response.divisions;
    /*this.division = response.division;
    this.divisionCode = response.divisionCode;
    this.classes = response.classes;
    this.entries = response.entries;*/
  }
}
