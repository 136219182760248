import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CaballoService } from 'src/app/services/caballo.service';
import { firstValueFrom } from 'rxjs';
import { PersonaService } from 'src/app/services/persona.service';


@Component({
  selector: 'app-form-horse',
  templateUrl: './form-horse.component.html',
  styleUrls: ['./form-horse.component.css']
})

export class FormHorseComponent implements OnInit, OnChanges {
  @Input() public horse_id: string | number;
  @Input() public showId: number;
  @Output() public onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

  public horse: any;
  public horseForm: UntypedFormGroup;
  public actions: any
  public token: string;
  public sessionData: any;
  public userId: string;
  //public showId: string | false;
  public loaders: any;
  public owners: any[] = [];
  public selectedHorse: string;
  public showPanel: boolean;
  public feiError: string;
  public usefError: string;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private caballoService: CaballoService,
    private personaService: PersonaService
  ) {
    this.actions = {
      registerNewHorse: false,
      updatingHorse: false,
    };
    this.horseForm = this.formBuilder.group({
      fei: new UntypedFormControl('', []),
      usef_id: new UntypedFormControl('', []),
      ushja_id: new UntypedFormControl('', []),
      fullname: new UntypedFormControl('', []),
      chip_id: new UntypedFormControl('', []),
      owner_id: new UntypedFormControl('', []),
      country_of_birth: new UntypedFormControl('', []),
      color_code: new UntypedFormControl('', []),
      color_complement: new UntypedFormControl('', []),
      breed: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      date_of_birth: new UntypedFormControl('', []),
      sire: new UntypedFormControl('', []),
      dam: new UntypedFormControl('', []),
      height: new UntypedFormControl('', []),
      height_category: new UntypedFormControl('', []),
      notas: new UntypedFormControl('', [])
    })


  }

  ngOnInit(): void {
  }

  //este método se ejecuta cada vez que el valor de la variable id_caballo cambia
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.horse_id) { //si el id_caballo cambia y es diferente de null
      this.actions.registerNewHorse = !this.horse_id;
      this.actions.updatingHorse = !!this.horse_id;
      this.horseForm.reset('');
      this.getHorseInfo(this.horse_id);
      return;
    }
  }

  public displayOwner(idPersona): string {
    if (!idPersona) {
      return '';
    }
    const owner = this.owners.find(p => p.id_persona == idPersona);

    return owner ? owner.fullname : idPersona;
  }

  public getPersonas(e) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.showId).subscribe(
        response => {
          if (!response.error) {
            this.owners = response.personas;
          } else {
           // $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          //$.NotificationApp.send("Error", 'It has not been possible to query the owners, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.owners = [];
    }
  }

  public async getHorseInfo(horse_id) {
    if(!horse_id) {
      return;
    }

    const { info, error } = await firstValueFrom(this.caballoService.getInfo(this.horse_id)).then(r => ({ info: r?.error ? null : r?.info, error: r?.error ? r?.message : null })).catch(e => ({ info: null, error: e?.message||e }));
    if (error) {
      console.log('Error getHorseInfo: ',error);
      return;
    }
    this.owners = [{
      id_persona: info.owner_id,
      fullname: info.owner_name
    }];

    this.horseForm.patchValue({
      fei: info?.fei,
      usef_id: info?.usef_id,
      ushja_id: info?.ushja_id,
      fullname: info?.name,
      chip_id: info?.chip_id,
      owner_id: info?.owner_id,
      country_of_birth: info?.country_of_birth,
      color_code: info?.color,
      color_complement: info?.color_complement,
      breed: info?.breed,
      gender: info?.gender,
      date_of_birth: info?.date_of_birth,
      sire: info?.sire,
      dam: info?.dam,
      height: info?.height,
      height_category: info?.height_category,
      notas: info?.notes
    });
  }

  public async getHorseFeiInfo() {
    console.log('Getting horse FEI info');
    try {
      const response = await firstValueFrom(this.caballoService.getFeiInfo(this.horseForm.value.fei));
      if (response.error) {
        this.feiError = response.message;
        console.log('FEI Error: ', response.message);
      } else {
        this.feiError = '';
        const caballo = response?.caballo ?? {};
        this.horseForm.patchValue({
          fullname: caballo.name || this.horseForm.value.fullname,
          chip_id: caballo.chipId || this.horseForm.value.chip_id,
          owner_id: caballo.owner || this.horseForm.value.owner_id,
          country_of_birth: caballo.countryOfOrigin || this.horseForm.value.country_of_birth,
          color_code: caballo.color || this.horseForm.value.color_code,
          color_complement: caballo.colorComplement || this.horseForm.value.color_complement,
          breed: caballo.breed || this.horseForm.value.breed,
          date_of_birth: caballo.dateOfBirth || this.horseForm.value.date_of_birth,
          sire: caballo.sire || this.horseForm.value.sire,
          dam: caballo.dam || this.horseForm.value.dam,
          gender: caballo.gender || this.horseForm.value.gender,
        });
      }
    } catch (error) {
      //this.handleError(error, "It has not been possible to perform the query.");
      console.log(error);
    }
  }

  public async getHorseUsefData() {
    const { usefData, error } = await firstValueFrom(this.caballoService.getUsefData(this.horseForm.value.usef_id, this.showId)).then(r => ({ usefData: r?.error ? null : r?.usefData, error: r?.error ? r?.message : null })).catch(e => ({ usefData: null, error: e?.message||e }));
    if (error) {
      this.usefError = error;
      console.log('USEF Error: ', error);
      return;
    }
    this.usefError = '';
    const caballo = usefData ?? {};
    this.horseForm.patchValue({
      fullname: caballo.name || this.horseForm.value.fullname,
      chip_id: caballo.chip || this.horseForm.value.chip_id,
      country_of_birth: caballo.countryOfOrigin || this.horseForm.value.country_of_birth,
      color_code: caballo.color || this.horseForm.value.color_code,
      breed: caballo.breed || this.horseForm.value.breed,
      gender: caballo.gender || this.horseForm.value.gender,
      date_of_birth: caballo.dateofBirth || this.horseForm.value.date_of_birth,
      sire: caballo.sire || this.horseForm.value.sire,
      dam: caballo.dam || this.horseForm.value.dam,
      owner_id: caballo.owner.id ? caballo.owner.id : this.horseForm.value.owner_id,
      fei: caballo.fei.trim() || this.horseForm.value.fei,
      height: caballo.height || this.horseForm.value.height
    });
  }

  public cancel() {
    this.onCancel.emit();
    this.horseForm.reset('');
  }

  public async submit() {
    //Validar que el valor de owner sea tomado desde la lista
    if(!this.owners.find(p => p.id_persona == this.horseForm.value.owner)){
      this.horseForm.get('owner_id').setValue('');
    }

    if(this.horseForm.valid) {
      const caballo = {
        id_caballo: this.horse_id||null,
        breed: this.horseForm.value.breed||'',
        chip_id: this.horseForm.value.chip_id||'',
        color_code: this.horseForm.value.color_code||'',
        color_complement: this.horseForm.value.color_complement||'',
        country_of_birth: this.horseForm.value.country_of_birth||'',
        dam: this.horseForm.value.dam||'',
        date_of_birth: this.horseForm.value.date_of_birth||'',
        fei: this.horseForm.value.fei||'',
        fullname: this.horseForm.value.fullname||'',
        gender: this.horseForm.value.gender||'',
        height: this.horseForm.value.height||'',
        height_category: this.horseForm.value.height_category||'',
        notas: this.horseForm.value.notas||'',
        owner_id: this.horseForm.value.owner_id||null,
        sire: this.horseForm.value.sire||'',
        usef_id: this.horseForm.value.usef_id||'',
        ushja_id: this.horseForm.value.ushja_id||'',
      };
      //Save/Edit Horse nest
      const { data, error } = await this.caballoService.save(caballo).then(r => ({ data: r?.data, error: null })).catch(e => ({ data: null, error: e?.message||e }));
      if(error){
        console.log('Error saving horse');
        return;
      }
      if(this.actions.updatingHorse){
        this.onUpdate.emit(data);
      } else if(this.actions.registerNewHorse){
        this.onCreate.emit(data);
      }
    } else {
      console.log('Form is invalid');
    }
  }

  // TODO: TEST FEI ID FORMAT
  // TODO: TEST USEF ID FORMAT

}
