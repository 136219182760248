<div class="row">
  <div class="col-sm-12 col-md-5">
    <ng-container *ngIf="total">
      <div style="padding-top: .85em;font-weight: 600;">Showing {{ from }} to {{ to }} of {{ totalItems }}.</div>
    </ng-container>
  </div>
  <div class="col-sm-12 col-md-7">
    <nav>
      <ul class="pagination justify-content-end">
        <li class="page-item" [ngClass]="{'disabled': current === 1}">
          <a class="page-link" href="javascript: void(0);" aria-label="Previous" [attr.tabindex]="current === 1 ? '-1' : null" (click)="onPrevious()">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <ng-container *ngFor="let page of pages;">
          <li class="page-item" [ngClass]="{'active': page === current}" (click)="onGoTo(page)">
            <a class="page-link" href="javascript: void(0);">{{ page }}</a>
          </li>
        </ng-container>
        <li class="page-item" [ngClass]="{'disabled': current === total}">
          <a class="page-link" href="javascript: void(0);" aria-label="Next" [attr.tabindex]="current === total ? '-1' : null" (click)="onNext()">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
