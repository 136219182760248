import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getProducts(): Observable<any>{
    const headers = { 'x-api-key': this.api_key };

    return this.http.get(`${environment.api.uri}productos/`, {headers: headers});
  }

  public save(product): Observable<any>{
    const params: string = JSON.stringify(product);
    const headers = { 'x-api-key': this.api_key };

    return this.http.post(`${environment.api.uri}productos/save/`, params, {headers: headers});
  }

  public edit(product): Observable<any>{
    const params: string = JSON.stringify(product);
    const headers = { 'x-api-key': this.api_key };

    return this.http.post(`${environment.api.uri}productos/edit/`, params, {headers: headers});
  }

  public concept(idConcurso): Observable<any>{
    const params: string = JSON.stringify({ idConcurso });
    const headers = { 'x-api-key': this.api_key };

    return this.http.post(`${environment.api.uri}productos/concepto/`, params, {headers: headers});
  }

  public updateQty(product): Observable<any>{
    const params: string = JSON.stringify(product);
    const headers = { 'x-api-key': this.api_key };

    return this.http.post(`${environment.api.uri}productos/updateQty/`, params, {headers: headers});
  }
  public getMerchSummary(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}productos/summary/`, params, {headers: headers});
  }
}
