import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class BinomioService extends Api{

  constructor(private http: HttpClient) {
    super();
  }

  public getBinomios(idConcurso): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}binomios/main/?c=${idConcurso}`, {headers: headers});
  }

  public addBinomio(binomio): Observable<any>{
    const params = JSON.stringify(binomio);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/add/`, params, {headers: headers});
  }

  public updateBinomio(binomio): Observable<any>{
    const params = JSON.stringify(binomio);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.put(`${environment.api.uri}binomios/update/`, params, {headers: headers});
  }

  public inscribir(bpc): Observable<any>{
    const params = JSON.stringify(bpc);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/inscribir/`, params, {headers: headers});
  }

  public getInformacionBinomio(idConcurso,idBinomio,idResponsable,idCaballo): Observable<any>{
    const params = JSON.stringify({ id_concurso: idConcurso, id_binomio: idBinomio, id_responsable: idResponsable, id_caballo: idCaballo });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/conceptos_binomio/`, params, {headers: headers});
  }

  public enviarTransaccion(activo,idTransaccion,idConcepto,idUsuario,idConcurso,idResponsable,idCaballo,montoConcepto): Observable<any>{
    const params = JSON.stringify({accion: activo, id_transaccion: idTransaccion, id_concepto: idConcepto, id_usuario: idUsuario, id_concurso: idConcurso, id_responsable: idResponsable, id_caballo: idCaballo, monto_concepto: montoConcepto});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/transacciones/`, params, {headers: headers});
  }

  public eliminarBinomioNP(idBinomio, ipc): Observable<any>{
    const params = JSON.stringify({idBinomio, ipc});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/eliminarBinomioNP/`, params, {headers: headers});
  }

  public eliminarBinomio(idBinomio, idConcurso): Observable<any>{
    const params = JSON.stringify({idBinomio, idConcurso});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/delete/`, params, {headers: headers});
  }
}
