import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class InscripcionesRemotasService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getUsersResponsables(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}users_responsables/?c=${idConcurso}`, { headers: headers });
  }

  public getUsuariosOnline(): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}inscripciones-online/usuarios-online/?c=1`, { headers: headers });
  }

  public getUsuariosSinResponsable(): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}inscripciones-online/usuarios-sin-responsable/`, { headers: headers });
  }

  public getUsuariosActivos(): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}inscripciones-online/usuarios-activos/`, { headers: headers });
  }

  public getInformacionResponsables(nombre, correo, accion): Observable<any> {
    const params = JSON.stringify({ nombre, correo, accion });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/responsables/`, params, { headers: headers });
  }

  public accionesUsuarioActivo(idActual, nombreActual, usuarioActual, idNuevo, nombreNuevo, usuarioNuevo, accion): Observable<any> {
    const params = JSON.stringify({ idActual, nombreActual, usuarioActual, idNuevo, nombreNuevo, usuarioNuevo, accion });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/usuarios-activos/actualizar-informacion/`, params, { headers: headers });
  }

  public accionesUsuarioOnline(id, nombre, correo, accion): Observable<any> {
    const params = JSON.stringify({ id_user_responsable: id, nombre: nombre, correo: correo, accion: accion });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/acciones-usuario/`, params, { headers: headers });
  }
  public getSolicitudesAccepted(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/solicitudes-accepted/`, params, { headers: headers });
  }
  public getSolicitudesPendientes(organizer, status, showId?): Observable<any> {
    let params = { organizer, status };
    if(showId) params['showId'] = showId;
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.get(`${environment.api.nest.uri}online-entries`, { headers, params });
  }

  public getSolicitudPendiente(idSolicitud): Observable<any> {
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.get(`${environment.api.nest.uri}online-entries/${idSolicitud}`, { headers: headers });
  }

  public actualizarPagoSolicitud(idSolicitud, pago, estatus): Observable<any> {
    const params = JSON.stringify({ idSolicitud, pago, estatus });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/actualizar-pago-solicitud/`, params, { headers: headers });
  }

  public getFormatosSolicitudesPendientes(ids, idConcurso): Observable<any> {
    const params = JSON.stringify({ ids, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/formato-solicitudes-pendientes/`, params, { headers: headers });
  }

  public aceptarSolicitud(solicitud, cucarda, idUsuario): Observable<any> {
    const params = JSON.stringify({ solicitud, cucarda, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/aceptar-solicitud-inscripcion/`, params, { headers: headers });
  }

  public markAsSigned(idSolicitud): Observable<any> {
    const params = JSON.stringify({ idSolicitud });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/marcar-solicitud-firmada/`, params, { headers: headers });
  }

  public markAsPrinted(idSolicitud): Observable<any> {
    const params = JSON.stringify({ idSolicitud });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/marcar-solicitud-impresa/`, params, { headers: headers });
  }

  public markAsCancelled(idSolicitud): Observable<any> {
    const params = JSON.stringify({ idSolicitud });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/marcar-solicitud-cancelada/`, params, { headers: headers });
  }


  public sendSignatureMails(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/send-signature-mails/`, params, { headers: headers });
  }

  public getTrainerReservations(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/trainer-reservations/`, params, { headers: headers });
  }

  public getTrainerReservation(id): Observable<any> {
    const params = JSON.stringify({ id });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/trainer-reservation/`, params, { headers: headers });
  }

  public acceptTrainerReservation(id, cargos, idEntrenador, name, idConcurso, idUsuario): Observable<any> {
    const params = JSON.stringify({ id, cargos, idEntrenador, name, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/accept-trainer-reservation/`, params, { headers: headers });
  }


  public chaseInquiry(idConcurso, retryTrace): Observable<any> {
    const params = JSON.stringify({ idConcurso, retryTrace });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}chase/inquiry/`, params, { headers: headers });

  }

  public getUnassignedPayments(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/unassigned-payments/`, params, { headers: headers });

  }

  public refundPayment(idConcurso, txRefNum, retryTrace, amount): Observable<any> {
    const params = JSON.stringify({ idConcurso, txRefNum, retryTrace, amount });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/refund-payment/`, params, { headers: headers });

  }

  public agregarPago(idSolicitud): Observable<any> {
    const params = JSON.stringify({ idSolicitud });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/agregar-pago-solicitud/`, params, { headers: headers });
  }

  public resumenCaballerizas(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/caballerizas/`, params, { headers: headers });
  }

  public getEntryFromBackup(paymentId): Observable<any> {
    const params = JSON.stringify({ paymentId });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/create-entry-from-backup/`, params, { headers: headers });
  }

  public createEntryFromBackup(backup): Observable<any> {
    const params = JSON.stringify(backup);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}inscripciones-online/solicitud-inscripcion/`, params, { headers: headers });
  }
}
