<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="row w-100 mt-3 d-print-none">
        <div class="col-12">
          <form class="form-inline">
            <div class="form-group mr-3">
              <label class="mr-3">Day</label>
              <select class="form-control" [(ngModel)]="diaSeleccionado" [ngModelOptions]="{standalone: true}">
                <option value=""></option>
                <option [value]="dia" *ngFor="let dia of dias">{{ dia | date }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="mr-3">Ring</label>
              <select class="form-control" [(ngModel)]="pistaSeleccionada" [ngModelOptions]="{standalone: true}">
                <option value=""></option>
                <option [value]="pista" *ngFor="let pista of pistas">{{ pista }}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <!-- Incluir NGFOR aqui, debe ser uno de estos por cada prueba. -->
      <ng-container *ngFor="let prueba of pruebas">
        <ng-container *ngIf="diaSeleccionado == prueba.fecha">
          <ng-container *ngIf="pistaSeleccionada == prueba.pista">
            <div class="w-100 judgeCard"
              *ngIf="prueba.nombreModalidad == 'Hunters' || prueba.nombreModalidad == 'Equitation'  || prueba.nombreModalidad == 'Hunter Model'|| prueba.nombreModalidad == 'Under Saddle' || prueba.nombreModalidad == 'Hunters - Under Saddle' || prueba.nombreModalidad == 'Hunters Two Rounds'; else jumperCard">
              <div class="row">
                <div class="col-12 text-center">
                  <h3>{{ nombreConcurso }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">Class: {{ prueba.numero }} - {{ prueba.prueba }}</div>
                <div class="col-4">Ring: {{ prueba.pista }}</div>
                <div class="col-4">Date: {{ prueba.fecha | date }}</div>
              </div>
              <div class="row">
                <div class="col-4">Judge Note:</div>
                <div class="col-4">Note: <strong> {{prueba.description}}</strong></div>
                <div class="col-4">Ribbons:</div>
              </div>
              <div class="table mb-0">
                <table class="table table-bordered table-sm mb-0">
                  <tbody>
                    <tr>
                      <td>1st</td>
                      <td>2nd</td>
                      <td>3rd</td>
                      <td>4th</td>
                      <td>5th</td>
                      <td>6th</td>
                      <td>7th</td>
                      <td>8th</td>
                      <td>9th</td>
                      <td>10th</td>
                      <td>11th</td>
                      <td>12th</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table table-bordered">
                <!-- Header-->
                <thead>
                  <tr>
                    <td colspan="2"> <b>Entry</b> </td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                    <td colspan="4" style="min-width:130px"><b>Notes</b></td>
                    <td class="text-center">
                      < 60s</td>
                    <td class="text-center">70s</td>
                    <td class="text-center">80s</td>
                    <td class="text-center">90s</td>
                    <td colspan="2"><b>Average</b> </td>
                    <td colspan="2"><b>Order</b> </td>
                  </tr>
                </thead>
                <tbody>


                  <tr>
                    <td colspan="2"><small>1</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"><small>5</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"><small>10</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"><small>15</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"><small>20</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"><small>25</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>

                </tbody>

              </table>
              <div class="row">
                <div class="col-12 text-center">Judge's Signature: __________________________________________</div>
                <br>
                <br>
              </div>
            </div>
            <ng-template #jumperCard>
              <div class="w-100 judgeCard jumpers">
                <div class="row">
                  <div class="col-12 text-center">
                    <h3>{{ nombreConcurso }}</h3>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">Class: <b>{{ prueba.numero }} - {{ prueba.prueba }}</b> </div>
                  <div class="col-3">Ring: <b>{{ prueba.pista }}</b> </div>
                  <div class="col-4">Date: {{ prueba.fecha | date }}</div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-3">Height: {{prueba.altura}}</div>
                      <div class="col-5">Table: {{prueba.claveModalidad}}</div>
                      <div class="col-3">Prize Money: {{prueba.premio}}</div>
                    </div>
                    <div class="row">
                      <div class="col-6">Times Allowed: _______________</div>
                      <div class="col-6">Jump Off: _____________________</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-2">1 ______ </div>
                      <div class="col-2">2 ______ </div>
                      <div class="col-2">3 ______ </div>
                      <div class="col-2">4 ______ </div>
                      <div class="col-2">5 ______ </div>
                      <div class="col-2">6 ______ </div>
                      <div class="col-2">7 ______ </div>
                      <div class="col-2">8 ______ </div>
                      <div class="col-2">9 ______ </div>
                      <div class="col-2">10 ______ </div>
                      <div class="col-2">11 ______ </div>
                      <div class="col-2">12 ______ </div>
                    </div>

                  </div>

                </div>
                <div class="row">
                  <div class="col-8">Judge Note:</div>

                </div>

                <table class="table table-bordered table-sm">
                  <!-- Header-->
                  <thead>
                    <tr>
                      <td><b>Entry</b> </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td colspan="2">.</td>
                      <td colspan="2">.</td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td colspan="2">.</td>
                      <td colspan="2">.</td>


                    </tr>
                  </thead>
                  <tbody>


                    <tr *ngFor='let repeat of repeats'>
                      <td><small *ngIf="(repeat+1) % 5 == 0">{{repeat + 1}} </small></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td colspan="2"></td>
                      <td colspan="2"></td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td colspan="2"></td>
                      <td colspan="2"></td>
                    </tr>



                  </tbody>

                </table>
                <div class="row">
                  <div class="col-12 text-center">Judge's Signature: __________________________________________</div>

                </div>

              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Tarjeta adicional en blanco -->




      <div *ngIf="diaSeleccionado == ''">
        <div class="w-100 judgeCard jumpers">
          <div class="row">
            <div class="col-12 text-center">
              <h3>{{ nombreConcurso }} </h3>
            </div>
          </div>

          <div class="row">
            <div class="col-3">Class: <b></b> </div>
            <div class="col-3">Ring: <b></b> </div>
            <div class="col-4">Date: </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-3">Height: </div>
                <div class="col-5">Table: </div>
                <div class="col-3">Prize Money: </div>
              </div>
              <div class="row">
                <div class="col-6">Times Allowed: _______________</div>
                <div class="col-6">Jump Off: _____________________</div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2">1 ______ </div>
                <div class="col-2">2 ______ </div>
                <div class="col-2">3 ______ </div>
                <div class="col-2">4 ______ </div>
                <div class="col-2">5 ______ </div>
                <div class="col-2">6 ______ </div>
                <div class="col-2">7 ______ </div>
                <div class="col-2">8 ______ </div>
                <div class="col-2">9 ______ </div>
                <div class="col-2">10 ______ </div>
                <div class="col-2">11 ______ </div>
                <div class="col-2">12 ______ </div>
              </div>

            </div>

          </div>
          <div class="row">
            <div class="col-8">Judge Note:</div>

          </div>

          <table class="table table-bordered table-sm">
            <!-- Header-->
            <thead>
              <tr>
                <td><b>Entry</b> </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td colspan="2">.</td>
                <td colspan="2">.</td>

                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td colspan="2">.</td>
                <td colspan="2">.</td>


              </tr>
            </thead>
            <tbody>


              <tr *ngFor='let repeat of repeats'>
                <td><small *ngIf="(repeat+1) % 5 == 0">{{repeat + 1}} </small></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td colspan="2"></td>
                <td colspan="2"></td>

                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td colspan="2"></td>
                <td colspan="2"></td>
              </tr>



            </tbody>

          </table>
          <div class="row">
            <div class="col-12 text-center">Judge's Signature: __________________________________________</div>

          </div>

        </div>
      </div>



    </div>
  </div>
</div>