import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { PruebaService } from '../services/prueba.service';
import { ResultadoService } from '../services/resultado.service';
import { TraduccionPipe } from '../pipes/traduccion.pipe';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FilterResultadosPipe } from '../pipes/resultados.pipe';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
declare var $ :any;

@Component({
    selector: 'resultados',
    templateUrl: 'resultados.component.html',
    styleUrls: ['resultados.component.css'],
    providers: [
        TraduccionPipe,
        CurrencyPipe,
        DatePipe,
        FilterResultadosPipe
    ]
})

export class ResultadosComponent implements OnInit {
    private token: string;
    private sessionData: any;
    private idUsuario;
    public idConcurso: string | boolean;
    public nombreConcurso: string;
    public pruebas: Array<any>;
    public resultados: Array<any>;
    public ipc: string;
    public fases: string;
    public prueba: any;
    public fecha;
    public categoriaSeleccionada: string;
    public privilegios: number;
    public totalResultados: number;

    constructor(
        private router: Router,
        private authService: AuthService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private pruebaService: PruebaService,
        private resultadoService: ResultadoService,
        private traduccionPipe: TraduccionPipe,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private resultadosPipe: FilterResultadosPipe
    ){
        this.idUsuario = '';
        this.token = '';
        this.sessionData = {};
        this.idConcurso = '';
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.pruebas = [];
        this.resultados = [];
        this.ipc = '';
        this.fases = '';
        this.prueba = {};
        this.fecha = new Date();
        this.categoriaSeleccionada = '';
        this.privilegios = 0;
        this.totalResultados = 0;
    }

    ngOnInit() {
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
            this.privilegios = this.sessionData.privilegios;
        }
        if(this.authService.validarConcurso()){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.iconRegistry.addSvgIcon('print', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/print.svg'));
        this.iconRegistry.addSvgIcon('csv', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/csv.svg'));
        this.getPruebas();
    }

    private getPruebas(){
        this.pruebaService.getPruebasList(this.idConcurso).subscribe(
            response => {
                $('#loader').hide();
                if(!response.error){
                    this.pruebas = response.pruebas;
                } else{
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public cambioPrueba(select){
        this.ipc = select.currentTarget.value;
        const index = this.pruebas.findIndex(prueba => {
            return prueba.ipc == this.ipc;
        })
        this.prueba = this.pruebas[index];
        this.prueba.categorias = this.prueba.overall ? ['Overall', 'Jr/Am'] : [];
        this.categoriaSeleccionada = this.prueba.categorias.length > 1 ? 'Overall' : '';

        this.resultadoService.getResultados(this.ipc).subscribe(
            response => {
                if(!response.error){
                    this.resultados = response.resultados;
                    this.totalResultados = this.filtroOverall().length;
                    /*
                    if(this.prueba.premio > 0 ) {
                        this.resultados = this.resultados.filter(resultado => resultado.premio > '0');
                    }
                    */
                    this.fases = this.resultados.length > 0 ? this.resultados[0].fases : '0';
                } else{
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $.NotificationApp.send("Error", 'They have not been able to consult the class results.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public convertToCsv(){
        if(this.ipc && this.resultados.length > 0){
            const fases = this.resultados[0].fases;
            let titulos = [];
            if(fases == '1'){
                titulos = [
                    this.traduccionPipe.transform('"Rank"', []),
                    this.traduccionPipe.transform('"Back #"', []),
                    this.traduccionPipe.transform('"Reg"', []),
                    this.traduccionPipe.transform('"Rider"', []),
                    this.traduccionPipe.transform('"Country"', []),
                    this.traduccionPipe.transform('"Reg"', []),
                    this.traduccionPipe.transform('"Horse"', []),
                    this.traduccionPipe.transform('"Owner"', []),
                    this.traduccionPipe.transform('"Trainer"', []),
                    this.traduccionPipe.transform('"Order"', []),
                    this.traduccionPipe.transform('"F"', []),
                    this.traduccionPipe.transform('"T"', [])
                ];
                if(this.prueba.premio > 0)
                    titulos.push(this.traduccionPipe.transform('"Premio"', []));
            } else if(fases == '2'){
                titulos = [
                    this.traduccionPipe.transform('"Rank"', []),
                    this.traduccionPipe.transform('"Back #"', []),
                    this.traduccionPipe.transform('"Reg"', []),
                    this.traduccionPipe.transform('"Rider"', []),
                    this.traduccionPipe.transform('"Country"', []),
                    this.traduccionPipe.transform('"Reg"', []),
                    this.traduccionPipe.transform('"Horse"', []),
                    this.traduccionPipe.transform('"Owner"', []),
                    this.traduccionPipe.transform('"Trainer"', []),
                    this.traduccionPipe.transform('"Order"', []),
                    this.traduccionPipe.transform('"F"', []),
                    this.traduccionPipe.transform('"T"', []),
                    this.traduccionPipe.transform('"F"', []),
                    this.traduccionPipe.transform('"T"', [])
                ];
                if(this.prueba.premio > 0)
                    titulos.push(this.traduccionPipe.transform('"Premio"', []));
            }

            let csv: any = this.resultados.map(resultado => {
                if(fases == '1'){
                    return [
                        `"${resultado.posicion}"`,
                        `"${resultado.cucarda}"`,
                        `"${resultado.usef_jinete}"`,
                        `"${resultado.nombre_jinete} ${resultado.apellido_jinete}"`,
                        `"${resultado.pais}"`,
                        `"${resultado.usef_caballo}"`,
                        `"${resultado.caballo}"`,
                        `"${resultado.propietario}"`,
                        `"${resultado.entrenador}"`,
                        `"${resultado.entrada}"`,
                        `"${resultado.calificado1 == '1' && resultado.status1 == '0' ? resultado.faltas1 : ''}"`,
                        `"${!(resultado.calificado1 == '1') ? '' : (resultado.status1 == '0' ? resultado.tiempo1 : resultado.status1)}"`,
                        `"${this.currencyPipe.transform(resultado.premio) ? this.currencyPipe.transform(resultado.premio) : ''}"`
                    ].join(',');
                } else if(fases == '2'){
                    return [
                        `"${resultado.posicion}"`,
                        `"${resultado.cucarda}"`,
                        `"${resultado.usef_jinete}"`,
                        `"${resultado.nombre_jinete} ${resultado.apellido_jinete}"`,
                        `"${resultado.pais}"`,
                        `"${resultado.usef_caballo}"`,
                        `"${resultado.caballo}"`,
                        `"${resultado.propietario}"`,
                        `"${resultado.entrenador}"`,
                        `"${resultado.entrada}"`,
                        `"${resultado.calificado1 == '1' && resultado.status1 == '0' ? resultado.faltas1 : ''}"`,
                        `"${!(resultado.calificado1 == '1') ? '' : (resultado.status1 == '0' ? resultado.tiempo1 : resultado.status1)}"`,
                        `"${resultado.calificado2 == '1' && resultado.status2 == '0' ? resultado.faltas2 : ''}"`,
                        `"${!(resultado.calificado2 == '1') ? '' : (resultado.status2 == '0' ? resultado.tiempo2 : resultado.status2)}"`,
                        `"${this.currencyPipe.transform(resultado.premio) ? this.currencyPipe.transform(resultado.premio) : ''}"`
                    ].join(',');
                }

                return '';
            });
            csv.unshift(titulos.join(','));
            csv = csv.join('\r\n');

            let link = document.createElement('a');
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = `${this.prueba.numero} - ${this.prueba.nombre}.csv`; //Nombre del archivo
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else{
            $.NotificationApp.send("Error", 'You have not selected a class.', 'bottom-right', '#fa5c7c', 'error');
        }

    }

    public formatoSuma239(suma: number): number{
        return +suma.toFixed(12);
    }

    public seleccionarCategoria(categoria: string): void{
        this.categoriaSeleccionada = categoria;
    }

    public filtroOverall(): any[]{
        if(this.prueba.overall && this.categoriaSeleccionada != 'Overall'){
            return this.resultados.filter(b => b.categoria.toLowerCase() == 'amateur' || b.categoria.toLowerCase() == 'junior');
        }
        return this.resultados.filter(resultado => resultado.status1 != 'DNS');
    }

    public s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    public exportarExcel(){
        const fases = this.resultados[0].fases;
        const categorias = this.prueba.overall ? ['Overall', 'Jr/Am'] : ['Overall'];
        const wb = {
            SheetNames:[],
            Sheets:{}
        };
        const wopts: XLSX.WritingOptions = {
            bookType: 'xlsx',
            bookSST: false,
            type:'binary'
        };

        for(const categoria of categorias){

            const resultados = this.resultados.filter(b => b.status1 != '' && (categoria != 'Overall' ? (b.categoria.toLowerCase() == 'amateur' || b.categoria.toLowerCase() == 'junior') : true)).map(resultado => {
                let res = {};
                res['Rank'] = resultado.posicion;
                res['Back #'] = resultado.cucarda;
                //res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'Reg' : 'FEI'}`] = resultado.reg_jinete;
                res['Rider'] = `${resultado.nombre_jinete} ${resultado.apellido_jinete}`;
                //res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'Club' : 'Country'}`] = `${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? resultado.club.toUpperCase() : resultado.pais.toUpperCase()}`;
                //res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'Reg ' : 'FEI '}`] = resultado.reg_caballo;
                res['Horse'] = resultado.caballo;
                res['Order'] = resultado.entrada;
                res['F | T'] = `${resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.faltas1 + `${this.prueba.modalidad != '8' ? ' | ' : ' + '}` + resultado.tiempo1 : resultado.status1) : ''}`;
                if(this.fases == '2')
                    res['F | T '] = `${resultado.calificado2 == '1' ? (resultado.status2 == '0' ? resultado.faltas2 + ' | ' + resultado.tiempo2 : resultado.status2) : ''}`;
                if(this.prueba.modalidad == '8')
                    res['Result'] = `${resultado.calificado1 == '1' ? (resultado.status1 == '0' ? this.formatoSuma239(resultado.faltas1 + resultado.tiempo1) : resultado.status1) : ''}`
                if(this.prueba.premio > 0)
                    res['Prize'] = `${this.currencyPipe.transform(resultado.premio) ? this.currencyPipe.transform(resultado.premio) : ''}`

                return res;
            }).map((b, i) => {
                b['Rank'] = categoria != 'Overall' ? i+1 : b['Rank'];

                return b;
            });

            console.log(this.resultados);
            const ws = XLSX.utils.json_to_sheet(resultados);
            wb.SheetNames.push(`${categoria ? categoria.replace('/', '-') : ''}`);
            wb.Sheets[`${categoria ? categoria.replace('/', '-') : ''}`] = ws;
        }

        const wbout = XLSX.write(wb,wopts);

        saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), `${this.prueba.numero} - ${this.prueba.nombre}.xlsx`);
    }

    public exportarXML(){
        console.log('Exporting XML');
        console.log('Prueba: ', this.prueba);
        //const fecha = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
        const fecha = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss+00:00');
        const fechaPrueba = `${this.prueba.inicioFei}T00:00:00+00:00`;
        const showCountry = this.prueba.organizingCountry;
        const showStart = this.prueba.inicioConcursoFei;
        const showEnd = this.prueba.finConcursoFei;
        const eventCode = this.prueba.eventCode;
        const numPrueba = this.prueba.numero.replace('#', '');
        const nombrePrueba = this.prueba.nombre;
        const monedaPremio = this.prueba.prizeCurrency;
        const claveModalidad = `${this.prueba.claveModalidad}${this.prueba.gp ? '-GP' : ''}${this.prueba.world_cup ? '-W' : ''}`;
        const isWNominated = this.prueba.world_cup ? 'true' : 'false';
        const competitionID = this.prueba.competitionID;
        const competitionIDArray = competitionID.split('_');
        const showId = `${competitionIDArray[0]}_${competitionIDArray[1]}_${competitionIDArray[2]}`;
        //const eventId = this.prueba.eventId;
        const eventId = competitionID.substring(0, competitionID.length - 3);
        const venueName = this.prueba.venueName;

        const resultados = this.resultados.map(resultado => {
            const premio = isNaN(parseFloat(resultado.premio)) ? 0 : parseFloat(resultado.premio);

            if(resultado.status1 == 'WD' || resultado.status1 == 'DNS'){
                console.log('Skipping: ', resultado);
                return ''
            } else {
                console.log('Processing: ', resultado);

            return `<Participation>
                      <Athlete FEIID="${resultado.reg_jinete}" FirstName="${resultado.nombre_jinete}" FamilyName="${resultado.apellido_jinete}" CompetingFor="${resultado.pais}" />
                      <Horse FEIID="${resultado.reg_caballo.toUpperCase()}" Name="${resultado.caballo}" />
                      ${premio ? `<PrizeMoney Value="${premio}" />` : ''}
                      <Complement IsWNominated="${isWNominated}" />
                      <Position Status="${resultado.status1 == '0' && !(this.prueba.modalidad == '41' && resultado.status2 != '0') ? 'R' : (this.prueba.modalidad == '41' && resultado.status2 != '0') ? (resultado.status2 == 'DNS' ? 'WD' : (resultado.status2 == 'E' ? 'EL' : (resultado.status2 == 'R' ? 'RET' : resultado.status2))) : (resultado.status1 == 'DNS' ? 'WD' : (resultado.status1 == 'E' ? 'EL' : (resultado.status1 == 'R' ? 'RET' : resultado.status1)))}" ${resultado.status1 != '0' || (this.prueba.modalidad == '41' && resultado.status2 != '0') ? '' : `Rank="${resultado.posicion}"`} ${resultado.status1 == 'DSQ' ? 'Complement="Report Judge"' : ''} />
                      <Round Number="1">
                        <Result${resultado.status1 != '0' ? ` Status="${resultado.status1 == 'DNS' ? 'WD' : (resultado.status1 == 'E' ? 'EL' : (resultado.status1 == 'R' ? 'RET' : resultado.status1))}"` : ''} ${resultado.status1 == '0' && this.prueba.modalidad != '8' ? `TotalFaults="${resultado.faltas1}"` : ''} ${resultado.status1 == '0' ? `Time="${this.prueba.modalidad == '8' ? this.formatoSuma239(resultado.tiempo1 + resultado.faltas1) : resultado.tiempo1}"` : ''} />
                      </Round>
                      ${resultado.calificado2 == '1' ? `<${this.prueba.modalidad == '4' || this.prueba.modalidad == '40' || this.prueba.modalidad == '41' || this.prueba.modalidad == '42' ? 'Round Number="2"' : 'JumpOff Number="1"'}>
                        <Result${resultado.status2 != '0' ? ` Status="${resultado.status2 == 'DNS' ? 'WD' : (resultado.status2 == 'E' ? 'EL' : (resultado.status2 == 'R' ? 'RET' : resultado.status2))}"` : ''} ${resultado.status2 == '0' ? `TotalFaults="${resultado.faltas2}"` : ''} ${resultado.status2 == '0' ? `Time="${resultado.tiempo2}"` : ''} />
                      </${this.prueba.modalidad == '4' || this.prueba.modalidad == '40' || this.prueba.modalidad == '41' || this.prueba.modalidad == '42' ? 'Round' : 'JumpOff'}>` : ''}
                    </Participation>`;
                      }
        }).join('\n');


        const xml = `<?xml version="1.0" encoding="utf-8"?>
<HorseSport xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.fei.org/Result">
  <Generated Date="${fecha}" Software="EquestrianDigital" SoftwareVersion="4.1" Organization="ED" />
  <EventResult>
    <Show FEIID="${showId}" StartDate="${showStart}" EndDate="${showEnd}">
      <Venue Name="${venueName}" Country="${showCountry}" />
      <JumpingEvent FEIID="${eventId}" Code="${eventCode}" NF="${showCountry}" StartDate="${showStart}" EndDate="${showEnd}">
        <Competitions>
          <Competition FEIID="${competitionID}" ScheduleCompetitionNr="${numPrueba}" Rule="${claveModalidad}" Name="${nombrePrueba}" DateTime="${fechaPrueba}" Team="false">
            <Description>
              <Prizes Currency="${monedaPremio}" />
            </Description>
            <ParticipationList>
              ${resultados}
            </ParticipationList>
          </Competition>
        </Competitions>
      </JumpingEvent>
    </Show>
  </EventResult>
</HorseSport>`;

        const encodedUri = encodeURI(`data:xml;charset=utf-8,${xml}`);
        const link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${numPrueba}.xml`);
        link.innerHTML= "Click Here to download";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
