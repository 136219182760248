<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row mt-3">
                    <div class="col-4">
                        <div class="card">
                            <div class="card-body">
                                <table class="table table-sm table-hover table-striped" id="personas">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-8" *ngIf="personaSeleccionada.ids">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="form" (ngSubmit)="merge()">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ personaSeleccionada.name }}</h4>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label># FEI</label>
                                                <select name="fei" id="fei" class="form-control form-control-sm" formControlName="fei" *ngIf="personaSeleccionada?.feis?.length > 1">
                                                    <option [value]="fei" *ngFor="let fei of personaSeleccionada.feis">{{ fei }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.fei" *ngIf="!(personaSeleccionada?.feis?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label># USEF</label>
                                                <select name="usef" id="usef" class="form-control form-control-sm" formControlName="usef" *ngIf="personaSeleccionada?.usefs?.length > 1">
                                                    <option [value]="usef" *ngFor="let usef of personaSeleccionada.usefs">{{ usef }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.usef" *ngIf="!(personaSeleccionada?.usefs?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Amateur Pro Status</label>
                                                <select name="categoria" id="categoria" class="form-control form-control-sm" formControlName="categoria" *ngIf="personaSeleccionada?.categorias?.length > 1">
                                                    <option [value]="categoria" *ngFor="let categoria of personaSeleccionada.categorias">{{ categoria }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.categoria" *ngIf="!(personaSeleccionada?.categorias?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3 d-flex">
                                            <div class="form-group my-auto">
                                                <div class="custom-control custom-checkbox mr-3">
                                                    <input class="custom-control-input" formcontrolname="amateur" id="amateur" type="checkbox" formControlName="amateur">
                                                    <label class="custom-control-label" for="amateur">Amateur</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <select name="email" id="email" class="form-control form-control-sm" formControlName="email" *ngIf="personaSeleccionada?.emails?.length > 1">
                                                    <option [value]="email" *ngFor="let email of personaSeleccionada.emails">{{ email }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.email" *ngIf="!(personaSeleccionada?.emails?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Age</label>
                                                <select name="age" id="age" class="form-control form-control-sm" formControlName="age" *ngIf="personaSeleccionada?.ages?.length > 1">
                                                    <option [value]="age" *ngFor="let age of personaSeleccionada.ages">{{ age }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.age" *ngIf="!(personaSeleccionada?.ages?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Nationality</label>
                                                <select name="nacionalidad" id="nacionalidad" class="form-control form-control-sm" formControlName="nacionalidad" *ngIf="personaSeleccionada?.nacionalidades?.length > 1">
                                                    <option [value]="nacionalidad" *ngFor="let nacionalidad of personaSeleccionada.nacionalidades">{{ nacionalidad }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.nacionalidad" *ngIf="!(personaSeleccionada?.nacionalidades?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Gender</label>
                                                <select name="genero" id="genero" class="form-control form-control-sm" formControlName="genero" *ngIf="personaSeleccionada?.generos?.length > 1">
                                                    <option [value]="genero" *ngFor="let genero of personaSeleccionada.generos">{{ genero }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.genero" *ngIf="!(personaSeleccionada?.generos?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Date of birth</label>
                                                <select name="dob" id="dob" class="form-control form-control-sm" formControlName="dob" *ngIf="personaSeleccionada?.dobs?.length > 1">
                                                    <option [value]="dob" *ngFor="let dob of personaSeleccionada.dobs">{{ dob }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.dob" *ngIf="!(personaSeleccionada?.dobs?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Telephone</label>
                                                <select name="telefono" id="telefono" class="form-control form-control-sm" formControlName="telefono" *ngIf="personaSeleccionada?.telefonos?.length > 1">
                                                    <option [value]="telefono" *ngFor="let telefono of personaSeleccionada.telefonos">{{ telefono }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.telefono" *ngIf="!(personaSeleccionada?.telefonos?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Cell Phone</label>
                                                <select name="celular" id="celular" class="form-control form-control-sm" formControlName="celular" *ngIf="personaSeleccionada?.celulares?.length > 1">
                                                    <option [value]="celular" *ngFor="let celular of personaSeleccionada.celulares">{{ celular }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.celular" *ngIf="!(personaSeleccionada?.celulares?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <select name="domicilio" id="domicilio" class="form-control form-control-sm" formControlName="domicilio" *ngIf="personaSeleccionada?.domicilios?.length > 1">
                                                    <option [value]="domicilio" *ngFor="let domicilio of personaSeleccionada.domicilios">{{ domicilio }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.domicilio" *ngIf="!(personaSeleccionada?.domicilios?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Address 2</label>
                                                <select name="domicilio2" id="domicilio2" class="form-control form-control-sm" formControlName="domicilio2" *ngIf="personaSeleccionada?.domicilios2?.length > 1">
                                                    <option [value]="domicilio2" *ngFor="let domicilio2 of personaSeleccionada.domicilios2">{{ domicilio2 }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.domicilio2" *ngIf="!(personaSeleccionada?.domicilios2?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>City</label>
                                                <select name="ciudad" id="ciudad" class="form-control form-control-sm" formControlName="ciudad" *ngIf="personaSeleccionada?.ciudades?.length > 1">
                                                    <option [value]="ciudad" *ngFor="let ciudad of personaSeleccionada.ciudades">{{ ciudad }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.ciudad" *ngIf="!(personaSeleccionada?.ciudades?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>State</label>
                                                <select name="estado" id="estado" class="form-control form-control-sm" formControlName="estado" *ngIf="personaSeleccionada?.estados?.length > 1">
                                                    <option [value]="estado" *ngFor="let estado of personaSeleccionada.estados">{{ estado }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.estado" *ngIf="!(personaSeleccionada?.estados?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Zip</label>
                                                <select name="postal" id="postal" class="form-control form-control-sm" formControlName="postal" *ngIf="personaSeleccionada?.postales?.length > 1">
                                                    <option [value]="postal" *ngFor="let postal of personaSeleccionada.postales">{{ postal }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.postal" *ngIf="!(personaSeleccionada?.postales?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Country</label>
                                                <select name="pais" id="pais" class="form-control form-control-sm" formControlName="pais" *ngIf="personaSeleccionada?.paises?.length > 1">
                                                    <option [value]="pais" *ngFor="let pais of personaSeleccionada.paises">{{ pais }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.pais" *ngIf="!(personaSeleccionada?.paises?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>TAX ID</label>
                                                <select name="rfc" id="rfc" class="form-control form-control-sm" formControlName="rfc" *ngIf="personaSeleccionada?.rfcs?.length > 1">
                                                    <option [value]="rfc" *ngFor="let rfc of personaSeleccionada.rfcs">{{ rfc }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.rfc" *ngIf="!(personaSeleccionada?.rfcs?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>TAX Name</label>
                                                <select name="razon" id="razon" class="form-control form-control-sm" formControlName="razon" *ngIf="personaSeleccionada?.razones?.length > 1">
                                                    <option [value]="razon" *ngFor="let razon of personaSeleccionada.razones">{{ razon }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.razon" *ngIf="!(personaSeleccionada?.razones?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                              <label>Stripe Customer ID</label>
                                              <select name="razon" id="razon" class="form-control form-control-sm" formControlName="stripe_customer_id" *ngIf="personaSeleccionada?.stripe_customer_ids?.length > 1">
                                                  <option [value]="stripe_customer_id" *ngFor="let stripe_customer_id of personaSeleccionada.stripe_customer_ids">{{ stripe_customer_id }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.stripe_customer_id" *ngIf="!(personaSeleccionada?.stripe_customer_ids?.length > 1)">
                                          </div>
                                      </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Notes</label>
                                                <select name="nota" id="nota" class="form-control form-control-sm" formControlName="nota" *ngIf="personaSeleccionada?.notas?.length > 1">
                                                    <option [value]="nota" *ngFor="let nota of personaSeleccionada.notas">{{ nota }}</option>
                                                </select>
                                                <input type="text" class="form-control form-control-sm" readonly [value]="form.value.nota" *ngIf="!(personaSeleccionada?.notas?.length > 1)">
                                            </div>
                                        </div>
                                        <div class="col-12 text-right">
                                            <button type="button" class="btn btn-secondary mr-3" (click)="personaSeleccionada = {}">Cancel</button>
                                            <button type="submit" class="btn btn-success">Merge</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

