import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class JineteService extends Api{

  constructor(private http: HttpClient) {
    super();
  }

  public getJineteFEM(fem): Observable<any>{
    const params = JSON.stringify({ fem });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}fem/jinetes/`, params, {headers: headers});
  }

  public getFeiInfo(jinete): Observable<any>{
    const params = JSON.stringify(jinete);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}jinetes/fei/`, params, {headers: headers});
  }

  public getJinetes(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}jinetes/main/`, params, {headers: headers});
  }

  public addJinete(jinete, idConcurso): Observable<any>{
    jinete.idConcurso = idConcurso;
    const params = JSON.stringify(jinete);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}jinetes/insert_into/`, params, {headers: headers});
  }

  public updateJinete(jinete, idConcurso): Observable<any>{
    jinete.idConcurso = idConcurso;
    const params = JSON.stringify(jinete);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.put(`${environment.api.uri}jinetes/update/`, params, {headers: headers});
  }

  public getUsefData(usefId:string): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}jinetes/usef/?id=${usefId}`, {headers: headers});
  }

  public getInformacionJinete(idJinete): Observable<any>{
    const params = JSON.stringify({ id_jinete: idJinete });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}jinetes/jinete_datos/`,params, {headers: headers});
  }

  public getJinetesFiltrados(filtro, idConcurso): Observable<any>{
    const params = JSON.stringify({ filtro, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}jinetes/query/`, params, {headers: headers});
  }
}
