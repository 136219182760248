import { Component, OnInit, NgZone } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ClubService } from '../services/club.service';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { Router } from "@angular/router";
declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'crear-concursos',
    templateUrl: './crear-concursos.component.html',
    styleUrls: ['./crear-concursos.component.css']
})

export class CrearConcursosComponent implements OnInit {
    public form: UntypedFormGroup;
    public es: any;
    public clubs: Array<any>;
    public queryClub: string;
    private idUsuario: string;
    private token: string;
    private sessionData: any;

    constructor(
        private clubService: ClubService,
        private authService: AuthService,
        private router: Router,
        private concursoService: ConcursoService,
        private ngZone: NgZone
    ) {
        this.form = new UntypedFormGroup({
            nombre: new UntypedFormControl('',[Validators.required]),
            tipo: new UntypedFormControl('',[Validators.required]),
            idOficial: new UntypedFormControl('',[Validators.required]),
            organizador: new UntypedFormControl('',[Validators.required]),
            lugar: new UntypedFormControl('',[Validators.required]),
            inicio: new UntypedFormControl('',[]),
            fin: new UntypedFormControl('',[]),
            costoNominacion: new UntypedFormControl('',[Validators.required]),
            porcentajeADeducir: new UntypedFormControl('',[Validators.required]),
            porcentajeSalesTax: new UntypedFormControl('',[Validators.required]),
            desinscripcion1: new UntypedFormControl('',[Validators.required]),
            desinscripcion2: new UntypedFormControl('',[Validators.required]),
            publico: new UntypedFormControl(false,[]),
            salesTax: new UntypedFormControl(false,[]),
            feedOrderSalesTax: new UntypedFormControl(false,[]),
            merchSalesTax: new UntypedFormControl(false,[]),
            ed: new UntypedFormControl(false,[])
        });
        this.es = {
            firstDayOfWeek: 0,
            dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
            dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
            dayNamesMin: [ "D","L","M","I","J","V","S" ],
            monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
            monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
            today: 'Hoy',
            clear: 'Borrar'
        }
        this.clubs = [];
        this.queryClub = '';
        this.idUsuario = '';
        this.token = '';
        this.sessionData = {};
    }

    ngOnInit() {
        window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunctionInicio: (inicio) => this.inicioChange(inicio), loadAngularFunctionFin: (fin) => this.finChange(fin) };
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
        }
        this.getClubs();
        this.form.get("organizador").valueChanges.subscribe(
            data => {
                this.queryClub = data;
            }
        );
    }

    public displayOrganizador(val): string{
        return val ? val.club : val;
    }

    public getClubs(){
        this.clubService.getClubs().subscribe(
            response => {
              if(!response.error){
                  this.clubs = response.clubs;
              } else{
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }
            },
            error => {
              $.NotificationApp.send("Error", 'No ha sido posible consultar el listado de clubes.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
        );
    }

    public guardarConcurso(){
        if(this.form.valid){
            if(this.form.get('organizador').value['id_club']){
                const nombre = this.form.get('nombre').value;
                const tipo = this.form.get('tipo').value;
                const idOficial = this.form.get('idOficial').value;
                const organizador = this.form.get('organizador').value['id_club'];
                const lugar = this.form.get('lugar').value;
                let dateInicio = this.form.get('inicio').value;
                dateInicio = dateInicio.split('/');
                const inicio = `${dateInicio[2]}-${dateInicio[0]}-${dateInicio[1]} 08:00`;
                //const inicio = `${dateInicio.getFullYear()}-${dateInicio.getMonth() < 9 ? `0${dateInicio.getMonth() + 1}` : dateInicio.getMonth() + 1}-${dateInicio.getDate() < 10 ? `0${dateInicio.getDate()}` : dateInicio.getDate()} ${dateInicio.getHours() < 10 ? `0${dateInicio.getHours()}` : dateInicio.getHours()}:${dateInicio.getMinutes() < 10 ? `0${dateInicio.getMinutes()}` : dateInicio.getMinutes()}:00`;
                let dateFin = this.form.get('fin').value;
                dateFin = dateFin.split('/');
                const fin = `${dateFin[2]}-${dateFin[0]}-${dateFin[1]} 18:00`;
                //const fin = `${dateFin.getFullYear()}-${dateFin.getMonth() < 9 ? `0${dateFin.getMonth() + 1}` : dateFin.getMonth() + 1}-${dateFin.getDate() < 10 ? `0${dateFin.getDate()}` : dateFin.getDate()} ${dateFin.getHours() < 10 ? `0${dateFin.getHours()}` : dateFin.getHours()}:${dateFin.getMinutes() < 10 ? `0${dateFin.getMinutes()}` : dateFin.getMinutes()}:00`;
                const costoNominacion = this.form.get('costoNominacion').value;
                const porcentajeADeducir = this.form.get('porcentajeADeducir').value;
                const porcentajeSalesTax = this.form.get('porcentajeSalesTax').value;
                const desinscripcion1 = this.form.get('desinscripcion1').value;
                const desinscripcion2 = this.form.get('desinscripcion2').value;
                const publico = this.form.get('publico').value ? 1 : 0;
                const salesTax = this.form.get('salesTax').value ? 1 : 0;
                const feedOrderSalesTax = this.form.get('feedOrderSalesTax').value ? 1 : 0;
                const merchSalesTax = this.form.get('merchSalesTax').value ? 1 : 0;
                const ed = this.form.get('ed').value ? 1 : 0;

                const concurso = {
                    nombre,
                    tipo,
                    idOficial,
                    organizador,
                    lugar,
                    inicio,
                    fin,
                    costoNominacion,
                    porcentajeADeducir,
                    porcentajeSalesTax,
                    desinscripcion1,
                    desinscripcion2,
                    publico,
                    salesTax,
                    feedOrderSalesTax,
                    merchSalesTax,
                    ed
                };

                this.concursoService.create(concurso).subscribe(
                    response =>{
                        if(!response.error){
                            this.router.navigate(['']);
                        } else{
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                    },
                    error => {
                      $.NotificationApp.send("Error", 'No ha sido posible crear el concurso.', 'bottom-right', '#fa5c7c', 'error');
                      console.log(error);
                    }
                );
            } else {
                this.form.get('organizador').reset();
                this.form.get('organizador').setValue('');
                this.form.get('organizador').markAsTouched();
            }
        } else {
            this.form.get('nombre').markAsTouched();
            this.form.get('tipo').markAsTouched();
            this.form.get('idOficial').markAsTouched();
            this.form.get('organizador').markAsTouched();
            this.form.get('lugar').markAsTouched();
            this.form.get('costoNominacion').markAsTouched();
            this.form.get('porcentajeADeducir').markAsTouched();
            this.form.get('porcentajeSalesTax').markAsTouched();
            this.form.get('desinscripcion1').markAsTouched();
            this.form.get('desinscripcion2').markAsTouched();
        }
    }

    public inicioChange(inicio){
        this.form.get('inicio').setValue(inicio);
    }

    public finChange(fin){
        this.form.get('fin').setValue(fin);
    }

}
