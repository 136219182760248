import { Component, OnInit, Directive, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {UntypedFormControl, Validators, Form} from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';

//SERVICES
import { VideoService } from '../services/videos.service';
declare var jquery:any;
declare var $ :any;

export interface Paquetes {
  value: number;
  name: string;
}

export interface MetodosPago {
  value: string;
  name: string;
}

@Component({
  selector: 'perfil-cliente',
  templateUrl: './perfil-cliente.component.html',
  styleUrls: ['./perfil-cliente.component.css']
})
export class PerfilClienteComponent implements OnInit {

  paquetes: Paquetes[] = [
    {value: 1, name: '1 Video'},
    {value: 5, name: '5 Videos'},
    {value: 10, name: '10 Videos'},
    {value: 20, name: '20 Videos'},
    {value: 50, name: '50 Videos'}
  ];

  metodosPago: MetodosPago[] = [
    {value: 'PayPal', name: 'PayPal'},
    {value: 'Efectivo', name: 'Efectivo'},
    {value: 'Transferencia', name: 'Transferencia'},
    {value: 'Oxxo', name: 'Oxxo'},
    {value: 'Cortesia', name: 'Cortesia'},
    {value: 'Stripe', name: 'Stripe'}
  ];

  private token: string;
  private sessionData: any;
  public idConcurso: string | boolean;
  public nombreConcurso: string;
  private idUsuario: string;
  public privilegios: number;
  public infoCuenta: any;
  public paquetesContratados: any;
  public videosPorCuenta: any;
  public id_cliente: number;
  public edit_profile: boolean;
  public validadorNombreCliente: UntypedFormControl;
  public validadorTelefonoCliente: UntypedFormControl;
  public validadorCorreoCliente: UntypedFormControl;
  public paqueteVideosSeleccionado: number;
  public metodoPagoSeleccionado: string;
  public notas: string
  public displayedColumns: Array<string>
  public dataSource;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private videoService: VideoService,
    private router: Router
  ) {
    this.token = '';
    this.idConcurso = '';
    this.idUsuario = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.infoCuenta = [];
    this.paquetesContratados = [];
    this.videosPorCuenta = [];
    this.id_cliente = 0;
    this.edit_profile = false;
    this.metodoPagoSeleccionado = '';
    this.notas = '';
    this.paqueteVideosSeleccionado = 0;
    this.validadorNombreCliente = new UntypedFormControl('',[Validators.required]);
    this.validadorTelefonoCliente = new UntypedFormControl('',[Validators.pattern('[0-9]{0,18}')]);
    this.validadorCorreoCliente = new UntypedFormControl('',[Validators.required, Validators.email]);
    this.displayedColumns = ['Prueba','Binomio','Fase','Entrada','Entregado','Videos'];
    this.dataSource = new MatTableDataSource();
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {

    $('#loader').show();
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }

    this.route.params.subscribe(params => {
      this.id_cliente = parseFloat(params['id_cliente']);
    });

    this.getInfoCuenta();
    this.getPaquetes();
    this.getVideosPorCuenta();
  }

  ngAfterViewInit(){
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(
      () => {
        this.paginator.pageIndex = 0;
        const active = this.sort.active;
        const direction = this.sort.direction;
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public getInfoCuenta(){
    this.videoService.getPerfilCliente(this.id_cliente).subscribe(
      response => {
        this.infoCuenta = response;
        $('#loader').fadeOut(1000);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getPaquetes(){
    this.videoService.getPaquetes(this.id_cliente).subscribe(
      response => {
        this.paquetesContratados = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  public getVideosPorCuenta(){
    this.videoService.getVideosPorCuenta(this.id_cliente).subscribe(
      response => {
        this.dataSource.data = response;
        this.videosPorCuenta = response;
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  }
  public setPaquete(){
    if(this.paqueteVideosSeleccionado == 0 || this.metodoPagoSeleccionado == ''){
      $.NotificationApp.send( "Error",  "There are empty fields.",'bottom-right', '#ffd358', 'warning');
    }else{
      this.videoService.setPaquete(this.id_cliente,this.paqueteVideosSeleccionado,this.metodoPagoSeleccionado,this.notas).subscribe(
        response => {
          if(response.error == 0){
            this.getInfoCuenta();
            this.getPaquetes();
            $.NotificationApp.send( "New package",  "A pack of "+this.paqueteVideosSeleccionado+" videos was added to this account.", 'bottom-right', '#06d5a1', 'success');
            this.metodoPagoSeleccionado = '';
            this.notas = '';
            this.paqueteVideosSeleccionado = 0;
          } else if(response.error == 1){
            $.NotificationApp.send( "Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
          } else if(response.error == 2){
            $.NotificationApp.send( "Error",  response.msj, 'bottom-right', '#fa5c7c', 'error');
          } else{
            $.NotificationApp.send( "Error",  "The package has not been added.", 'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  public convertString(value){
    return parseFloat(value)
  }

  public mostrarPanelAgregar(){
    this.edit_profile = (this.edit_profile) ? false : true;
    if(this.edit_profile == false){
      this.validadorCorreoCliente.reset();
      this.validadorNombreCliente.reset();
      this.validadorTelefonoCliente.reset();
    }else{
      this.validadorCorreoCliente.setValue(this.infoCuenta[0].email);
      this.validadorNombreCliente.setValue(this.infoCuenta[0].nombre_cliente);
      this.validadorTelefonoCliente.setValue(this.infoCuenta[0].telefono);
    }
  }

  public onSubmitAgregarCliente(){
    if(this.validadorCorreoCliente.valid && this.validadorNombreCliente.valid){
      let valido = true;
      if(valido){
        let cliente = {
          id_cliente: this.id_cliente,
          nombre: this.validadorNombreCliente.value,
          correo: this.validadorCorreoCliente.value,
          telefono: this.validadorTelefonoCliente.value
        };
        this.videoService.updateCuenta(cliente).subscribe(
          response => {
                if(response.error == 0){
                  this.getInfoCuenta()
                  this.mostrarPanelAgregar();
                  $.NotificationApp.send("Changes applied",  "The user profile was updated.", 'bottom-right', '#06d5a1', 'success');
                } else if(response.error == 1){
                  $.NotificationApp.send("Error",  response.msj, 'bottom-right', '#fa5c7c', 'error');
                } else if(response.error == 2){
                  $.NotificationApp.send("Duplicate costumer",  response.msj, 'bottom-right', '#ffd358', 'warning');
                } else{
                  $.NotificationApp.send("Error",  "The customer has not been added.", 'bottom-right', '#fa5c7c', 'error');
                }
              },
              error => {
                console.log(error);
                $.NotificationApp.send("Error",  "The customer has not been added.", 'bottom-right', '#fa5c7c', 'error');
              }
          );

      }
    } else{
      $.NotificationApp.send("Invalid data",  "There are pending fields to be completed.", 'bottom-right', '#ffd358', 'warning');
    }
  }

}
