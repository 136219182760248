import { Component, OnInit, Directive, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import {FormControl, Validators, Form,FormBuilder, FormGroup} from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

//SERVICES
import { VideoService } from '../services/videos.service';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'pagos-sin-asignar',
  templateUrl: './pagos-sin-asignar.component.html',
  styleUrls: ['./pagos-sin-asignar.component.css']
})
export class PagosSinAsignarComponent implements OnInit {

  public idConcurso: string | boolean;
  public nombreConcurso: string;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public balanceVentaPaquetes: any;
  public ventasSinClienteAsignado: any;
  public cuentasDeVideos: any;

  public filtroCuentasVideo: string;
  public id_cliente: number;

  constructor(
    private router: Router,
    private videoService: VideoService,
    private authService: AuthService
    ) {
    this.idConcurso = '';
    this.token = '';
    this.idUsuario = '';
    this.filtroCuentasVideo = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.balanceVentaPaquetes = [];
    this.ventasSinClienteAsignado = [];
    this.cuentasDeVideos = [];
    this.id_cliente = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').fadeIn(200);
    this.getBalanceVentas();
    this.getVentasSinAsignar();
  }

  public getBalanceVentas(){
    this.videoService.getBalanceVenta().subscribe(
      response => {
        this.balanceVentaPaquetes = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  public getVentasSinAsignar(){
    this.videoService.getPagosSinAsignar().subscribe(
      response => {
        this.ventasSinClienteAsignado = response;
        console.log(response);
        $('#loader').fadeOut(2000);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getResponsablesVideos(event: any){
    this.filtroCuentasVideo = event.target.value;
    if(this.filtroCuentasVideo.length > 2){
      this.videoService.getResponsablesVideos(this.filtroCuentasVideo).subscribe(
        response => {
          this.cuentasDeVideos = response
        },
        error => {
          console.log(error);
        }
      );
    }else{
      this.cuentasDeVideos = [];
      console.log('Busqueda muy corta');
    }
  }

  public setClienteVideo(cliente,pago){
    console.log(cliente,pago);
    this.videoService.setCuenta(cliente,pago).subscribe(
      response => {
        if(response.error == 0){
          $.gritter.add({title:"Cliente asignado",text:"Se registro el cliente de forma exitosa.",class_name:"color success"});
          this.getBalanceVentas();
          this.getVentasSinAsignar();
        }else{
          $.gritter.add({title:"Error",text: response.msj,class_name:"color danger"});
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public displayResponsableCuenta(val): string {
    return val ? val.nombre_cliente: val;
  }

}
