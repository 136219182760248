import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ConcursoService } from '../services/concurso.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppService } from '../services/app.service';
import { EmailService } from '../services/email.service';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';
declare let $: any;

@Component({
  selector: 'app-day-plan',
  templateUrl: './day-plan.component.html',
  styleUrls: ['./day-plan.component.css']
})
export class DayPlanComponent implements OnInit {
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  public info: any;
  public pista = ' Walnut';
  public pruebasPista: any[] = [];
  public minXCaballo: number = 2;
  public minXCaballoVar: any = {};
  public minXPrueba: number = 0;
  public minXPruebaVar: any = {};
  public minEntrePruebas: number = 0;
  public horaInicioPruebaTs: number = 0;
  public horaFinPruebaTs: number = 0;
  public minConst: number = 60000;
  public primerPruebaGrupo: number = -1;
  public horaInicioFija: any = {};
  public pruebasPistaEdit: any = {};
  public pistaAnterior: string = '';
  public dias: any[] = [];
  public diaSeleccionado: string = '';
  public pistas: string[];
  public logoClub: string;
  public isSaving: boolean;
  public days: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pruebaService: PruebaService,
    private concursoService: ConcursoService,
    private appService: AppService,
    private emailService: EmailService
  ) {
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.idConcurso = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pistas = [];
    this.logoClub = '';
    this.isSaving = false;
    this.days = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    //Ingate: 50
    if (this.authService.validarConcurso() && this.privilegios >= 50) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    //console.log(this.sessionData.nombre);
    this.getPruebas();
    this.getConcurso();
  }

  private async getConcurso() {
    const response = await this.concursoService.getConcurso(this.idConcurso).toPromise();
    const [inicio] = response.concurso.inicio.split(' ');
    const [fin] = response.concurso.fin.split(' ');
    this.days = this.getDates(new Date(inicio.split('-')), new Date(fin.split('-')));
    console.log(this.days);
    this.days.push('');
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.logoClub = response.concurso.logoClub;
    this.pistas = response.concurso.pistas
  }

  private getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate.toLocaleDateString('sv-SE'));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }

  private async getPruebas() {
    this.info = await firstValueFrom(this.pruebaService.getPruebas(this.idConcurso, true));
    this.filtrarResultado(this.info);
  }

  private filtrarResultado(dataset: any) {
    const date = new Date();
    //Agregar un dia para que la fecha sea del dia siguiente
    date.setDate(date.getDate() + 1);
    const dia = `${date.getDate()}`.padStart(2, '0');
    const mes = `${date.getMonth() + 1}`.padStart(2, '0');
    const year = `${date.getFullYear()}`;
    const hoy = `${year}-${mes}-${dia}`;
    this.dias = this.days;
    if (this.dias.indexOf(hoy) > -1) {
      this.diaSeleccionado = hoy;
    } else {
      this.diaSeleccionado = this.dias.filter(d => d).shift();
    }
    for (let i in dataset) {
      //TODO: Quitar filtro para pruebas sin fecha de inicio
      //if (i == '') continue;
      for (let prueba of dataset[i]) {
        //Validar si la prueba ya tiene asignado  minutos por caballo en la bd sino dejar valor caluclado arriba
        if (prueba.adjust) {
          this.minXCaballoVar[prueba.numero] = prueba.adjust;
          prueba.minXCaballo = prueba.adjust;
        } else if (prueba.claveModalidad == 'Hunters') {
          // Si es hunters, ponerle 2.5 minutos por caballo
          this.minXCaballoVar[prueba.numero] = 3;
          prueba.minXCaballo = 3;
        } else {
          //Inicializar minXCaballoVar con cada prueba
          this.minXCaballoVar[prueba.numero] = this.minXCaballo;
          prueba.minXCaballo = this.minXCaballo;
        }

        if (
          ( prueba.claveModalidad == 'Under Saddle'
            || (prueba.prueba.includes('U/S')
            || (prueba.prueba.includes('Equitation') && prueba.prueba.includes('Flat')))
          ) ||
          ( prueba.claveModalidad == 'Hunter Model' && prueba.prueba.toUpperCase().includes('MODEL'))
        ) {
          // Marcar si es under saddle (U/S)

          //console.log('Model Class: ', prueba.numero);

          prueba.inscritos = 1;
          prueba.us = true;
          this.minXCaballoVar[prueba.numero] = 15;
          prueba.minXCaballo = 15;
        }

        //Convertir hard_start 1 o 0 a booleano
        prueba.hard_start = prueba.hard_start == '1';
        //Si la prueba es hard start inicializar valor en arreglo
        if (prueba.hard_start) {
          //Validar la hora esta guardada en el formato correcto
          const match = prueba.inicio.match(/([01][0-9]|2[0-3]):([0-5][0-9])/);
          if (match) {
            this.horaInicioFija[prueba.numero] = match.shift();
          }
        }
        prueba.horaHardStart = prueba.hard_start ? this.horaInicioFija[prueba.numero] : '';
        //Validar si el dia ya existe en el arreglo, sino crearlo
        const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == (i));
        if (indexDia > -1) {//Si ya existe el dia
          if(!prueba.us) this.pruebasPista[indexDia].inscritos += prueba.inscritos;
          else this.pruebasPista[indexDia].inscritos_us += prueba.inscritos_us;
          //validar si ya existe la pista
          const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => {
            return (p.nombre == prueba.pista);
          });
          if (indexPista > -1) {//Si ya existe la pista solo hacer un push con la prueba
            this.pruebasPista[indexDia].pistas[indexPista].pruebas.push(prueba);
            if(!prueba.us) this.pruebasPista[indexDia].pistas[indexPista].inscritos += prueba.inscritos;
            else this.pruebasPista[indexDia].pistas[indexPista].inscritos_us += prueba.inscritos_us;
          } else {//Hacer un push a la pista
            this.pruebasPista[indexDia].pistas.push({
              nombre: prueba.pista,
              inscritos: prueba.inscritos,
              inscritos_us: prueba.inscritos_us,
              pruebas: [prueba]
            });
          }
        } else {
          this.pruebasPista.push({
            nombre: i,
            inscritos: prueba.inscritos,
            inscritos_us: prueba.inscritos_us,
            pistas: [{
              nombre: prueba.pista,
              inscritos: prueba.inscritos,
              inscritos_us: prueba.inscritos_us,
              pruebas: [prueba]
            }]
          });
        }
      }
    }
    this.days.forEach(day => {
      if (this.pruebasPista.findIndex(p => p.nombre == day) > -1) return;
      this.pruebasPista.push({
        nombre: day,
        inscritos: 0,
        inscritos_us: 0,
        pistas: [],
      });
    });

    console.log(this.pruebasPista);
    //Acomoda las pistas por orden alfabetico
    this.pruebasPista.map(p => {
      p.pistas = p.pistas.sort((a, b) => {
        if (a.nombre == b.nombre) return 0;

        return a.nombre > b.nombre ? 1 : -1;
      });
      return p;
    });
    this.setMasonry();
    //Si se paso el parametro de dia seleccionar ese dia por defecto
    if (this.route.snapshot.params.dia) {
      this.seleccionarDia(this.route.snapshot.params.dia);
      setTimeout(() => { window.print(); }, 500);
      window.onafterprint = () => {
        setTimeout(() => { window.close(); }, 500);
      };
    }
  }

  public setTiempoXCaballo(event: any, dia: string, pista: string, ipc: string, pruebaNumero: number) {
    let tiempo = parseFloat(event.target.value)
    let tiempo2Dec = +tiempo.toFixed(2);
    this.minXCaballoVar[pruebaNumero] = tiempo2Dec;

    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);
    const indexPrueba = this.pruebasPista[indexDia].pistas[indexPista].pruebas.findIndex((p: any) => p.ipc == ipc);
    this.pruebasPista[indexDia].pistas[indexPista].pruebas[indexPrueba].minXCaballo = tiempo2Dec;
  }

  public setTiempoEntrePruebas(event: any) {
    let tiempo = parseFloat(event.target.value)
    let tiempo2Dec = +tiempo.toFixed(2);
    this.minEntrePruebas = tiempo2Dec;
  }

  public setTiempoFijo(event: any, pruebaNumero: number) {
    let tiempo = event.target.value;
    this.horaInicioFija[pruebaNumero] = tiempo;
  }

  public selectTiempoFijo(hard_start: boolean, pruebaNumero: number) {
    if (!hard_start) {
      // quita elemento de objeto
      delete this.horaInicioFija[pruebaNumero];
    }
  }

  public calcMinXPrueba(prueba: any) {
    let inscritosEnPrueba = prueba.inscritos;
    let minXPrueba = inscritosEnPrueba * this.minXCaballoVar[prueba.numero];
    let minXPrueba2Dec = +minXPrueba.toFixed(2);
    this.minXPruebaVar[prueba.numero] = minXPrueba2Dec;
    // Si la prueba es Hunters y tipo U/S, darle 15 en total. Para checar si es U/S checamos el nombre
    if ((prueba.claveModalidad == 'Under Saddle' || (prueba.prueba.includes('U/S') || (prueba.prueba.includes('Equitation') && prueba.prueba.includes('Flat')))) || (prueba.claveModalidad == 'Hunter Model' && prueba.prueba.includes('Model'))) {
      this.minXPruebaVar[prueba.numero] = 15;
    }

    return this.minXPruebaVar[prueba.numero];
  }

  public saveToFinalObject(prueba: any, param: any, value: any) {
    // guarda en objeto final
    //this.pruebasPistaEdit[ prueba.numero ].param = value;
  }

  public calcInicioPrueba(prueba: any, pista: string, dia: string) {
    let tieneInicioFijo = typeof this.horaInicioFija[prueba.numero] !== 'undefined';
    let inicioPruebaTs;

    if (this.primerPruebaGrupo == -1 || this.pistaAnterior != pista) {
      this.primerPruebaGrupo = prueba.numero;
    }
    let esPrimerPrueba = this.primerPruebaGrupo == prueba.numero;   // inicializa banderas
    // crea tiempo de inicio, segun condiciones
    if (!esPrimerPrueba && !tieneInicioFijo && this.horaFinPruebaTs) {
      // lee hora de fin de prueba (anterior) la usa como inicio de prueba (actual)
      inicioPruebaTs = this.horaFinPruebaTs;
      // agrega tiempo ente pruebas
      inicioPruebaTs = this.agregarMinutos(inicioPruebaTs, this.minEntrePruebas);
    } else {
      if (!prueba.inicio && !this.horaInicioFija[prueba.numero]) return null;
      // toma parte de tiempo y quita fecha
      let time = typeof this.horaInicioFija[prueba.numero] !== 'undefined' ? this.horaInicioFija[prueba.numero] : prueba.inicio.slice(-8);
      inicioPruebaTs = this.convStrATimestamp(time);
    }

    this.horaInicioPruebaTs = inicioPruebaTs;
    this.pistaAnterior = pista;
    //console.log(inicioPruebaTs);
    if (!this.isValidDate(inicioPruebaTs)) {
      $.NotificationApp.send("Error", `${inicioPruebaTs} is not a valid date.`, 'bottom-right', '#fa5c7c', 'error');
      return;
    };
    const inicioPruebaStr = new Date(inicioPruebaTs);

    //buscar el indice de la prueba en el arreglo de pruebas para agregar el dato al arreglo original
    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);
    const indexPrueba = this.pruebasPista[indexDia].pistas[indexPista].pruebas.findIndex((p: any) => p.ipc == prueba.ipc);
    if (indexPrueba > -1) {
      this.pruebasPista[indexDia].pistas[indexPista].pruebas[indexPrueba].horaInicio = inicioPruebaStr.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
    }
    return inicioPruebaStr;
  }

  public calcFinPrueba(pruebaNumero: number, inicio: string, receso: any) {
    let esPrimerPrueba = this.primerPruebaGrupo == pruebaNumero;   // inicializa banderas
    if (!this.horaInicioPruebaTs || (esPrimerPrueba && !inicio && !this.horaInicioFija[pruebaNumero])) return null;
    let inicioPruebaTs = this.horaInicioPruebaTs;
    receso = isNaN(parseFloat(receso)) ? 0 : parseFloat(receso);
    let finPruebaTs = this.agregarMinutos(inicioPruebaTs, this.minXPruebaVar[pruebaNumero] + receso);
    this.horaFinPruebaTs = finPruebaTs;
    if (!this.isValidDate(finPruebaTs)) {
      $.NotificationApp.send("Error", `${finPruebaTs} is not a valid date.`, 'bottom-right', '#fa5c7c', 'error');
      return;
    };
    const finPruebaStr = new Date(finPruebaTs)/*.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })*/;
    return finPruebaStr;
  }

  public convStrATimestamp(hourMinSecsStr: string) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let todayDate = mm + '/' + dd + '/' + yyyy;
    let todayDateTime = todayDate + ' ' + hourMinSecsStr;
    //console.log(todayDateTime);
    let ts = Date.parse(todayDateTime); // convierte a timestamp
    return ts;
  }

  public agregarMinutos(ts: number, minutos: number) {
    ts = ts + (minutos * this.minConst);
    return ts;
  }

  public async save(dia: string, pista: string) {
    this.isSaving = true;
    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);

    const pruebas = this.pruebasPista[indexDia].pistas[indexPista].pruebas.map((prueba: any) => {
      const [startDate] = (prueba.inicio||'').split(' ');
      let invalidDateMessage = '';
      //Validar formato de hora (HH:MM)
      const regexTime = /^([01][0-9]|2[0-3]):([0-5][0-9])\s(AM|PM)$/;
      let inicio;
      let hard_start;
      //Si tiene fecha de inicio y algun dato es invalido
      if(prueba.inicio && (!startDate || !prueba.horaInicio || !regexTime.test(prueba.horaInicio) || !moment(`${startDate} ${prueba.horaInicio}`).isValid())) {
        invalidDateMessage = `${`Class ${prueba.numero} - has a invalid start date ${startDate}`} ${prueba.horaInicio}.`;
        inicio = '';
        hard_start = false;
      } else if(prueba.inicio) {//Si tiene fecha de inicio y es valida
        inicio = moment(`${startDate} ${prueba.horaInicio}`).format('YYYY-MM-DD HH:mm');
        hard_start = prueba.hard_start;
      } else{//No tiene fecha de inicio
        inicio = '';
        hard_start = false;
      }

      return {
        pista: prueba.pista,
        ipc: prueba.ipc,
        inicio,
        hard_start,
        hide_start: prueba.hide_start,
        adjust: prueba.minXCaballo,
        break: prueba.break || '',
        noDate: !dia,
        invalidDateMessage
      };
    });

    const errors = pruebas.filter(p => p.invalidDateMessage);
    if(errors.length){
      $.NotificationApp.send("Error", errors.map(e => e.invalidDateMessage), 'bottom-right', '#fa5c7c', 'error', 15000);
      this.isSaving = false;
      return;
    }

    const response = await firstValueFrom(this.pruebaService.actualizarHorarios(pruebas));
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      this.isSaving = false;
      return;
    }

    this.appService.updateClasses(this.idConcurso);
    $.NotificationApp.send("Success", 'Class starts has been saved successfully.', 'bottom-right', '#3c763d', 'success');
    this.isSaving = false;

    const logData: string = `<p>Show: ${sessionStorage.nombreConcurso}</p><p>User: ${this.sessionData.nombre}</p><p>Component: ${'Day Plan'}</p>`;
    const responseMail = await firstValueFrom(this.emailService.send('Updated class', logData));
    console.log(responseMail);
  }

  public seleccionarDia(dia: string) {
    this.diaSeleccionado = dia;
    this.primerPruebaGrupo = -1;
    this.pistaAnterior = '';
    this.horaInicioPruebaTs = 0;
    this.horaFinPruebaTs = 0;
    this.setMasonry();
  }

  public setMasonry() {
    setTimeout(() => {
      $('.grid').masonry('reloadItems');
    }, 500);
    setTimeout(() => {
      $('.grid').masonry({
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        percentPosition: true
        /*gutter: 10*/
      });
    }, 500);
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  public clearTimes(ipc, dia, pista) {
    //console.log(this.pruebasPista, dia);
    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
    if (indexDia == -1) return;
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);
    if (indexPista == -1) return;
    const indexPrueba = this.pruebasPista[indexDia].pistas[indexPista].pruebas.findIndex((p: any) => p.ipc == ipc);
    if (indexPrueba == -1) return;

    let prueba = this.pruebasPista[indexDia].pistas[indexPista].pruebas.splice(indexPrueba, 1)[0];
    prueba.pista = '';
    prueba.hard_start = false;
    prueba.inicio = '';

    const indexDiaVacio = this.pruebasPista.findIndex((p: any) => p.nombre == '');
    //Si no existe la fecha vacia agregarla al arreglo
    if (indexDiaVacio == -1) {
      this.pruebasPista.unshift({
        nombre: '',
        pistas: [{
          nombre: prueba.pista,
          pruebas: [prueba]
        }]
      });
      this.dias.unshift('');
    } else {
      const indexPistaVacia = this.pruebasPista[indexDiaVacio].pistas.findIndex((p: any) => {
        return (p.nombre == prueba.pista);
      });
      if (indexPistaVacia > -1) {//Si ya existe la pista solo hacer un push con la prueba
        this.pruebasPista[indexDiaVacio].pistas[indexPistaVacia].pruebas.push(prueba);
      } else {//Hacer un push a la pista
        this.pruebasPista[indexDiaVacio].pistas.push({
          nombre: prueba.pista,
          pruebas: [prueba]
        });
      }
    }
    this.save('', '');
  }

  public quitarElementosVacios(arr: any[]): any[] {
    return arr.filter(a => a)
  }

  public setDate(e, ipc, oldDay, pista) {
    const day = e.target.value;
    console.log(day, ipc, oldDay, pista, this.pruebasPista);
    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == day);
    console.log(indexDia)
    const indexDiaAnterior = this.pruebasPista.findIndex((p: any) => p.nombre == oldDay);
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);
    const indexPistaAnterior = this.pruebasPista[indexDiaAnterior].pistas.findIndex((p: any) => p.nombre == pista);
    const indexPruebaAnterior = this.pruebasPista[indexDiaAnterior].pistas[indexPistaAnterior].pruebas.findIndex((p: any) => p.ipc == ipc);

    let prueba = this.pruebasPista[indexDiaAnterior].pistas[indexPistaAnterior].pruebas.splice(indexPruebaAnterior, 1)[0];
    prueba.inicio = `${day} 22:00`;

    if (!this.isValidDate(`${day} 22:00`)) {
      $.NotificationApp.send("Error", `${`${day} 22:00`} is not a valid date.`, 'bottom-right', '#fa5c7c', 'error');
      return;
    };
    prueba.horaInicio = new Date(`${day} 22:00`).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });

    //Validar si existe el dia y la pista de destino
    if (indexDia == -1) {
      this.pruebasPista.push({
        nombre: day,
        pistas: [{
          nombre: prueba.pista,
          pruebas: [prueba]
        }]
      });
    } else {
      if (indexPista == -1) {
        this.pruebasPista[indexDia].pistas.push({
          nombre: prueba.pista,
          pruebas: [prueba]
        });
      } else {
        this.pruebasPista[indexDia].pistas[indexPista].pruebas.push(prueba);
      }
    }

    this.save(day, prueba.pista);
    this.setMasonry();
  }

  public setRing(e, ipc, oldRing, dia) {
    const ring = e.target.value;
    const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
    const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == ring);
    const indexPistaAnterior = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == oldRing);
    const indexPruebaAnterior = this.pruebasPista[indexDia].pistas[indexPistaAnterior].pruebas.findIndex((p: any) => p.ipc == ipc);

    let prueba = this.pruebasPista[indexDia].pistas[indexPistaAnterior].pruebas.splice(indexPruebaAnterior, 1)[0];
    prueba.pista = ring;

    if (indexPista == -1) {
      this.pruebasPista[indexDia].pistas.push({
        nombre: prueba.pista,
        pruebas: [prueba]
      });
    } else {
      this.pruebasPista[indexDia].pistas[indexPista].pruebas.push(prueba);
    }
    this.save(dia, prueba.pista);
    this.seleccionarDia(dia);
  }

  public enterClassNumber(e, dia, pista) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.agregarPrueba(dia, pista);
    }
  }

  public agregarPrueba(dia, pista) {
    const inputClassNumber: any = document.getElementById(`input-class-${pista || 'No ring'}`);
    const numero = inputClassNumber.value;
    for (let i in this.pruebasPista) {
      for (let j in this.pruebasPista[i].pistas) {
        //Indice del arreglo de pruebas
        const k = this.pruebasPista[i].pistas[j].pruebas.findIndex(p => p.numero == numero);
        if (k > -1) {
          let prueba = this.pruebasPista[i].pistas[j].pruebas.splice(k, 1)[0];
          inputClassNumber.value = '';
          prueba.pista = pista;
          prueba.inicio = `${dia} 22:00`;
          if (!this.isValidDate(`${dia} 22:00`)) {
            $.NotificationApp.send("Error", `${`${dia} 22:00`} is not a valid date.`, 'bottom-right', '#fa5c7c', 'error');
            return;
          };
          prueba.horaInicio = new Date(`${dia} 22:00`).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
          const indexDia = this.pruebasPista.findIndex((p: any) => p.nombre == dia);
          const indexPista = this.pruebasPista[indexDia].pistas.findIndex((p: any) => p.nombre == pista);
          this.pruebasPista[indexDia].pistas[indexPista].pruebas.push(prueba);
          this.save(dia, pista);
          this.seleccionarDia(dia);
          return;
        }
      }
    }
  }

  private isValidDate(date): boolean {
    return String(new Date(date)) !== 'Invalid Date';
  }

  private addDays(date: Date, days): Date {
    const dat = new Date(date.valueOf())
    dat.setDate(dat.getDate() + days);
    return dat;
  }
}
