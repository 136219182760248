<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="card">
                    <div class="card-header pt-4 pb-4 text-center bg-primary" style="padding:0 !important">
                        <span><img src="assets/images/logo.png" alt="Logo ED" height="96"></span>
                    </div>
                    <div class="card-body p-4">
                        <div class="text-center w-75 m-auto">
                            <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Log In</h4>
                            <p class="text-muted mb-4">Please log in.</p>
                        </div>
                        <form [formGroup]="form" (submit)="onSubmit()">
                            <div class="form-group">
                                <div class="input-group">
                                    <label for="inputUsuario">User</label>
                                    <input formControlName="usuario" type="text" placeholder="User" autocomplete="off" class="form-control" id="inputUsuario" required>
                                </div>
                                <div *ngIf="form.get('usuario').invalid && (form.get('usuario').touched || form.get('usuario').dirty)" style="margin-top: 10px;color:red !important;">
                                    The username field cannot be empty.
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label for="inputPassword">Password</label>
                                    <input formControlName="password" type="password" placeholder="Password" class="form-control" id="inputPassword" required>
                                </div>
                                <div *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)" style="margin-top: 10px;color:red !important;">
                                    The password field cannot be empty.
                                </div>
                            </div>
                            <div class="form-group mb-0 text-center login-submit">
                                <button class="btn btn-primary" type="submit">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
