
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ChaseService extends Api {
    constructor(private http: HttpClient) {
        super();
    }

    public payment(card, idConcurso, entry, payment, idUsuario, trainers, retryTrace): Observable<any>{
        const params = JSON.stringify({ card, idConcurso, entry, payment, idUsuario, trainers, retryTrace });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/payment/`, params, {headers: headers});
    }

    public paymentToken(customerRefNum, ccAccountNum, idConcurso, entry, payment, idUsuario, trainers, retryTrace): Observable<any>{
        const params = JSON.stringify({ customerRefNum, ccAccountNum, idConcurso, entry, payment, idUsuario, trainers, retryTrace });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/payment-token/`, params, {headers: headers});
    }

    public trainerPayment(card, idConcurso, trainer, payment, pagosEntries, idUsuario, retryTrace): Observable<any>{
        const params = JSON.stringify({ card, idConcurso, trainer, payment, pagosEntries, idUsuario, retryTrace });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/trainer-payment/`, params, {headers: headers});
    }

    public addCard(card): Observable<any>{
        const params = JSON.stringify(card);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/save-card/`, params, {headers: headers});
    }
    
    public deleteCard(customerRefNum, personID): Observable<any>{
      const params = JSON.stringify({ customerRefNum, personID });
      const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
      };

      return this.http.post(`${environment.api.uri}chase/delete-card/`, params, {headers: headers});
    }

    public trainerPaymentToken(customerRefNum, ccAccountNum, idConcurso, trainer, payment, pagosEntries, idUsuario, retryTrace): Observable<any>{
        const params = JSON.stringify({ customerRefNum, ccAccountNum, idConcurso, trainer, payment, pagosEntries, idUsuario, retryTrace });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/trainer-payment-token/`, params, {headers: headers});
    }

    public saveConfig(chaseData: any, showID: string | boolean): Observable<any>{
        const params = JSON.stringify({ chaseData, showID });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}chase/save-config/`, params, {headers: headers});
    }
}
