import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';

@Injectable()
export class ResultadoService extends Api {

  constructor(private http: HttpClient, private firestore: Firestore){
      super();
  }

  public getResultados(ipc): Observable<any>{
      const params = JSON.stringify({ ipc });
      const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
      };

      return this.http.post(`${environment.api.uri}resultados/`, params, {headers: headers});
  }

  /*public guardarFirestore(ipc): Observable<any>{
      const headers = {
          'x-api-key': this.api_key
      };

      return this.http.get(`${environment.api.uri}actualizar/resultados/pruebas.php?id=${ipc}`, {headers: headers});
  }*/

  public guardarConcursoFirestore(idConcurso): Observable<any>{
      const headers = {
          'x-api-key': this.api_key
      };

      return this.http.get(`${environment.api.uri}actualizar/resultados/?id=${idConcurso}`, {headers: headers});
  }

  public guardarHunters(entriesNuevos, posiciones, premios, entrada, ipc, idConcurso, entriesInscritos, entriesPosiciones, numDivisionesPrueba): Observable<any>{
      const params = JSON.stringify({ entriesNuevos, posiciones, premios, entrada, ipc, idConcurso, entriesInscritos, entriesPosiciones, numDivisionesPrueba });
      const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

      return this.http.post(`${environment.api.uri}resultados/resultados-hunters/`, params, {headers: headers});
  }

  public californiaSplit(resultados, ipc): Observable<any>{
    const params = JSON.stringify({ resultados, ipc });
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

    return this.http.post(`${environment.api.uri}resultados/cali-split-results/`, params, {headers: headers});
  }

  public clearResults(ipc): Observable<any>{
      const params = JSON.stringify({ ipc });
      const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

      return this.http.post(`${environment.api.uri}resultados/clear-results/`, params, {headers: headers});
  }

  public cambiarEstatus(ipc, idBinomio, estatus): Observable<any>{
      const params = JSON.stringify({ ipc, idBinomio, estatus });
      const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

      return this.http.post(`${environment.api.uri}jueces/estatusDnf/`, params, {headers: headers});
  }

  public separateCategory(ipc, categoria): Observable<any>{
    const params = JSON.stringify({ ipc, categoria });
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

    return this.http.post(`${environment.api.uri}resultados/separate-category/`, params, {headers: headers});
  }

  public saveResultsPyramid(results: any[], showId: string | number, classNumber: string): Promise<any>{
    const params = JSON.stringify({ results, showId, classNumber });
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}resultados/savePyramid/`, params, {headers: headers})).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async teamCompetitionResults(ipc: string | number): Promise<any>{
    const resultsRef = collection(this.firestore, 'results');
    const q = query(resultsRef, where('ipc', '==', `us_${ipc}`));
    const snapshot = await getDocs(q);
    const teams = snapshot.docs.map(d => ({...d.data(), teamResult: true})).filter((d: any) => d.teamName).sort((a: any, b: any) => a.order - b.order);
    const riders = teams.reduce((acc, team: any) => [...acc, ...team.riders.map(r => ({...r, rank: team.rank||'', order: r.order+(team.order*3)}))], []).sort((a: any, b: any) => a.order - b.order);;
    return [...teams, ...riders];
  }
}
