<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
	<menu-lateral class="left-side-menu"></menu-lateral>
	<div class="content-page p-print-0">
		<div class="content">
			<menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
			<div class="container-fluid">
        <div class="row w-100">
            <div class="col-12 mt-3">
                <div class="card">
                    <div class="card-body">
                        <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
                            <li class="nav-item">
                                <a href="#teamStandings" data-toggle="tab" aria-expanded="true" class="nav-link rounded-0 active">
                                    <i class="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                                    <span class="d-none d-lg-block">Team Standings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#individualStandings" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                                    <i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
                                    <span class="d-none d-lg-block">Individual Standings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                              <a href="#teamMembers" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                                  <i class="mdi mdi-account d-lg-none d-block mr-1"></i>
                                  <span class="d-none d-lg-block">Team Members</span>
                              </a>
                          </li>
                          <li class="nav-item">
                            <a href="#dataSource" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                                <i class="mdi mdi-account d-lg-none d-block mr-1"></i>
                                <span class="d-none d-lg-block">App/Web Data source</span>
                            </a>
                        </li>
                        </ul>
                        <div class="tab-content">
                          <!-- Team Standings -->
                          <ng-container *ngTemplateOutlet="tabTeamStandings"></ng-container>
                          <!-- Individual Standings -->
                          <ng-container *ngTemplateOutlet="tabIndividualStandings"></ng-container>
                          <!-- Team Members -->
                          <ng-container *ngTemplateOutlet="tabTeamMembers"></ng-container>
                          <!-- Data Source -->
                          <ng-container *ngTemplateOutlet="tabDataSource"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tabTeamStandings>
  <div class="tab-pane show active" id="teamStandings">
    <div class="row">
      <div class="col-10">
        <button class="btn btn-success mb-2 mr-3" (click)="recalcularPosiciones()">Update Score</button>
        <button class="btn btn-success mb-2" (click)="guardarPosicionesRecalculadas()" *ngIf="cambiosPendientesGuardar">Confirm Score</button>
      </div>
        <div class="col-10">

            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Team Name</th>
                        <th>Score</th>
                        <ng-container *ngIf="cambiosPendientesGuardar">
                          <th>New Rank</th>
                          <th>Show Rank</th>
                          <th>Added Points</th>
                          <th>New Score</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let team of teams">
                        <td><input type="text" class="form-control form-control-sm" [value]="team.order" (change)="editTeam($event, team, 'order')"></td>
                        <td>{{ team.teamName }}</td>
                        <td><input type="text" class="form-control form-control-sm" [value]="team.points" (change)="editTeam($event, team, 'points')"></td>
                        <ng-container *ngIf="cambiosPendientesGuardar">
                          <td *ngIf="team.newRank">{{ team.newRank }}</td>
                          <td *ngIf="team.showRank">{{ team.showRank }}</td>
                          <td *ngIf="team.addedPoints">{{ team.addedPoints }}</td>
                          <td><b *ngIf="team.puntajeNuevo">{{ team.puntajeNuevo }}</b></td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-2">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th class="text-center">Rank</th>
                        <th  class="text-center">Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">1</td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>
                        <td class="text-center">8</td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>
                        <td class="text-center">6</td>
                    </tr>
                    <tr>
                        <td class="text-center">4</td>
                        <td class="text-center">5</td>
                    </tr>
                    <tr>
                        <td class="text-center">5</td>
                        <td class="text-center">4</td>
                    </tr>
                    <tr>
                        <td class="text-center">6</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td class="text-center">7</td>
                        <td class="text-center">2</td>
                    </tr>
                    <tr>
                        <td class="text-center">8</td>
                        <td class="text-center">1</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #tabIndividualStandings>
  <div class="tab-pane" id="individualStandings">
    <table class="table table-sm">
        <thead>
            <tr>
                <th>Placing</th>
                <th>Name</th>
                <th>Photo</th>
                <th>Team</th>
                <th>Pts</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr [formGroup]="form">
                <td><input type="text" class="form-control form-control-sm" formControlName="placing" required></td>
                <td><input type="text" class="form-control form-control-sm" data-provide="typeahead" id="riderName"></td>
                <td><img [src]="form.value.photo" alt="" *ngIf="form.value.photo" style="max-width: 100px;"></td>
                <td>{{ form.value.team }}</td>
                <td><input type="text" class="form-control form-control-sm" formControlName="pts" required></td>
                <td class="table-action">
                    <a href="javascript: void(0);" class="action-icon" (click)="addRider()">
                        <i class="text-success mdi mdi-plus-circle-outline"></i>
                    </a>
                </td>
            </tr>
            <tr *ngFor="let rider of riders">
                <td><input type="number" class="form-control form-control-sm" [value]="rider.placing" (change)="editRider($event, rider, 'placing')"></td>
                <td><input type="text" class="form-control form-control-sm" [value]="rider.name" (change)="editRider($event, rider, 'name')"></td>
                <td><img [src]="rider.photo" alt="" *ngIf="rider.photo" style="max-width: 100px;"></td>
                <td>{{ rider.team }}</td>
                <td colspan="2"><input type="text" class="form-control form-control-sm" [value]="rider.pts" (change)="editRider($event, rider, 'pts')"></td>
            </tr>
        </tbody>
    </table>
  </div>
</ng-template>

<ng-template #tabTeamMembers>
  <div class="tab-pane" id="teamMembers">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-success btn-sm mb-2 d-flex" (click)="updateTeamLogos()" [disabled]="actualizando">
          <div class="lds-ring my-auto mr-1" *ngIf="actualizando"><div></div><div></div><div></div><div></div></div>
          Update Team Logos
        </button>
      </div>
    </div>
    <table class="table table-sm m-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Photo</th>
          <th>Team</th>
          <th>#FEI</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr [formGroup]="formRider">
          <td><input type="text" class="form-control form-control-sm" formControlName="name"></td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-info btn-sm" onclick="$(this).next().click();">
                <i class="mdi mdi-camera"></i> Photo
              </button>
              <input class="form-control d-none" id="photo-input-form" type="file" (change)="uploadphoto($event)">
              <img *ngIf="formRider.value.photo" [src]="formRider.value.photo" alt="image" class="img-fluid" style="max-width: 6rem;;"/>
            </div>
            <!--input type="text" class="form-control form-control-sm"-->
          </td>
          <td>
            <select class="form-control form-control-sm" formControlName="team">
              <option value=""></option>
              <option [value]="teams.teamName" *ngFor="let teams of teams">{{ teams.teamName }}</option>
            </select>
          </td>
          <td><input type="text" class="form-control form-control-sm" formControlName="fei"></td>
          <td class="text-right"><button class="btn btn-success btn-sm" (click)="addTeamMember()"><i class="mdi mdi-plus"></i></button></td>
        </tr>
        <tr *ngFor="let teamMember of teamMembers; let index = index;">
          <td>{{ teamMember.name }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-info btn-sm" onclick="$(this).next().click();">
                <i class="mdi mdi-camera"></i> Photo
              </button>
              <input class="form-control d-none" [id]="'photo-input-'+index" type="file" (change)="uploadphoto($event, index)">
              <img *ngIf="teamMember.photo" [src]="teamMember.photo" alt="image" class="img-fluid" style="max-width: 6rem;;"/>
            </div>
          </td>
          <td>{{ teamMember.team }}</td>
          <td>{{ teamMember.fei }}</td>
          <td class="table-action">
            <a href="javascript: void(0);" class="action-icon" (click)="deleteTeamMember(teamMember.documentId)">
              <i class="mdi mdi-trash-can-outline"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #tabDataSource>
  <div class="tab-pane" id="dataSource">
    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-1">
        <span class="my-auto">Update results automatically every</span>
        <input class="form-control form-control-sm mx-1" type="number" [(ngModel)]="ms" style="max-width: 75px;">
        <span class="my-auto">milliseconds.</span>
        <input class="my-auto ml-1" type="checkbox" id="switch-interval" data-switch="bool" (change)="changeUpdate($event)">
        <label class="my-auto ml-1" for="switch-interval" [attr.data-on-label]="'On'" data-off-label="Off"></label>
      </div>
      <div class="col-12 mb-1">
        <div class="card">
          <div class="card-body p-2">
            <table class="table table-sm table-bordered mb-1">
              <thead>
                <tr>
                  <th>TA</th>
                  <th>TA 2</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ ta1 }}</td>
                  <td>{{ ta2 }}</td>
                  <td>
                    <select class="form-control form-control-sm" name="estatus_prueba" id="estatus_prueba" [(ngModel)]="classMlsj.estatus_prueba">
                      <option value="0">Not Started</option>
                      <option value="1">Live</option>
                      <option value="2">Finished</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1 d-flex justify-content-end" *ngIf="!this.intervalId">
        <button type="button" class="btn btn-light btn-sm d-flex" [disabled]="saving" (click)="updateFirestore()">
          <i class="mdi mdi-firebase mr-1" style="color: #ff9100" *ngIf="!saving;else spinner"></i>
          <ng-template #spinner>
            <span class="spinner d-flex mr-1"></span>
          </ng-template>
          Update
        </button>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-2">
            <!--h4 class="card-title mb-0"><img [src]="team.teamBadge" style="max-width: 30px; max-height: 30px;" alt=""><b>{{ team.teamName }}</b></h4-->
            <table class="table table-sm table-bordered mb-1">
              <tbody>
                <ng-container *ngFor="let team of teamsMlsj; let last=last;">
                  <!-- Team Headers -->
                  <tr class="small">
                    <th colspan="3">Team</th>
                    <th>Order</th>
                    <th>Rank</th>
                    <th class="bg-round1 text-center" colspan="3">Faults 1</th>
                    <th class="bg-round1">Time 1</th>
                    <th class="bg-round1">Result 1</th>
                    <th class="bg-round2 text-center" colspan="3">Faults 2</th>
                    <th class="bg-round2">Time 2</th>
                    <th class="bg-round2">Result 2</th>
                    <th class="bg-round3 text-center" colspan="2">Faults 3</th>
                    <th class="bg-round3">Time 3</th>
                    <th class="bg-round3">Result 3</th>
                  </tr>
                  <!-- Team Data -->
                  <tr>
                    <td colspan="3">
                      <img [src]="team.teamBadge" style="max-width: 25px; max-height: 25px;" alt=""><b>{{ team.teamName }}</b>
                    </td>
                    <td>{{ team.order }}</td>
                    <td>{{ team.rank }}</td>
                    <td class="bg-round1 text-center" colspan="3">{{ team.faults1 }}</td>
                    <td class="bg-round1">{{ team.time1 }}</td>
                    <td class="bg-round1">{{ team.result1 }}</td>
                    <td class="bg-round2 text-center" colspan="3">{{ team.faults2 }}</td>
                    <td class="bg-round2">{{ team.time2 }}</td>
                    <td class="bg-round2">{{ team.result2 }}</td>
                    <td class="bg-round3" colspan="2">{{ team.faults3 }}</td>
                    <td class="bg-round3">{{ team.time3 }}</td>
                    <td class="bg-round3">{{ team.result3 }}</td>
                  </tr>
                  <!-- Rider Headers -->
                  <tr class="small">
                    <th>Entry</th>
                    <th>Rider</th>
                    <th>Horse</th>
                    <th>Order</th>
                    <th>Rank</th>
                    <th class="bg-round1">Status 1</th>
                    <th class="bg-round1">Time Faults 1</th>
                    <th class="bg-round1">Faults 1</th>
                    <th class="bg-round1">Time 1</th>
                    <th class="bg-round1">Result 1</th>
                    <th class="bg-round2">Status 2</th>
                    <th class="bg-round2">Time Faults 2</th>
                    <th class="bg-round2">Faults 2</th>
                    <th class="bg-round2">Time 2</th>
                    <th class="bg-round2">Result 2</th>
                    <th class="bg-round3">Status 3</th>
                    <th class="bg-round3">Faults 3</th>
                    <th class="bg-round3">Time 3</th>
                    <th class="bg-round3">Result 3</th>
                  </tr>
                  <!-- Riders Data -->
                  <ng-container *ngFor="let rider of team?.riders">
                    <tr>
                      <td>{{ rider?.entryNumber }}</td>
                      <td>{{ rider?.riderName }}</td>
                      <td>{{ rider?.horseName }}</td>
                      <td>{{ rider?.order }}</td>
                      <td>{{ rider?.rank }}</td>
                      <td class="bg-round1">{{ rider?.status1 }}</td>
                      <td class="bg-round1">{{ rider?.timeFaults1 }}</td>
                      <td class="bg-round1">{{ rider?.faults1 }}</td>
                      <td class="bg-round1">{{ rider?.time1 }}</td>
                      <td class="bg-round1">{{ rider?.result1 }}</td>
                      <td class="bg-round2">{{ rider?.status2 }}</td>
                      <td class="bg-round2">{{ rider?.timeFaults2 }}</td>
                      <td class="bg-round2">{{ rider?.faults2 }}</td>
                      <td class="bg-round2">{{ rider?.time2 }}</td>
                      <td class="bg-round2">{{ rider?.result2 }}</td>
                      <td class="bg-round3">{{ rider?.status3 }}</td>
                      <td class="bg-round3">{{ rider?.faults3 }}</td>
                      <td class="bg-round3">{{ rider?.time3 }}</td>
                      <td class="bg-round3">{{ rider?.result3 }}</td>
                    </tr>
                  </ng-container>
                  <!-- Team Separator -->
                  <tr *ngIf="!last">
                    <td colspan="18" class="py-2"></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
