import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { PruebaService } from './../../services/prueba.service';
declare var $: any;

@Component({
  selector: 'app-split-class',
  templateUrl: './split-class.component.html',
  styleUrls: ['./split-class.component.css']
})
export class SplitClassComponent implements OnInit, OnChanges {
  @Input() public prueba: { ipc: number | string, numero: string };
  @Input() public binomios: any[];
  @Output() public onSaved: EventEmitter<any> = new EventEmitter<any>();
  public sections: string[];
  public chunks: number;
  public sectionsMap: Map<string, any[]>;
  public saving: boolean;

  constructor(private pruebaService: PruebaService){
    this.sections = ['A', 'B', 'C', 'D', 'E'];
    this.chunks = 2;
    this.sectionsMap = new Map<string, any[]>();
    this.saving = false;
  }

  ngOnInit(): void {
    this.splitClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.splitClass();
  }


  public get sectionsSelected() : string[] {
    return this.sections.slice(0, this.chunks);
  }

  public splitClass(): void {
    //split array binoimos in chunks keeping the order using function slice
    this.sectionsMap.clear();
    let i = 0;
    let chunkSize = Math.ceil(this.binomios.length / this.chunks);
    for (let section of this.sectionsSelected) {
      this.sectionsMap.set(section, this.binomios.slice(i, i + chunkSize));
      i += chunkSize;
    }
  }

  public async saveSections() {
    this.saving = true;
    const sections = this.sectionsSelected.map((section, i) => {
      let prueba: { numero: string, ipc?: number | string } = {
        numero: `${this.prueba.numero||''}${section}`,
      };
      if(i == 0) prueba.ipc = parseInt(`${this.prueba.ipc}`);

      return {
        section,
        prueba,
        entries: this.sectionsMap.get(section).map(e => ({ ibpc: parseInt(e.ibpc), binomio: e.binomio}))
      };
    });

    //TODO: Reasignar numeros de entrada en las secciones nuevas

    const response = await this.pruebaService.splitClass(this.prueba.ipc, sections);
    if(response.error) {
      this.saving = false;
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      return;
    }

    this.onSaved.emit(response.data);
    this.saving = false;
  }

}
