<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
        <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
        <div class="container-fluid" style="width: 100%; float: left; display: flex;">
          <div class="col-md-12">
            <div class="card margin-top-35">
              <div class="card-body padding-0">
                <div class="col-md-12" style="margin-top:30px;">
                  <div class="col-md-12">
                    <div class="widget widget-fullwidth widget-small table-responsive panel-clientes" [style.margin-left]="(panelAgregarCliente) ? '0%': '0%'" [style.width]="(panelAgregarCliente) ? '78%': '100%'">
                      <div class="col-12" style="margin-top:10px;">
                        <mat-form-field class="col-md-11 col-lg-11 col-xs-11 col-sm-11 example-header float-left">
                          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar...">
                        </mat-form-field>
                        <div class="col-md-1 col-lg-1 col-xs-1 col-sm-1 float-left" style="margin-top: 11px;">
                          <button *ngIf="!panelAgregarCliente" mat-mini-fab type="button" class="btn btn-space btn-info" style="float:right;background-color: #00ca82;" (click)="mostrarPanelAgregar()">
                            <img style="height:25px;" src="assets/images/icons/add-contact.svg" alt="Agregar cliente">
                          </button>
                          <button *ngIf="panelAgregarCliente" mat-mini-fab type="button" class="btn btn-space btn-info" style="float:right;background-color: #e85143;" (click)="cerrarPanel()">
                            <img style="height:40px;margin-top:-8px;" src="assets/images/icons/cancel.svg" alt="Agregar cliente">
                          </button>
                        </div>
                      </div>
                      <mat-table #table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="nombre" class="col-md-3">
                          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Nombre</mat-header-cell>
                          <mat-cell *matCellDef="let cuenta" [routerLink]="['/perfil/',cuenta.id_cliente]">
                            {{ cuenta.nombre }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="contacto" class="col-md-3">
                          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Contacto</mat-header-cell>
                          <mat-cell *matCellDef="let cuenta" [routerLink]="['/perfil/',cuenta.id_cliente]">
                            <strong>{{ cuenta.email }}</strong> <br>
                            {{ cuenta.telefono }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="comprados" class="col-md-1">
                          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Comprados</mat-header-cell>
                          <mat-cell *matCellDef="let cuenta" [routerLink]="['/perfil/',cuenta.id_cliente]">
                            {{ cuenta.comprados }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="enviados" class="col-md-1">
                          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Enviados</mat-header-cell>
                          <mat-cell *matCellDef="let cuenta" [routerLink]="['/perfil/',cuenta.id_cliente]">
                              {{ cuenta.enviados }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="saldo" class="col-md-1">
                          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Saldo</mat-header-cell>
                          <mat-cell *matCellDef="let cuenta" [style.color]="(cuenta.saldo > 0) ? 'green': 'red'" [routerLink]="['/perfil/',cuenta.id_cliente]">
                            <strong>{{ cuenta.saldo}}</strong>
                          </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                      </mat-table>
                      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                    <div class="panel panel-default panel-agregar" [style.margin-left]="(panelAgregarCliente) ? '15px': '0'" [style.width]="(panelAgregarCliente) ? '20%': '0'">
                        <div class="panel-heading" i18n="@@tituloPanelAgregarCaballo">Agregar un nuevo cliente </div>
                        <div class="panel-body">
                          <form #ClienteForm="ngForm" (ngSubmit)="onSubmitAgregarCliente()">
                            <div class="row2">
                              <div class="col2">
                                <div class="form-group">
                                  <label>Nombre<b style="color: red">*</b> <mat-error *ngIf="validadorNombreCliente.invalid && validadorNombreCliente.touched">{{getError(1)}}</mat-error></label>
                                  <input type="text" placeholder="Nombre del cliente" class="form-control" [formControl]='validadorNombreCliente' id="formControlInicio">
                                </div>
                              </div>
                              <div class="col2">
                                <div class="form-group">
                                  <label>Teléfono</label>
                                  <input type="number" placeholder="Teléfono de contacto" class="form-control" [formControl]="validadorTelefonoCliente">
                                </div>
                              </div>
                            </div>
                            <div class="row2">
                              <div class="col2">
                                <div class="form-group">
                                  <label>Correo electrónico<b style="color: red">*</b> <mat-error *ngIf="validadorCorreoCliente.invalid && validadorCorreoCliente.touched">{{getError(2)}}</mat-error> </label>
                                  <input type="email" placeholder="Email" class="form-control" [formControl]="validadorCorreoCliente">
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12" style="text-align: center; margin-top: 30px; margin-bottom: 40px; padding-left: 0px !important;">
                              <button class="btn btn-default" style="margin-right: 1px" (click)="cerrarPanel()" type="button" i18n="@@botonCancelarCaballo">Cancelar</button>
                              <button class="btn btn-primary" type="submit" i18n="@@botonAgregarCliente">Agregar</button>
                            </div>
                          </form>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
