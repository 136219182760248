<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row justify-content-center mt-2">
                    <div class="col-8">
                        <div class="card">
                            <div class="card-body">
                                <div style="float: right;">
                                    <button class="btn btn-info mb-3 mr-1"  type="button" (click)="printAllChecks()"><i class="mdi mdi-printer mr-1"></i>Print all</button>
                                    <button class="btn btn-success mb-3"  type="button" (click)="exportExcel()">Excel</button>
                                </div>
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Entry</th>
                                            <th>Horse</th>
                                            <th>Payee</th>
                                            <th>Amount</th>
                                            <th></th>
                                            <th style="width: 15%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let check of checks; let i = index;">
                                            <td>{{ check.entry }}</td>
                                            <td>{{ check.horse }}</td>
                                            <td>{{ check.payee }}</td>
                                            <td>{{ toNumber(check.amount) | currency }}</td>
                                            <td class="table-action">
                                                <a href="javascript: void(0);" (click)="printCheck(check.entry)" class="action-icon"> <i class="mdi mdi-printer"></i></a>
                                            </td>
                                            <td>
                                                <input type="number" class="form-control" *ngIf="i == 0 && !check.number; else checkNumber;" (change)="guardarNumeroCheque($event)">
                                                <ng-template #checkNumber>
                                                    <!-- *ngIf="check.number" -->
                                                    <input  class="form-control" type="number" [value]="check.number" (change)="cambiarNumeroCheque($event, check.id, check.entry, check.amount)">
                                                </ng-template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td><b>Total</b></td>
                                            <td><b>{{ total | currency }}</b></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>