import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PruebaService } from '../services/prueba.service';
import { ListaService } from '../services/lista.service';
import { EntriesService } from '../services/entries.service';
import { ResultadoService } from '../services/resultado.service';
import { ConcursoService } from '../services/concurso.service';
import { PersonaService } from '../services/persona.service';
import { TraduccionPipe } from '../pipes/traduccion.pipe';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FilterResultadosPipe } from '../pipes/resultados.pipe';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
import { xml2js, js2xml } from 'xml-js';
import { FeiStatus } from './../enums/fei-status';
import { environment } from './../../environments/environment';
import * as moment from 'moment';
declare let $: any;

@Component({
  selector: 'app-hunters-results',
  templateUrl: './hunters-results.component.html',
  styleUrls: ['./hunters-results.component.css'],
  providers: [
    TraduccionPipe,
    CurrencyPipe,
    DatePipe,
    FilterResultadosPipe
]
})
export class HuntersResultsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public pruebas: any[];
  public prueba: any;
  public fases: string;
  public categoriaSeleccionada: string;
  public entriesInicial: any[];
  public ipc: string;
  public classNumber: string;
  public entriesInscritos: any[];
  public loadAddEntry: boolean;
  public loadAddEntryRank: boolean;
  public entriesPosiciones: any[];
  public premio: number;
  public premiosPagarAparte: any[];
  public seleccionarDistribucion: boolean;
  public distribucion: string;
  public prize_distribution: number[];
  public type_prize_distribution: string;
  public name_prize_distribution: string;
  public entries: any[];
  public verifyList: any [];
  public header: string;
  public header2: string;
  public footer: string;
  public nombrePrueba: string;
  public numeroPrueba: string;
  public imprimirInscritos: boolean;
  public imprimirResultados: boolean;
  public numDivisionesPrueba: number;
  public resultados: Array<any>;
  public totalResultados: number;
  public calificada: boolean;
  public listVerified: boolean;
  public premios: any[];
  public categorias: string;
  public mostrarPremios: boolean;
  public caliSplit: boolean;
  public listasCaliSplit: any[];
  public californiaSplitList: any[];
  public hunters: boolean;
  public appliedPrizes: boolean;
  public aplicarPremios:boolean;
  public fecha: Date;
  public riderCategories: string[];
  public applyingPrizes: boolean;
  public designers: any[];
  public judges: any[];
  public judges2: any[];
  public designer: string;
  public judge: string;
  public judge2: string;
  public loading: Map<string, boolean>;
  public visibleElements: any;
  public estatusPrueba: string;
  public tablaEntradasArray: any[];
  public teamCompetition: boolean;
  public version: string;
  public ibpcSwapSeleccionado: string;//Rider swap
  public formRiderSwap: UntypedFormGroup;
  public jinetes: any[];
  public csi: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private pruebaService: PruebaService,
    private listaService: ListaService,
    private entriesService: EntriesService,
    private resultadoService: ResultadoService,
    private concursoService: ConcursoService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private appService: AppService,
    private personaService: PersonaService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.entriesInicial = [];
    this.ipc = '';
    this.classNumber = '';
    this.entriesInscritos = [];
    this.loadAddEntry = false;
    this.loadAddEntryRank = false;
    this.entriesPosiciones = [];
    this.premio = 0;
    this.seleccionarDistribucion = false;
    this.distribucion = '';
    this.prize_distribution = [];
    this.type_prize_distribution = '';
    this.entries = [];
    this.verifyList = [];
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.nombrePrueba = '';
    this.numeroPrueba = '';
    this.prueba = {};
    this.categoriaSeleccionada = '';
    this.imprimirInscritos = false;
    this.imprimirResultados = false;
    this.numDivisionesPrueba = 1;
    this.resultados = [];
    this.fases = '';
    this.totalResultados = 0;
    this.calificada = false;
    this.listVerified = false;
    this.prize_distribution = [];
    this.name_prize_distribution = '';
    this.type_prize_distribution = '';
    this.premios = [];
    this.premiosPagarAparte = [];
    this.mostrarPremios = false;
    this.caliSplit = false;
    this.listasCaliSplit = [];
    this.hunters = false;
    this.appliedPrizes = false;
    this.aplicarPremios = false;
    this.fecha = new Date();
    this.riderCategories = [];
    this.applyingPrizes = false;
    this.designers = [];
    this.judges = [];
    this.judges2 = [];
    this.designer = '';
    this.judge = '';
    this.judge2 = '';
    this.loading = new Map<string, boolean>();
    this.californiaSplitList = [];
    this.visibleElements = {
      caliSaveButton: false,
      caliSplitButton: false,
      FTColumn: false,
      prizeColumn: false,
    };
    this.estatusPrueba = '0';
    this.tablaEntradasArray = [];
    this.teamCompetition = false;
    this.version = environment.version;
    this.ibpcSwapSeleccionado = '';
    this.formRiderSwap = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required])
    });
    this.jinetes = [];
    this.csi = false;
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
    this.getConcurso();
    this.getPremiosPagarAparte();
  }

  private getPruebas(): void{
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.pruebas = response.pruebas;//.filter(p => p.hunters);
          if(this.ipc) this.selectClass({target:{ value: this.ipc }});
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  private getConcurso(): void{
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.aplicarPremios = response.concurso.aplicar_premios;
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the show info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public selectClass(e){
    this.ipc = e.target.value;
    this.seleccionarDistribucion = false;
    this.premio = 0;
    this.entriesInicial = [];
    this.distribucion = '';
    this.prize_distribution = [];
    this.type_prize_distribution = '';
    this.entries = [];
    this.entriesInscritos = [];
    this.entriesPosiciones = [];
    this.verifyList = [];
    this.caliSplit = false;
    this.listasCaliSplit = [];
    this.riderCategories = [];
    this.designers = [];
    this.judges = [];
    this.jinetes = [];
    this.tablaEntradasArray = [];
    this.designer = '';
    this.judge = '';
    this.judge2 = '';
    this.csi = false;
    this.teamCompetition = false;

    const prueba = this.pruebas.find(p => p.ipc == this.ipc);
    if(prueba){
      this.classNumber = prueba.numero;
      this.prize_distribution = prueba.prize_distribution;
      this.name_prize_distribution = prueba.name_prize_distribution;
      this.type_prize_distribution = prueba.type_prize_distribution;
      this.hunters = prueba.hunters;
      this.appliedPrizes = prueba.appliedPrizes;
      this.estatusPrueba = prueba.estatus_prueba||'0';
      this.csi = !['1', '2'].includes(prueba.ambito);
      this.teamCompetition = prueba.modalidad == '71';
      if(this.isValid(prueba.id_designer||'') && prueba.designer){
        this.designers.push({
          id_persona: prueba.id_designer,
          fullname: prueba.designer
        });
        this.designer = prueba.id_designer;
      }
      if(this.isValid(prueba.id_judge||'') && prueba.judge){
        this.judges.push({ id_persona: prueba.id_judge, fullname: prueba.judge });
        this.judge = prueba.id_judge;
      }

      if(this.isValid(prueba.id_judge_2||'') && prueba.judge2){
        this.judges2.push({ id_persona: prueba.id_judge_2, fullname: prueba.judge2 });
        this.judge2 = prueba.id_judge_2;
      }
    }

    this.getResultados(this.ipc);
    this.getPremios();

    if(!this.ipc){
    } else{
      $('#loader').show();
      this.calificada = this.pruebas.find(p => p.ipc == this.ipc)['calificada'];
      this.listVerified = this.pruebas.find(p => p.ipc == this.ipc)['verify_list'] == '[]' ? false : true ;
      this.listaService.getBinomios(this.ipc).then(
        response => {
          if(!response.error){
            this.entries = response.binomios;
            this.entriesInscritos = response.info.verify_list;
            this.entriesPosiciones = response.info.results_list;
            this.numDivisionesPrueba = response.info.divide_class_into;
            this.premio = response.info.premio;
            this.distribucion = response.info.distribution;
            this.prize_distribution = response.info.prize_distribution;
            this.type_prize_distribution = response.info.type_prize_distribution;
            this.seleccionarDistribucion = this.premio > 0;
            this.entriesInicial = response.binomios.map(e => e.cucarda);
            this.nombrePrueba = response.info.nombre;
            this.numeroPrueba = response.info.numero;
            this.mostrarPremios = response.info.status == 2 ? true : false;
            this.tablaEntrada();
            $('#loader').hide();
          } else{
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
            $('#loader').hide();
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          $('#loader').hide();
        }
      );
    }
  }

  public getPremios() {
    this.pruebaService.getPremios(this.ipc).subscribe(
      response => {
        if(!response.error){
          this.premio = response.prueba.premio;
          this.premios = response.prueba.premios;
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the prizes.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public async getResultados(ipc){
      this.ipc = ipc;
      const index = this.pruebas.findIndex(prueba => {
        return prueba.ipc == this.ipc;
      })
      this.prueba = this.pruebas[index];
      this.prueba.categorias = this.prueba.overall ? ['Overall', 'Jr/Am'] : [];
      this.categoriaSeleccionada = this.prueba.categorias.length > 1 ? 'Overall' : '';

      if(!this.teamCompetition){
        const response = await firstValueFrom(this.resultadoService.getResultados(this.ipc)).then(r => ({...r, error: r.message||''})).catch(e => ({error: e||''}));
        if(response.error){
          $('#loader').hide();
          $.NotificationApp.send("Error", response.error, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.error);
          return;
        }
        this.riderCategories = response.categorias||[];
        this.resultados = response.resultados.map(r => {
          r.pagarAparte = r.idPremio ? r.pagarAparte : !this.aplicarPremios;
          r.deduccion *= 100
          return r;
        });
        //Calificados
        this.totalResultados = this.filtroOverall().filter(resultado => resultado.calificado1 == '1' ).length;
        this.fases = this.resultados.length > 0 ? this.resultados[0].fases : '0';
        $('#loader').hide();
      } else{//MLSJ Team Competition
        const response = await this.resultadoService.teamCompetitionResults(this.ipc);
        this.resultados = response.map(r => {
          if(r.status1) r.status1 = FeiStatus[r.status1]||r.status1;
          return r;
        });
        console.log('Resultados prueba de equipos', this.resultados);
      }
  }

  public filtroOverall(): any[]{
    if(this.prueba.overall && this.categoriaSeleccionada != 'Overall'){
        return this.resultados.filter(b => b.categoria.toLowerCase() == 'amateur' || b.categoria.toLowerCase() == 'junior');
    }
    return this.resultados.filter(resultado => resultado.status1 != 'DNS');
  }

  public guardarPremio(premio, index): void{
    if(premio.monto > 0){
      $('#loader').show();
      const deduccion = this.resultados[index].deduccion;
      this.resultados[index].montoAPagar = premio.monto - (premio.monto * deduccion);

      const datos = {
        idUsuario: this.idUsuario,
        ipc: this.ipc,
        idPremio: premio.idPremio,
        idBinomio: premio.idBinomio,
        nombre: premio.posicion,
        monto: Number(premio.monto),
        deduccion: Number(premio.deduccion),
        pagarAparte: premio.pagarAparte ? '1' : '0',
        referencia: premio.referencia
      };

      firstValueFrom(this.pruebaService.agregarPremio(datos)).then(
        response => {
          if (!response.error) {
            const index = this.resultados.findIndex(premio => premio.idBinomio == response.premio.idBinomio);
            response.premio.deduccion *= 100;
            response.premio.deduccion = parseFloat(response.premio.deduccion.toFixed(2));
            response.premio.plantillaPremio = false;
            this.resultados[index].deduccion = response.premio.deduccion;
            this.resultados[index].plantillaPremio = false;
            this.resultados[index].monto = response.premio.monto;
            this.resultados[index].montoDeduccion = response.premio.montoDeduccion||0;
            this.resultados[index].montoAPagar = response.premio.montoAPagar;
            this.resultados[index].idPremio = response.premio.idPremio;
            //this.premios[index] = response.premio;
            this.getPremiosPagarAparte();
            $('#loader').hide();
            $.NotificationApp.send("Saved", "The prize has been saved.", 'bottom-right', '#06d5a1', 'success');
          } else {
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", "It has not been possible to save the prize.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      $.NotificationApp.send("Invalid prize", "The amount of the prize must be greater than zero", 'bottom-right', '#fa5c7c', 'error');
    }
    $('#loader').hide();

  }

  public eliminarPremio(idPremio) {
    this.pruebaService.eliminarPremio(idPremio).subscribe(
      response => {
        if (!response.error) {
          this.selectClass({target:{ value: this.ipc }});
          $.NotificationApp.send("Deleted", "The prize has been deleted.", 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to delete the prize", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public aplicarDistribucionPremios(){
    this.applyingPrizes = true;
    firstValueFrom(this.pruebaService.applyPrizeDistribution(this.ipc, this.idUsuario)).then(
      response => {
        if (!response.error) {
          this.selectClass({target:{ value: this.ipc }});
          $.NotificationApp.send("Applied", "The prize distribution has been applied.", 'bottom-right', '#06d5a1', 'success');
          this.applyingPrizes = false;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          this.applyingPrizes = false;
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to delete the prize", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        this.applyingPrizes = false;
      }
    );
  }

  public plantillaPremios(){
    //Lista de descuentos segun plantilla (25 o 30)
    const premio = this.premio;
    let posicionAnterior = 0;
    let sumaEmpate = 0;
    let indices = [];
    for(let i in this.resultados){
      if(parseInt(i) < 12 && this.resultados[i].status1 == '0'){
        if(this.resultados[i].posicion == posicionAnterior){
          sumaEmpate += this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
          indices.push(i);
        } else{
          if(indices.length > 1){
            for(const indice of indices){
              this.resultados[indice].monto = parseFloat((sumaEmpate / indices.length).toFixed(2));
              this.resultados[indice].plantillaPremio = this.resultados[indice].monto > 0;
            }
          }

          //Despues del if hace esto
          sumaEmpate = this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
          indices = [i];
        }
        this.resultados[i].monto = this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
        this.resultados[i].plantillaPremio = this.resultados[i].monto > 0;
      }
      posicionAnterior = parseInt(this.resultados[i].posicion);
    }
  }

  public confirmAllPrizes(): void{
    $('#loader').show();
    const premios = this.resultados.filter(p => p.plantillaPremio && p.monto > 0).map(p => {
      const datos = {
        idUsuario: this.idUsuario,
        ipc: this.ipc,
        idPremio: p.idPremio,
        idBinomio: p.idBinomio,
        nombre: p.posicion,
        monto: p.monto,
        deduccion: p.deduccion,
        pagarAparte: p.pagarAparte ? 1 : 0,
        referencia: p.referencia
      };

      return this.pruebaService.agregarPremio(datos).toPromise().then(response => {
        if(!response.error){
          const index = this.resultados.findIndex(p => p.idBinomio == response.premio.idBinomio);
          response.premio.deduccion *= 100;
          response.premio.deduccion = parseFloat(response.premio.deduccion.toFixed(2));
          response.premio.plantillaPremio = false;
          this.resultados[index].deduccion = response.premio.deduccion;
          this.resultados[index].plantillaPremio = false;
          this.resultados[index].monto = response.premio.monto;
          this.resultados[index].montoAPagar = response.premio.montoAPagar;
          //this.premios[index] = response.premio;
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      }).catch(reason => {
        $.NotificationApp.send("Error", reason, 'bottom-right', '#fa5c7c', 'error');
        console.log(reason);
      });
    });
    Promise.all(premios).then(() => {
      this.getPremiosPagarAparte();
      $('#loader').hide();
      $.NotificationApp.send("Saved", "The prizes have been saved.", 'bottom-right', '#06d5a1', 'success');
    });
  }

  public mostrarConfirmarTodo(): boolean{
    return true;
  }

  public getPremiosPagarAparte() {
    this.pruebaService.getPremiosPagarAparte(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.premiosPagarAparte = response.premios;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the prizes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public guardarReferenciaPremio(idPremio, e) {
    const referencia = e.target.value;

    this.pruebaService.guardarReferenciaPremio(idPremio, referencia).subscribe(
      response => {
        if (!response.error) {
          this.getPremiosPagarAparte();
          $.NotificationApp.send("Updated", "The prize has been updated", 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It was not possible to update the prize", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public guardarListaVerify() {
    let list = JSON.stringify(this.verifyList);
    this.pruebaService.guardarCampo('verify_list', 'json', list, this.idConcurso, this.ipc).subscribe(
        response => {
            if(!response.error){
                const index = this.pruebas.findIndex(prueba => {
                    return prueba.ipc == this.ipc;
                })
                this.pruebas[index].verify_list = list;
                $.NotificationApp.send("Saved", "The list has been saved correctly.", 'bottom-right', '#fa5c7c', 'success');
            } else{
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
            }
            $('#loader').hide();
        },
        error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", 'Verify list could not be saved.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        }
    );
  }

  public async addEntry(e): Promise<void>{
    const entry = e.target.value;
    if(entry){
      //No permitir repetidos
      const index = this.entriesInscritos.findIndex(e => e.entry == entry);
      if(index > -1){
        $.NotificationApp.send("Error", 'This entry is already registered in the class', 'bottom-right', '#fa5c7c', 'error');
        e.target.value = '';
        return;
      }
      const ent = this.entries.find(e => e.cucarda == entry);
      if(ent){
        this.entriesInscritos.push({
          entry,
          binomio: ent.idBinomio,
          horse: ent.caballo,
          rider: `${ent.nombreJinete} ${ent.apellidosJinete}`.trim()
        });
        e.target.value = '';
        return;
      }
      //this.loadAddEntry = true;
      const info = await this.entriesService.getEntryInfo(entry, this.idConcurso).toPromise();
      if(!info.error){
        if(info.horse){
          this.entriesInscritos.push({
            entry,
            binomio: info.id_binomio,
            horse: info.horse,
            rider: info.rider
          });
          $.NotificationApp.send("Alert", 'This entry is not registered in the class.', 'bottom-right', '#ffbc00', 'warning');
        } else{
          $.NotificationApp.send("Error", 'This entry doesn\'t exist.', 'bottom-right', '#fa5c7c', 'error');
        }
      } else{
        $.NotificationApp.send("Error", info.message, 'bottom-right', '#fa5c7c', 'error');
        console.log(info.message);
      }
      e.target.value = '';
      this.tablaEntrada();
      this.loadAddEntry = false;
    }
  }

  public async editEntryRank(e, i): Promise<void>{
    const entry = e.target.value;
    if(entry){
      //No permitir repetidos
      const index = this.entriesInscritos.findIndex(e => e.entry == entry);
      if(index > -1){
        $.NotificationApp.send("Error", 'This entry is already registered in the class', 'bottom-right', '#fa5c7c', 'error');
        this.entriesInscritos.splice(i, 1);
        //e.target.value = '';
        return;
      }
      const ent = this.entries.find(e => e.cucarda == entry);
      if(ent){
        this.entriesInscritos[i] = {
          entry,
          horse: ent.caballo,
          rider: `${ent.nombreJinete} ${ent.apellidosJinete}`.trim()
        };
        return;
      }
      this.loadAddEntry = true;
      const info = await this.entriesService.getEntryInfo(entry, this.idConcurso).toPromise();
      if(!info.error){
        if(info.horse){
          this.entriesInscritos[i] = {
            entry,
            horse: info.horse,
            rider: info.rider
          };
        } else{
          $.NotificationApp.send("Error", 'This entry doesn\'t exist.', 'bottom-right', '#fa5c7c', 'error');
        }
      } else{
        $.NotificationApp.send("Error", info.message, 'bottom-right', '#fa5c7c', 'error');
        console.log(info.message);
      }
      //e.target.value = '';
      this.loadAddEntry = false;
    } else{
      this.entriesInscritos.splice(i, 1);
    }
    this.tablaEntrada();
  }

  public async addEntryRank(e, i): Promise<void>{
    const entry = e.target.value;
    if(entry){
      //No permitir repetidos
      const index = this.entriesPosiciones.findIndex(e => e.entry == entry);
      if(index > -1){
        $.NotificationApp.send("Error", 'This entry is already registered in the class', 'bottom-right', '#fa5c7c', 'error');
        e.target.value = '';
        return;
      }
      if(this.entriesInscritos.map(e => e.entry).indexOf(entry) == -1){
        $.NotificationApp.send("Error", 'This entry is not registered in the class or was not validated.', 'bottom-right', '#fa5c7c', 'error');
        e.target.value = '';
        this.loadAddEntryRank = false;
        return;
      }
      const ent = this.entriesInscritos.find(e => e.entry == entry);

      this.entriesPosiciones.push({
        rank: this.entriesPosiciones.filter(e => e.categoria == i).length+1,
        entry,
        binomio: ent.binomio,
        categoria: i,
        horse: ent.horse,
        rider: ent.rider,
        prize: this.prize_distribution[this.entriesPosiciones.filter(e => e.categoria == i).length] ? (this.type_prize_distribution == 'percentage' ? this.prize_distribution[this.entriesPosiciones.filter(e => e.categoria == i).length] * this.premio : this.prize_distribution[this.entriesPosiciones.filter(e => e.categoria == i).length]) : 0
      });

      e.target.value = '';
      this.loadAddEntryRank = false;
      this.tablaEntrada();
    }
  }

  public cambiarPosicion(e, entry){
    const index = this.entriesPosiciones.findIndex(ep => ep.entry == entry);
    const rank = isNaN(parseInt(e.target.value)) ? this.entriesPosiciones[index].rank  : parseInt(e.target.value);
    e.target.value = rank;
    this.entriesPosiciones[index].rank = rank;
  }

  public editPrize(e, i){
    const prize = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
    this.entriesPosiciones[i].prize = prize;
  }

  public isEntryInList(entry): boolean{
    return this.entriesInicial.indexOf(entry) > -1;
  }

  public async save(): Promise<void>{
    $('#loader').show();
    this.entriesInscritos.map(e => {
      e.didHorseFalls = e.didHorseFalls||false;
      e.didRiderFalls = e.didRiderFalls||false;

      return e;
    });

    const entriesEliminados = this.entriesInscritos.filter(e => e.didHorseFalls || e.didRiderFalls).map(e => e.entry);

    //Lista de binomios np
    const entriesNp = this.entriesInicial.filter(e => (this.entriesInscritos.findIndex(ei => ei.entry == e) == -1) && !entriesEliminados.includes(e));

    //Lista de binomios a inscribir
    const entriesNuevos = this.entriesInscritos.filter(e => this.entriesInicial.indexOf(e.entry) == -1);

    //Lista de binomios a poner resultados
    let posiciones = [];
    for (let index = 0; index < this.numDivisionesPrueba; index++) {
      posiciones = posiciones.concat(this.entriesPosiciones.filter(e => e.categoria == index).map((e, i) =>({entry: e.entry, rank: e.rank, categoria: e.categoria, status: '0', prize: e.prize, binomio: e.binomio})));
    }
    //Sacar binomios inscritos que no estan en posiciones
    for(const e of this.entriesInscritos.filter(e => (this.entriesPosiciones.findIndex(ep => ep.entry == e.entry) == -1) && (!e.didHorseFalls && !e.didRiderFalls))){
      posiciones.push({entry: e.entry, rank: posiciones.filter(e => e.categoria == 0).length+1, categoria: 0, status: 'DNP', prize: 0, binomio: e.binomio});
    }
    //Lista de binomios eliminados por caida
    for(const e of this.entriesInscritos.filter(e => e.didHorseFalls || e.didRiderFalls)){
      posiciones.push({entry: e.entry, rank: posiciones.filter(e => e.categoria == 0).length+1, categoria: 0, status: 'E', prize: 0, binomio: e.binomio, didHorseFalls: e.didHorseFalls, didRiderFalls: e.didRiderFalls});
    }
    //Posiciones de binomios np
    for(const e of entriesNp){
      posiciones.push({entry: e, rank: posiciones.filter(e => e.categoria == 0).length+1, categoria: 0, status: 'DNS', prize: 0, binomio: this.entries.find(entr => entr.cucarda == e)['idBinomio']});
    }

    //Lista de premios a guardar
    const premios = posiciones.filter(e => e.prize > 0);

    const entrada = this.entriesInscritos.concat(entriesNp.map(e => this.entries.find(entr => entr.cucarda == e)).map(e => ({ entry: e.cucarda, horse: e.caballo, rider: `${e.nombreJinete} ${e.apellidosJinete}`,binomio: e.idBinomio}))).map((e, i) => {
      e.entrada = i+1;
      return e;
    });

    posiciones.map(p => {
      p.didHorseFalls = p.didHorseFalls||false;
      p.didRiderFalls = p.didRiderFalls||false;

      return p;
    });

    //Incribir, posiciones, premios ipc concurso
    this.resultadoService.guardarHunters(entriesNuevos, posiciones, premios, entrada, this.ipc, this.idConcurso, this.entriesInscritos, this.entriesPosiciones, this.numDivisionesPrueba).subscribe(
      response => {
        if(!response.error){
          this.selectClass({target:{ value: this.ipc }}); //jala resultados de la prueba
          //this.resultadoService.guardarFirestore(this.ipc).toPromise();
          this.appService.deleteEntriesAndUpdate(this.idConcurso, this.ipc);

          $.NotificationApp.send("Success", "Results have been saved successfully.", 'bottom-right', '#3c763d', 'success');
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the results.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public removeInscrito(i, entry){
    const index = this.entriesPosiciones.findIndex(e => e.entry == entry);
    if(index == -1){
      this.entriesInscritos.splice(i, 1);
      this.tablaEntrada();
    } else{
      $.NotificationApp.send("Error", "This entry is registered in the prize table, please remove the prize to continue.", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public removeCalificado(entry){
    const index = this.entriesPosiciones.findIndex(ep => ep.entry == entry);
    this.entriesPosiciones.splice(index, 1);
  }

  public imprimirTablaInscritos(): void{
    this.imprimirInscritos = true;
    this.imprimirResultados = false;
    setTimeout(() => {
      window.print();
    });
  }

  public imprimirTablaResultados(): void{
    this.imprimirInscritos = false;
    this.imprimirResultados = true;
    this.caliSplit = false;
    this.listasCaliSplit = [];
    setTimeout(() => {
      window.print();
    });
  }

  public imprimirTablaResultadosCaliSplit(): void{
    this.imprimirInscritos = false;
    this.imprimirResultados = true;
    if( !this.caliSplit) {
      this.caliSplit = true;
      this.doCaliSplit();
    }
    setTimeout(() => {
      window.print();
    });
  }

  public tablaPosiciones(): any[]{
    let posiciones = this.entriesPosiciones.map((e, i) =>({entry: e.entry, rank: i+1, horse: e.horse, rider: e.rider, status: '0', prize: e.prize}));
    return posiciones;
  }

  public tablaEntrada(){
    console.log('tablaEntrada');
    const entriesNp = this.entriesInicial.filter(e => this.entriesInscritos.findIndex(ei => ei.entry == e) == -1).map(e => this.entries.find(ent => ent.cucarda == e)).map(e => ({
      entry: e.cucarda||'',
      horse: e.caballo||'',
      rider: `${e.nombreJinete||''} ${e.apellidosJinete||''}`.trim(),
      message: 'DNS'
    }));
    const entriesNuevos = this.entriesInscritos.filter(e => this.entriesInicial.indexOf(e.entry) == -1).map(e => {
      e.message = 'Did not enter';
      return e;
    });

    this.tablaEntradasArray = this.entriesInscritos.map(e => ({
      entry: e.entry,
      horse: e.horse,
      rider: e.rider,
      message: 'Entered',
    })).concat(entriesNuevos.concat(entriesNp));
    console.log(this.tablaEntradasArray);
  }

  public filtroEntriesPosiciones(categoria): any[]{
    return this.entriesPosiciones.filter(e => e.categoria == categoria);
  }

  public exportarExcel(){
      const fases = this.resultados[0].fases;
      const categorias = this.prueba.overall ? ['Overall', 'Jr/Am'] : ['Overall'];
      const wb = {
          SheetNames:[],
          Sheets:{}
      };
      const wopts: XLSX.WritingOptions = {
          bookType: 'xlsx',
          bookSST: false,
          type:'binary'
      };

      for(const categoria of categorias){
          const resultados = this.resultados.filter(b => b.status1 != '' && (categoria != 'Overall' ? (b.categoria.toLowerCase() == 'amateur' || b.categoria.toLowerCase() == 'junior') : true)).map(resultado => {
              let res = {};
              res['Rank'] = resultado.status1 == 'DNP' ? 'DNP' : resultado.posicion;
              res['Back #'] = resultado.cucarda;
              res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'ID' : 'ID'}`] = resultado.reg_jinete;
              res['Rider'] = `${resultado.nombre_jinete} ${resultado.apellido_jinete}`;
              res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'Club' : 'Club'}`] = `${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? resultado.club.toUpperCase() : resultado.pais.toUpperCase()}`;
              res[`${this.prueba.ambito == '1' || this.prueba.ambito == '2' ? 'ID ' : 'ID '}`] = resultado.reg_caballo;
              res['Horse'] = resultado.caballo;
              res['Orer'] = resultado.entrada;
              if( !this.listVerified) {
                res['F | T'] = `${resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + `${this.prueba.modalidad != '8' ? ' | ' : ' + '}` + resultado.tiempo1 : resultado.status1) : ''}`;
              }
              if(this.fases == '2')
                  res['F | T '] = `${resultado.calificado2 == '1' ? (resultado.status2 == '0' ? resultado.totales2 + ' | ' + resultado.tiempo2 : resultado.status2) : ''}`;
              if(this.prueba.modalidad == '8')
                  res['Result'] = `${resultado.calificado1 == '1' ? (resultado.status1 == '0' ? this.formatoSuma239(resultado.totales1 + resultado.tiempo1) : resultado.status1) : ''}`
              if(this.prueba.premio > 0)
                  res['Prize'] = `${this.currencyPipe.transform(resultado.premio) ? this.currencyPipe.transform(resultado.premio) : ''}`

              return res;
          }).map((b, i) => {
              b['Posicion'] = categoria != 'Overall' ? i+1 : b['Posicion'];

              return b;
          });

          const ws = XLSX.utils.json_to_sheet(resultados);
          wb.SheetNames.push(`${categoria ? categoria.replace('/', '-') : ''}`);
          wb.Sheets[`${categoria ? categoria.replace('/', '-') : ''}`] = ws;
      }

      const wbout = XLSX.write(wb,wopts);

      saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), `${this.prueba.numero} - ${this.prueba.nombre}.xlsx`);
  }

  public formatoSuma239(suma: number): number{
    return +suma.toFixed(12);
  }

  public escapeXml(unsafe: string): string {
    return unsafe.replace(/&/g, "&amp;").replace(/'/g, "&apos;");
  }

  public s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  public clearResults(){
    $('#loader').show();
    this.resultadoService.clearResults(this.ipc).subscribe(
      response => {
        if(!response.error){
          this.getPruebas();
          //this.resultadoService.guardarFirestore(this.ipc).toPromise();
          this.appService.updateShow(this.idConcurso, this.ipc);

          $.NotificationApp.send("Success", "Results have been cleared successfully.", 'bottom-right', '#3c763d', 'success');
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the results.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public cambiarEstatus(binomio, estatus){
    $('#loader').show();
    this.resultadoService.cambiarEstatus(this.ipc, binomio.idBinomio, estatus).subscribe(
      response => {
        if(!response.error){
          this.getResultados(this.ipc);
          //this.resultadoService.guardarFirestore(this.ipc).toPromise();
          this.appService.updateShow(this.idConcurso, this.ipc);

          //$.NotificationApp.send("Success", "Results have been cleared successfully.", 'bottom-right', '#3c763d', 'success');
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the results.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public arePrizesAssigned(): boolean{
    //console.log('Are prizes assigned');
    //console.log(this.resultados);
    return !this.resultados.find(r => r.idPremio);
  }

  public async separateCategory(categoria){
    if (! confirm("Do you want to split categories?")) {
      return;
    }
    $('#loader').show();
    const response = await this.resultadoService.separateCategory(this.ipc, categoria).toPromise();
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      $('#loader').hide();
      return;
    }
    this.getPruebas();
    this.getResultados(this.ipc);
    await this.appService.deleteEntriesAndUpdate(this.idConcurso, this.ipc);
    await this.appService.deleteEntriesAndUpdate(this.idConcurso, response.ipcCategoria);
    $('#loader').hide();
  }

  public getPersonas(e, type: string): void {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            if(type == 'judge'){
              this.judges = response.personas;
            } else if(type == 'judge2'){
              this.judges2 = response.personas;
            } else if(type == 'designer'){
              this.designers = response.personas;
            } else if(type == 'rider'){
              this.jinetes = response.personas;
            }
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the designers, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      if(type == 'judge'){
        this.judges = [];
      } else if(type == 'judge2'){
        this.judges2 = [];
      } else if(type == 'designer'){
        this.designers = [];
      } else if(type == 'rider'){
        this.jinetes = [];
      }
    }
  }

  public displayPersona(idPersona): string {
    const persona = [...this.designers,...this.judges,...this.judges2].find(p => p.id_persona == idPersona);

    return persona ? persona.fullname : idPersona;
  }

  async selectJudge(){
    const response: any = await this.personaService.setJudgeUsef(this.ipc, this.judge).catch(reason => ({error: true, message: reason.error.message||(reason.error||(reason.message||(reason||'Unknown error')))}));
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    $.NotificationApp.send("Success", "Judge has been saved successfully.", 'bottom-right', '#3c763d', 'success');
  }

  async selectJudge2(){
    const response: any = await this.personaService.setJudge2Usef(this.ipc, this.judge2).catch(reason => ({error: true, message: reason.error.message||(reason.error||(reason.message||(reason||'Unknown error')))}));
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    $.NotificationApp.send("Success", "Judge has been saved successfully.", 'bottom-right', '#3c763d', 'success');
  }

  async selectDesigner(){
    const response: any = await this.personaService.setDesignerUsef(this.ipc, this.designer).catch(reason => ({error: true, message: reason.error.message||(reason.error||(reason.message||(reason||'Unknown error')))}));
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    $.NotificationApp.send("Success", "Designer has been saved successfully.", 'bottom-right', '#3c763d', 'success');
  }

  public isValid(id: string | number): boolean{
    return id && !isNaN(parseInt(`${id}`));
  }

  async dropEntry(e: string){
    console.log('Dropping Entry: ', e);
    this.loading.set(e, true);
    let entry = this.entries.find(ei => e == ei.cucarda) ?? this.resultados.find(ei => e == ei.cucarda);

    console.log('Entry: ', entry);
    if(!entry){
      //Handle error.

      this.loading.set(e, false);
      return;
    }

    const response = await firstValueFrom(this.entriesService.eliminarBinomioNP(entry.idBinomio, this.ipc, this.idConcurso, this.idUsuario));
    if(response.error){
      this.loading.set(e, false);
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    await this.appService.deleteEntriesAndUpdate(this.idConcurso, this.ipc);

    this.entriesInicial = this.entriesInicial.filter(ei => ei != entry.cucarda);
    this.entries = this.entries.filter(ei => ei.cucarda != entry.cucarda);

    this.loading.set(e, false);
    this.selectClass({target:{ value: this.ipc }}); //obtener resultados de la tabla
    $.NotificationApp.send("Success", "Entry has been removed successfully.", 'bottom-right', '#3c763d', 'success');
  }

  public async setHeight(height: string, r: any){
    const response = await this.entriesService.setHeight(height, this.ipc, r.idBinomio);
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    //Actualizar arreglo de resultados
    const index = this.resultados.findIndex(re => re.idBinomio == r.idBinomio);
    this.resultados[index].height = height;

    $.NotificationApp.send("Success", "Height has been saved successfully.", 'bottom-right', '#3c763d', 'success');
  }

  public async setDeclaredSectionCode(declaredSectionCode: string, r: any){
    const response = await this.entriesService.setDeclaredSectionCode(declaredSectionCode, this.ipc, r.idBinomio);
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    //Actualizar arreglo de resultados
    const index = this.resultados.findIndex(re => re.idBinomio == r.idBinomio);
    this.resultados[index].declaredSectionCode = declaredSectionCode;

    $.NotificationApp.send("Success", "Declared Section Code has been saved successfully.", 'bottom-right', '#3c763d', 'success');
  }

  public onPyramidResultsSaved(response: any){
    console.log('Pyramid Results Saved: ', response);
    this.selectClass({target:{ value: this.ipc }});
  }

  // CALIFORNIA SPLIT FUNCTIONS //
  public doCaliSplit(){
    let lista = [];
    let lista2 = [];
    let current_pos = 0;
    this.resultados.forEach(r=> {
      if( current_pos == r.posicion) {
        current_pos = r.posicion;
        lista.push(r);
      }
      if( current_pos != r.posicion) {
        current_pos = r.posicion;
        lista2.push(r);
      }
    });
    this.listasCaliSplit.push(lista2);
    this.listasCaliSplit.push(lista);
  }

  public openCaliSplitModal() {
    this.visibleElements.caliSplitButton = false;
    this.visibleElements.caliSplitSave = false;
    this.visibleElements.FTColumn = false;
    this.visibleElements.prizeColumn = false;
    this.californiaSplitList = [];
    const upTo:any = document.getElementById('caliSplitValue') as HTMLInputElement;
    upTo.value = '';
    $('#modal-cali-split').modal('show');
  }

  public validateCaliSplitInput(e: any): void {
    e.target.value = parseInt(e.target.value)
    const maxSplit = Math.floor(this.resultados.length / 2);
    if(e.target.value > maxSplit ){
      e.target.value = maxSplit;
      $.NotificationApp.send("Atention!", "Can't split higher than " + maxSplit, 'bottom-right', '#fa5c7c', 'warning');
    }
    const value = e.target.value;
    console.log('Value: ', value);
    if(value && value != '' && value > 0 ){
      this.visibleElements.caliSplitButton = true;
    } else{
      this.visibleElements.caliSplitButton = false;
    }
  }

  public californiaSplit(): void {
    this.californiaSplitList = [];
    let prizes:any[] = []; //arreglo de premios asignados a la prueba
    const stagger:any = (document.getElementById('caliSplitValue') as HTMLInputElement).value; //obtiene el valor del stagger del input en el modal

    const finalResults = this.resultados.map((r,index) => {
      r.rank_split = Math.floor(index / 2) + 1; //asigna la posicion de la lista dividida en 2
      return { ...r, originalIndex: index }; //retorna el objeto con la posicion original
    }).sort((a, b) => a.order - b.order);

    finalResults.forEach( (row,index) => {
      if (index > (stagger * 2) - 1) row.rank_split = row.posicion; //si la posicion es mayor al stagger, le asigna el ranking original
      // se crea un arreglo con los premios ya asignados
      if(row.premio && row.premio != ""){
        prizes.push({
          rank: row.posicion,
          prize: row.premio,
          pagarAparte: row.pagarAparte
        });
      }
      let premio = prizes.find(p => p.rank == row.rank_split);
      if (premio) {
        row.premio = premio.prize;
        row.montoAPagar = row.premio;
        row.monto = row.premio;
        row.pagarAparte = premio.pagarAparte;
      }
      this.visibleElements.caliSplitSave = true;
      if(prizes.length > 0) this.visibleElements.FTColumn = true;
    });

    this.californiaSplitList = finalResults;
    console.log('California Split List:', this.californiaSplitList);
  }

  public async saveCaliSplitResults(): Promise<void>{
    $('#loader').show();
    this.resultadoService.californiaSplit(this.californiaSplitList,this.ipc).subscribe(
      response => {
        if(!response.error){
          console.log('California Split Results Saved: ', response);

          this.selectClass({target:{ value: this.ipc }});
          $('#modal-cali-split').modal('hide');
          $.NotificationApp.send("Success", "Results have been saved successfully.", 'bottom-right', '#3c763d', 'success');
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the results.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  async setClassFinished(){
    $('#loader').show();
    const response = await this.pruebaService.saveStatus(this.ipc, '2');
    if(response.error){
      $('#loader').hide();
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.estatusPrueba = '2';
    await this.appService.updateClass(this.idConcurso, this.ipc);
    $('#loader').hide();
    $.NotificationApp.send("Success", "Class has been finished successfully.", 'bottom-right', '#3c763d', 'success');
  }

  public exportarXML(){
      const fecha = moment().format('YYYY-MM-DDTHH:mm:ssZ');
      const fechaPrueba = `${this.prueba.inicioFei}T00:00:00+00:00`;
      const showCountry = this.prueba.organizingCountry;
      const showStart = this.prueba.inicioFei;
      const showEnd = this.prueba.finConcurso;
      const eventCode = this.prueba.eventCode;
      const numPrueba = this.prueba.numero.replace('#', '');
      const nombrePrueba = this.prueba.nombre;
      const monedaPremio = this.prueba.prizeCurrency;
      const claveModalidad = `${this.prueba.claveModalidad}${this.prueba.gp ? '-GP' : ''}${this.prueba.world_cup ? '-W' : ''}`;
      const isWNominated = this.prueba.world_cup ? 'true' : 'false';
      const competitionID = this.prueba.competitionID;
      const competitionIDArray = competitionID.split('_');
      const showId = `${competitionIDArray[0]}_${competitionIDArray[1]}_${competitionIDArray[2]}`;
      //const eventId = this.prueba.eventId;
      const eventId = competitionID.substring(0, competitionID.length - 3);
      const venueName = this.prueba.venueName;

      const resultados = this.resultados.map(resultado => {
        const premio = isNaN(parseFloat(resultado.premio)) ? 0 : parseFloat(resultado.premio);
        //Rider info
        const riderFei = resultado.reg_jinete;
        const riderName = resultado.nombre_jinete;
        const riderLastname = resultado.apellido_jinete;
        const riderNationality = resultado.pais;
        //Horse info
        const horseFei = resultado.reg_caballo.toUpperCase();
        const horseName = resultado.caballo;

        //Combination info
        let status = '';
        let rank = '';
        let statusRound1 = '';
        let totalFaults1 = '';
        let obstacleFaults1 = '';
        let timeFaults1 = '';
        let timeRound1 = '';
        let tagRound2 = '';
        let tagCloseRound2 = '';
        let showResult2 = false;
        let statusRound2 = '';
        let totalFaults2 = '';
        let obstacleFaults2 = '';
        let timeFaults2 = '';
        let timeRound2 = '';
        //Estatus R es para los binomios con posicion, se valida que no tenga estatus en la primera ronda y en caso de que la prueba sea de 2 fases especial se valida que no tenga estatus en la segunda ronda
        if(resultado.status1 == '0' && !(this.prueba.modalidad == '41' && resultado.status2 != '0')){
          status = 'R';
          //El rank solo se pone cuando el estatus 1 es 0 y en caso de que la prueba sea 2 fases especial y tenga estatus en la segunda vuelta el rank no se pone
          rank = `Rank="${resultado.posicion}"`;
          //Si no tiene estatus en la primera ronda se agrega el campo de faltas
          totalFaults1 = `TotalFaults="${resultado.totales1}"`;
          obstacleFaults1 = `ObstacleFaults="${resultado.faltas1}"`;
          timeFaults1 = `TimeFaults="${resultado.faltasTpo1}"`;
          //Para el tiempo aplica igual que en las faltas, solo en caso de no tener estatus
          timeRound1 = `Time="${resultado.tiempo1}"`;
          //Si la prueba es de 2 fases especial y tiene estatus en la segunda ronda se le asigna el estatus de la segunda ronda
        } else if(this.prueba.modalidad == '41' && resultado.status2 != '0'){
          switch(resultado.status2){
            case 'DNS':
              status = 'WD';
              break;
            case 'E':
              status = 'EL';
              break;
            case 'R':
              status = 'RET';
              break;
            case 'RT':
              status = 'RET';
              break;
            default:
              status = resultado.status2;
              break;
          }
          statusRound1 = `Status="${status}"`;
          //En caso de que el estatus de la primera ronda sea diferente de 0 se pone ese estatus
        } else if(resultado.status1 != '0'){
          switch(resultado.status1){
            case 'DNS':
              status = 'WD';
              break;
            case 'E':
              status = 'EL';
              break;
            case 'R':
              status = 'RET';
              break;
            case 'RT':
              status = 'RET';
              break;
            default:
              status = resultado.status1;
              break;
          }
          statusRound1 = `Status="${status}"`;
        }

        //Si esta calificado en la ronda 2 y en caso de que sea 2 fases especial que no tega estatus 2
        if(resultado.calificado2 == '1' && !(this.prueba.modalidad == '41' && resultado.status2 != '0')){
          /**
           * Si la prueba es de 2 recorridos como 2 fases o winning round se pone el numero de la ronda 2, en caso de las demas modalidades (desempate, desempate sucesivo, etc.) se pone JumpOff
           * 4 FEI Two Phases
           * 40 II.2d Power and Speed
           * 41 Special Two Phases
           * 42 FEI Two Rounds
           */
          const modalidades2Recorridos = ['2','4','40','41','42'];
          if(modalidades2Recorridos.includes(this.prueba.modalidad)){
            tagRound2 = '<Round Number="2">';
            tagCloseRound2 = '</Round>';
          } else{
            tagRound2 = '<JumpOff Number="1">';
            tagCloseRound2 = '</JumpOff>';
          }
          showResult2 = true;
        }

        //Si tiene estatus en la segunda ronda y debe ser visible se pone el estatus
        if(resultado.status2 != '0' && showResult2){
          switch(resultado.status2){
            case 'DNS':
              statusRound2 = 'Status="WD"';
              break;
            case 'E':
              statusRound2 = 'Status="EL"';
              break;
            case 'R':
              statusRound2 = 'Status="RET"';
              break;
            case 'RT':
              statusRound2 = 'Status="RET"';
              break;
            default:
              statusRound2 = `Status="${resultado.status2}"`;
              break;
          }
        } else if(resultado.status2 == '0' && showResult2){//En caso de no tener estatus se muestran las faltas y el tiempo
          totalFaults2 = `TotalFaults="${resultado.totales2}"`;
          obstacleFaults2 = `ObstacleFaults="${resultado.faltas2}"`;
          timeFaults2 = `TimeFaults="${resultado.faltasTpo2}"`;
          timeRound2 = `Time="${resultado.tiempo2}"`;
        }

        if(resultado.status1 == 'WD' || resultado.status1 == 'DNS'){
          return '';
        }

        return `<Participation>
                <Athlete FEIID="${riderFei}" FirstName="${riderName}" FamilyName="${riderLastname}" CompetingFor="${riderNationality}" />
                <Horse FEIID="${horseFei}" Name="${horseName}" />
                ${premio ? `<PrizeMoney Value="${premio}" />` : ''}
                <Complement IsWNominated="false" />
                <Position Status="${status}" ${rank} />
                <Round Number="1">
                  <Result ${statusRound1} ${totalFaults1} ${timeRound1} ${obstacleFaults1} ${timeFaults1} />
                </Round>
                ${tagRound2}
                  ${showResult2 ? '<Result' : ''} ${statusRound2} ${totalFaults2} ${timeRound2} ${obstacleFaults2} ${timeFaults2} ${showResult2 ? '/>' : ''}
                ${tagCloseRound2}
              </Participation>`;
      }).join('\n\t\t\t\t\t\t');

      const xml = `<?xml version="1.0" encoding="utf-8"?>
                    <HorseSport xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.fei.org/Result">
                    <Generated Date="${fecha}" Software="EquestrianDigital" SoftwareVersion="4.1" Organization="ED" />
                    <EventResult>
                    <Show FEIID="${showId}" StartDate="${showStart}" EndDate="${showEnd}">
                      <Venue Name="${venueName}" Country="${showCountry}" />
                      <JumpingEvent FEIID="${eventId}" Code="${eventCode}" NF="${showCountry}" StartDate="${showStart}" EndDate="${showEnd}">
                        <Competitions>
                          <Competition FEIID="${competitionID}" ScheduleCompetitionNr="${numPrueba}" Rule="${claveModalidad}" Name="${nombrePrueba}" DateTime="${fechaPrueba}" Team="false">
                            <Description>
                              <Prizes Currency="${monedaPremio}" />
                            </Description>
                            <ParticipationList>
                              ${resultados}
                            </ParticipationList>
                          </Competition>
                        </Competitions>
                      </JumpingEvent>
                    </Show>
                    </EventResult>
                    </HorseSport>`;

      const escapedXml = this.escapeXml(xml);
      const encodedUri = encodeURI(`data:xml;charset=utf-8,${escapedXml}`);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${numPrueba}.xml`);
      link.innerHTML= "Click Here to download";
      document.body.appendChild(link);
      link.click();
      link.remove();
  }

  /**
   * Team competition
   */
  async exportXML(){
    const startDate = moment(this.prueba.inicio).format('YYYY-MM-DDTHH:mm:ssZ');
    const eventCode = this.prueba.eventCode||'';
    const numPrueba = this.prueba.numero.replace('#', '');
    const nombrePrueba = this.prueba.nombre.replace(/['"]/g, '');
    const claveModalidad = this.prueba.claveModalidad||'';
    //2024_CI_0155_S_S_01_04
    const competitionID = this.prueba.competitionID||'';
    //2024_CI_0155
    const [showId] = competitionID.split('_S_');
    //2024_CI_0155_S_S_01
    const eventId = competitionID.split('_').filter((p, i, a) => i != a.length - 1).join('_');
    const showCountry = this.prueba.organizingCountry||'';
    const monedaPremio = 'USD';
    const venueName = this.prueba.venueName||'';
    const showStart = this.prueba.inicioConcurso||'';
    const showEnd = this.prueba.finConcurso||'';

    // Replace all occurrences of 'E' with 'EL', 'DNS' with 'WD', and 'R' with 'RET'
    const xmlObject = {
      HorseSport: {
        _attributes: {
          'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
          'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          xmlns: 'http://www.fei.org/Result',
        },
        Generated: {
          _attributes: {
            Date: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            Software: 'EquestrianDigital',
            SoftwareVersion: this.version,
            Organization: 'ED'
          }
        },
        EventResult: {
          Show: {
            _attributes: {
              FEIID: showId,
              StartDate: showStart,
              EndDate: showEnd,
              NF: showCountry
            },
            Venue: {
              _attributes: {
                Name: venueName,
                Country: showCountry
              }
            },
            JumpingEvent: {
              _attributes: {
                FEIID: eventId,
                Code: eventCode,
                NF: showCountry,
                StartDate: showStart,
                EndDate: showEnd
              },
              Competitions: {
                Competition: {
                  _attributes: {
                    'FEIID': competitionID,
                    'ScheduleCompetitionNr': numPrueba,
                    'Rule': claveModalidad,
                    'Name': nombrePrueba ,
                    'DateTime': startDate,
                    'Team': 'true'
                  },
                  Description: {
                    Prizes: {
                      _attributes: {
                        'Currency': monedaPremio
                      }
                    }
                  },
                  ParticipationList: {
                    Participation: this.resultados.filter((resultado: any) => !resultado.teamResult).map((resultado: any) => {
                      //TODO: Add prize money
                      const prize = '';
                      //Rider info
                      const riderFei = (resultado.riderFEI||'').trim();
                      const [riderName, riderLastname] = (resultado.riderName||'').trim().split(' ');
                      const riderNationality = (resultado.country||'');
                      //Horse info
                      const horseFei = (resultado.horseFEI||'').trim().toUpperCase();
                      const horseName = resultado.horseName;
                      //TeamName
                      const teamName = resultado.team||'';
                      //Combination info
                      const status = ((resultado.status1||'0') !== '0' ? (resultado.status1||'0') : 'R')||'';
                      //Get team rank
                      const rank = (resultado.status1||'0') == '0' ? resultado.rank : null;
                      const totalFaults1 = (resultado.status1||'0') == '0' ? (parseFloat(resultado.faults1||'0') + parseFloat(resultado.timeFaults1||'0')||'0') : null;
                      const timeRound1 = (resultado.status1||'0') == '0' ? resultado.time1 : null;
                      const statusRound1 = (resultado.status1||'0') == '0' ? null : (resultado.status1||'0');
                      const statusDsq = '';
                      const obstacleFaults1 = (resultado.status1||'0') == '0' ? (resultado.faults1||'0') : null;
                      const timeFaults1 = (resultado.status1||'0') == '0' ? (resultado.timeFaults1||'0') : null;

                      const timeRound2 = resultado.time2||'';
                      const totalFaults2 = resultado.status2 == '0' ? (parseFloat(resultado.faults1||'0') + parseFloat(resultado.timeFaults1||'0'))||'0' : null;
                      const obstacleFaults2 = resultado.status2 == '0' ? (resultado.faults2||'0') : null;
                      const timeFaults2 = resultado.status2 == '0' ? (resultado.timeFaults2||'0') : null;

                      const timeRound3 = resultado.time3||'';
                      const totalFaults3 = resultado.faults3||'0';

                      let riderResult = {
                        Athlete: {
                          _attributes: {
                            'FEIID': riderFei,
                            'FirstName': riderName,
                            'FamilyName': riderLastname,
                            'CompetingFor': riderNationality
                          }
                        },
                        Horse: {
                          _attributes: {
                            'FEIID': horseFei,
                            'Name': horseName
                          }
                        },
                        Team: {
                          _attributes: {
                            'Name': teamName
                          }
                        },
                        Complement: {
                          _attributes: {
                            'IsWNominated': 'false'
                          }
                        },
                        Position: {
                          _attributes: {
                            'Status': status,
                            'Rank': rank,
                          }
                        },
                        Round: {
                          _attributes: {
                            'Number': '1'
                          },
                          Result: {
                            _attributes: {
                              'TotalFaults': totalFaults1,
                              'Time': timeRound1,
                              'Status': statusRound1,
                              'ObstacleFaults': obstacleFaults1,
                              'TimeFaults': timeFaults1,
                            }
                          }
                        },
                      };

                      if(timeRound2 || timeRound3){
                        riderResult['JumpOff'] = {
                          _attributes: {
                            'Number': timeRound2 ? '1' : '2'
                          },
                          Result: {
                            _attributes: {
                              'TotalFaults': totalFaults2 || totalFaults3,
                              'Time': timeRound2 || timeRound3,
                              'ObstacleFaults': obstacleFaults2 || totalFaults3,
                              'TimeFaults': timeFaults2||'0'
                            }
                          }
                        }
                      }

                      return riderResult;
                    }),
                    TeamParticipation: this.resultados.filter((resultado: any) => resultado.teamResult).map((resultado: any) => ({
                      Team: {
                        _attributes: {
                          'Name': resultado.teamName||'',
                          'NF': 'USA'
                        },
                      },
                      Position: {
                        _attributes: {
                          'Status': 'R',
                          'Rank': resultado.rank||''
                        },
                      },
                      PrizeMoney: {
                        _attributes: {
                          'Value': ''
                        }
                      }
                    }
                    ))
                  },
                }
              }
            }
          }
        }
      }
    };

    // Convert the XML structure to a string
    const xmlString = js2xml(xmlObject, { compact: true, ignoreComment: true, spaces: 4 });
    const blob = new Blob([xmlString], { type: 'text/xml;charset=utf-8' });
    saveAs(blob, `${this.prueba.numero} - ${nombrePrueba}.xml`);
  }

  public riderSwapModal(ibpc) {
    if(!ibpc) {
      this.ibpcSwapSeleccionado = '';
      this.jinetes = [];
      return;
    }
    this.ibpcSwapSeleccionado = ibpc;
    this.jinetes = [];
    this.formRiderSwap.setValue({
      rider: ''
    });
    $('form').removeClass('was-validated');
    $('#riderSwapModal').modal('show');
  }

  public async riderSwap() {
    //Validar que el valor de jinete sea tomado desde la lista
    const indexJinete = this.jinetes.findIndex(p => p.id_persona == this.formRiderSwap.get('rider').value);
    if (indexJinete == -1) {
      this.formRiderSwap.get('rider').setValue('');
    }

    if (this.formRiderSwap.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.riderSwap(this.ibpcSwapSeleccionado, this.formRiderSwap.value.rider));
        if (!response.error) {
          this.getResultados(this.ipc);
          this.getPremios();
          $('#riderSwapModal').modal('hide');
        } else {
          this.handleError(response.message, "It has not been possible to perform the rider swap.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to perform the rider swap.");
      }

    }
  }

  public handleError(reason: any, messageToUser?: string) {
    $('#loader').hide();
    const message = (reason.error || {}).text || (((reason.error || {}).error || {}).message || reason.message || reason.error || 'Error during request.');
    $.NotificationApp.send("Error", messageToUser || message, 'bottom-right', '#fa5c7c', 'error');
    console.log(`%c Handling Error:`, "color: white; font-size: 11px; background-color: #a02d24; padding: 1px 2px; border-radius: 3px;");
    console.error(message);
    return { error: true, message: messageToUser || message };
  }

  public displayJinete(idPersona): string {
    const jinete = this.jinetes.find(p => p.id_persona == idPersona);
    return jinete ? jinete.fullname : idPersona;
  }
}
