import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PruebaService } from '../services/prueba.service';
import { ModalidadService } from '../services/modalidad.service';
import { ConceptoService } from '../services/concepto.service';
import { ConcursoService } from '../services/concurso.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../services/app.service';
import { PersonaService } from '../services/persona.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { JuezService } from '../services/juez.service';
import firebase from 'firebase/compat/app';
declare let $: any;

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public id: string;
  public classes: any[];
  public form: FormGroup;
  public pruebas: any[];
  public mostrarFormularioPruebas = false;
  public formPrueba: FormGroup;
  public modalidades: any[];
  public cargos: any[];
  public conceptos: any[];
  public conceptoSeleccionado: string;
  public porcentajeSubida: number;
  public imgPatrocinador: string;
  public croquis: string;
  public ipcSeleccionado: string;
  public ipcEliminar: string;
  public agregarPrueba: boolean;
  public header: string;
  public header2: string;
  public footer: string;
  public distribuciones: any[];
  public pistas: string[];
  public actualizarPruebasConcurso: boolean;
  public personas: any[];
  public jueces: Array<any>;
  public esHunters: string;
  public reining: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private pruebaService: PruebaService,
    private modalidadService: ModalidadService,
    private conceptoService: ConceptoService,
    private appService: AppService,
    private concursoService: ConcursoService,
    private personaService: PersonaService,
    private db: AngularFirestore,
    private juezService: JuezService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.id = this.route.snapshot.paramMap.get('id');
    this.agregarPrueba = !!this.route.snapshot.paramMap.get('accion');
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      cost: new FormControl('', [Validators.required]),
      //discount: new FormControl('', []),
      section_code: new FormControl('', []),
      type: new FormControl('hunters', [Validators.required]),
      charged: new FormControl('class-fees', [Validators.required])
    });
    this.pruebas = [];
    this.mostrarFormularioPruebas = false;
    this.formPrueba = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      numero: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      modalidad: new FormControl('', [Validators.required]),
      //ambito: new FormControl('', [Validators.required]),
      altura: new FormControl('', []),
      pista: new FormControl('', []),
      costo: new FormControl('', []),
      premio: new FormControl('', []),
      prize_distribution: new FormControl('', []),
      inicio: new FormControl('', []),
      juez: new FormControl('', []),
      video: new FormControl(false, []),
      designer: new FormControl('', []),
      youtube: new FormControl('', []),
      fei_competition_id: new FormControl('', []),
      fei_event_id: new FormControl('', []),
      usef_section: new FormControl('', []),
      hard_start: new FormControl(false, []),
      overall: new FormControl(false, []),
      height_unit: new FormControl('M', [])
    });
    this.modalidades = [];
    this.cargos = [];
    this.conceptos = [];
    this.conceptoSeleccionado = '';
    this.porcentajeSubida = 0;
    this.imgPatrocinador = '';
    this.croquis = '';
    this.ipcSeleccionado = '';
    this.ipcEliminar = '';
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.distribuciones = [];
    this.pistas = [];
    this.actualizarPruebasConcurso = false;
    this.personas = [];
    this.jueces = [];
    this.esHunters = "0";
    this.reining = sessionStorage.getItem('reining') == '1';
  }

  ngAfterViewInit(): void {
    if (this.agregarPrueba) {
      setTimeout(() => {
        this.mostrarAgregarPrueba();
      });
    }
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getDivision();
    this.getClasses();
    //this.getModalidades();  // ahora se llama desde getDivision()
    this.getConceptos();
    this.getConcurso();
    this.getDistributions();
    this.getPistas();
    this.getJueces();
  }

  public getJueces() {
    this.juezService.getJueces().subscribe(
      response => {
        if (!response.error) {
          this.jueces = response.jueces;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the judges list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getPistas(): void {
    this.concursoService.getPistas(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pistas = response.pistas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the rings list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDistributions(): void {
    this.pruebaService.getPrizeDistributions(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.distribuciones = [];
          for (let distribucion of response.distribuciones) {
            if (distribucion.tipo == 'percentage') {
              distribucion.distribucion = distribucion.distribucion.map(d => d * 100);
              this.distribuciones.push(distribucion);
            } else {
              this.distribuciones.push(distribucion);
            }
          }
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getConcurso() {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the show info.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDivision(): void {
    this.pruebaService.getDivision(this.id, this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pruebas = response.division.pruebas;
          this.form.setValue({
            name: response.division.name,
            code: response.division.code,
            cost: response.division.cost,
            section_code: response.division.section_code,
            //discount: response.division.discount,
            type: response.division.type,
            charged: response.division.charged_by
          });
          this.esHunters = "0";
          if (this.form.value.type == 'hunters') {this.esHunters = "1"}
          this.getModalidades();
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the division info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
    $('#loader').hide();
  }

  public getClasses(): void {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.classes = response.pruebas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getModalidades(): void {
    this.modalidadService.getModalidades().subscribe(
      response => {
        if (!response.error) {
          this.modalidades = response.modalidades.filter((item) => item.hunters == this.esHunters);
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the tables list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getConceptos(): void {
    this.conceptoService.getConceptos(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.conceptos = response.conceptos;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the concepts list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public addClass(e): void {
    const num = e.target.value;
    if (num) {
      //TODO: Agreagr tipo de prueba hunters o jumpers al filtro
      const prueba = this.classes.find(c => c.numero == num);
      if (prueba) {
        //TODO: Validar pruebas repetidas
        this.pruebas.push({
          ipc: prueba.ipc,
          numero: prueba.numero,
          nombre: prueba.nombre,
          altura: prueba.altura
        });
        e.target.value = '';
      } else {
        e.target.value = '';
        $.NotificationApp.send("Error", "There is no class with that number.", 'bottom-right', '#fa5c7c', 'error', 10000);
      }
    }
  }

  public modalRemoveClass(ipc) {
    this.ipcEliminar = ipc;
    $('#modal-eliminar-prueba').modal('show');
  }

  public async deleteClass() {
    this.pruebaService.deletePrueba(this.ipcEliminar, this.idUsuario, this.idConcurso).subscribe(
      async (response) => {
        if (!response.error) {
          await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${this.ipcEliminar}`).delete();
          const indexClasses = this.classes.findIndex(c => c.ipc == this.ipcEliminar);
          const indexPruebas = this.pruebas.findIndex(p => p.ipc == this.ipcEliminar);
          if (indexClasses > -1) this.classes.splice(indexClasses, 1);
          if (indexPruebas > -1) this.pruebas.splice(indexPruebas, 1);
          $('#modal-eliminar-prueba').modal('hide');
          $.NotificationApp.send("Success", 'The class has been successfully deleted', 'bottom-right', '#3c763d', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to update the division.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public updateDivision() {
    if (this.form.valid) {
      $('#loader').show();
      this.pruebaService.updateDivision(this.form.value, this.id, this.pruebas).subscribe(
        response => {
          if (!response.error) {
            $.NotificationApp.send("Success", 'The division has been successfully updated', 'bottom-right', '#3c763d', 'success');
            $('#loader').hide();
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
            $('#loader').hide();
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to update the division.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          $('#loader').hide();
        }
      );
    }
  }

  public filtroConceptosSinAgregar(): any[] {
    const cargos = this.cargos.map(c => c.id);
    return this.conceptos.filter(c => cargos.indexOf(c.id) === -1);
  }

  public agregarCargo(): void {
    if (this.conceptoSeleccionado) {
      const concepto = this.conceptos.find(c => c.id == this.conceptoSeleccionado);
      this.cargos.push({
        id: concepto.id,
        name: concepto.nombre
      });
      this.conceptoSeleccionado = '';
    }
  }

  public removeCharge(id): void {
    const index = this.cargos.findIndex(c => c.id == id);
    this.cargos.splice(index, 1);
  }

  public imagenPatrocinador(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      if (file.type === 'image/jpeg') {
        $('#loader').show();

        const ref = firebase.storage().ref('sponsor').child(`${new Date().getTime()}`);
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                $('#loader').hide();
                this.imgPatrocinador = downloadURL;
              },
              error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public archivoCroquis(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      $('#loader').show();
      const ref = firebase.storage().ref('croquis').child(`${new Date().getTime()}`);
      const task = ref.put(file);
      task.on('state_changed',
        (snapshot: any) => { //Cuando hay cambio de estado
          this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error: any) => { //Cuando hubo un error
          $('#loader').hide();
          $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
          console.log(error);
        },
        () => { //Cuando la funcion termino correctamente
          task.snapshot.ref.getDownloadURL().then(
            downloadURL => {
              $('#loader').hide();
              this.croquis = downloadURL;
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
              console.log(error);
            }
          );
        }
      );
    }
  }

  public mostrarAgregarPrueba(): void {
    $('form').removeClass('was-validated');
    this.ipcSeleccionado = '';
    this.conceptoSeleccionado = '';
    this.porcentajeSubida = 0;
    this.imgPatrocinador = '';
    this.croquis = '';
    this.cargos = [];
    this.personas = [];
    this.actualizarPruebasConcurso = false;
    this.formPrueba.setValue({
      nombre: '',
      numero: '',
      description: '',
      modalidad: '',
      altura: '',
      pista: '',
      costo: '',
      premio: '',
      prize_distribution: '',
      inicio: '',
      juez: '',
      video: false,
      designer: '',
      youtube: '',
      fei_competition_id: '',
      fei_event_id: '',
      usef_section: '',
      hard_start: false,
      overall: false,
      height_unit: 'M'
    });
    this.mostrarFormularioPruebas = true;
    setTimeout(() => {
      $("input[name=numero]").focus();
    });
  }

  public crearPrueba(): void {
    if (this.formPrueba.valid) {
      const indexModalidad = this.modalidades.findIndex((modalidad) => {
        return this.formPrueba.get('modalidad').value == modalidad.id;
      });
      const prueba = {
        altura: this.formPrueba.get('altura').value ? this.formPrueba.get('altura').value : '',
        //ambito: this.formPrueba.get('ambito').value,
        categorias: [],
        costo: this.formPrueba.get('costo').value ? parseInt(this.formPrueba.get('costo').value.toString().replace(/[\.,A-Za-z]/g, '')) : 0, //this.multicosto ? JSON.stringify(this.costo) : (this.formPrueba.get('costo').value ? this.formPrueba.get('costo').value : 0),
        designer: this.formPrueba.get('designer').value,
        inicio: this.formPrueba.get('inicio').value,
        ipc: this.ipcSeleccionado,
        juez: this.formPrueba.get('juez').value,
        modalidad: this.formPrueba.get('modalidad').value,
        nombre: this.formPrueba.get('nombre').value,
        nombreModalidad: indexModalidad > -1 ? this.modalidades[indexModalidad]['nombre'] : '',
        numero: this.formPrueba.get('numero').value,
        description: this.formPrueba.get('description').value,
        pista: this.formPrueba.get('pista').value,
        premio: this.formPrueba.get('premio').value ? parseInt(this.formPrueba.get('premio').value.toString().replace(/[\.,A-Za-z]/g, '')) : 0,
        video: this.formPrueba.get('video').value ? 1 : 0,
        youtube: this.formPrueba.get('youtube').value,
        imgPatrocinador: this.imgPatrocinador,
        fei_competition_id: this.formPrueba.get('fei_competition_id').value,
        fei_event_id: this.formPrueba.get('fei_event_id').value,
        usef_section: this.formPrueba.get('usef_section').value,
        hard_start: this.formPrueba.get('hard_start').value ? 1 : 0,
        overall: this.formPrueba.get('overall').value ? 1 : 0,
        croquis: this.croquis,
        cargosPrueba: this.cargos,
        prize_distribution: this.formPrueba.get('prize_distribution').value,
        division: this.id,
        height_unit: this.formPrueba.get('height_unit').value
      };

      if (!this.ipcSeleccionado) {
        this.pruebaService.createPrueba(prueba, this.idConcurso).subscribe(
          response => {
            if (!response.error) {
              this.appService.updateClasses(this.idConcurso, response.ipc);
              this.classes.push({
                ipc: response.ipc,
                numero: prueba.numero,
                nombre: prueba.nombre,
                altura: prueba.altura,
                costo: prueba.costo,
                premio: prueba.premio,
                sponsorImage: !!prueba.imgPatrocinador,
                croquis: !!prueba.croquis
              });
              this.pruebas.push({
                altura: prueba.altura,
                ipc: response.ipc,
                nombre: prueba.nombre,
                numero: prueba.numero,
                costo: prueba.costo,
                premio: prueba.premio,
                sponsorImage: !!prueba.imgPatrocinador,
                croquis: !!prueba.croquis
              });
              this.mostrarFormularioPruebas = false;
              $.NotificationApp.send("Success", 'The class has been successfully created.', 'bottom-right', '#06d5a1', 'success');
            } else {
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response);
            }
          },
          error => {
            $.NotificationApp.send("Error", 'The class could not be created', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      } else {
        this.pruebaService.updatePrueba(prueba, this.idConcurso).subscribe(
          response => {
            if (!response.error) {
              if (this.actualizarPruebasConcurso) {
                this.appService.updateClasses(this.idConcurso);
              } else {
                this.appService.updateClass(this.idConcurso, this.ipcSeleccionado);
              }
              //Actualizar pruebas en el arreglo de pruebas
              const indexClasses = this.classes.findIndex(c => c.ipc == this.ipcSeleccionado);
              const indexPruebas = this.pruebas.findIndex(p => p.ipc == this.ipcSeleccionado);
              if (indexClasses > -1) {
                this.classes[indexClasses] = {
                  ipc: this.ipcSeleccionado,
                  numero: prueba.numero,
                  nombre: prueba.nombre,
                  altura: prueba.altura,
                  costo: prueba.costo,
                  premio: prueba.premio,
                  sponsorImage: !!prueba.imgPatrocinador,
                  croquis: !!prueba.croquis
                };
              }
              if (indexPruebas > -1) {
                this.pruebas[indexPruebas] = {
                  altura: prueba.altura,
                  ipc: this.ipcSeleccionado,
                  nombre: prueba.nombre,
                  numero: prueba.numero,
                  costo: prueba.costo,
                  premio: prueba.premio,
                  sponsorImage: !!prueba.imgPatrocinador,
                  croquis: !!prueba.croquis
                };
              }
              this.mostrarFormularioPruebas = false;
              $.NotificationApp.send("Success", 'The class has been successfully updated.', 'bottom-right', '#06d5a1', 'success');
            } else {
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response);
            }
          },
          error => {
            $.NotificationApp.send("Error", 'The class could not be updated', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      }
    }
  }

  public editClass(ipc): void {
    $('form').removeClass('was-validated');
    this.ipcSeleccionado = ipc;
    this.conceptoSeleccionado = '';
    this.porcentajeSubida = 0;
    this.actualizarPruebasConcurso = false;
    this.personas = [];
    this.pruebaService.getPrueba(ipc).subscribe(
      response => {
        if (!response.error) {
          this.imgPatrocinador = response.prueba.sponsorImage;
          this.croquis = response.prueba.croquis;
          this.cargos = response.prueba.charges;
          this.formPrueba.setValue({
            nombre: response.prueba.nombre,
            numero: response.prueba.numero,
            modalidad: response.prueba.idModalidad,
            altura: response.prueba.altura,
            pista: response.prueba.pista,
            costo: response.prueba.costo,
            premio: response.prueba.premio,
            prize_distribution: response.prueba.prize_distribution,
            inicio: response.prueba.inicio || '',
            juez: response.prueba.juez,
            video: response.prueba.video,
            designer: response.prueba.designer,
            youtube: response.prueba.youtube,
            fei_competition_id: response.prueba.competitionID,
            description: response.prueba.description,
            fei_event_id: response.prueba.eventId,
            usef_section: response.prueba.usef_section,
            hard_start: response.prueba.hard_start,
            overall: response.prueba.overall,
            height_unit: response.prueba.height_unit
          });
          this.mostrarFormularioPruebas = true;
          setTimeout(() => {
            $("input[name=numero]").focus();
          });
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to query the class information', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public async duplicateClass(ipc: string, numero: string){
    //Buscar mas pruebas con el mismo numero
    const lastClassSameNumber = this.pruebas.filter(p => {
      const regex = new RegExp(`^${numero.replace(/[A-Za-z]/g,'')}[A-Za-z]?$`);
      return regex.test(p.numero);
    }).sort((a, b) => {
      let charCodeA = a.numero.replace(/\d/g,'').charCodeAt(0);
      let charCodeB = b.numero.replace(/\d/g,'').charCodeAt(0);
      if(isNaN(charCodeA)) charCodeA = 0;
      if(isNaN(charCodeB)) charCodeB = 0;

      if(charCodeA == charCodeB) return 0;

      return charCodeA > charCodeB ? -1 : 1;
    }).shift();
    const charCode = lastClassSameNumber.numero.replace(/\d/g,'').charCodeAt(0);
    let letra;
    if(isNaN(charCode)) letra = 'A';
    else letra = String.fromCharCode(charCode+1);
    const response = await this.pruebaService.getPrueba(ipc).toPromise();
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const prueba = {
      altura: response.prueba.altura||'',
      //ambito: this.formPrueba.get('ambito').value,
      categorias: response.prueba.categorias||[],
      costo: response.prueba.costo||'',
      designer: response.prueba.designer_id||'',
      inicio: '',
      juez: response.prueba.juez||'',
      modalidad: response.prueba.idModalidad||'',
      nombre: response.prueba.nombre||'',
      nombreModalidad: response.prueba.nombreModalidad||'',
      numero: `${response.prueba.numero.replace(/[A-Za-z]/g,'')}${letra}`,
      description: response.prueba.description||'',
      pista: response.prueba.pista||'',
      premio: response.prueba.premio||'0',
      video: response.prueba.video ? 1 : 0,
      youtube: '',
      imgPatrocinador: response.prueba.sponsorImage||'',
      fei_competition_id: '',
      fei_event_id: response.prueba.eventId||'',
      usef_section: response.prueba.usef_section||'',
      hard_start: response.prueba.hard_start ? 1 : 0,
      overall: response.prueba.overall ? 1 : 0,
      croquis: '',
      cargosPrueba: response.prueba.charges||[],
      prize_distribution: response.prueba.prize_distribution||'',
      division: this.id,
      height_unit: response.prueba.height_unit||''
    };

    const responseCreateClass = await this.pruebaService.createPrueba(prueba, this.idConcurso).toPromise();
    if(responseCreateClass.error){
      $.NotificationApp.send("Error", responseCreateClass.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    await this.getDivision();
    await this.getClasses();
    $.NotificationApp.send("Success", `The class ${prueba.numero} has been successfully created.`, 'bottom-right', '#06d5a1', 'success');
  }

  public cambioPista() {
    console.log('cambioPista');
    this.actualizarPruebasConcurso = true;
  }

  public getPersonas(e, entidad) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            this.personas = response.personas;
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the designers, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.personas = [];
    }
  }

  public displayPersona(idPersona): string {
    const designer = this.personas.find(p => p.id_persona == idPersona);

    return designer ? designer.fullname : idPersona;
  }

  // Keyboard Shortcuts
  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event) {
    console.log(event);
    if (event.ctrlKey == true && event.key == 'q') {
      event.preventDefault();
      console.log("New Class");
      this.mostrarAgregarPrueba();
    }
    if (event.ctrlKey == true && event.key == 'Q') {
      event.preventDefault();
      console.log("New Class");
      this.mostrarAgregarPrueba();
    }
  }
}
