<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>

<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row w-100">
                    <div class="mt-3 col-12">
                        <div class="card">
                            <div class="card-body">
                                <h2>List of Champions and reserves</h2>
                                <ng-container *ngFor="let division of divisions">
                                    <div *ngIf="division.champions.length != 0" class="mt-3" style=" page-break-inside:avoid">

                                    <h4>
                                        {{division.code }} | {{ division.name }}
                                    </h4>
                                    <div class="row w-60">
                                        <div class="col-6">
                                            <h5>Champions</h5>
                                            <div *ngFor="let champion of division.champions">
                                              {{champion.entry}} -  {{ champion.rider }} - {{ champion.horse }}
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <h5>Reserves</h5>
                                            <div *ngFor="let reserve of division.reserves">
                                                {{reserve.entry}} -  {{ reserve.rider }} - {{ reserve.horse }}
                                              </div>
                                            </div>
                                    </div>
                                  
                                    </div>
                                   
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row w-100 d-print-none" >
                    <div class="mt-3 panel-division" [ngClass]="{'col-12': !divisionSeleccionada, 'col-6': divisionSeleccionada }">
                        <div class="card">
                            <div class="card-body p-2">
                                <table class="table table-sm table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Division Number</th>
                                            <th>Name</th>
                                            <th>Cost</th>
                                            <th>Charge by</th>
                                            <th>Type</th>
                                            <th>Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let division of divisions" class="division" (click)="seleccionarDivision(division.id, division.name, division.code, division.champions, division.reserves)">
                                            <td>{{ division.code }}</td>
                                            <td>{{ division.name }}</td>
                                            <td>{{ division.cost | currency }}</td>
                                            <td>{{ division.charged_by | titlecase }}</td>
                                            <td>{{ division.type | titlecase }}</td>
                                            <td>{{ division.created | date }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 panel-division" [ngClass]="{'col-0': !divisionSeleccionada, 'col-6': divisionSeleccionada }">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h5>{{ nombreDivision }}</h5>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label><b>Champions</b></label>
                                            <input type="text" class="form-control" [(ngModel)]="championSearch" (change)="addEntryChampion()">
                                            <div class="listado-entries" *ngIf="championSearch">
                                                <div *ngFor="let entry of filtroChampion()" (click)="addEntryChampion(entry.id_binomio)">
                                                    <b>{{ entry.entry }}</b> {{ entry.horse }} - {{ entry.rider }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="d-flex" style="flex-direction: column;">
                                                <div *ngFor="let entry of entriesChampion; let index = index;" class="d-flex" style="justify-content: space-between; padding: .275rem 0;">
                                                    <span><b>{{ entry.entry }}</b> {{ entry.horse }} - {{ entry.rider }}</span><a href="javascript:;" (click)="removeEntryChampion(index)"><i class="mdi mdi-close"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label><b>Reserves</b></label> 
                                            <input type="text" class="form-control" [(ngModel)]="reserveSearch" (change)="addEntryReserve()">
                                            <div class="listado-entries" *ngIf="reserveSearch">
                                                <div *ngFor="let entry of filtroReserve()" (click)="addEntryReserve(entry.id_binomio)">
                                                    <b>{{ entry.entry }}</b> {{ entry.horse }} - {{ entry.rider }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="d-flex" style="flex-direction: column;">
                                                <div *ngFor="let entry of entriesReserve; let index = index;" class="d-flex" style="justify-content: space-between; padding: .275rem 0;">
                                                    <span><b>{{ entry.entry }}</b> {{ entry.horse }} - {{ entry.rider }}</span><a href="javascript:;" (click)="removeEntryReserve(index)"><i class="mdi mdi-close"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 text-right">
                                        <button type="button" class="btn btn-secondary mr-3" (click)="divisionSeleccionada = '';">Close</button>
                                        <button type="button" class="btn btn-success" (click)="save()">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
