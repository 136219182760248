<div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <h3 style="margin: 0px">
      <span>Checks</span>
      <!--span style="float: right">{{ totalPagosEntrenador | currency }}</span-->
    </h3>
  </div>
  <div class="card-body text-panel p-print-0">
    <table class="table table-hover table-condensed m-0">
        <thead>
          <tr>
            <th><b>Check number</b></th>
            <th *ngIf="!isEntry"><b>Entry</b></th>
            <th *ngIf="isEntry"><b>Trainer</b></th>
            <th><b>Notes</b></th>
            <th><b>Payee</b></th>
            <th><b>Amount</b></th>
            <th class="d-print-none"></th>
          </tr>
        </thead>
        <tbody>
          <tr class=" d-print-none" [formGroup]="form" *ngIf="!hideForm">
            <td><input type="text" class="form-control" formControlName="number" required></td>
            <td *ngIf="!isEntry">
                <select class="form-control" formControlName="entry" required>
                    <option [value]="entry.entry" *ngFor="let entry of entries">{{ entry.entry }} - {{ entry.nombre }}</option>
                </select>
            </td>
            <td *ngIf="isEntry">
                <input type="text" class="form-control" formControlName="trainer" required [matAutocomplete]="autocompleteTrainer" (keyup)="getPersonas($event, 'trainer')">
                <mat-autocomplete #autocompleteTrainer="matAutocomplete" [displayWith]="displayTrainer.bind(this)">
                    <mat-option *ngFor="let trainer of trainers" [value]="trainer.id_persona" 
                        class="fila-autocomplete" style="height:5em; line-height: 2em">
                        <img src="{{trainer.flag}}" style="height:.9em" />
                        <span id="trainerName" style="font-weight: bold;">{{ (trainer.fullname) ? trainer.fullname : '-' }}</span>
                        <div>
                        <span id="USEF">
                            <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">{{ trainer.usef }}
                        </span>
                        <span *ngIf="trainer.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ trainer.fei }}
                        </span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </td>
            <td><input type="text" class="form-control" formControlName="notes"></td>
            <td>
                <input type="text" class="form-control" formControlName="payee" [matAutocomplete]="autocompleteResponsable" (keyup)="getPersonas($event, 'payee')">
                <mat-autocomplete #autocompleteResponsable="matAutocomplete" [displayWith]="displayPayee.bind(this)">
                    <mat-option *ngFor="let payee of payees" [value]="payee.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em">
                        <img src="{{payee.flag}}" style="height:.9em" />
                        <span id="trainerName" style="font-weight: bold;">{{ (payee.fullname) ?
                        payee.fullname : '-' }}</span>
                        <div>
                        <span id="USEF">
                            <img src="/assets/images/icons/usef.svg" alt="USEF: "
                            style="height: 1em; padding-right: 5px;">{{ payee.usef }}
                        </span>
                        <span *ngIf="payee.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                            style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ payee.fei }}
                        </span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </td>
            <td><input type="number" class="form-control" formControlName="amount" min="1" required></td>
            <td class="bg-dark text-white text-center">
                <button class="btn btn-icon btn-dark p-0" style="font-size:1.5rem; box-shadow: 0 0px 0px 0 rgba(57,175,209,.5);" (click)="submit()">
                  <i class="mdi mdi-plus-circle"></i>
                </button>
              </td>
          </tr>
          <tr *ngFor="let check of checks">
            <td>{{ check.number }}</td>
            <td *ngIf="!isEntry">{{ check.entry }}</td>
            <td *ngIf="isEntry">{{ check.trainer }}</td>
            <td>{{ check.notes }}</td>
            <td>{{ check.payee }}</td>
            <td>{{ check.amount | currency }}</td>
            <td class="d-print-none"></td>
          </tr>
        </tbody>
    </table>
</div>