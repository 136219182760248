import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UsuarioService } from '../services/usuario.service';
import { JuezService } from '../services/juez.service';
import { ClubService } from '../services/club.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'usuarios',
    templateUrl: 'usuarios.component.html',
    styleUrls: ['usuarios.component.css']
})

export class UsuariosComponent implements OnInit{
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public usuarios: Array<any>;
  public formUsuario: UntypedFormGroup;
  public jueces: Array<any>;
  public clubs: Array<any>;

  constructor(
      private router: Router,
      private authService: AuthService,
      private juezService: JuezService,
      private usuarioService: UsuarioService,
      private clubService: ClubService,
  ){
      this.token = '';
      this.sessionData = {};
      this.idConcurso = '';
      this.idUsuario = '';
      this.nombreConcurso = this.authService.getNombreConcurso();
      this.privilegios = 0;
      this.usuarios = [];
      this.formUsuario = new UntypedFormGroup({
          idUsuario: new UntypedFormControl('', []),
          usuario: new UntypedFormControl('', [Validators.required]),
          privilegios: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
          idClub: new UntypedFormControl('', []),
          nombre: new UntypedFormControl('', [Validators.required]),
          apellidos: new UntypedFormControl('', [Validators.required]),
          activo: new UntypedFormControl(true, [Validators.required]),
          //idJuez: new FormControl('', []),
          password: new UntypedFormControl('', [])
      });
      this.jueces = [];
      this.clubs = [];
  }

  ngOnInit(){
      if(!this.authService.isLoggedIn()){
          this.authService.logOut();
          return;
      } else{
          this.token = this.authService.getAuthorizationToken();
          this.sessionData = this.authService.getSessionData(this.token);
          this.idUsuario = String(this.sessionData['idUsuario']);
          this.privilegios = this.sessionData.privilegios;
      }
      if(this.authService.validarConcurso() && this.privilegios >= 300){
          this.idConcurso = this.authService.validarConcurso();
      } else{
          this.router.navigate(['']);
          return;
      }
      this.getUsers();
      this.getClubs();
  }

  public getUsers(){
      this.usuarioService.getUsers().subscribe(
          response => {
              if(!response.error){
                  this.usuarios = response.usuarios;
              } else{
                  $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              }
              $('#loader').hide();
          },
          error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'It has not been possible to query the users list.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
          }
      );
  }

  public getClubs(){
      this.clubService.getClubs().subscribe(
          response => {
              if(!response.error){
                  this.clubs = response.clubs;
              } else{
                  $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                  console.log(response.message);
              }
          },
          error => {
              $.NotificationApp.send("Error", 'It was not possible to query the clubs list', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
          }
      );
  }

  public privilegiosToText(p: number): string{
      let t: string = '';

      switch (p) {
          case 300:
              t = 'Desarrollador';
              break;
          case 250:
              t = 'Accounting';
              break;
          case 200:
              t = 'Office Leader';
              break;
          case 150:
              t = 'Office';
              break;
          case 100:
              t = 'Judge';
              break;
          /*case 80:
              t = 'Veterinario';
              break;*/
          case 50:
              t = 'Ingate';
              break;
          default:
              t = 'Sin privilegios'
              break;
      }

      return t;
  }

  public modalCrear(){
      //Limpiar formulario
      this.formUsuario.reset();
      this.formUsuario.get('idUsuario').setValue('');
      this.formUsuario.get('usuario').setValue('');
      this.formUsuario.get('privilegios').setValue(0);
      this.formUsuario.get('idClub').setValue('');
      this.formUsuario.get('nombre').setValue('');
      this.formUsuario.get('apellidos').setValue('');
      this.formUsuario.get('activo').setValue(true);
      //this.formUsuario.get('idJuez').setValue('');
      this.formUsuario.get('password').setValue('');

      $('#signup-modal').modal('show');
  }

  public modalActualizar(usuario){
      //Cargar valores en formGroup
      this.formUsuario.reset();
      this.formUsuario.get('idUsuario').setValue(usuario.idUsuario);
      this.formUsuario.get('usuario').setValue(usuario.usuario);
      this.formUsuario.get('privilegios').setValue(usuario.privilegios);
      this.formUsuario.get('idClub').setValue(usuario.idClub);
      this.formUsuario.get('nombre').setValue(usuario.nombre);
      this.formUsuario.get('apellidos').setValue(usuario.apellidos);
      this.formUsuario.get('activo').setValue(usuario.activo);
      //this.formUsuario.get('idJuez').setValue(usuario.idJuez);
      this.formUsuario.get('password').setValue('');

      $('#signup-modal').modal('show');
  }

  public guardarUsuario(){
    if(this.formUsuario.valid && (this.formUsuario.get('idUsuario').value || (!this.formUsuario.get('idUsuario').value && this.formUsuario.get('password').value)) && (this.formUsuario.get('privilegios').value != 100 || (this.formUsuario.get('privilegios').value == 100/* && this.formUsuario.get('idJuez').value*/))){
        $('#loader').show();
        const usuario = this.formUsuario.value;
        if(usuario.idUsuario){ //Actualizar
          this.usuarioService.actualizar(usuario).subscribe(
            response => {
                if(!response.error){
                    $('#signup-modal').modal('hide');
                    this.getUsers();
                    $.NotificationApp.send("Updated", 'The user has been updated.', 'bottom-right', '#fa5c7c', 'success');
                } else{
                    $('#loader').hide();
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The user could not be updated.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
          );
        } else{ //Crear
          this.usuarioService.crear(usuario).subscribe(
              response => {
                  if(!response.error){
                      $('#signup-modal').modal('hide');
                      this.getUsers();
                      $.NotificationApp.send("Created", 'The user has been created.', 'bottom-right', '#fa5c7c', 'success');
                  } else{
                      $('#loader').hide();
                      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                      console.log(response.message);
                  }
              },
              error => {
                  $('#loader').hide();
                  $.NotificationApp.send("Error", 'It has not been possible to create the user.', 'bottom-right', '#fa5c7c', 'error');
                  console.log(error);
              }
          );
        }
    } else{
      this.formUsuario.get('usuario').markAsTouched();
      this.formUsuario.get('privilegios').markAsTouched();
      this.formUsuario.get('nombre').markAsTouched();
      this.formUsuario.get('apellidos').markAsTouched();
      this.formUsuario.get('password').markAsTouched();
      //this.formUsuario.get('idJuez').markAsTouched();
    }
  }

  public toggleUsuario(user){ //Activa o desactiva un usuario
      $('#loader').show();
      const usuario = {
          idUsuario: user.idUsuario,
          usuario: user.usuario,
          privilegios: user.privilegios,
          idClub: user.idClub,
          nombre: user.nombre,
          apellidos: user.apellidos,
          activo: !user.activo,
          //idJuez: user.idJuez,
          password: ''
      }

      this.usuarioService.actualizar(usuario).subscribe(
          response => {
              if(!response.error){
                  $('#signup-modal').modal('hide');
                  this.getUsers();
                  $.NotificationApp.send("Updated", 'The user has been updated.', 'bottom-right', '#fa5c7c', 'success');
              } else{
                  $('#loader').hide();
                  $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                  console.log(response.message);
              }
          },
          error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'It was not possible to update the user.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
          }
      );
  }
}
