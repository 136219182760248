import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FeedOrdersService } from '../services/feed-orders.service';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConceptoService } from '../services/concepto.service';
import { EntrenadorService } from '../services/entrenador.service';
import { PersonaService } from '../services/persona.service';
import { EntriesService } from '../services/entries.service';
import { firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-feed-orders',
  templateUrl: './feed-orders.component.html',
  styleUrls: ['./feed-orders.component.css'],
  providers: [
    DatePipe,
    CurrencyPipe
  ]
})
export class FeedOrdersComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public table: any;
  private orders: any[];
  public mostrarPanelAgregar: boolean;
  public mostrarPanelVer: boolean;
  public charges: any[];
  public form: UntypedFormGroup;
  public concept: string;
  public notes: string;
  public qty: string;
  public amount: string;
  public conceptos: any[];
  public entrenadores: any[];
  public ordenSeleccionada: string;
  public summaryOrders: any[];
  public entries: any[];
  public printSummary: boolean;
  public printOrder: boolean;
  public loadingSave: boolean;
  public trainers: any[];

  constructor(
    private authService: AuthService,
    private feedOrdersService: FeedOrdersService,
    private router: Router,
    private datePipe: DatePipe,
    private conceptoService: ConceptoService,
    private entrenadorService: EntrenadorService,
    private entriesService: EntriesService,
    private personaService: PersonaService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.table = {};
    this.orders = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
    this.charges = [];
    this.form = new UntypedFormGroup({
      stall: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', []),
      entry: new UntypedFormControl('', []),
      delivered_by: new UntypedFormControl('', []),
      delivery_date: new UntypedFormControl('', []),
     // online: new UntypedFormControl(false, [])
    });
    this.concept = '';
    this.notes = '';
    this.qty = '';
    this.amount = '';
    this.conceptos = [];
    this.entrenadores = [];
    this.ordenSeleccionada = '';
    this.summaryOrders = [];
    this.entries = [];
    this.printSummary = true;
    this.printOrder = false;
    this.loadingSave = false;
    this.trainers = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getShowTrainers();
  }

  ngAfterViewInit(): void {
    this.table = $('#orders').DataTable({
      dom: 'Bfrtip',
      buttons:[{
        extend: 'print',
        title: function () {
          //return $('#tableTitle').text();
          return 'Feed Orders';
        }
      }],
      language:{
        paginate:{
          previous:"<i class='mdi mdi-chevron-left'>",
          next:"<i class='mdi mdi-chevron-right'>"
        }
      },
      rowId: 0,
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      }
    });
    this.clickTable();
    this.getFeedConcepts();
  }

  private clickTable(){
    $('#orders tbody').on('click', 'tr .table-action a.ver', (event) => {
      const order = event.currentTarget.getAttribute('order');
      this.abrirPanelVer(order);
    });
    $('#orders tbody').on('click', 'tr .table-action a.eliminar', (event) => {
      const order = event.currentTarget.getAttribute('order');
      this.deleteOrder(order);
    });
  }

  public getFeedConcepts(){
    this.conceptoService.getFeedConcepts(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.conceptos = response.conceptos;
          this.getEntries();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the concept list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getSummary(){
    this.feedOrdersService.summary(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.summaryOrders = response.summary.sort((a, b) => {
            if(a.producto > b.producto) return 1;
            if(a.producto < b.producto) return -1;

            return 0;
          });
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the summary.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getEntries(){
    this.entriesService.getEntries(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.entries = response.data;
          this.getFeedOrders();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the entries list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getFeedOrders(){
    this.feedOrdersService.getOrders(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.orders = response.orders;
          console.log(this.orders);
          this.table.rows.add(this.orders.map(o => {
            return [o.id, o.stall, o.trainer, this.datePipe.transform(o.created_at, 'dd/MMM'), this.datePipe.transform(o.delivered, 'dd/MMM'), o.online == 1 ? '<i class="mdi mdi-earth d-print" style="color:black"></i><span style="color:white">O</span>' : '',
              `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon ver" order="${o.id}"> <i class="mdi mdi-pencil"></i></a></div>`,
              `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon eliminar" order="${o.id}"> <i class="mdi mdi-trash-can-outline"></i></a></div>`
            ]
          })).draw(false);
          this.getSummary();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the order list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public async getShowTrainers(){
    const response = await firstValueFrom(this.entrenadorService.getEntrenadoresPorConcurso(this.idConcurso));
    if(response.error){
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    this.trainers = response.entrenadores.map(e => e.id_persona);
  }

  public abrirPanelAgregar(){
    this.ordenSeleccionada = '';
    this.charges = [];
    this.entrenadores = [];
    this.concept = '';
    this.amount = '';
    this.qty = '';
    this.notes = '';
    this.form.setValue({
      stall: '',
      trainer: '',
      entry: '',
      delivered_by: '',
      delivery_date: ''
    });
    this.mostrarPanelAgregar = true;
    //this.mostrarPanelVer = false;
  }

  public cerrarPanelAgregar(){
    this.charges = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
  }

  public abrirPanelVer(orderId){
    this.ordenSeleccionada = orderId;
    const order = this.orders.find(o => o.id == orderId);
    const [entryNumber] = (order.entry||'').split(' - ');
    if(!order) return;
    this.charges = typeof(order.data) == 'string' ? JSON.parse(order.data) : order.data;
    this.entrenadores = [];
    this.concept = '';
    this.amount = '';
    this.qty = '';
    this.notes = '';
    if(order.trainer && order.id_entrenador){
      this.entrenadores = [{
        id_persona: order.id_entrenador,
        fullname: order.trainer,
        inShow: this.trainers.includes(order.id_entrenador)
      }];
    }
    this.form.setValue({
      stall: order.stall||'',
      trainer: order.id_entrenador||'',
      entry: entryNumber,
      delivered_by: order.delivered_by||'',
      delivery_date: order.delivered||''
    });
    this.mostrarPanelAgregar = true;
    //this.mostrarPanelVer = true;
  }

  public cerrarPanelVer(){
    this.charges = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
  }

  public print(e: Event, seccion){
    e.stopPropagation();
    if( seccion == 'summary') {
      this.cerrarPanelVer();
      this.printSummary = true;
      this.printOrder = false;
    }
    if( seccion == 'order') {
      console.log('Print order');
      this.printOrder = true;
      this.printSummary = false;
    }
    setTimeout(() => {
      window.print();
    });
  }

  public calcularCargo() {
    if (this.concept && this.qty) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.concept;
      });
      this.amount = (parseFloat(this.conceptos[index].monto) * parseFloat(this.qty)).toString();
    } else if (this.concept && !this.qty) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.concept;
      });
      this.qty = '1';
      this.amount = parseFloat(this.conceptos[index].monto).toString();
    }
  }

  public addCharge(){
    if(this.concept && this.qty && this.amount){
      const index = this.conceptos.findIndex(c => c.id == this.concept);

      this.charges.push({
        id: this.concept,
        linea: this.amount,
        cantidad: this.qty,
        producto: this.conceptos[index].nombre,
        unitario: this.conceptos[index].monto,
        notas: this.notes
      });
      this.concept = '';
      this.notes = '';
      this.qty = '';
      this.amount = '';
    } else{
      $.NotificationApp.send("Warning", "Please complete all fields.", 'bottom-right', '#ffbc00', 'warning', 7000);
    }
  }

  public async saveOrder(){
    if(this.loadingSave) return;
    //Validar que el valor de entrenador sea tomado desde la lista
    const entrenador = this.entrenadores.find(p => p.id_persona == this.form.get('trainer').value);
    if(!entrenador){
      this.form.get('trainer').setValue('');
    }
    if(this.charges.length == 0){
      $.NotificationApp.send("Warning", "At least one charge must be added to the order.", 'bottom-right', '#ffbc00', 'warning', 7000);
      return;
    }
    if(!this.form.get('trainer').value && !this.form.get('entry').value){
      $.NotificationApp.send("Warning", "Please choose a trainer or an entry.", 'bottom-right', '#ffbc00', 'warning', 7000);
      return;
    }
    if(!this.form.valid) return;

    this.loadingSave = true;
    this.form.disable();
    //$('#loader').show();
    const total = this.charges.reduce((a, b) => {
      return a + parseFloat(b.linea||'0');
    }, 0);

    //Validar si tiene una orden seleccionada, de ser asi, se actualiza, de lo contrario se crea una nueva
    if(this.ordenSeleccionada){
      const selectedOrder = this.orders.find(o => o.id == this.ordenSeleccionada);
      const newOrder = {
        id: this.ordenSeleccionada,
        idConcurso: this.idConcurso,
        idEntrenador: this.form.value.trainer||'',
        entry: this.form.value.entry||'',
        idUsuario: this.idUsuario,
        stall: this.form.value.stall||'',
        total,
        charges: this.charges,
        online: selectedOrder.online,
      };
      //Crear orden en mysql
      const { order, error } = await this.feedOrdersService.editFeedOrder(newOrder);
      if(error){
        this.loadingSave = false;
        this.form.enable();
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }
      //Actualizar order en tabla
      const index = this.orders.findIndex(o => o.id == order.id);
      this.orders[index] = { ...order, online: order.online ? 1 : 0};
      const row = this.table.row(`#${order.id||''}`);
      row.remove();
      this.table.row.add([order.id,order.stall,order.trainer,this.datePipe.transform(order.created_at, 'dd/MMM'),'','',`<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon ver" order="${order.id}"> <i class="mdi mdi-pencil"></i></a></div>`,`<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon eliminar" order="${order.id}"> <i class="mdi mdi-trash-can-outline"></i></a></div>`]).draw(false);
      $('form').removeClass('was-validated');
      this.getSummary();
      //this.cerrarPanelVer();
      //this.entrenadores = [];
      this.loadingSave = false;
      this.form.enable();
      $.NotificationApp.send("Saved", `The order ${order.id} has been updated successfully`, 'bottom-right', '#0acf97', 'success', 10000);
    } else{
      const order = await this.feedOrdersService.save(this.idConcurso, this.form.value.trainer, this.form.value.entry, this.idUsuario,this.form.value.stall, total, this.charges);
      this.orders.push({ ...order, online: order.online ? 1 : 0});
      this.table.row.add([order.id,order.stall,order.trainer,this.datePipe.transform(order.created_at, 'dd/MMM'),'','',`<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon ver" order="${order.id}"> <i class="mdi mdi-pencil"></i></a></div>`,`<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon eliminar" order="${order.id}"> <i class="mdi mdi-trash-can-outline"></i></a></div>`]).draw(false);
      //this.abrirPanelVer(order.id);
      $('form').removeClass('was-validated');
      this.getSummary();
      //this.cerrarPanelVer();
      this.abrirPanelVer(order.id);
      //this.entrenadores = [];
      this.loadingSave = false;
      this.form.enable();
      $.NotificationApp.send("Saved", `The order ${order.id} has been created successfully`, 'bottom-right', '#0acf97', 'success', 10000);
    }
  }

  public async syncOrder(){
    const order = this.orders.find(o => o.id == this.ordenSeleccionada);
    if(!order) return;
    const data = typeof(order.data) == 'string' ? JSON.parse(order.data) : order.data;
    const newOrderFS = {
      data: data.map(d => ({ cantidad: `${d.cantidad}`, producto: d.producto })),
      id: order.id,
      id_concurso: this.idConcurso,
      stall: order.stall,
      delivered: order.delivered ? new Date(order.delivered) : null,
      created_at: new Date(),
      trainer: order.trainer,
      online: false,

    };
    await this.feedOrdersService.createFeedOrderFS(newOrderFS);


  }

  public removeCharge(index){
    this.charges.splice(index, 1);
  }

  public displayEntrenador(idPersona): string{
    const entrenador = this.entrenadores.find(p => p.id_persona == idPersona);

    return entrenador ? entrenador.fullname : idPersona;
  }

  public async getPersonas(e){
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if(filtro.length > 3){
      const response = await firstValueFrom(this.personaService.getPersonasFiltradas(filtro, this.idConcurso));
      if(response.error){
        $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }
      this.entrenadores = response.personas.map(p => ({ id_persona: p.id_persona, fullname: p.fullname, inShow: this.trainers.includes(p.id_persona) }));
    } else{
      this.entrenadores = [];
      console.log('Busqueda de personas muy corta');
    }
  }

  public async markDelivered(order){
    console.log("Order: ", order);
    await this.feedOrdersService.markDelivered(order).then( (response: any) => {
      this.table.clear().draw();
      this.getFeedOrders();
      this.ordenSeleccionada = null;
      this.cerrarPanelVer();
    });

  }

  public async deleteOrder(orderId){
    $('#loader').show();
    await this.feedOrdersService.delete(orderId, this.idConcurso, this.idUsuario).then(
      (response: any) => {
        if(!response.error){
          const rowIndex = this.orders.findIndex(o => o.id ==  orderId);
          this.orders.splice(rowIndex, 1);
          const row = this.table.row(`#${orderId||''}`);
          row.remove().draw(false);
          /*this.table.rows().remove().draw();
          this.table.rows.add(this.orders.map(o => {
            return [o.id, o.stall,o.trainer, o.entry,this.currencyPipe.transform(o.order_total),this.datePipe.transform(o.created_at),'',`<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon ver" order="${o.id}"> <i class="mdi mdi-pencil"></i></a></div>`, `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon eliminar" order="${o.id}"> <i class="mdi mdi-trash-can-outline"></i></a></div>`]
          })).draw(false);*/
          $.NotificationApp.send("Deleted", `The order ${orderId} has been deleted successfully`, 'bottom-right', '#0acf97', 'success', 7000);
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to delete the order, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
