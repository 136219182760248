<div class="cad-body padding-0">
    <form [formGroup]="horseForm" class="needs-validation" novalidate id="editHorse">
        <div class="row">
            <!-- Campos FEI, USEF -->
            <ng-container>
              <div class="col-6">
                <div class="form-group">
                    <label># FEI</label>
                    <div class="input-group">
                      <input type="text" class="form-control" style="text-transform: uppercase;" formControlName="fei" id="fei-horse">
                      <div class="input-group-append">
                        <button style="color:#4a0d53" type="button" mat-raised-button (click)="getHorseFeiInfo()">
                          <img src="assets/images/icons/fei.svg" alt="fei-logo" class="fei-logo">
                          CONNECT
                        </button>
                      </div>
                    </div>
                    <small style="color:red;">{{feiError}}</small>
                </div>
              </div>

              <div class="col-6">
                  <div class="form-group">
                      <label># USEF</label>
                      <div class="input-group">
                        <input type="text" class="form-control" formControlName="usef_id">
                        <div class="input-group-append">
                          <button style="color:#113978" type="button" mat-raised-button (click)="getHorseUsefData()">
                            <img src="assets/images/icons/usef.svg" alt="usef-logo" class="usef-logo">
                            CONNECT
                          </button>
                        </div>
                      </div>
                      <small style="color:red;">{{usefError}}</small>
                  </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                    <label># USHJA</label>
                    <input type="text" class="form-control" formControlName="ushja_id">
                </div>
            </div>
            </ng-container>
            <!-- Campo NRHA -->


            <div class="col-12">
                <div class="form-group">
                    <label>Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="fullname" required>
                    <div class="invalid-feedback">
                        Please provide a name.
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Owner</label>
                    <input type="text" class="form-control" formControlName="owner_id" [matAutocomplete]="autocompleteOwner" (keyup)="getPersonas($event)">
                    <mat-autocomplete #autocompleteOwner="matAutocomplete" [displayWith]="displayOwner.bind(this)" >
                        <mat-option *ngFor="let owner of owners" [value]="owner.id_persona" class="fila-autocomplete">
                            {{ (owner.fullname) ? owner.fullname : '-' }}
                            </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <!--div class="col-6">
                <div class="form-group">
                    <label># Usef Owner(s)</label>
                    <input type="text" placeholder="#Usef comma separated" class="form-control" formControlName="ownerUsef">
                </div>
            </div-->
            <div class="col-6">
                <div class="form-group">
                    <label>Chip ID</label>
                    <input type="text" class="form-control" formControlName="chip_id">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Country of origin</label>
                    <input type="text" class="form-control" formControlName="country_of_birth">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Color</label>
                    <input type="text" class="form-control" formControlName="color_code">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Color complement</label>
                    <input type="text" class="form-control" formControlName="color_complement">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Breed</label>
                    <input type="text" class="form-control" formControlName="breed">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Gender</label>
                    <input type="text" class="form-control" formControlName="gender">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Date of birth</label>
                    <input type="text" class="form-control" placeholder="YYYY-MM-DD" data-toggle="input-mask" data-mask-format="0000-00-00" maxlength="10" formControlName="date_of_birth">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Height</label>
                    <input type="text" class="form-control" formControlName="height">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Height Category</label>
                    <select class="form-control" formControlName="height_category">
                        <option value=""></option>
                        <option value="Pony - Small">Pony - Small</option>
                        <option value="Pony - Medium">Pony - Medium</option>
                        <option value="Pony - Large">Pony - Large</option>
                        <option value="Horse - Small">Horse - Small</option>
                        <option value="Horse - Large">Horse - Large</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Sire</label>
                    <input type="text" class="form-control" formControlName="sire">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Dam</label>
                    <input type="text" class="form-control" formControlName="dam">
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label>Notes</label>
                    <textarea class="form-control" rows="3" formControlName="notas" style="resize: none;"></textarea>
                </div>
            </div>
            <div class="col-12 mt-3 text-center">
                <button type="button" class="btn btn-light mr-4" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-success" (click)="submit()">Save</button>
            </div>
        </div>
    </form>
</div>


