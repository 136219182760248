import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
declare let $: any;

@Component({
  selector: 'app-trainer-reservation',
  templateUrl: './trainer-reservation.component.html',
  styleUrls: ['./trainer-reservation.component.css']
})
export class TrainerReservationComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public id: string;
  public name: string;
  public status: string;
  public cargos: any[];
  public idEntrenador: string;

  constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute, private inscripcionesRemotasService: InscripcionesRemotasService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.name = '';
    this.status = '';
    this.cargos = [];
    this.idEntrenador = '';
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getReservation();
  }

  private getReservation(): void{
    this.inscripcionesRemotasService.getTrainerReservation(this.id).subscribe(
      response => {
        if(!response.error){
          this.name = response.reservation.trainer;
          this.status = response.reservation.status;
          this.cargos = response.reservation.cargos;
          this.idEntrenador = response.reservation.trainer_id;
          $('#loader').hide();
        } else{
          console.log(response);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }

  public aceptarReservacion(){
    $('#loader').show();
    this.inscripcionesRemotasService.acceptTrainerReservation(this.id, this.cargos, this.idEntrenador, this.name, this.idConcurso, this.idUsuario).subscribe(
      response => {
        if(!response.error){
          this.status = '1';
          $.NotificationApp.send("Success", 'The reservation has been accepted successfully', 'bottom-right', '#3c763d', 'success');
          $('#loader').hide();
        } else{
          console.log(response);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }
}
