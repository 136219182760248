import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { firstValueFrom } from 'rxjs';
declare var jquery:any;
declare var $ :any;

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit, AfterViewInit {
    public form: UntypedFormGroup;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        this.form = new UntypedFormGroup({
            usuario: new UntypedFormControl('',[Validators.required]),
            password: new UntypedFormControl('',[Validators.required])
        });
    }

    ngOnInit() {
        if(this.authService.isLoggedIn()){
            this.router.navigate(['']);
        }
    }

    ngAfterViewInit(){
        $('#am-splash-screen').css('height', `${window.innerHeight}px`);
        $("body").addClass("authentication-bg");
    }

    public onSubmit(){
        if(this.form.valid){
            const user = this.form.value['usuario'];
            const password = this.form.value['password'];

            firstValueFrom(this.authService.signIn(user,password)).then(
                response => {
                    if(response.error){
                      $.NotificationApp.send( "Error",  response.message,'bottom-right', '#fa5c7c', 'error');
                      console.log(response.message);
                      return;
                    }

                    if(response.type_user == 'judge'){
                        window.location.replace("https://jueces.ecuestre.digital/login");
                        return;
                    }

                    const token = response.jwt;
                    //Guardar token en local storage
                    localStorage.clear();
                    localStorage.setItem('token', token);
                    this.authService.identifyUserLOG(user);
                    //Redirigir a pagina de inicio
                    $("body").removeClass("authentication-bg");
                    this.router.navigate(['']);
                },
                error => {
                    $.NotificationApp.send( "Error",  "It was not possible to login.",'bottom-right', '#fa5c7c', 'error');
                    console.log(error);
                }
            );
        } else{
            this.form.get('usuario').markAsTouched();
            this.form.get('password').markAsTouched();
        }
    }
}
