import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, AfterViewInit, EventEmitter } from '@angular/core';
import { ResultadoService } from './../services/resultado.service';
declare var $: any;

@Component({
  selector: 'app-import-results',
  templateUrl: './import-results.component.html',
  styleUrls: ['./import-results.component.css']
})
export class ImportResultsComponent implements OnInit, OnChanges, AfterViewInit {
  public headers: string[];
  public rows: any[];
  public saving: boolean;
  @Input() public showId: string | number;
  @Input() public classNumber: string;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private resultadoService: ResultadoService) {
    this.headers = [];
    this.rows = [];
    this.saving = false;
  }

  ngAfterViewInit(): void {
    $('#import-results-modal').on('hide.bs.modal', event => {
      $('input[type=file]').val('');
    })
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.classNumber){
      this.rows = [];
      this.headers = [];
      $('input[type=file]').val('');
    }
  }

  onFileChange(files: any[]) {
    const [file] = [...files];
    if(!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      //Split fin de linea, y filter para quitar renglones vacios
      const contents = e.target.result.toString().split('\r\n').filter(row => row);

      //Validar entries duplicados
      const duplicatedEntries = contents.map(r => {
        const [backNumber] = r.split(',');
        return backNumber;
      }).filter((e,i,a) => {
        return a.indexOf(e) !== i;
      });

      this.rows = contents.map(row => 
        {const [backNumber, riderName, horse, jumpFaultsR1, timeFaultsR1, totalFaultsR1, timeR1, jumpFaultsR2, timeFaultsR2, totalFaultsR2, timeR2, rank] = row.split(',');
        let errors = '';
        if(duplicatedEntries.includes(backNumber)){
          errors += 'Duplicated entry. ';
        }

        let status1 = '';
        if(timeR1 == 'EL') status1 = 'EL';
        if(timeR1 == 'WD') status1 = 'WD';
        if(timeR1 == 'RT') status1 = 'RT';
        let status2 = '';
        if(timeR2 == 'EL') status2 = 'EL';
        if(timeR2 == 'WD') status2 = 'WD';
        if(timeR2 == 'RT') status2 = 'RT';
        return {
          showId: this.showId,
          round: 1,
          classNumber: this.classNumber,
          backNumber,
          riderName,
          horse,
          jumpFaultsR1,
          timeFaultsR1,
          totalFaultsR1,
          timeR1,
          status1: status1 ,
          jumpFaultsR2,
          timeFaultsR2,
          totalFaultsR2,
          timeR2,
          status2: status2,
          rank,
          errors
        };
      });
      console.log('rows', this.rows)
      console.log(duplicatedEntries);
      $('#import-results-modal').modal('show');
    };
    reader.readAsText(file);
  }

  async saveResults() {
    this.saving = true;
    const response = await this.resultadoService.saveResultsPyramid(this.rows, this.showId, this.classNumber);
    if(response.error){
      this.saving = false;
      $.NotificationApp.send("Error", response.message, 'top-right', 'rgba(0,0,0,0.2)', 'error');
      return;
    }

    console.log('saveResults', response);
    const entriesNotFound = response.data.entriesNotFound||[];
    for(const e of entriesNotFound){
      const index = this.rows.findIndex(r => r.backNumber == e.entry);
      this.rows[index].errors = 'Entry not found in class.';
    }

    this.saving = false;
    this.onSaved.emit(response);
    //TODO: validar si no hay errores
    if(!entriesNotFound.length) $('#import-results-modal').modal('hide');
    $.NotificationApp.send("Success", "Results saved successfully.", 'top-right', 'rgba(0,0,0,0.2)', 'success');

  }

  public get hasErrors(): boolean {
    return this.rows.some(r => r.errors);
  }
}
