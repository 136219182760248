<div id="modal-metodos" tabindex="-1" role="dialog" class="modal fade" style="display: none;">
  <div class="modal-dialog modal-full-width">
    <div class="modal-content">
      <div class="modal-header modal-colored-header bg-success">
        <h3 class="modal-title"><span>Summary by Payment Method</span> ({{metodoSeleccionado }}) </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          <i style="color: #ffffff;" class=" mdi mdi-close-box-outline"></i>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped table-fw-widget">
          <thead>
            <tr>
              <th>User</th>
              <th>Amount</th>
              <th>Entry/Trainer</th>
              <th>Notes</th>
              <th *ngIf="metodoSeleccionado == 'Cheque'">Check Date</th>
              <th>Logged</th>
              <!--th>Received</th-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pago of detallesMetodo">
              <td>{{ pago.usuario }}</td>
              <td>{{ pago.cantidad | currency }}</td>
              <td>{{ pago.responsable }}</td>
              <td>{{ pago.referencia }}</td>
              <td *ngIf="metodoSeleccionado == 'Cheque'">{{pago.recibido | date:'MMMM d, y'}}</td>
              <td>{{ pago.creado | date:'MMMM d, y' }}</td>
              <!--td>{{ pago.recibido | date:'MMMM d, y' }}</td-->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-success">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content" [ngClass]="{'d-print-none': printMerch}">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <div class="row margin-top-35">
          <pre></pre>
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <div id="basicwizard">
                  <!-- inicio de pestañas principales (PAGOS y BALANCE) -->
                  <ul class="nav nav-pills bg-light nav-justified form-wizard-header d-print-none">
                    <li class="nav-item">
                      <a href="#tabBalance" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2 active show">
                        <i class="mdi mdi mdi-calculator mr-1"></i>
                        <span class="d-none d-sm-inline">Balance</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="privilegios >= 200">
                      <a href="#tabPagos" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2">
                        <i class="mdi mdi mdi-cash-usd mr-1"></i>
                        <span class="d-none d-sm-inline">Payment</span>
                      </a>
                    </li>
                  </ul>
                  <!-- fin de pestañas principales (PAGOS y BALANCE) -->
                  <div class="tab-content b-0 mb-0 padding-0" style="margin-top:35px;">
                    <!-- inicio de pestaña de BALANCE -->
                    <div class="tab-pane active show" id="tabBalance">
                      <h2 class="row  d-print-block hide-on-screen">
                        {{nombreConcurso}}
                      </h2>
                      <div class="row">
                        <div class="col-xl-12">
                          <!-- INICIO de submenu de general-->
                          <div class="card">
                            <div class="card-body" style="padding-top:0 !important;">
                              <div id="btnwizard">

                                <div class="tab-content b-0 mb-0 padding-0">
                                  <div class="tab-pane active show" id="tabGeneral">

                                    <div class="row">
                                      <div class="col-12 padding-0">
                                        <div class="panel-body">
                                          <div class="col-md-12 padding-0">
                                            <div class="row">
                                              <div class="col-3">
                                                <div class="card">
                                                  <div class="card-header bg-dark text-white">
                                                    <h3>Summary</h3>
                                                  </div>
                                                  <div class="card-body padding-0">
                                                    <table class="table table-hover table-sm">
                                                      <tbody>
                                                        <tr>
                                                          <td>Total Revenue</td>
                                                          <td>{{ incomeRows.total_ingresos | currency }}</td>
                                                        </tr>
                                                        <tr>
                                                          <td>Prizes</td>
                                                          <td>{{ totalPrizes | currency }}</td>
                                                        </tr>
                                                        <tr *ngIf="totalChampionshipPrizes > 0">
                                                          <td>Prizes Championship Series</td>
                                                          <td>{{ totalChampionshipPrizes | currency }}</td>
                                                        </tr>
                                                        <tr *ngIf="totalExpenses > 0">
                                                          <td>Expenses</td>
                                                          <td>{{ totalExpenses | currency }}</td>
                                                        </tr>
                                                      </tbody>
                                                      <tfoot>
                                                        <tr>
                                                          <td>Balance</td>
                                                          <td>{{ incomeRows.total_ingresos - totalPrizes - totalExpenses
                                                            | currency }}</td>
                                                        </tr>
                                                      </tfoot>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <div class="card-header bg-danger text-white">
                                                    <h3>Expenses</h3>
                                                  </div>
                                                  <div class="card-body p-0">
                                                    <table class="table table-hover table-sm">
                                                      <thead style="display:none;">
                                                        <th>Concept</th>
                                                        <th>Payment Method</th>
                                                        <th>Notes</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                      </thead>
                                                      <tbody>
                                                        <tr class="d-print-none">
                                                          <td><input class="form-control" type="text"
                                                              [formControl]="expenseConcept"></td>
                                                          <td>
                                                            <select class="form-control"
                                                              [formControl]="expensePaymentMethod">
                                                              <option>Credit Card</option>
                                                              <option>Check</option>
                                                              <option>Bank Deposit</option>

                                                              <option>Cash</option>
                                                              <option>Wire Transfer</option>
                                                            </select>
                                                          </td>
                                                          <td><input class="form-control" type="text"
                                                              [formControl]="expenseReference"></td>
                                                          <td><input class="form-control" type="number"
                                                              [formControl]="expenseAmount"></td>
                                                          <td>
                                                            <button class="btn btn-icon no-shadow padding-0"
                                                              style="font-size:1.875rem; margin-top:-5px; margin-bottom:-15px"
                                                              (click)="setChangeExpense('plus')">
                                                              <i class="mdi mdi-plus-circle"
                                                                style="line-height: 0.5 !important; color: #0acf97 !important;"></i>
                                                            </button>
                                                          </td>
                                                        </tr>
                                                        <tr *ngFor="let expense of expenses">
                                                          <td>{{ expense.concepto }}</td>
                                                          <td>{{ expense.forma_pago }}</td>
                                                          <td>{{ expense.referencia }}</td>
                                                          <td>{{ expense.cantidad | currency }}</td>
                                                          <td>
                                                            <button class="btn btn-icon no-shadow padding-0"
                                                              style="font-size:1.875rem; margin-top:-5px; margin-bottom:-15px"
                                                              (click)="setChangeExpense('less',expense.id_gastos_concurso)">
                                                              <i class="mdi mdi-minus-circle"
                                                                style="line-height: 0.5 !important; color: #fa5c7c!important"></i>
                                                            </button>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                      <tfoot>
                                                        <tr>
                                                          <td colspan="3">Total Expenses</td>
                                                          <td colspan="2">{{ totalExpenses | currency }}</td>
                                                        </tr>
                                                      </tfoot>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-4 p-0">
                                                <div class="card">
                                                  <div class="card-header bg-success text-white">
                                                    <h3><b>Revenue</b></h3>
                                                  </div>
                                                  <div class="card-body padding-0">
                                                    <table class="table table-hover table-sm">
                                                      <tbody>
                                                        <tr>
                                                          <td>Class Entries</td>
                                                          <td>{{ incomeRows.total_inscripciones | number}}</td>
                                                          <td>{{ incomeRows.inscripciones | currency }}</td>
                                                        </tr>
                                                        <tr *ngFor="let concepto of incomeRows.conceptos">
                                                          <td>{{ concepto.nombre }}</td>
                                                          <td>{{ concepto.cantidad | number}}</td>
                                                          <td>{{ concepto.monto | currency}}</td>
                                                        </tr>
                                                      </tbody>
                                                      <tfoot>
                                                        <tr>
                                                          <td colspan="2">Total Revenue</td>
                                                          <td>{{ incomeRows.total_ingresos | currency }}</td>
                                                        </tr>
                                                      </tfoot>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-4">
                                                <div class="card">
                                                  <div class="card-header bg-info text-white">
                                                    <h3>Feed orders summary</h3>
                                                  </div>
                                                  <div class="card-body p-0">
                                                    <table class="table table-hover table-sm">
                                                      <thead>
                                                        <th>Concept</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                      </thead>
                                                      <tbody>
                                                        <tr *ngFor="let order of orders">
                                                          <td>{{ order.producto }}</td>
                                                          <td>{{ order.cantidad }}</td>
                                                          <td>{{ order.total | currency }}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <div class="card-header bg-info text-white flex"
                                                    style="white-space: nowrap;">
                                                    <h3 style="display: inline-block;">Merch summary</h3>
                                                    <div class="card-widgets">
                                                      <a href="javascript:void(0)" (click)="printMerchSummary()"
                                                        title="Print"><i class="mdi mdi-printer"></i></a>
                                                    </div>
                                                  </div>
                                                  <div class="card-body p-0">
                                                    <table class="table table-hover table-sm">
                                                      <thead>
                                                        <th>Concept</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                      </thead>
                                                      <tbody>
                                                        <tr *ngFor="let product of merch">
                                                          <td>{{ product.Product }}</td>
                                                          <td>{{ product.Quantity }}</td>
                                                          <td>{{ product.Total | currency }}</td>
                                                        </tr>
                                                        <tr>
                                                          <td>Total</td>
                                                          <td></td>
                                                          <td>{{ totalMerch | currency }}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div> <!-- end col -->
                                    </div> <!-- end row -->
                                  </div>


                                </div> <!-- tab-content -->
                              </div> <!-- end #basicwizard-->
                            </div> <!-- end card-body -->
                          </div>
                          <!-- FIN de submenu de general-->
                          <div class="panel-body d-none">
                            <div class="card">
                              <div class="card-header bg-dark text-white">
                                <h3><b>Prizes</b> <span id="monto-bolsa-premios"> {{totalPrizes | currency }} </span>
                                </h3>
                              </div>
                              <div class="card-body padding-0">
                                <table class="table table-hover table-condensed">
                                  <thead>
                                    <th>Entry</th>
                                    <th>Class</th>
                                    <th>Rank</th>
                                    <th>Horse</th>
                                    <th>Rider</th>
                                    <th colspan="2" style="text-align:center;">Amount</th>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let fila of filas">
                                      <td>{{ fila.responsable }}</td>
                                      <td>{{ fila.nombre_prueba }}</td>
                                      <td>{{ fila.posicion }}</td>
                                      <td>{{ fila.nombre_caballo }}</td>
                                      <td>{{ fila.nombre_jinete }}</td>
                                      <td>
                                        <i *ngIf="fila.corresponde_cheque == 1" class="mdi mdi-square-inc-cash"></i>
                                      </td>
                                      <td>{{ fila.monto | currency }}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                            <!--div class="col-md-12">
                                                            <div class="col-md-12">
                                                                <div class="panel panel-info">
                                                                    <div class="panel-heading" style="background-color: #923f3f;">
                                                                        <h2>Pruebas - Total inscritos en el concurso: {{ totalInscritos }}</h2>
                                                                    </div>
                                                                    <ng-container *ngFor="let prize of testPrizes; let index = index">
                                                                        <div>
                                                                            <div class="panel-body col-md-4" style="float: left; border-color: #b76161; border-width: 1px; border-style: solid; width: 20%;">
                                                                                <h4>{{ prize.fecha_prueba}}</h4>
                                                                                <table class="table table-hover">
                                                                                    <thead>
                                                                                        <th><h4>Prueba</h4></th>
                                                                                        <th><h4>Inscritos</h4></th>
                                                                                        <th><h4>Monto</h4></th>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr *ngFor="let p of prize.pruebas">
                                                                                            <td>
                                                                                                <strong>
                                                                                                    {{ p.prueba }}
                                                                                                </strong> {{ p.nombre }}
                                                                                                <strong *ngIf="p.categoria != null">
                                                                                                    {{ p.categoria }}
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td>{{ p.cantidad }}</td>
                                                                                            <td>{{ p.costo_total | currency }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <strong>
                                                                                                    Total inscritos por día:
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td colspan="2">{{ prize.inscritos }}</td>
                                                                                        </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12" style="margin-bottom: 30px;" *ngIf="index == 4"></div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div-->
                          </div>
                        </div> <!-- end col -->
                      </div> <!-- end row -->
                    </div>
                    <div class="tab-pane" id="tabPagos" *ngIf="privilegios >= 200">
                      <div class="row">
                        <div class="col-12">
                          <div class="card">
                            <div class="card-body padding-0">
                              <ul class="nav nav-tabs nav-justified nav-bordered mb-3">
                                <li class="nav-item">
                                  <a href="#tabRecibidos" data-toggle="tab" aria-expanded="false"
                                    class="nav-link active show">
                                    <i class="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                    <span class="d-none d-lg-block">Received</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a href="#tabPorRecibir" data-toggle="tab" aria-expanded="true" class="nav-link">
                                    <i class="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
                                    <span class="d-none d-lg-block">Not Received</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a href="#tabTotales" data-toggle="tab" aria-expanded="false" class="nav-link">
                                    <i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
                                    <span class="d-none d-lg-block">Totals</span>
                                  </a>
                                </li>
                              </ul>

                              <div class="tab-content">
                                <div class="tab-pane active show" id="tabRecibidos">
                                  <div id="recibidos" class="tab-pane active">
                                    <div class="col-12 mb-3">
                                      <input type="text" class="form-control" placeholder="Browse" [(ngModel)]="filtro">
                                    </div>
                                    <div class="col-xl-12">
                                      <div *ngFor="let payment of paymentsReceived">
                                        <h3 style='margin-bottom:30px'>{{ payment.usuario_pagos_recibidos }}</h3>
                                        <div *ngFor="let m of payment.metodos_pago"
                                          style="float:left;margin-bottom: 5px;">
                                          <span [ngSwitch]="m.metodo">
                                            <span *ngSwitchCase="'Efectivo'" class="badge text-white Efectivo"><i
                                                class="mdi mdi-cash"></i>
                                              <span>Cash: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Deposito'" class="badge text-white Deposito">
                                              <i class="mdi mdi-bank"></i>
                                              <span>Bank Deposit: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                              <i class="mdi mdi-view-list"></i>
                                              <span>Check: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Cortesia'" class="badge text-white Cortesia">
                                              <i class="mdi mdi-gift"></i>
                                              <span>Courtesy: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Reembolso'" class="badge text-white Reembolso">
                                              <i class="mdi mdi-backburger"></i>
                                              <span>Refund: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                              <i class="mdi mdi-credit-card"></i>
                                              <span>Credit Card: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Transferencia'"
                                              class="badge text-white Transferencia">
                                              <i class="mdi mdi-bank"></i>
                                              <span>Bank Transfer: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Traspaso'" class="badge text-white Traspaso">
                                              <i class="mdi mdi-arrow-expand-right"></i>
                                              <span>Balance Transfer: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchCase="'Intercambio'" class="badge text-white Intercambio">
                                              <i class="mdi mdi-arrow-collapse"></i>
                                              <span>Exchange: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                            <span *ngSwitchDefault class="badge">
                                              <span>{{ m.metodo }}: {{ m.cantidad |
                                                currency }}</span>
                                            </span>
                                          </span>
                                        </div>
                                        <div class="panel-body">
                                          <table class="table table-hover table-condensed">
                                            <thead>
                                              <tr>
                                                <th>Entry/Trainer</th>
                                                <th>Amount</th>
                                                <th>Reference</th>
                                                <th>Notes</th>
                                                <th>Received</th>
                                                <th>Date</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let p of filtrarPagos(payment.pagos_recibidos)">
                                                <td>{{ p.responsable }}</td>
                                                <td [ngSwitch]="p.metodo">{{ p.cantidad | currency }}
                                                  <span *ngSwitchCase="'Efectivo'" class="badge text-white Efectivo"><i
                                                      class="mdi mdi-cash"></i>
                                                    <span>Cash</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Deposito'" class="badge text-white Deposito">
                                                    <i class="mdi mdi-bank"></i>
                                                    <span>Bank Deposit</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                                    <i class="mdi mdi-view-list"></i>
                                                    <span>Check</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Cortesia'" class="badge text-white Cortesia">
                                                    <i class="mdi mdi-gift"></i>
                                                    <span>Courtesy</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Reembolso'" class="badge text-white Reembolso">
                                                    <i class="mdi mdi-backburger"></i>
                                                    <span>Refund</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                                    <i class="mdi mdi-credit-card"></i>
                                                    <span>Credit Card</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Transferencia'"
                                                    class="badge text-white Transferencia">
                                                    <i class="mdi mdi-bank"></i>
                                                    <span>Transfer</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Traspaso'" class="badge text-white Traspaso">
                                                    <i class="mdi mdi-arrow-expand-right"></i>
                                                    <span>Balance Transfer</span>
                                                  </span>
                                                  <span *ngSwitchCase="'Intercambio'"
                                                    class="badge text-white Intercambio">
                                                    <i class="mdi mdi-arrow-collapse"></i>
                                                    <span>Exchange</span>
                                                  </span>
                                                </td>
                                                <td>{{ p.referencia }}</td>
                                                <td>
                                                  <div class="form-group">
                                                    <input type="text" placeholder="Notas" class="form-control"
                                                      [value]="p.destino"
                                                      (change)="setPaymentReceivedNotes($event, p.id)">
                                                  </div>
                                                </td>
                                                <td>
                                                  <div class="custom-control custom-checkbox">
                                                    <input id="checkboxPR{{p.id}}" type="checkbox"
                                                      class="custom-control-input"
                                                      (change)="setPaymentReceived($event, p.id)" checked='true'
                                                      [disabled]="privilegios < 250">
                                                    <label for="checkboxPR{{p.id}}"
                                                      class="custom-control-label"></label>
                                                  </div>
                                                </td>
                                                <td>{{ p.fecha_pago | date:'EEEE, MMMM d, y' }}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="tab-pane" id="tabPorRecibir">
                                  <div id="por_recibir" class="tab-pane cont">
                                    <div class="panel-body">
                                      <div class="col-12 mb-3">
                                        <input type="text" class="form-control" placeholder="Browse"
                                          [(ngModel)]="filtro">
                                      </div>
                                      <div class="col-md-12">
                                        <div *ngFor="let payment of paymentToReceive">
                                          <h3 style='margin-bottom:30px'>{{ payment.usuario_pagos_por_recibir }}</h3>
                                          <div *ngFor="let m of payment.metodos_pago"
                                            style="float:left;margin-bottom: 5px;">
                                            <span [ngSwitch]="m.metodo">
                                              <span *ngSwitchCase="'Efectivo'" class="badge text-white Efectivo"><i
                                                  class="mdi mdi-cash"></i>
                                                <span>Cash: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Deposito'" class="badge text-white Deposito">
                                                <i class="mdi mdi-bank"></i>
                                                <span>Bank Deposit: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                                <i class="mdi mdi-view-list"></i>
                                                <span>Check: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Cortesia'" class="badge text-white Cortesia">
                                                <i class="mdi mdi-gift"></i>
                                                <span>Courtesy: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Reembolso'" class="badge text-white Reembolso">
                                                <i class="mdi mdi-backburger"></i>
                                                <span>Refund: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                                <i class="mdi mdi-credit-card"></i>
                                                <span>Credit Card: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Transferencia'"
                                                class="badge text-white Transferencia">
                                                <i class="mdi mdi-bank"></i>
                                                <span>Bank Transfer: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Traspaso'" class="badge text-white Traspaso">
                                                <i class="mdi mdi-arrow-expand-right"></i>
                                                <span>Balance Transfer: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchCase="'Intercambio'" class="badge text-white Intercambio">
                                                <i class="mdi mdi-arrow-collapse"></i>
                                                <span>Exchange: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                              <span *ngSwitchDefault class="badge">
                                                <span>{{ m.metodo }}: {{ m.cantidad |
                                                  currency }}</span>
                                              </span>
                                            </span>
                                          </div>
                                          <div class="panel-body">
                                            <table class="table table-hover table-condensed">
                                              <thead>
                                                <tr>
                                                  <th>Entry/Trainer</th>
                                                  <th>Amount</th>
                                                  <th>Reference</th>
                                                  <th>Notes</th>
                                                  <th>Date</th>
                                                  <th>Received</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  *ngFor="let p of filtrarPagos(payment.pagos_por_recibir); let i = index">
                                                  <td>{{ p.responsable }}</td>
                                                  <td [ngSwitch]="p.metodo">{{ p.cantidad | currency }}
                                                    <span *ngSwitchCase="'Efectivo'"
                                                      class="badge text-white Efectivo"><i class="mdi mdi-cash"></i>
                                                      <span>Cash</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Deposito'" class="badge text-white Deposito">
                                                      <i class="mdi mdi-bank"></i>
                                                      <span>Bank Deposit</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                                      <i class="mdi mdi-view-list"></i>
                                                      <span>Check</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Cortesia'" class="badge text-white Cortesia">
                                                      <i class="mdi mdi-gift"></i>
                                                      <span>Courtesy</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Reembolso'"
                                                      class="badge text-white Reembolso">
                                                      <i class="mdi mdi-backburger"></i>
                                                      <span>Refund</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                                      <i class="mdi mdi-credit-card"></i>
                                                      <span>Credit Card</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Transferencia'"
                                                      class="badge text-white Transferencia">
                                                      <i class="mdi mdi-bank"></i>
                                                      <span>Bank Transfer</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Traspaso'" class="badge text-white Traspaso">
                                                      <i class="mdi mdi-arrow-expand-right"></i>
                                                      <span>Balance Transfer</span>
                                                    </span>
                                                    <span *ngSwitchCase="'Intercambio'"
                                                      class="badge text-white Intercambio">
                                                      <i class="mdi mdi-arrow-collapse"></i>
                                                      <span>Exchange</span>
                                                    </span>
                                                    <span *ngSwitchDefault class="badge">
                                                      <span>{{ p.metodo }}</span>
                                                    </span>
                                                  </td>
                                                  <td>{{ p.referencia }}</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input type="text" placeholder="Notas" class="form-control"
                                                        [value]="p.destino"
                                                        (change)="setPaymentReceivedNotes($event, p.id)">
                                                    </div>
                                                  </td>
                                                  <td>{{ p.fecha_pago | date:'EEEE, MMMM d, y' }}</td>
                                                  <td>
                                                    <div class="custom-control custom-checkbox">
                                                      <input id="checkboxPR{{p.id}}" type="checkbox"
                                                        class="custom-control-input"
                                                        (change)="setPaymentReceived($event, p.id)"
                                                        [disabled]="privilegios < 250">
                                                      <label for="checkboxPR{{p.id}}"
                                                        class="custom-control-label"></label>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="tab-pane" id="tabTotales">
                                  <div id="totales" class="tab-pane cont">
                                    <div class="panel-body">
                                      <div class="col-md-12">
                                        <div class='col-md-12'>
                                          <div class='card'>
                                            <div class='card-header bg-success text-white'>
                                              <span class='title'>Payment Method Summary</span>
                                            </div>
                                            <div class='card-body' id="contenido-totales">
                                              <div class="panel-body padding-0">
                                                <table class="table table-hover table-condensed">
                                                  <thead>
                                                    <th>Method</th>
                                                    <th>Amount</th>
                                                  </thead>
                                                  <tbody>
                                                    <tr *ngFor="let p of totalPayments"
                                                      (click)="mostrarDetalleMetodo(p.metodo)">
                                                      <td [ngSwitch]="p.metodo">
                                                        <span *ngSwitchCase="'Efectivo'"
                                                          class="badge text-white Efectivo"><i class="mdi mdi-cash"></i>
                                                          <span>Cash</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Deposito'"
                                                          class="badge text-white Deposito">
                                                          <i class="mdi mdi-bank"></i>
                                                          <span>Bank Deposit</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                                          <i class="mdi mdi-view-list"></i>
                                                          <span>Check</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Cortesia'"
                                                          class="badge text-white Cortesia">
                                                          <i class="mdi mdi-gift"></i>
                                                          <span>Courtesy</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Reembolso'"
                                                          class="badge text-white Reembolso">
                                                          <i class="mdi mdi-backburger"></i>
                                                          <span>Refund</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                                          <i class="mdi mdi-credit-card"></i>
                                                          <span>Credit Card</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Transferencia'"
                                                          class="badge text-white Transferencia">
                                                          <i class="mdi mdi-bank"></i>
                                                          <span>Bank Transfer</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Traspaso'"
                                                          class="badge text-white Traspaso">
                                                          <i class="mdi mdi-arrow-expand-right"></i>
                                                          <span>Balance Transfer</span>
                                                        </span>
                                                        <span *ngSwitchCase="'Intercambio'"
                                                          class="badge text-white Intercambio">
                                                          <i class="mdi mdi-arrow-collapse"></i>
                                                          <span>Exchange</span>
                                                        </span>
                                                        <span *ngSwitchDefault class="badge">
                                                          <span>{{ p.metodo }}</span>
                                                        </span>
                                                      </td>
                                                      <td>{{ p.cantidad | currency }}</td>
                                                    </tr>
                                                  </tbody>
                                                  <tfoot>
                                                    <tr>
                                                      <td>Total (All payment methods)</td>
                                                      <td>{{ totalPaymentAmount | currency }}</td>
                                                    </tr>
                                                  </tfoot>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngFor="let p of paymentsPerUser">
                                      <div class='col-md-6 float-left'>
                                        <div class='card'>
                                          <div class='card-header bg-success text-white'>
                                            <span class='title'><span>Registered by </span> {{p.payment_user}}</span>
                                          </div>
                                          <div class='card-body padding-0' id="contenido-individuales">
                                            <div class="panel-body">
                                              <table class="table table-hover table-condensed">
                                                <thead>
                                                  <th>Method</th>
                                                  <th>Amount</th>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let m of p.conceptos"  (click)="mostrarDetalleMetodoPersona(m.metodo, p.payment_user)">
                                                    <td [ngSwitch]="m.metodo">
                                                      <span *ngSwitchCase="'Efectivo'"
                                                        class="badge text-white Efectivo"><i class="mdi mdi-cash"></i>
                                                        <span>Cash</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Deposito'"
                                                        class="badge text-white Deposito">
                                                        <i class="mdi mdi-bank"></i>
                                                        <span>Bank Deposit</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Cheque'" class="badge text-white Cheque">
                                                        <i class="mdi mdi-view-list"></i>
                                                        <span>Check</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Cortesia'"
                                                        class="badge text-white Cortesia">
                                                        <i class="mdi mdi-gift"></i>
                                                        <span>Courtesy</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Reembolso'"
                                                        class="badge text-white Reembolso">
                                                        <i class="mdi mdi-backburger"></i>
                                                        <span>Refund</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Tc'" class="badge text-white Tc">
                                                        <i class="mdi mdi-credit-card"></i>
                                                        <span>Credit Card</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Transferencia'"
                                                        class="badge text-white Transferencia">
                                                        <i class="mdi mdi-bank"></i>
                                                        <span>Bank Transfer</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Traspaso'"
                                                        class="badge text-white Traspaso">
                                                        <i class="mdi mdi-arrow-expand-right"></i>
                                                        <span>Balance Transfer</span>
                                                      </span>
                                                      <span *ngSwitchCase="'Intercambio'"
                                                        class="badge text-white Intercambio">
                                                        <i class="mdi mdi-arrow-collapse"></i>
                                                        <span>Exchange</span>
                                                      </span>
                                                      <span *ngSwitchDefault class="badge">
                                                        <span>{{ m.metodo }}</span>
                                                      </span>
                                                    </td>
                                                    <td>{{ m.cantidad | currency }}</td>
                                                  </tr>
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td>Total (All payment methods)</td>
                                                    <td>{{ p.sumatoria | currency }}</td>
                                                  </tr>
                                                </tfoot>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> <!-- end card-body-->
                          </div>
                        </div> <!-- end col -->
                      </div> <!-- end row -->
                    </div>
                  </div> <!-- tab-content -->
                </div> <!-- end #basicwizard-->
              </div> <!-- end card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>


    <ng-container>
      <div class="row justify-content-center d-print-block hide-on-screen"
        style="padding:20px;padding-top:0px !important" *ngIf="printMerch">
        <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
          <div class="row">
            <div style="display: flex; justify-content: space-between; width:100%;">
              <div style="flex: 1;" class="text-left">
                <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                  onload="this.style.display='-webkit-inline-box'">
              </div>
              <div>
                <h3>{{ nombreConcurso }}</h3>
                <h4 style="text-align:center"><span>Merch summary</span></h4>
              </div>
              <div style="flex: 1" class="text-right">

              </div>
            </div>
            <div class="mt-3">
              <div><b>Start:</b> {{ fechaInicio }}</div>
              <div><b>End:</b> {{ fechaFin }}</div>
            </div>
          </div>

          <table class="table table-hover table-condensed table-summary" style="margin-top:25px;">
            <thead>
              <th>Concept</th>
              <th>Qty</th>
              <th>Total</th>
            </thead>
            <tbody>
              <tr *ngFor="let product of merch">
                <td>{{ product.Product }}</td>
                <td>{{ product.Quantity }}</td>
                <td>{{ product.Total | currency }}</td>
              </tr>
              <tr>
                <td></td>
                <td><b>Total</b></td>
                <td>{{ totalMerch | currency }}</td>
              </tr>
            </tbody>
          </table>

          <p class="d-none d-print-block pl-2 mt-3">
            <span>Powered by </span> <img src="assets/images/logo.png" style="width: 15px;">
            <span>EquestrianDigital</span>
          </p>
          <p class="info-panel" style="float:right">Download the App for Class Results, Live Video and Add/Scratches</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
