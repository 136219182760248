<div class="wrapper">
  <div class="content-page m-0">
    <div class="content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="card d-flex">
              <div class="row">
                <div class="col-4 text-center">
                  <p class="mb-0 entries-due mt-3">
                    <b>
                      ENTRIES DUE
                      <br>
                      {{ show?.entriesDue | date : 'MMM dd, yyyy' | titlecase }}</b>
                  </p>
                </div>
                <div class="col-4 text-center">
                  <img [src]="show?.logoClub">
                  <h5 class="m-0">{{ show?.name }}</h5>
                  <h4 class="m-0">Entry Form</h4>
                </div>
                <div class="col-4 text-center mt-auto">
                  <p class="mb-0 info">ONE HORSE PER ENTRY FORM<br>
                    <b>{{ show?.entryAmount | currency }} Deposit Due {{ show?.entriesDue | date : 'MMM dd, yyyy' | titlecase }}</b><br>
                    <span>All horses must have membership cards,<br>
                      a valid coggins and health certificate.
                    </span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card mt-2">
                    <div class="card-body">
                      <h5 class="card-title">Horse</h5>
                      <div class="row">
                        <div class="col-3">
                          <div class="form-group mb-0 pl-2">
                            <label>Name</label>
                            <p class="m-0">{{ horse?.name }}</p>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group mb-0">
                            <label>Usef #</label>
                            <p class="m-0">{{ horse?.usef }}</p>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group mb-0">
                            <label>Fei #</label>
                            <p class="m-0">{{ horse?.fei }}</p>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group mb-0">
                            <label>Entry #</label>
                            <p class="m-0">{{ entry }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <label for="emergencyContact">Emergency contact: </label>
                            {{ emergencyContact?.name }}
                            <span *ngIf="emergencyContact?.name && emergencyContact?.phone"> - </span>
                            {{ emergencyContact?.phone }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <ng-container *ngFor="let rider of riders; let index = index;">
                  <div class="col-6 sameHeight50">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Rider {{ index+1 }}</h5>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group mb-0 pl-2">
                              <p class="m-0"><b>Name</b> {{ rider?.riderName }}</p>
                            </div>
                          </div>
                          <div class="col-3 nowrap">
                            <div class="form-group mb-0">
                              <p class="m-0 "><b>Usef #</b> {{ rider?.riderUsef }}</p>
                            </div>
                          </div>
                          <div class="col-3 nowrap">
                            <div class="form-group mb-0">
                              <p class="m-0 "><b>Fei #</b> {{ rider?.riderFei }}</p>
                            </div>
                          </div>
                          <div class="col-6 pr-0">
                            <div class="form-group mb-0 pl-2">
                              <p class="m-0" style="white-space: nowrap;"><b>Phone</b> {{ rider?.riderPhone }}</p>
                            </div>
                          </div>
                          <div class="col-6 nowrap">
                            <div class="form-group mb-0">
                              <p class="m-0 "><b>Email</b> {{ rider?.riderEmail }}</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-0 pl-2">
                              <p class="m-0"><b>Address</b> {{ rider?.riderAddress }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="col-6 sameHeight50">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Owner</h5>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Name</b> {{ owner?.name }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Usef #</b> {{ owner?.usef }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Phone</b> {{ owner?.phone }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Email</b> {{ owner?.email }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Address</b> {{ owner?.address }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 sameHeight50">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Trainer</h5>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Name</b> {{ trainer?.name }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Usef #</b> {{ trainer?.usef }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Phone</b> {{ trainer?.phone }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Email</b> {{ trainer?.email }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Address</b> {{ trainer?.address }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 sameHeight50">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Payee</h5>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Name</b> {{ payee?.name }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Usef #</b> {{ payee?.usef }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Phone</b> {{ payee?.phone }}</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group mb-0">
                            <p class="m-0"><b>Email</b> {{ payee?.email }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Address</b> {{ payee?.address }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 sameHeight50">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Stabling information</h5>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Stable with</b> {{ stablingInformation?.stableWith }}</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-0 pl-2">
                            <p class="m-0"><b>Arrival Date</b> {{ stablingInformation?.arrivalDate }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 sameHeight50">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Classes</h5>
                      <div class="row">
                        <div class="col-12">
                          <div class="w-100 d-flex prueba">
                            <div class="numero">{{ numerosPruebas(pruebas) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card charges">
                    <div class="card-body">
                      <h5 class="card-title">Charges</h5>
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-unstyled mb-1" style="column-count: 3;">
                            <li *ngFor="let cargo of cargos" class="charge">{{ cargo.nombre }} ({{ cargo.monto |
                              currency }}) x {{ cargo.qty }} = {{ cargo.total | currency }}</li>
                          </ul>
                          <p class="text-right lead mb-0">Total: {{ totalCargos() | currency }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 legal">
                  <div class="card">
                    <h5>FEDERATION ENTRY AGREEMENT</h5>
                    <p>By entering a Federation-licensed Competition and signing this entry blank as the Owner, Lessee,
                      Trainer, Manager, Agent, Coach, Driver, Rider, Handler,
                      Vaulter or Longeur and on behalf of myself and my principals, representatives, employees and agents, I
                      agree that I am subject to the Bylaws and Rules
                      of The United States Equestrian Federation, Inc. (the “Federation”) and the local rules of
                      __________________________(Competition). I agree to be
                      bound by the Bylaws and Rules of the Federation and of the competition. I will accept as final the
                      decision of the Hearing Committee on any question arising
                      under the Rules, and agree to release and hold harmless the competition, the Federation, their
                      officials, directors and employees for any action taken under
                      the Rules. I represent that I am eligible to enter and/or participate under the Rules, and every horse I
                      am entering is eligible as entered. I also agree that as
                      a condition of and in consideration of acceptance of entry, the Federation and/or the Competition may
                      use or assign photographs, videos, audios, cable -
                      casts, broadcasts, internet, film, new media or other likenesses of me and my horse taken during the
                      course of the competition for the promotion, coverage
                      or benefit of the competition, sport, or the Federation. Those likenesses shall not be used to advertise
                      a product and they may not be used in such a way as
                      to jeopardize amateur status. I hereby expressly and irrevocably waive and release any rights in
                      connection with such use, including any claim to compensation, invasion of privacy, right of publicity,
                      or to misappropriation. The construction and application of Federation rules are governed by the laws of
                      the State
                      of New York, and any action instituted against the Federation must be filed in New York State. See
                      GR908.4.</p>
                    <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND
                        KNOW IT CONTAINS AN ASSUMPTION
                        OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION
                        OBLIGATIONS.</b></p>
                    <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below)
                      hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the
                      minor’s) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                      minor’s behalf, makes a claim for Liability against any of the Released Parties, I will indemnify,
                      defend and hold harmless each of the Released Parties
                      from any such Liabilities as the result of such claim.</p>
                    <p><b>BY SIGNING BELOW, I AGREE</b> to be bound by all applicable Federation Rules and all terms and
                      provisions of this entry blank and all terms and provisions
                      of this Prize List. If I am signing and submitting this Agreement electronically, I acknowledge that my
                      electronic signature shall have the same validity,
                      force and effect as if I affixed my signature by my own hand.</p>
                      <h5>WAIVER AND RELEASE OF LIABILITY, ASSUMPTION OF RISK AND INDEMNITY AGREEMENT</h5>
                      <p>For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian (“USEF”) allowing me, the undersigned, to participate in any
                        capacity (including as a rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach, official, trainer or volunteer) in a USEF sanctioned, licensed or
                        approved event or activity, including but not limited to equestrian clinics, practices, shows, competitions and related or incidental activities and
                        ________________________________(<b>“USEF Event”</b> or <b>“USEF Events”</b>); I, for myself, and on behalf of my spouse, children, heirs and next of kin,
                        and any legal and personal representatives, executors, administrators, successors,, and assigns, hereby agree to and make the following contractual repre- sentations pursuant to this Agreement (the “Agreement”):
                      </p>
                      <p>
                        A. RULES AND REGULATIONS: I hereby agree that I have read, understand, and agree to be bound by all applicable Federation Bylaws, rules, and policies
                        including the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at www.usef.org, as amended from time to time.
                      </p>
                      <p>B. ACKNOWLEDGMENT OF RISK: I knowingly, willingly, and voluntarily acknowledge the inherent risks associated with the sport of equestrian and know that
                        horseback riding and related equestrian activities are inherently dangerous, and that participation in any USEF Event involves risks and dangers including, without limitation, the potential for serious bodily injury (including broken bones, head or neck injuries), sickness and disease (including communicable diseases),
                        trauma, pain & suffering, permanent disability, paralysis and death; loss of or damage to personal property (including my mount & equipment) arising out of the
                        unpredictable behavior of horses; exposure to extreme conditions and circumstances; accidents involving other participants, eventstaff, volunteersor spectators; contact orcollision with other participants and horses, natural or manmadeobjects; adverseweather conditions; facilities issues and premises conditions;
                        failure of protective equipment (including helmets); inadequate safety measures; participants of varying skill levels; situations beyond the immediate control of
                        the USEF Event organizers and competition management; and other undefined, not readily foreseeable and presently unknown risks and dangers <b>(“Risks”)</b>.
                      </p>
                      <p class="text-center"><b>EQUINE ACTIVITY LIABILITY ACT WARNING:</b><br>
                        CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. RIDE AT YOUR OWN RISK.<br>
                        <b>Under the laws of most States, an equine activity sponsor or equine professional is not liable for any injury to, or the death of, a participant in
                          equine activities resulting from the inherent risks of equine activities.</b>
                      </p>
                      <p>C. ASSUMPTION OF RISK: I understand that the aforementioned Risks may be caused in whole or in part or result directly or indirectly from the negligence
                        of my own actions or inactions, the actions or inactions of others participating in the USEF Events, or the negligent acts or omissions of the Released Parties defined below, and I hereby voluntarily and knowingly assume all such Risks and responsibility for any damages, liabilities, losses, or expenses that I
                        incur as a result of my participation in any USEF Events. I also agree to be responsible for any injury or damage caused by me, my horse, my employees or
                        contractors under my direction and control at any USEF Event.
                      </p>
                      <p>D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY: In conjunction with my participation in any USEF Event, I hereby release, waive
                        and covenant not to sue, and further agree to indemnify, defend and hold harmless the following parties: USEF, USEF Recognized Affiliate Associations,
                        the United States Olympic & Paralympic Committee (USOPC), USEF clubs, members, Event participants (including athletes/riders, coaches, trainers, judges/officials, and other personnel), the Event owner, licensee, and competition managers; the promoters, sponsors, or advertisers of any USEF Event; any
                        charity or other beneficiary which may benefit from the USEF Event; the owners, managers, or lessors of any facilities or premises where a USEF Event may
                        be held; and all directors, officers, employees, agents, contractors, and volunteers of any of the aforementioned parties <b>(Individually and Collectively, the
                        “Released Parties” or “Event Organizers”)</b>, with respect to any liability, claim(s), demand(s), cause(s) of action, damage(s), loss, or expense (including
                        court costs and reasonable attorney fees) of any kind or nature <b>(“Liability”)</b> which may arise out of, result from, or relate in any way to my participation in
                        the USEF Events, including claims for Liability caused in whole or in part by the negligent acts or omissions of the Released Parties.
                      </p>
                      <p>E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE: This Agreement represents the complete understanding between the parties regarding these issues
                        and no oral representations, statements or inducements have been made apart from this Agreement. If any provision of this Agreement is held to be
                        unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and
                        enforceability of any remaining provisions.
                      </p>
                      <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND KNOW IT CONTAINS AN ASSUMPTION
                        OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION OBLIGATIONS.</b>
                      </p>
                      <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below) hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the minor’s) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                        minor’s behalf, makes a claim for Liability against any of the Released Parties, I will indemnify, defend and hold harmless each of the Released Parties
                        from any such Liabilities as the result of such claim.
                      </p>
                      <p><b>The parties agree that this agreement may be electronically signed. The parties agree that the electronic signatures appearing on this agreement
                        are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.</b>
                      </p>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 3cm;">
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Rider 1</div>
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Owner</div>
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Trainer</div>
                </div>
              </div>
              <div class="row justify-content-center" style="margin-top: 3cm;">
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Rider 2</div>
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Rider 3</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
