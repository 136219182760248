<title>Accounts Summary</title>
<div *ngIf="!idConcurso" style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: #000; opacity: 0.5; z-index: 100000"></div>
<div *ngIf="!idConcurso" style="position: fixed; top: 10%; left: 30%; width:50%;  background-color: #efefef; z-index:1000001; opacity: 1">
    <div class="modal-dialog modal-full-width" style="width: 100% !important;margin-top: 0px !important; margin-bottom: 0px !important">
        <div class="modal-content">
            <div class="modal-header">
                <h3>
                    <b>Select Show Week</b>
                </h3>
                <h3>
                    <a routerLink="/crear-concurso"><span style="float:right">Create new show</span></a>
                </h3>
            </div>
            <div class="modal-body" style="overflow: scroll;" [style.height.px]="windowHeight * 0.6">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th><b>Show</b></th>
                            <th><b>Organizer</b></th>
                            <th><b>Start</b></th>
                            <!-- <th><b>End</b></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let concurso of concursos" (click)="seleccionarConcurso(concurso.id, concurso.nombre, concurso.id_club, concurso.public_key)">
                            <td>{{ concurso.nombre }}</td>
                            <td>{{ concurso.club }}</td>
                            <td>{{ concurso.inicio }}</td>
                            <!-- <td>{{ concurso.fin }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>

<div class="wrapper">
    <menu-lateral class="left-side-menu active"></menu-lateral>
    <div class="content-page">
        <div class="content" *ngIf="privilegios >= 100"><!-- Topbar Start -->
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" (uploaded)="cambiarConcurso()" class="navbar-custom"></menu>
            <div class="row mt-3">
                <div class="d-print-flex d-none w-100 text-center align-items-center">
                    <div style="flex: 1;">
                        <img [src]="header" style="max-height: 90px;" onload="this.style.display='unset'" onerror="this.style.display='none'">
                    </div>
                    <div style="flex: 1;">
                        <h3>{{ nombreConcurso }}</h3>
                        <p><b>Open Balances</b></p>
                    </div>
                    <div style="flex: 1;">
                        <img [src]="header2" style="max-height: 90px;" onload="this.style.display='unset'" onerror="this.style.display='none'">
                    </div>
                </div>
                <!--div class="d-none d-print-block col-12">
                    Header concurso "Open Balances"
                </div-->
                <div class="container m-0">
                    <div class="row">
                        <!-- <div class="col-12 d-print-none">
                            <div class="card">
                                <div class="card-body p-2">
                                    <table class="table table-hover table-sm mb-0">
                                        <thead>
                                            <tr>
                                                <th colspan="7" class="text-center d-print-none">Stall Summary (requested online)</th>
                                            </tr>
                                            <tr>
                                                <th>Type</th>
                                                <th>Qty (Entries)</th>
                                                <th>Amount (Entries)</th>
                                                <th>Qty (Trainers)</th>
                                                <th>Amount (Trainers)</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let caballeriza of caballerizas">
                                                <td>{{ caballeriza.name }}</td>
                                                <td>{{ caballeriza.qtyEntries }}</td>
                                                <td>{{ caballeriza.amountEntries | currency }}</td>
                                                <td>{{ caballeriza.qtyTrainers }}</td>
                                                <td>{{ caballeriza.amountTrainers | currency }}</td>
                                                <td><b>{{ caballeriza.qty }}</b></td>
                                                <td><b>{{ caballeriza.amount | currency }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-8 col-print-8">
                            <div class="card">
                                <div class="card-body p-2">
                                    <table class="table table-sm mb-0">
                                        <thead>
                                            <tr>
                                                <th style="width: 2px;" class="p-0"></th>
                                                <th colspan="4" class="text-center d-print-none">Open Entry Balances</th>
                                            </tr>
                                            <tr>
                                                <th style="width: 2px;" class="p-0"></th>
                                                <th>Entry</th>
                                                <th>Horse</th>
                                                <th>Trainer</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let entry of entries" [ngClass]="{'d-none d-print-none': entry.saldo == 0}" [routerLink]="['/entries', entry.entry]" style="cursor: pointer;">
                                                <td style="width: 2px;" class="p-0" [ngClass]="{'bg-danger': entry.saldo < 0, 'bg-success': entry.saldo > 0, 'bg-info': entry.saldo == 0}"></td>
                                                <td>{{ entry.entry }}</td>
                                                <td>{{ entry.horse }}</td>
                                                <td>{{ entry.trainer }}</td>
                                                <td>{{ entry.saldo | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body p-2">
                                    <table class="table table-hover table-sm mb-0">
                                        <thead>
                                            <tr>
                                                <th style="width: 2px;" class="p-0"></th>
                                                <th colspan="2" class="text-center d-print-none">Open Trainer Balances</th>
                                            </tr>
                                            <tr>
                                                <th style="width: 2px;" class="p-0"></th>
                                                <th>Name</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let trainer of entrenadores" [ngClass]="{'d-none d-print-none': trainer.saldo == 0}" [routerLink]="['/trainer-account', trainer.id]" style="cursor: pointer;">
                                                <td style="width: 2px;" class="p-0" [ngClass]="{'bg-danger': trainer.saldo < 0, 'bg-success': trainer.saldo > 0, 'bg-info': trainer.saldo == 0}"></td>
                                                <td>{{ trainer.nombre }}</td>
                                                <td>{{ trainer.saldo | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- [ngClass]="{'d-print-none': trainer.total >= 0}" -->
                    <!--ng-container *ngFor="let trainer of entrenadores; let i = index;">
                        <div class="col-3" >
                            <div class="card">
                                <div class="card-header card-header-sm text-white" [ngClass]="{'bg-danger': trainer.total < 0, 'bg-success': trainer.total > 0, 'bg-info': trainer.total == 0}">
                                    <a href="javascript:void(0)" [routerLink]="['/trainer-account', trainer?.id]">
                                        {{ trainer?.nombre }}
                                    </a>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm table-hover m-0">
                                        <tbody>
                                            <tr>
                                                <td>Trainer balance</td>
                                                <td class="text-right">{{ trainer.saldo | currency }}</td>
                                            </tr>
                                            <tr *ngFor="let entry of trainer.entries">
                                                <td>
                                                    <a href="javascript:void(0)" *ngIf="entry.entry;else nonEntry" [routerLink]="['/entries', entry?.entry]">
                                                        {{ entry?.entry }} - {{ entry?.horse }}
                                                    </a>
                                                    <ng-template #nonEntry>
                                                        {{ entry?.entry }} - {{ entry?.horse }}
                                                    </ng-template>
                                                </td>
                                                <td class="text-right">{{ entry.saldo | currency }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td class="text-right"><b>{{ trainer.total | currency }}</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="(i+1)%4==0" class="clearfix"></div>
                    </ng-container-->
                </div>
            </div>
        </div>
    </div>
</div>
