<div class="row">
  <div class="col">
    <h6 class="text-center">Entries With Credits</h6>
    <ng-container  *ngFor="let entry of entriesWithCredits; let index = index;">
      <div class="d-flex justify-content-between">
        <div class="custom-control custom-radio">
          <input type="radio" [id]="'creditsRadio'+index" name="creditsRadio" class="custom-control-input" [value]="entry?.entry" [(ngModel)]="entrySelected">
          <label class="custom-control-label" [for]="'creditsRadio'+index">{{ entry?.saldo | currency }}</label>
        </div>
        <span>
          {{ entry?.entry }} - {{ entry?.nombre }}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="col-8">
    <h6 class="text-center">Entries in Trainer Account</h6>
    <ng-container  *ngFor="let entry of entriesInTrainerAccount; let index = index;">
      <div class="d-flex justify-content-between mb-2">
        <span class="my-auto">
          <b class="mr-2">{{ entry?.saldo | currency }}</b>
          <span class="mr-2">
            {{ entry?.entry }} - {{ entry?.nombre }}
          </span>
        </span>
        <div class="d-flex my-auto">
          <input type="number" class="form-control form-control-sm mr-2" style="max-width: 100px;" [disabled]="!entrySelected" [(ngModel)]="entry.creditAssigned">
          <b class="my-auto">
            {{ balanceCredit(entry) | currency }}
          </b>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-12 text-right">
    <b class="mr-2">Available: {{ availableCredits | currency}}</b>
    <b class="mr-2">Used: {{ usedCredits | currency}}</b>
    <button type="button" class="btn btn-sm btn-success" [disabled]="(availableCredits <= 0 || usedCredits <= 0) || usedCredits > availableCredits || loading" (click)="assignCredit()">
      <span class="loader mr-1" *ngIf="loading"></span>
      Settle Credits
    </button>
  </div>
</div>

