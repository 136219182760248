<div class="wrapper">
  <!-- Left side menu -->
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <!-- Modal confirmar unassign -->
    <ng-container ngTemplateOutlet="modalConfirmarUnassign"></ng-container>
    <div class="content">
      <!-- Topbar Menu -->
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>

      <div class="container w-100 mw-100 pt-3">
        <div class="row d-print-none">
          <!-- Select date -->
          <div class="col-md-6 col-sm-12">
            <div class="form-group mb-3">
              <label for="select-date">Select a date</label>
              <div class="input-group">
                <select name="select-date" id="select-date" class="form-control input-sm" (change)="seleccionarDia($event)" [disabled]="loading.get('dates') || loading.get('classes') || loading.get('saving')">
                  <option value="">No date assigned</option>
                  <option [value]="dia" *ngFor="let dia of dias">{{ dia }}</option>
                </select>
                <div class="input-group-append" *ngIf="fechaSeleccionada">
                  <button class="btn btn-success btn-sm" type="button" (click)="save()" [disabled]="loading.get('saving')">
                    <i class="mdi mdi-loading mdi-spin" *ngIf="loading.get('saving')"></i>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- If date selected -->
          <ng-container *ngIf="fechaSeleccionada">
            <!-- Switch horse info -->
            <div class="col-2">
              <div class="form-group mb-3">
                <label class="mr-2" for="infoCaballo">Horse info:</label>
                <span class="clearfix"></span>
                <input type="checkbox" id="infoCaballo" data-switch="success" (change)="showInfoCaballo($event)">
                <label for="infoCaballo" [attr.data-on-label]="'Yes'" data-off-label="No" class="my-auto"></label>
              </div>
            </div>
            <!-- Switch rider country -->
            <div class="col-2">
              <div class="form-group mb-3">
                <label class="mr-2" for="paisJinete">Rider country: </label>
                <span class="clearfix"></span>
                <input type="checkbox" id="paisJinete" data-switch="success" (change)="showPaisJinete($event)">
                <label for="paisJinete" [attr.data-on-label]="'Yes'" data-off-label="No" class="my-auto"></label>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row d-none d-print-block">
          <!-- Show name -->
          <div class="col-12">
            <h3 class="text-center">{{ nombreConcurso }}</h3>
          </div>
          <!-- Selected date -->
          <div class="col-12">
            <p>{{ fechaSeleccionada | date }}</p>
          </div>
        </div>
        <div class="row" *ngIf="fechaSeleccionada">
          <div class="col-4 col-print-6 pista" *ngFor="let pista of pistas" [ngClass]="{'d-print-none': pista.pruebas.length == 0}">
            <h5>
              {{ pista.nombre ? pista.nombre : 'Unassigned' }}
              <button _ngcontent-nho-c4="" (click)="printClasses(pista.pruebas)" *ngIf="pista.pruebas.length > 0" class="btn btn-outline-info btn-sm float-right d-print-none" type="button" style="padding: 4px 7px;">
                <i _ngcontent-nho-c4="" class="mdi mdi-printer"></i> Orders
              </button>
              <button _ngcontent-nho-c4="" (click)="printClassSheets(pista.pruebas)" *ngIf="pista.pruebas.length > 0" class="btn btn-outline-info float-right d-print-none mr-1" type="button" style="padding: 4px 7px;">
                <i _ngcontent-nho-c4="" class="mdi mdi-printer"></i> Class Sheets
              </button>
            </h5>
            <div class="form-group d-print-none">
              <input type="text" class="form-control" (change)="modalConfirmar($event, pista.nombre)" placeholder="Type class number">
            </div>
            <div cdkDropList class="example-list" [id]="'list-'+pista.nombre" [cdkDropListData]="pista.pruebas" [cdkDropListConnectedTo]="connectTo(pista.nombre)" (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let prueba of pista.pruebas; let i = index;" cdkDrag>
                <div class="d-flex">
                  <a href="javascript:;" class="d-flex mr-1 text-danger d-print-none" (click)="unassignClass(prueba.ipc, pista.nombre)">
                    <i class="mdi mdi-close my-auto"></i>
                  </a>
                  <div>
                    <b>{{ prueba.numero }}</b> - {{ prueba.prueba }}
                  </div>

                </div>
                <div class="d-flex">
                  <a class="my-auto d-print-none" href="javascript:;" (click)="prueba.mostrarHora = !prueba.mostrarHora;" onclick="setTimeout(() => { $.App.init(); });">
                    <i [ngClass]="{'text-success': prueba.hard_start == '1' && prueba.horario}" class="mdi mdi-clock-outline ml-2 my-auto"></i>
                  </a>
                  <span *ngIf="prueba.mostrarHora||(prueba.hard_start == '1' && prueba.horario)" class="d-none d-print-block">{{ prueba.inicio | date:'shortTime' }}</span>
                  <input *ngIf="prueba.mostrarHora||(prueba.hard_start == '1' && prueba.horario)"  [(ngModel)]="prueba.horario" type="text" data-toggle="input-mask" data-mask-format="00:00" maxlength="5" class="form-control form-control-sm ml-2 d-print-none" style="max-width: 100px;" placeholder="HH:MM" (change)="asignarHardStart($event, prueba)">
                </div>
                <div class="d-flex ml-1" style="white-space: pre;">
                  <small *ngIf="prueba.inscritos > 0">Entries: {{ prueba.inscritos }}</small><br>
                  <small *ngIf="prueba.inscritos_us > 0">Entries: {{ prueba.inscritos_us }}</small>
                </div>
              </div>
            </div>
            <small style="float: left; margin-left: 60%">U/S Classes: {{ pista.pruebas_us | json}}</small>
            <small style="float: right;">Trips: {{ pista.inscritos | json}}</small>
          </div>
        </div>
        <div class="row w-100 mt-3 d-print-none">
          <div class="col-12">
            <h5 class="mb-0">Unassigned classes</h5>
          </div>
          <div class="col-3" *ngFor="let prueba of pruebasSinAsignar">
            <ul class="list-group mt-2">
              <li class="list-group-item">
                <b>{{ prueba.numero }}</b> - {{ prueba.prueba }} - Height: {{ prueba.altura }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirmar unassign -->
<ng-template #modalConfirmarUnassign>
  <div class="modal fade" id="modal-confirmar-unassign" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="mySmallModalLabel">Reassign class</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          This class is already assigned to {{ (fechaReassign||null) | date }} ring {{ pistaAnteriorReassing }}.
          Are you sure you want to reassign it?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <form action="javascript:;" (ngSubmit)="agregarPrueba()">
            <button id="confirm-reassing-class" type="submit" class="btn btn-primary">Confirm</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
