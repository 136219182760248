<div class="col-12">
  <form>
    <div class="form-row align-items-center mb-3">
      <label for="sections-number" class="col-auto col-form-label">Sections</label>
      <div class="col-3">
        <select class="form-control" id="sections-number" name="sections-number" [(ngModel)]="chunks" (change)="splitClass()" [disabled]="saving">
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-success" (click)="saveSections()" [disabled]="saving">
          <span class="loader" *ngIf="saving"></span>
          Save
        </button>
      </div>
    </div>
  </form>
  <div class="row">
    <ng-container *ngFor="let section of sectionsSelected">
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item list-group-item-info py-1 px-2"><b>Class {{ prueba?.numero }}{{ section }}</b></li>
          <li class="list-group-item py-1 px-2 list-group-item-light" *ngFor="let binomio of sectionsMap.get(section)">{{ binomio?.cucarda }}</li>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
