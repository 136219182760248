import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PruebaService } from '../services/prueba.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
declare let $: any;

@Component({
  selector: 'app-division-champions',
  templateUrl: './division-champions.component.html',
  styleUrls: ['./division-champions.component.css']
})
export class DivisionChampionsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public divisions: any[];
  public divisionSeleccionada: string;
  public nombreDivision: string;
  public entries: any[];
  public championSearch: string;
  public reserveSearch: string;
  public entriesChampion: any[];
  public entriesReserve: any[];
  public numeroDivision: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private pruebaService: PruebaService,
    private db: AngularFirestore
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.divisions = [];
    this.divisionSeleccionada = '';
    this.nombreDivision = '';
    this.numeroDivision = '';
    this.entries = [];
    this.championSearch = '';
    this.reserveSearch = '';
    this.entriesChampion = [];
    this.entriesReserve = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getDivisions();
  }

  public getDivisions(): void {
    this.pruebaService.getDivisions(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.divisions = response.divisiones.map(d => {
            d.created = d.created.replace(/-/g, '/');
            return d;
          });
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the divisions list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
    $('#loader').hide();
  }

  public seleccionarDivision(id, name, numero, champions, reserves){
    this.divisionSeleccionada = id;
    this.nombreDivision = name;
    this.numeroDivision = numero;
    this.entriesChampion = champions;
    this.entriesReserve = reserves;
    $('#loader').show();
    this.pruebaService.getDivisionEntries(id).subscribe(
      response => {
        if (!response.error) {
          this.entries = response.entries;
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the entry list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public filtroChampion(){
    return this.entries.filter(e => !this.entriesChampion.find(er => er.entry == e.entry)).filter(e => e.entry.includes(this.championSearch));
  }

  public filtroReserve(){
    return this.entries.filter(e => !this.entriesReserve.find(er => er.entry == e.entry)).filter(e => e.entry.includes(this.reserveSearch));
  }

  public addEntryChampion(idBinomio: any = false){
    if(!idBinomio){
      if(this.filtroChampion().length > 0){
        idBinomio = this.filtroChampion()[0].id_binomio;
      } else{
        return;
      }
    }
    const binomio = this.entries.find(e => e.id_binomio == idBinomio);
    this.entriesChampion.push(binomio);
    this.championSearch = '';
  }

  public addEntryReserve(idBinomio: any = false){
    if(!idBinomio){
      if(this.filtroReserve().length > 0){
        idBinomio = this.filtroReserve()[0].id_binomio;
      } else{
        return;
      }
    }
    const binomio = this.entries.find(e => e.id_binomio == idBinomio);
    this.entriesReserve.push(binomio);
    this.reserveSearch = '';
  }

  public removeEntryChampion(i){
    this.entriesChampion.splice(i, 1);
  }

  public removeEntryReserve(i){
    this.entriesReserve.splice(i, 1);
  }

  public async save(){
    let champions: any = [];
    let reserves: any = [];
    for(let i in this.entriesChampion){
      champions.push(this.entriesChampion[i]);
    }
    for(let i in this.entriesReserve){
      reserves.push(this.entriesReserve[i]);
    }
    this.pruebaService.saveChampionsAndReserves(this.divisionSeleccionada, this.entriesChampion, this.entriesReserve).subscribe(
      async response => {
        if (!response.error) {
          //Borrar de firebase regitros anteriores
          const batch = this.db.firestore.batch();
          await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('champions').doc(this.divisionSeleccionada).delete();
          this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('champions').doc(this.divisionSeleccionada).set({
            champions,
            reserves,
            divisionName: `Division ${this.numeroDivision}`,
            name: this.nombreDivision
          }, { merge: true });
          $.NotificationApp.send("Success", "Champions and reserves has been saved successfully.", 'bottom-right', '#3c763d', 'success');
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save champions and reserves.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }
}
