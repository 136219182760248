import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom, map } from 'rxjs';
import { Firestore, setDoc, doc, Timestamp } from '@angular/fire/firestore';
declare let $: any;

@Injectable()
export class FeedOrdersService extends Api {

  constructor(private http: HttpClient, private db: AngularFirestore, private firestore: Firestore) {
    super();
  }

  public getOrders(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}orders/`, params, {headers: headers});
  }

  public async save(idConcurso, idEntrenador, entry, idUsuario, stall, total, charges): Promise<any>{
    const params = JSON.stringify({ idConcurso, idEntrenador, entry, idUsuario, stall, total, charges });
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };
    return new Promise(async (resolve, reject) => {
      const orderSaveRequest: any = await firstValueFrom(this.http.post(`${environment.api.uri}orders/save/`, params, {headers: headers}));
      if(orderSaveRequest.error) return reject(orderSaveRequest.message);
      const order = orderSaveRequest.order;
      //Formato para order coleccion nueva
      const newOrderFS = {
        data: order.data.map(d => ({ cantidad: `${d.cantidad}`, producto: d.producto })),
        id: order.id,
        id_concurso: order.id_concurso,
        stall: order.stall,
        delivered: order.delivered,
        created_at: new Date(),
        trainer: order.trainer,
        online: order.online
      };
      await this.createFeedOrderFS(newOrderFS);
      return resolve(order);
    });
  }

  public async editFeedOrder(orden: any): Promise<any>{
    const params = JSON.stringify(orden);
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

    return new Promise(async (resolve, reject) => {
      const { order, error } = await firstValueFrom(this.http.post(`${environment.api.uri}orders/edit/`, params, { headers }).pipe(map((r: any) => ({ order: r.order||null, error:r.message||(r.error||null) }))));
      if(error) return reject({ error });
      const newOrderFS = {
        data: order.data.map(d => ({ cantidad: `${d.cantidad}`, producto: d.producto })),
        id: order.id,
        id_concurso: order.id_concurso,
        stall: order.stall,
        delivered: order.delivered,
        created_at: new Date(order.created_at),
        trainer: order.trainer,
        online: order.online
      };
      await this.createFeedOrderFS(newOrderFS);
      return resolve({ order });
    });
  }

  public async delete(orderId, idConcurso, idUsuario){
    const params = JSON.stringify({ orderId, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    await this.db.collection('srjt').doc('subCollections').collection('Orders').doc(`${orderId}`).delete();

    return this.http.post(`${environment.api.uri}orders/delete/`, params, {headers: headers}).toPromise();
  }

  public summary(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}orders/resumen/`, params, {headers: headers});
  }

  public trainerSummary(idConcurso, idTrainer): Observable<any>{
    const params = JSON.stringify({ idConcurso, idTrainer });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}orders/resumen/`, params, {headers: headers});
  }

  public entrySummary(idConcurso, entry): Observable<any>{
    const params = JSON.stringify({ idConcurso, entry });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}orders/resumen/`, params, {headers: headers});
  }

  public async createFeedOrderFS(order: any): Promise<any>{
    return setDoc(doc(this.firestore, 'srjt', 'subCollections', 'Orders',`${order.id}`), {  ...order, created_at: Timestamp.fromDate(order.created_at)}, { merge: true});
  }

  public async markDelivered(orderId: string): Promise<any>{
    let email: string = 'Office';
    const params = JSON.stringify({orderId, email});
    const headers = { 'x-api-key': this.api_key, 'Content-Type': 'application/json;charset=UTF-8' };

    return this.http.post(`${environment.api.uri}orders/marcar-entregada/`, params, {headers: headers}).toPromise();
  }
}
