import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ModalidadService } from '../services/modalidad.service';
import { JuezService } from '../services/juez.service';
import { CategoriaService } from '../services/categoria.service';
import { ConcursoService } from '../services/concurso.service';
import { ClubService } from '../services/club.service';
import { SerieService } from '../services/serie.service';
import { PaisService } from '../services/pais.service';
import { ConceptoService } from '../services/concepto.service';
import { PersonaService } from '../services/persona.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from '../services/app.service';
import { formatDate } from '@angular/common';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/storage';
import { firstValueFrom } from 'rxjs';
declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'convocatoria',
  templateUrl: './convocatoria.component.html',
  styleUrls: ['./convocatoria.component.css']
})

export class ConvocatoriaComponent implements OnInit, AfterViewInit {
  private idUsuario: string;
  public idConcurso: string | boolean;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  private ipcSeleccionado: string;
  public nombreConcurso: string;
  public pruebas: Array<any>;
  public nombreSeleccionado: string;
  public modalidades: Array<any>;
  public es: any;
  public jueces: Array<any>;
  public juecesPistas: any;
  public categorias: Array<any>;
  public formPrueba: UntypedFormGroup;
  public categoriasPrueba: Array<string>;
  public nombreControl: UntypedFormControl;
  public clubControl: UntypedFormControl;
  public inicioControl: UntypedFormControl;
  public finControl: UntypedFormControl;
  public nominacionControl: UntypedFormControl;
  public deduccionControl: UntypedFormControl;
  public salesTaxPercentControl: UntypedFormControl;
  public desinscripcion1Control: UntypedFormControl;
  public desinscripcion2Control: UntypedFormControl;
  public feiFee1Control: UntypedFormControl;
  public feiFee2Control: UntypedFormControl;
  public feiFee3Control: UntypedFormControl;
  public correoControl: UntypedFormControl;
  public usefIDControl: UntypedFormControl;
  public officialIDControl: UntypedFormControl;
  public publicoControl: UntypedFormControl;
  public edControl: UntypedFormControl;
  public videosForSale: UntypedFormControl;
  public inscripcionesRemotasControl: UntypedFormControl;
  public pagoConChequeControl: UntypedFormControl;
  public salesTaxControl: UntypedFormControl;
  public feedOrdersSalesTaxControl: UntypedFormControl;
  public merchSalesTaxControl: UntypedFormControl;
  public divisionChampsControl: UntypedFormControl;
  public horarioCierreOnlineControl: UntypedFormControl;
  public entriesDue: UntypedFormControl;
  public entryAmount: UntypedFormControl;
  public timezone: UntypedFormControl;
  public zonaHora: string;
  public prizeCurrency: UntypedFormControl;
  public organizingCountry: UntypedFormControl;
  public buttonLink: UntypedFormControl;
  public buttonLink2: UntypedFormControl;
  public titleLink: UntypedFormControl;
  public titleLink2: UntypedFormControl;
  public clubs: Array<any>;
  public queryClub: string;
  public tiposSerie: Array<any>;
  public formSerie: UntypedFormGroup;
  public asignarPruebasSerie: boolean;
  public series: Array<any>;
  public pruebasSerie: Array<string>;
  public idSerieSeleccionada: string;
  public imgPatrocinador: string;
  public multicosto: boolean;
  public costo: any;
  public porcentajeSubida: number;
  public subiendoPortada: boolean;
  public subiendoHeader: boolean;
  public subiendoFooter: boolean;
  public subiendoLogo: boolean;
  public subiendoInvoiceFooter: boolean;
  public portada: string;
  public header: string;
  public footer: string;
  public logo: string;
  public convocatoria: string;
  public schedule: string;
  public feedForm: string;
  public entryBlank: string;
  public facilityMap: string;
  public invoiceFooter: string;
  public baseUrl: string;
  public croquis: string;
  public paises: any[];
  public conceptos: any[];
  public conceptoSeleccionado: string;
  public cargosPrueba: any[];
  public distribuciones: any[];
  public pistaSeleccionada: string;
  public pistas: string[];
  public actualizarPruebasConcurso: boolean;
  public banderaInicio: boolean;
  public banderaFin: boolean;
  public personas: any[];
  public reining: boolean;
  //Format dd/mm/yyyy
  public dateFormatRegEx: RegExp;
  public formButtons: FormGroup;
  public linkButtons: Map<string, string>[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private pruebaService: PruebaService,
    private modalidadService: ModalidadService,
    private juezService: JuezService,
    private categoriaService: CategoriaService,
    private concursoService: ConcursoService,
    private clubService: ClubService,
    private serieService: SerieService,
    private paisService: PaisService,
    private conceptoService: ConceptoService,
    private appService: AppService,
    private personaService: PersonaService,
    private ngZone: NgZone,
    private db: AngularFirestore,
    private fb: FormBuilder
  ) {
    this.juecesPistas = [];
    this.idUsuario = '';
    this.idConcurso = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.ipcSeleccionado = '';
    this.nombreSeleccionado = '';
    this.modalidades = [];
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "I", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
    this.jueces = [];
    this.categorias = [];
    this.formPrueba = new UntypedFormGroup({
      nombre: new UntypedFormControl('', [Validators.required]),
      numero: new UntypedFormControl('', [Validators.required]),
      altura: new UntypedFormControl('', []),
      modalidad: new UntypedFormControl('', [Validators.required]),
      //ambito: new FormControl('', [Validators.required]),
      description: new UntypedFormControl('', []),
      pista: new UntypedFormControl('', []),
      costo: new UntypedFormControl('', []),
      premio: new UntypedFormControl('', []),
      prize_distribution: new UntypedFormControl('', []),
      inicio: new UntypedFormControl('', []),
      hora_inicio: new UntypedFormControl('', []),
      juez: new UntypedFormControl('', []),
      video: new UntypedFormControl(false, []),
      designer: new UntypedFormControl('', []),
      youtube: new UntypedFormControl('', []),
      fei_competition_id: new UntypedFormControl('', []),
      fei_event_id: new UntypedFormControl('', []),
      usef_section: new UntypedFormControl('', []),
      hard_start: new UntypedFormControl(false, []),
      overall: new UntypedFormControl(false, []),
      height_unit: new UntypedFormControl('M', []),
      attachmentUrl: new UntypedFormControl('', []),
      attachmentTxt: new UntypedFormControl('', []),
    });
    this.categoriasPrueba = [];
    this.nombreControl = new UntypedFormControl('', [Validators.required]);
    this.clubControl = new UntypedFormControl('', [Validators.required]);
    this.inicioControl = new UntypedFormControl('', []);
    this.finControl = new UntypedFormControl('', []);
    this.nominacionControl = new UntypedFormControl('', [Validators.required]);
    this.deduccionControl = new UntypedFormControl('', [Validators.required]);
    this.salesTaxPercentControl = new UntypedFormControl('', [Validators.required]);
    this.desinscripcion1Control = new UntypedFormControl('', [Validators.required]);
    this.desinscripcion2Control = new UntypedFormControl('', [Validators.required]);
    this.feiFee1Control = new UntypedFormControl('', []);
    this.feiFee2Control = new UntypedFormControl('', []);
    this.feiFee3Control = new UntypedFormControl('', []);
    this.correoControl = new UntypedFormControl('', []);
    this.usefIDControl = new UntypedFormControl('', []);
    this.officialIDControl = new UntypedFormControl('', []);
    this.publicoControl = new UntypedFormControl('', []);
    this.edControl = new UntypedFormControl('', []);
    this.videosForSale = new UntypedFormControl('', []);
    this.inscripcionesRemotasControl = new UntypedFormControl('', []);
    this.pagoConChequeControl = new UntypedFormControl(false, []);
    this.salesTaxControl = new UntypedFormControl(false, []);
    this.feedOrdersSalesTaxControl = new UntypedFormControl(false, []);
    this.merchSalesTaxControl = new UntypedFormControl(false, []);
    this.divisionChampsControl = new UntypedFormControl(false, []);
    this.horarioCierreOnlineControl = new UntypedFormControl('', [Validators.minLength(5)]);
    this.dateFormatRegEx = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    this.entriesDue = new UntypedFormControl('', [Validators.minLength(10), Validators.pattern(this.dateFormatRegEx)]);
    this.entryAmount = new UntypedFormControl('', [Validators.required]);
    this.timezone = new UntypedFormControl('America/Chicago', [Validators.required]);
    this.zonaHora = '-07:00';
    this.prizeCurrency = new UntypedFormControl('', []);
    this.organizingCountry = new UntypedFormControl('', []);
    this.buttonLink = new UntypedFormControl('', [Validators.maxLength(255)]);
    this.buttonLink2 = new UntypedFormControl('', [Validators.maxLength(255)]);
    this.titleLink = new UntypedFormControl('', [Validators.maxLength(32)]);
    this.titleLink2 = new UntypedFormControl('', [Validators.maxLength(32)]);
    this.clubs = [];
    this.queryClub = '';
    this.tiposSerie = [];
    this.formSerie = new UntypedFormGroup({
      nombre: new UntypedFormControl('', [Validators.required]),
      tipo: new UntypedFormControl('', [Validators.required]),
      fecha: new UntypedFormControl(new Date(), [])
    });
    this.asignarPruebasSerie = false;
    this.series = [];
    this.pruebasSerie = [];
    this.idSerieSeleccionada = '';
    this.imgPatrocinador = '';
    this.multicosto = false;
    this.costo = {};
    this.subiendoPortada = false;
    this.subiendoHeader = false;
    this.subiendoFooter = false;
    this.subiendoLogo = false;
    this.subiendoInvoiceFooter = false;
    this.porcentajeSubida = 0;
    this.convocatoria = '';
    this.schedule = '';
    this.feedForm = '';
    this.entryBlank = '';
    this.facilityMap = '';
    this.baseUrl = window.location.hostname;
    this.croquis = '';
    this.paises = [];
    this.conceptos = [];
    this.conceptoSeleccionado = '';
    this.cargosPrueba = [];
    this.distribuciones = [];
    this.pistaSeleccionada = '';
    this.pistas = [];
    this.actualizarPruebasConcurso = false;
    this.banderaInicio = false;
    this.banderaFin = false;
    this.personas = [];
    this.reining = sessionStorage.getItem('reining') == '1';
    this.formButtons = this.fb.group({
      link: ['', Validators.required],
      title: ['', Validators.required],
    });
    this.linkButtons = [];
  }

  ngOnInit() {
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunctionInicioChange: (inicio) => this.inicioChange(inicio), loadAngularFunctionFinChange: (fin) => this.finChange(fin) };
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if (this.authService.validarConcurso() && this.privilegios >= 200) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
    this.getModalidades();
    this.getJueces();
    this.getCategorias();
    this.getConcurso();
    this.getClubs();
    this.getPaises();
    this.getTiposSerie();
    this.getSeries();
    this.getConceptos();
    this.clubControl.valueChanges.subscribe(
      data => {
        this.queryClub = data;
      }
    );
    this.getDistributions();
    this.getPistas();
  }

  ngAfterViewInit(): void {
    this.enableTooltips();
  }

  /**
   * Enable bootstrap tooltips everywhere
   */
  async enableTooltips(){
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
      tooltipTriggerEl.onclick = () => {
        $(tooltipTriggerEl).tooltip('hide');
      };
      return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover click' })
    });
    return tooltipList;
  }

  private getPistas(): void {
    this.concursoService.getPistas(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pistas = response.pistas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the rings list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDistributions(): void {
    this.pruebaService.getPrizeDistributions(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.distribuciones = [];
          for (let distribucion of response.distribuciones) {
            if (distribucion.tipo == 'percentage') {
              distribucion.distribucion = distribucion.distribucion.map(d => d * 100);
              this.distribuciones.push(distribucion);
            } else {
              this.distribuciones.push(distribucion);
            }
          }
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  private getPruebas() {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {

          this.juecesPistas = [];
          this.pruebas = response.pruebas.map(prueba => {
            if (prueba.multicosto) {
              prueba.costo = JSON.parse(prueba.costo);
            } else {
              prueba.costo = parseFloat(prueba.costo);
            }
            if (prueba.juez && prueba.pista) {
              const judge = this.jueces.find(juez => juez.id == prueba.juez);
              const pistaIndex = this.juecesPistas.findIndex(item => item.pista === prueba.pista);
              if (pistaIndex === -1) {
                this.juecesPistas.push({ pista: prueba.pista, jueces: [judge?.nombre] });
              } else {
                if (judge && !this.juecesPistas[pistaIndex].jueces.includes(judge.nombre)) {
                  this.juecesPistas[pistaIndex].jueces.push(judge.nombre);
                }
              }
            }
            return prueba;
          });
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getModalidades() {
    this.modalidadService.getModalidades().subscribe(
      response => {
        if (!response.error) {
          this.modalidades = response.modalidades;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to consult the tables list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getJueces() {
    this.juezService.getJueces().subscribe(
      response => {
        if (!response.error) {
          this.jueces = response.data;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the judges list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getCategorias() {
    this.categoriaService.getCategorias().subscribe(
      response => {
        if (!response.error) {
          this.categorias = response.categorias;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the categories list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getConcurso() {
    firstValueFrom(this.concursoService.getConcurso(this.idConcurso)).then(
      response => {
        if (!response.error) {
          const concurso = response.concurso;
          this.nombreControl.setValue(concurso['nombre']);
          this.clubControl.setValue({
            id_club: concurso['club'],
            club: concurso['nombreClub']
          });
          let inicio = concurso['inicio'].split(' ').shift().split('-');
          let fin = concurso['fin'].split(' ').shift().split('-');
          if (inicio.length == 3) {
            inicio = `${inicio[1]}/${inicio[2]}/${inicio[0]}`;
            fin = `${fin[1]}/${fin[2]}/${fin[0]}`;
          } else {
            inicio = '';
            fin = '';
          }

          this.inicioControl.setValue(inicio);
          this.finControl.setValue(fin);
          this.nominacionControl.setValue(concurso['nominacion']);
          this.deduccionControl.setValue(concurso['deduccion']);
          this.salesTaxPercentControl.setValue(concurso['salesTaxPercent']);
          this.desinscripcion1Control.setValue(concurso['desinscripcion1']);
          this.desinscripcion2Control.setValue(concurso['desinscripcion2']);
          this.feiFee1Control.setValue(concurso['feiFee1']);
          this.feiFee2Control.setValue(concurso['feiFee2']);
          this.feiFee3Control.setValue(concurso['feiFee3']);
          this.correoControl.setValue(concurso['correo']);
          this.usefIDControl.setValue(concurso['usefID']);
          this.officialIDControl.setValue(concurso['idOficial']);
          this.publicoControl.setValue(concurso['publico'] ? true : false);
          this.edControl.setValue(concurso['ed'] ? true : false);
          this.videosForSale.setValue(concurso['videosForSale'] ? true : false);
          this.inscripcionesRemotasControl.setValue(concurso['inscripcionesRemotas'] ? true : false);
          this.pagoConChequeControl.setValue(concurso['pagoCheque']);
          this.salesTaxControl.setValue(concurso['salesTax']);
          this.feedOrdersSalesTaxControl.setValue(concurso['feedOrdersSalesTax']);
          this.merchSalesTaxControl.setValue(concurso['merchSalesTax']);
          this.divisionChampsControl.setValue(concurso['divisionChamps']);
          this.horarioCierreOnlineControl.setValue(concurso['horarioCierre']);
          this.entriesDue.setValue((concurso['entriesDue'] || '').split('-').reverse().join('/'));
          this.entryAmount.setValue(concurso['entryAmount']);
          this.timezone.setValue(concurso['timezone']);
          this.zonaHora = this.getTimeZone();
          this.prizeCurrency.setValue(concurso['prizeCurrency']);
          this.organizingCountry.setValue(concurso['organizingCountry']);
          this.titleLink.setValue(concurso['buttonTitle']);
          this.titleLink2.setValue(concurso['buttonTitle2']);
          this.buttonLink.setValue(concurso['buttonLink']);
          this.buttonLink2.setValue(concurso['buttonLink2']);
          this.portada = concurso.portada ? concurso.portada : 'portada.jpg';
          this.header = concurso.header ? concurso.header : 'header.jpg';
          this.footer = concurso.footer ? concurso.footer : 'footer.jpg';
          this.logo = concurso.header2 ? concurso.header2 : 'header2.jpg';
          this.invoiceFooter = concurso.invoiceFooter ? concurso.invoiceFooter : 'invoiceFooter.jpg';
          this.convocatoria = concurso.convocatoria;
          this.schedule = concurso.schedule;
          this.feedForm = concurso.feedForm;
          this.entryBlank = concurso.entryBlank;
          this.facilityMap = concurso.facilityMap;
          this.invoiceFooter = concurso.invoiceFooter;
          this.pistas = concurso.pistas;
          this.linkButtons = (concurso.buttons||[]).map(b => new Map<string, string>([['title', b.title||''], ['link', b.link||'']]));
          setTimeout(() => { this.enableTooltips(); });
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the show data.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getClubs() {
    this.clubService.getClubs().subscribe(
      response => {
        if (!response.error) {
          this.clubs = response.clubs;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the clubs list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getPaises() {
    this.paisService.getPaises().subscribe(
      response => {
        if (!response.error) {
          this.paises = response.paises;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the clubs list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getTiposSerie() {
    this.serieService.getTipos().subscribe(
      response => {
        if (!response.error) {
          this.tiposSerie = response.tipos;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the series types list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getSeries() {
    this.serieService.getSeries(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          const series = $.map(response.series, (o) => {
            return o;
          });
          this.series = series;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the series list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getConceptos(): void {
    this.conceptoService.getConceptos(this.idConcurso).subscribe(
      response => {
        this.conceptos = response.conceptos;
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the concepts list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public displayClub(val): string {
    return val ? val.club : val;
  }

  public submitPrueba() {
    if (this.formPrueba.valid) {
      const indexModalidad = this.modalidades.findIndex((modalidad) => {
        return this.formPrueba.get('modalidad').value == modalidad.id;
      });
      //Validar que costo no sea cadena vacia en multiprecio
      if (this.multicosto) {
        for (let i in this.costo) {
          this.costo[i] = this.costo[i] ? this.costo[i] : 0;
        }
      }
      let prueba = {
        altura: this.formPrueba.get('altura').value || '',
        categorias: this.categoriasPrueba,
        costo: parseInt((this.formPrueba.get('costo').value || 0).toString().replace(/[\.,A-Za-z]/g, '')),
        designer: this.formPrueba.get('designer').value,
        inicio: `${this.formPrueba.value.inicio} ${this.formPrueba.value.hora_inicio}`,
        ipc: this.ipcSeleccionado,
        juez: this.formPrueba.get('juez').value,
        modalidad: this.formPrueba.get('modalidad').value,
        nombre: this.formPrueba.get('nombre').value,
        nombreModalidad: indexModalidad > -1 ? this.modalidades[indexModalidad]['nombre'] : '',
        numero: this.formPrueba.get('numero').value,
        description: this.formPrueba.get('description').value,
        pista: this.formPrueba.get('pista').value,
        premio: parseInt((this.formPrueba.get('premio').value || 0).toString().replace(/[\.,A-Za-z]/g, '')),
        video: this.formPrueba.get('video').value ? 1 : 0,
        youtube: this.formPrueba.get('youtube').value,
        imgPatrocinador: this.imgPatrocinador,
        fei_competition_id: this.formPrueba.get('fei_competition_id').value || '',
        fei_event_id: this.formPrueba.get('fei_event_id').value || '',
        usef_section: this.formPrueba.get('usef_section').value,
        hard_start: this.formPrueba.get('hard_start').value ? 1 : 0,
        overall: this.formPrueba.get('overall').value ? 1 : 0,
        croquis: this.croquis,
        cargosPrueba: this.cargosPrueba,
        prize_distribution: this.formPrueba.get('prize_distribution').value,
        id_prize_distribution: this.formPrueba.get('prize_distribution').value,
        division: '',
        height_unit: this.formPrueba.get('height_unit').value,
        attachmentUrl: this.formPrueba.get('attachmentUrl').value,
        attachmentTxt: this.formPrueba.get('attachmentTxt').value,
      };
      if (this.ipcSeleccionado) {
        this.pruebaService.updatePrueba(prueba, this.idConcurso).subscribe(
          response => {
            if (!response.error) {
              if (this.actualizarPruebasConcurso) {
                this.appService.updateClasses(this.idConcurso);
              } else {
                this.appService.updateClass(this.idConcurso, this.ipcSeleccionado)
              }
              const index = this.pruebas.findIndex(pba => {
                return pba.ipc == prueba.ipc;
              });
              this.pruebas[index] = prueba;
              $('#modal-prueba').modal('hide');
            } else {
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response);
            }
          },
          error => {
            $.NotificationApp.send("Error", 'The class could not be updated', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      } else {
        this.pruebaService.createPrueba(prueba, this.idConcurso).subscribe(
          response => {
            if (!response.error) {
              prueba['ipc'] = response.ipc;
              this.appService.updateClasses(this.idConcurso, response.ipc);
              console.log(response);
              this.pruebas.unshift(prueba);
              $('#modal-prueba').modal('hide');
            } else {
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response);
            }
          },
          error => {
            $.NotificationApp.send("Error", 'The class could not be created', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      }
    } else {
      this.formPrueba.get('nombre').markAsTouched();
      this.formPrueba.get('numero').markAsTouched();
      this.formPrueba.get('modalidad').markAsTouched();
      this.formPrueba.get('costo').markAsTouched();
    }
  }

  public modalCrear() {
    //Limpiar prueba seleccionada
    this.ipcSeleccionado = '';
    this.imgPatrocinador = '';
    this.croquis = '';
    this.actualizarPruebasConcurso = false;
    //Limpiar el arreglo de personas(designers) al abrir el modal
    this.personas = [];
    //Limpiar array de categorias seleccionadas
    this.categoriasPrueba = [];

    //Asignar valor multicosto a variable global y en caso de ser true asignar costo a variable global
    this.multicosto = false;
    this.costo = {};

    //Limpiar formulario
    this.formPrueba.reset();

    this.conceptoSeleccionado = '';
    this.cargosPrueba = [];

    $('#modal-prueba').modal('show');
  }

  public modalEditar(prueba) {
    console.log(prueba);
    //Cambiar prueba seleccionada
    this.ipcSeleccionado = prueba['ipc'];
    this.imgPatrocinador = prueba['sponsorImage'];
    this.croquis = prueba['croquis'];
    this.actualizarPruebasConcurso = false;
    //Limpiar el arreglo de personas(designers) al abrir el modal
    this.personas = [];
    //Falta Cambiar array de categorias seleccionadas
    this.categoriasPrueba = prueba['categorias'].map(categoria => {
      return String(categoria);
    });
    //Asignar valor multicosto a variable global y en caso de ser true asignar costo a variable global
    this.multicosto = prueba.multicosto;
    if (this.multicosto) {
      this.costo = prueba.costo;
    } else {
      this.costo = {};
    }
    // const [inicio, hora_inicio] = prueba['inicio'].split(' ');
    const [inicio, hora_inicio] = prueba['inicio'] ? prueba['inicio'].split(' ') : ['', ''];

    //Limpiar formulario
    this.formPrueba.setValue({
      nombre: prueba['nombre'] || '',
      numero: prueba['numero'] || '',
      modalidad: prueba['modalidad'] || '',
      altura: prueba['altura'] || '',
      description: prueba['description'] || '',
      pista: prueba['pista'] || '',
      costo: !prueba.multicosto ? prueba['costo'] : '',
      premio: prueba['premio'] || '',
      inicio: inicio || '',
      hora_inicio: hora_inicio || '',
      juez: prueba['juez'] || '',
      video: !!prueba['video'] || '',
      designer: prueba['designer'] || '',
      youtube: prueba['youtube'] || '',
      fei_competition_id: prueba['competitionID'] || '',
      fei_event_id: prueba['eventId'] || '',
      usef_section: prueba['usef_section'] || '',
      hard_start: prueba['hard_start'] || '',
      overall: prueba['overall'] || '',
      prize_distribution: prueba['id_prize_distribution'] || '',
      height_unit: prueba['height_unit'] || '',
      attachmentUrl: prueba['attachmentUrl'] || '',
      attachmentTxt: prueba['attachmentTxt'] || '',
    });
    this.conceptoSeleccionado = '';
    this.cargosPrueba = prueba['charges'] ? prueba['charges'] : [];

    $('#modal-prueba').modal('show');
  }

  public validarCategoria(idCategoria): boolean {
    return this.categoriasPrueba.indexOf(idCategoria) > -1 ? true : false;

  }

  public toggleCategoria(idCategoria: string) {
    const index = this.categoriasPrueba.indexOf(idCategoria);
    if (index > -1) {
      //Borrar categoria de array de categorias prueba
      this.categoriasPrueba.splice(index, 1);
      //Hacer validaciones para el multicosto
      if (this.categoriasPrueba.length > 1) {
        if (this.multicosto) { //Si ya es multicosto borrar valor del json de costo
          delete this.costo[idCategoria];
        } else { //Si aun no era multicosto crear json con categorias
          this.multicosto = true;
          this.costo = {};
          for (const categoria of this.categoriasPrueba) {
            this.costo[categoria] = '';
          }
        }
      } else { //Si queda con una categoria o menos no es multicosto
        this.multicosto = false;
        this.costo = {};
      }
    } else {
      this.categoriasPrueba.push(idCategoria);
      //Hacer validaciones para el multicosto
      if (this.categoriasPrueba.length > 1) {
        if (this.multicosto) { //Si ya es multicosto solo agregar el valor al json
          this.costo[idCategoria] = '';
        } else {//Si aun no era multicosto crear json con categorias
          this.multicosto = true;
          this.costo = {};
          for (const categoria of this.categoriasPrueba) {
            this.costo[categoria] = '';
          }
        }
      } else { //Si solo se agrega una categoria se queda con un solo costo
        this.multicosto = false;
        this.costo = {};
      }
    }
  }

  public modalBorrar(ipc, numero, nombre) {
    this.ipcSeleccionado = ipc;
    this.nombreSeleccionado = `${numero} - ${nombre}`;
    $('#modal-borrar-prueba').modal('show');
  }

  public async borrarPrueba() {
    this.pruebaService.deletePrueba(this.ipcSeleccionado, this.idUsuario, this.idConcurso).subscribe(
      async response => {
        if (!response.error) {
          //this.appService.updateClass(this.idConcurso, this.ipcSeleccionado);
          await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${this.ipcSeleccionado}`).delete();
          const index = this.pruebas.findIndex(pba => {
            return pba.ipc == this.ipcSeleccionado;
          });
          this.pruebas.splice(index, 1);
          $('#modal-borrar-prueba').modal('hide');
          $.NotificationApp.send("Deleted", response.message, 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.query);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'The test could not be deleted', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public nombreChange() {
    if (this.nombreControl.valid) {
      const nombre = this.nombreControl.value;
      this.guardarCampo('fullname', 'texto', nombre, this.idConcurso);
    } else {
      this.nombreControl.markAsTouched();
    }
  }

  public clubChange() {
    if (this.clubControl.valid) {
      if (this.clubControl.value['id_club']) {
        const club = this.clubControl.value['id_club'];
        this.guardarCampo('id_club', 'numero', club, this.idConcurso);
      } else {
        this.clubControl.setValue('');
        this.clubControl.markAsTouched();
      }
    } else {
      this.clubControl.markAsTouched();
    }
  }

  public inicioChange(dateInicio) {
    if (this.banderaInicio) {
      if (dateInicio.length == 10) {
        dateInicio = dateInicio.split('/');
        dateInicio = `${dateInicio[2]}-${dateInicio[0]}-${dateInicio[1]} 08:00`;
        this.guardarCampo('inicio', 'texto', dateInicio, this.idConcurso);
      }
    }
    this.banderaInicio = true;
  }

  public finChange(dateFin) {
    if (this.banderaFin) {
      if (dateFin.length == 10) {
        dateFin = dateFin.split('/');
        dateFin = `${dateFin[2]}-${dateFin[0]}-${dateFin[1]} 18:00`;
        this.guardarCampo('fin', 'texto', dateFin, this.idConcurso);
      }
    }
    this.banderaFin = true;
  }

  public nominacionChange() {
    if (this.nominacionControl.valid) {
      const nominacion = this.nominacionControl.value;
      this.guardarCampo('nominacion', 'numero', nominacion, this.idConcurso);
    } else {
      this.nominacionControl.markAsTouched();
    }
  }

  public deduccionChange() {
    if (this.deduccionControl.valid) {
      const deduccion = this.deduccionControl.value;
      this.guardarCampo('deduccion', 'numero', deduccion, this.idConcurso);
    } else {
      this.deduccionControl.markAsTouched();
    }
  }

  public salesTaxPercentChange() {
    if (this.salesTaxPercentControl.valid) {
      const salesTaxPercent = this.salesTaxPercentControl.value;
      this.guardarCampo('sales_tax_percent', 'numero', salesTaxPercent, this.idConcurso);
    } else {
      this.salesTaxPercentControl.markAsTouched();
    }
  }

  public desinscripcion1Change() {
    if (this.desinscripcion1Control.valid) {
      const desinscripcion1 = this.desinscripcion1Control.value;
      this.guardarCampo('desinscripcion_1', 'numero', desinscripcion1, this.idConcurso);
    } else {
      this.desinscripcion1Control.markAsTouched();
    }
  }

  public desinscripcion2Change() {
    if (this.desinscripcion2Control.valid) {
      const desinscripcion2 = this.desinscripcion2Control.value;
      this.guardarCampo('desinscripcion_2', 'numero', desinscripcion2, this.idConcurso);
    } else {
      this.desinscripcion2Control.markAsTouched();
    }
  };

  public feiFee1Change() {
    if (this.feiFee1Control.valid) {
      const feiFee1 = this.feiFee1Control.value ? this.feiFee1Control.value : 0;
      this.guardarCampo('fei_fee_1', 'numero', feiFee1, this.idConcurso);
    } else {
      this.feiFee1Control.markAsTouched();
    }
  }

  public feiFee2Change() {
    if (this.feiFee2Control.valid) {
      const feiFee2 = this.feiFee2Control.value ? this.feiFee2Control.value : 0;
      this.guardarCampo('fei_fee_2', 'numero', feiFee2, this.idConcurso);
    } else {
      this.feiFee2Control.markAsTouched();
    }
  }

  public feiFee3Change() {
    if (this.feiFee3Control.valid) {
      const feiFee3 = this.feiFee3Control.value ? this.feiFee3Control.value : 0;
      this.guardarCampo('fei_fee_3', 'numero', feiFee3, this.idConcurso);
    } else {
      this.feiFee3Control.markAsTouched();
    }
  }

  public correoChange() {
    const correo = this.correoControl.value;
    this.guardarCampo('correo_cuentas_responsable', 'texto', correo, this.idConcurso);
  }

  public officialIDChange() {
    const idOficial = this.officialIDControl.value;
    this.guardarCampo('id_oficial', 'texto', idOficial, this.idConcurso);
  }

  public usefIDChange() {
    const usefID = this.usefIDControl.value;
    this.guardarCampo('usef_id', 'texto', usefID, this.idConcurso);
  }

  public publicoChange() {
    const publico = this.publicoControl.value ? 1 : 0;
    this.guardarCampo('publico', 'numero', publico, this.idConcurso);
  }

  public edChange() {
    const ed = this.edControl.value ? 1 : 0;
    this.guardarCampo('en_ed', 'numero', ed, this.idConcurso);
  }

  public videoForSaleChange() {
    const videosForSale = this.videosForSale.value ? 1 : 0;
    this.guardarCampo('videos_for_sale', 'numero', videosForSale, this.idConcurso);
  }

  public inscripcionesRemotasChange() {
    const inscripcionesRemotas = this.inscripcionesRemotasControl.value ? 1 : 0;
    this.guardarCampo('inscripciones_remotas', 'numero', inscripcionesRemotas, this.idConcurso);
  }

  public pagoConChequeChange() {
    const pagoConCheque = this.pagoConChequeControl.value ? 1 : 0;
    this.guardarCampo('pago_cheque', 'numero', pagoConCheque, this.idConcurso);
  }

  public salesTaxChange() {
    const salesTax = this.salesTaxControl.value ? 1 : 0;
    this.guardarCampo('sales_tax', 'numero', salesTax, this.idConcurso);
  }

  public feedOrdersSalesTaxChange() {
    const feedOrdersSalesTax = this.feedOrdersSalesTaxControl.value ? 1 : 0;
    this.guardarCampo('feed_order_sales_tax', 'numero', feedOrdersSalesTax, this.idConcurso);
  }

  public merchSalesTaxChange() {
    const merchSalesTax = this.merchSalesTaxControl.value ? 1 : 0;
    this.guardarCampo('merch_sales_tax', 'numero', merchSalesTax, this.idConcurso);
  }

  public divisionChampsChange() {
    const divisionChamps = this.divisionChampsControl.value ? 1 : 0;
    this.guardarCampo('division_champs', 'numero', divisionChamps, this.idConcurso);
    //Si marcan el checkbox de division champs se debe mostrar el campo de division champs en el boton y link 1 del concurso
    if (this.divisionChampsControl.value) {
      this.titleLink.setValue('Div. Champs');
      this.titleLinkChange();
      this.buttonLink.setValue(`https://equestrian.digital/divisionChampions/us_${this.idConcurso}`);
      this.buttonLinkChange();
    }
  }

  public horarioCierreChange() {
    if (this.horarioCierreOnlineControl.valid) {
      const horarioCierre = this.horarioCierreOnlineControl.value;
      this.guardarCampo('cierre_inscripciones_online', 'texto', horarioCierre, this.idConcurso);
    }
  }

  public entriesDueChange() {
    if (this.entriesDue.valid && this.dateFormatRegEx.test(this.entriesDue.value)) {
      const entriesDue = this.entriesDue.value.split('/').reverse().join('-');
      this.guardarCampo('entries_due', 'texto', entriesDue, this.idConcurso);
    }
  }

  public entryAmountChange() {
    if (this.entryAmount.valid) {
      const entryAmount = this.entryAmount.value;
      this.guardarCampo('entry_amount', 'numero', entryAmount, this.idConcurso);
    }
  }

  public timeZoneChange() {
    if (this.timezone.valid) {
      const timezone = this.timezone.value;
      this.guardarCampo('timezone', 'texto', timezone, this.idConcurso);
    }
  }
  public getTimeZone() {
    if (this.timezone.value == 'America/New_York_DST') {
      return this.zonaHora = '-03:00';
    }
    if (this.timezone.value == 'America/New_York') {
      return this.zonaHora = '-04:00';
    }
    if (this.timezone.value == 'America/Chicago') {
      return this.zonaHora = '-05:00';
    }
    if (this.timezone.value == 'America/Denver') {
      return this.zonaHora = '-06:00';
    }
    if (this.timezone.value == 'America/Phoenix') {
      return this.zonaHora = '-07:00';
    }
    if (this.timezone.value == 'America/Los_Angeles') {
      return this.zonaHora = '-07:00';
    }
    if (this.timezone.value == 'America/Anchorage') {
      return this.zonaHora = '-08:00';
    }
    if (this.timezone.value == 'America/Adak') {
      return this.zonaHora = '-09:00';
    }
    if (this.timezone.value == 'Pacific/Honolulu') {
      return this.zonaHora = '-10:00';
    }
  }

  public prizeCurrencyChange() {
    if (this.prizeCurrency.valid) {
      const prize_currency = this.prizeCurrency.value;
      this.guardarCampo('prize_currency', 'texto', prize_currency, this.idConcurso);
    }
  }

  public organizingCountryChange() {
    if (this.organizingCountry.valid) {
      const organizingCountry = this.organizingCountry.value;
      this.guardarCampo('organizing_country', 'texto', organizingCountry, this.idConcurso);
    }
  }

  public buttonLinkChange() {
    if (this.buttonLink.valid) {
      const buttonLink = this.buttonLink.value;
      this.guardarCampo('buttonLink', 'texto', buttonLink, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The link is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public buttonLink2Change() {
    if (this.buttonLink2.valid) {
      const buttonLink2 = this.buttonLink2.value;
      this.guardarCampo('buttonLink2', 'texto', buttonLink2, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The link is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public titleLinkChange() {
    if (this.titleLink.valid) {
      const titleLink = this.titleLink.value;
      this.guardarCampo('buttonTitle', 'texto', titleLink, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The title is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public titleLink2Change() {
    if (this.titleLink2.valid) {
      const titleLink2 = this.titleLink2.value;
      this.guardarCampo('buttonTitle2', 'texto', titleLink2, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The title is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  private guardarCampo(nombreCampo, tipoCampo, valor, idConcurso) {
    $('#loader').show();
    return firstValueFrom(this.concursoService.guardarCampo(nombreCampo, tipoCampo, valor, idConcurso)).then(
      async response => {
        $('#loader').hide();
        if (!response.error) {
          $.NotificationApp.send("Updated", "The show has been updated.", 'bottom-right', '#06d5a1', 'success');
          await this.appService.updateShowFirestore(this.idConcurso);
          return response;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          return response.message;
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The show has not been updated', 'bottom-right', '#fa5c7c', 'error');
        return error;
      }
    );
  }

  public removerImagen(tipo) {
    switch (tipo) {
      case 'sponsor':
        this.imgPatrocinador = '';
        break;
      case 'course-desing':
        this.croquis = '';
        break;
    }
  }

  public subirImagen(event: any, tipo) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if (((file.type === 'image/jpeg' || file.type === 'image/png') && (tipo === 'portada' || tipo === 'header' || tipo === 'footer' || tipo === 'logo' || tipo === 'invoiceFooter')) || (file.type === 'application/pdf' && tipo === 'convocatoria') || (file.type === 'application/pdf' && tipo === 'schedule') || (file.type === 'application/pdf' && tipo === 'feedForm') || (file.type === 'application/pdf' && tipo === 'entryBlank') || (file.type === 'application/pdf' && tipo === 'facilityMap')) {
        this.porcentajeSubida = 0;
        switch (tipo) {
          case 'portada':
            this.subiendoPortada = true;
            break;
          case 'header':
            this.subiendoHeader = true;
            break;
          case 'footer':
            this.subiendoFooter = true;
            break;
          case 'logo':
            this.subiendoLogo = true;
            break;
          case 'convocatoria':
            $('#loader').show();
            break;
          case 'schedule':
            $('#loader').show();
            break;
          case 'feedForm':
            $('#loader').show();
            break;
          case 'entryBlank':
            $('#loader').show();
            break;
          case 'facilityMap':
            $('#loader').show();
            break;
          case 'invoiceFooter':
            this.subiendoInvoiceFooter = true;
            break;
        }

        const ref = firebase.storage().ref(tipo).child(String(this.idConcurso));
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            this.subiendoPortada = false;
            this.subiendoHeader = false;
            this.subiendoFooter = false;
            this.subiendoLogo = false;
            this.subiendoInvoiceFooter = false;
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                console.log(downloadURL);
                switch (tipo) {
                  case 'portada':
                    this.concursoService.guardarCampo('imagen', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.portada = downloadURL;
                          this.subiendoPortada = false;
                          $.NotificationApp.send("Updated", "The image of the show has been updated", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show image has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'header':
                    this.concursoService.guardarCampo('img_encabezado', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.header = downloadURL;
                          this.subiendoHeader = false;
                          $.NotificationApp.send("Updated", "The show header has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show header has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'footer':
                    this.concursoService.guardarCampo('img_footer', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.footer = downloadURL;
                          this.subiendoFooter = false;
                          $.NotificationApp.send("Updated", "The show footer has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show footer has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'logo':
                    this.concursoService.guardarCampo('img_encabezado_2', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.logo = downloadURL;
                          this.subiendoLogo = false;
                          $.NotificationApp.send("Updated", "The show logo has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show logo has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'invoiceFooter':
                    this.concursoService.guardarCampo('invoice_footer', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.invoiceFooter = downloadURL;
                          this.subiendoInvoiceFooter = false;
                          $.NotificationApp.send("Updated", "The invoice footer has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show logo has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'convocatoria':
                    this.concursoService.guardarCampo('convocatoria', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.convocatoria = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show prize list has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show prize list has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'schedule':
                    this.concursoService.guardarCampo('schedule', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.schedule = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show schedule has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show schedule has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'feedForm':
                    this.concursoService.guardarCampo('feed_form', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.feedForm = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show feed form has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show feed form has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'entryBlank':
                    this.concursoService.guardarCampo('entry_blank', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.entryBlank = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show entry blank has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show entry blank has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'facilityMap':
                    this.concursoService.guardarCampo('facilityMap', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.facilityMap = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show facility map has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show facility map has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;

                  case 'invoiceFooter':
                    break;
                }
              },
              error => {
                this.subiendoPortada = false;
                this.subiendoHeader = false;
                this.subiendoFooter = false;
                this.subiendoLogo = false;
                this.subiendoInvoiceFooter = false;
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public modalCrearSerie() {
    this.idSerieSeleccionada = '';
    this.asignarPruebasSerie = false;
    this.pruebasSerie = [];
    this.formSerie.get('nombre').reset('');
    this.formSerie.get('tipo').reset('');
    this.formSerie.get('fecha').reset(new Date());
    $('#modal-serie').modal('show');
  }

  public modalActualizarSerie(serie) {
    this.idSerieSeleccionada = serie['id'];
    this.asignarPruebasSerie = true;
    this.pruebasSerie = serie.pruebas.map(prueba => {
      return prueba['ipc'];
    });
    this.formSerie.get('nombre').reset(serie['nombre']);
    this.formSerie.get('tipo').reset(serie['tipo']);
    this.formSerie.get('fecha').reset(new Date(`${serie['fecha']} 00:00:00`.replace(/-/g, "/")));
    console.log(serie);
    $('#modal-serie').modal('show');
  }

  public mostrarAsignarPruebasSerie() {
    this.asignarPruebasSerie = true;
  }

  public vaciarPruebasSerie() {
    const tipo = this.formSerie.get('tipo').value;
    const index = this.tiposSerie.findIndex((v) => {
      return v.id == tipo;
    });

    const duracion: number = parseInt(this.tiposSerie[index]['duracion']);
    this.pruebasSerie = Array(duracion).fill('');
  }

  public submitSerie() {
    if (this.formSerie.valid) {
      //console.log(this.pruebasSerie);
      if (this.pruebasSerie.indexOf('') == -1) {
        const pruebas = this.pruebasSerie.map(ipc => {
          const index = this.pruebas.findIndex(p => {
            return p.ipc == ipc;
          });
          const prueba = this.pruebas[index];

          return {
            ipc: prueba.ipc,
            numero: prueba.numero,
            prueba: prueba.nombre
          };
        });
        const date = this.formSerie.get('fecha').value;
        const fecha = `${date.getFullYear()}-${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;

        let serie = {
          duracion: String(this.pruebasSerie.length),
          fecha: fecha,
          id: this.idSerieSeleccionada,
          nombre: this.formSerie.get('nombre').value,
          pruebas: pruebas,
          tipo: this.formSerie.get('tipo').value,

        };

        if (this.idSerieSeleccionada) {
          //Actualizar
          this.serieService.updateSerie(serie).subscribe(
            response => {
              if (!response.error) {
                const index = this.series.findIndex(s => {
                  return s.id == serie.id;
                });
                this.series[index] = serie;
                $.NotificationApp.send("Updated series", 'The series has been successfully updated.', 'bottom-right', '#06d5a1', 'success');
                $('#modal-serie').modal('hide');
              } else {
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }
            },
            error => {
              $.NotificationApp.send("Error", 'The series has not been updated, please try again.', 'bottom-right', '#06d5a1', 'success');
              console.log(error);
            }
          );
        } else {
          //Crear
          this.serieService.createSerie(serie).subscribe(
            response => {
              if (!response.error) {
                serie['id'] = String(response.idSerie);
                this.series.push(serie);
                $.NotificationApp.send("Created series", 'The series has been created successfully.', 'bottom-right', '#06d5a1', 'success');
                $('#modal-serie').modal('hide');
              } else {
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }

            },
            error => {
              $.NotificationApp.send("Error", 'The series has not been created, please try again.', 'bottom-right', '#06d5a1', 'success');
              console.log(error);
            }
          );
        }
      } else {
        $.NotificationApp.send("Classes not selected.", 'Select a class for each day of the series.', 'bottom-right', '#ffbc00', 'warning');
      }

    } else {
      this.formSerie.get('nombre').markAsTouched();
      this.formSerie.get('tipo').markAsTouched();
    }
  }

  public imagenPatrocinador(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      if (file.type === 'image/jpeg') {
        $('#loader').show();

        const ref = firebase.storage().ref('sponsor').child(`${this.ipcSeleccionado ? this.ipcSeleccionado : new Date().getTime()}`);
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                $('#loader').hide();
                this.imgPatrocinador = downloadURL;
              },
              error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public getLabelCategoria(idCategoria): string {
    const index = this.categorias.findIndex(categoria => {
      return categoria.id == idCategoria;
    });

    return this.categorias[index].siglas; //nombre o siglas
  }

  public cambioCostoCategoria(e, idCategoria) {
    const costo = e.currentTarget.value;
    this.costo[idCategoria] = costo;
  }

  public fechaSafari(fecha: string) {
    return fecha ? fecha.replace(/-/g, "/") : null;
  }

  public archivoCroquis(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      $('#loader').show();
      const ref = firebase.storage().ref('croquis').child(`${this.ipcSeleccionado ? this.ipcSeleccionado : new Date().getTime()}`);
      const task = ref.put(file);
      task.on('state_changed',
        (snapshot: any) => { //Cuando hay cambio de estado
          this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error: any) => { //Cuando hubo un error
          $('#loader').hide();
          $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
          console.log(error);
        },
        () => { //Cuando la funcion termino correctamente
          task.snapshot.ref.getDownloadURL().then(
            downloadURL => {
              $('#loader').hide();
              this.croquis = downloadURL;
              console.log(this.croquis);
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
              console.log(error);
            }
          );
        }
      );
    }
  }

  public agregarCargo(): void {
    if (this.conceptoSeleccionado) {
      const concepto = this.conceptos.find(c => c.id == this.conceptoSeleccionado);
      this.cargosPrueba.push({
        id: concepto.id,
        name: concepto.nombre
      });
      this.conceptoSeleccionado = '';
    }
  }

  public removeCharge(id): void {
    const index = this.cargosPrueba.findIndex(c => c.id == id);
    this.cargosPrueba.splice(index, 1);
  }

  public filtroConceptosSinAgregar(): any[] {
    const cargos = this.cargosPrueba.map(c => c.id);
    return this.conceptos.filter(c => cargos.indexOf(c.id) === -1);
  }

  public agregarPista(): void {
    if (this.pistaSeleccionada) {
      if (this.pistas.indexOf(this.pistaSeleccionada) > -1) {
        this.pistaSeleccionada = '';
        return;
      }

      this.pistas.push(this.pistaSeleccionada);
      this.pistaSeleccionada = '';
    }
    this.guardarPistas();
  }

  public eliminarPista(i) {
    this.pistas.splice(i, 1);
    this.guardarPistas();
  }

  public guardarPistas() {
    this.guardarCampo('pistas', 'texto', JSON.stringify(this.pistas), this.idConcurso);
  }

  public cambioDiaPrueba() {
    this.actualizarPruebasConcurso = true;
    if (!this.formPrueba.value.hora_inicio) {
      this.formPrueba.get('hora_inicio').setValue('08:00');
    }
  }

  public cambioHoraPrueba() {
    this.actualizarPruebasConcurso = true;
  }

  public updateFirestoreResults(ipc) {
    this.appService.deleteEntriesAndUpdate(this.idConcurso, ipc);
  }

  public getPersonas(e, entidad) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            this.personas = response.personas;
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the designers, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.personas = [];
    }
  }

  public displayPersona(idPersona): string {
    const designer = this.personas.find(p => p.id_persona == idPersona);

    return designer ? designer.fullname : idPersona;
  }

  async assingUserToRing(e: any, ring: any) {
    const judgeID = e.target.value;
    if (!this.pistas.includes(ring)) {
      $.NotificationApp.send("Error", `Ring ${ring} doesn't exist.`, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const response = await this.pruebaService.assignUserToRing(ring, judgeID, this.idConcurso);
    if (!response.error) {
      this.getPruebas();
      $.NotificationApp.send("Updated", "The judge has been assigned to the ring.", 'bottom-right', '#06d5a1', 'success');
    } else {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public returnUser(pista) {
    const juez = this.juecesPistas.find(p => p.pista == pista);
    if (!juez) return 'No judge assigned';
    return juez.jueces.length > 1 ? juez.jueces.concat() : juez.jueces[0];
  }

  async addButtonLink(){
    $('#form-buttons').addClass('was-validated');
    if(this.formButtons.valid){
      this.formButtons.disable();
      const buttonLink = new Map<string, string>([
        ['link', this.formButtons.value.link],
        ['title', this.formButtons.value.title]
      ]);
      this.linkButtons.push(buttonLink);
      const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
      await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);

      //Limpiar y habilitar el formulario
      $('#form-buttons').removeClass('was-validated');
      this.formButtons.enable();
      this.formButtons.reset();
      setTimeout(() => { this.enableTooltips(); });
    }
  }

  async deleteButtonLink(index: number){
    this.linkButtons.splice(index, 1);
    const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
    await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);
  }

  async enableEditButton(index: number){
    this.linkButtons[index].set('edit', 'true');
    setTimeout(() => { this.enableTooltips(); });
  }

  async disableEditButton(index: number){
    this.linkButtons[index].delete('edit');
    setTimeout(() => { this.enableTooltips(); });
  }

  async editButtonLink(index: number){
    const title = $(`#buttonTitle${index}`).val();
    const link = $(`#buttonLink${index}`).val();
    if(!title||!link){
      $.NotificationApp.send("Error", 'The title and link are required.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.linkButtons[index].set('saving', title);
    this.linkButtons[index].set('title', title);
    this.linkButtons[index].set('link', link);
    const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
    await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);
    this.linkButtons[index].delete('saving');
    this.linkButtons[index].delete('edit');
    setTimeout(() => { this.enableTooltips(); });
  }
}
