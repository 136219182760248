import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChaseService } from '../services/chase.service';
declare let $: any;

@Component({
  selector: 'app-cc-payments',
  templateUrl: './cc-payments.component.html',
  styleUrls: ['./cc-payments.component.css']
})
export class CcPaymentsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public paymentSystem: string;
  public chaseForm: UntypedFormGroup;

  constructor(private authService: AuthService, private router: Router, private chaseService: ChaseService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.paymentSystem = 'chase';
    this.chaseForm = new UntypedFormGroup({
      public_key: new UntypedFormControl('', [Validators.required]),
      secret_key: new UntypedFormControl('', [Validators.required]),
      user: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      merchant_id: new UntypedFormControl('', [Validators.required]),
      active: new UntypedFormControl(false, [Validators.required])
    });
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    $('#loader').hide();
  }

  public saveChaseConfig(){
    console.log('saveChaseConfig');
    if(this.chaseForm.valid){
      this.chaseService.saveConfig(this.chaseForm.value, this.idConcurso).subscribe(
        response => {
          if(!response.error){
            console.log(response);
          } else{
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to save the chase data.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }
}
