import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ResponsablesService } from '../services/responsables.service';
import { EntrenadorService } from '../services/entrenador.service';
import { AuthService } from '../services/auth.service';
import { ConceptoService } from '../services/concepto.service';
import { PruebaService } from '../services/prueba.service';
import { ConcursoService } from '../services/concurso.service';
import { EntriesService } from '../services/entries.service';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'caja',
  templateUrl: 'caja.component.html',
  styleUrls: ['caja.component.css'],
  providers: [
    CurrencyPipe,
    DecimalPipe
  ]
})

export class CajaComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public entrenadores: Array<any>;
  public entrenadorSeleccionado: string;
  public conceptos: Array<any>;
  public formConcepto: UntypedFormGroup;
  public premios: Array<any>;
  public idConceptoSeleccionado: string;
  public detallesConceptos: Array<any>;
  public nombreConceptoSeleccionado: string;
  public caballosPase: Array<string>;
  public comentariosPase: string;
  public pases: Array<any>;
  public caballosEnPases: Array<string>;
  public entriesBilling: Array<any>;
  public filtroConceptosEntrenador: any;
  public totalMontoConcepto: number;
  public totalUnidadesConcepto: number;
  public header: string;
  public header2: string;
  public footer: string;
  public nombreEntrenadorBillingMatrix: string;
  public formAutobilling: UntypedFormGroup;
  public pruebasAutobilling: any[];
  public entriesAutobilling: any[];
  public tablaDetalles: any;
  public filtro: boolean;
  //Add billing item to multiple entries
  public entries: any[];
  public selectedEntries: string[];
  public selectedConcept: string;
  public chargeNotes: string;
  public saving: boolean;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private responsablesService: ResponsablesService,
    private entrenadorService: EntrenadorService,
    private authService: AuthService,
    private conceptoService: ConceptoService,
    private pruebaService: PruebaService,
    private router: Router,
    private concursoService: ConcursoService,
    private entriesService: EntriesService,
    private currencyPipe: CurrencyPipe,
    private numberPipe: DecimalPipe,
    private appService: AppService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.entrenadores = [];
    this.entrenadorSeleccionado = '';
    this.totalMontoConcepto = 0;
    this.totalUnidadesConcepto = 0;
    this.conceptos = [];
    this.formConcepto = new UntypedFormGroup({
      nombre: new UntypedFormControl('', [Validators.required]),
      monto: new UntypedFormControl('', [Validators.required]),
      online: new UntypedFormControl(false, []),
      autobilling: new UntypedFormControl(false, []),
      feed: new UntypedFormControl(false, []),
      reservation: new UntypedFormControl(false, []),
      salesTax: new UntypedFormControl(false, [])
    });
    this.premios = [];
    this.idConceptoSeleccionado = '';
    this.detallesConceptos = [];
    this.nombreConceptoSeleccionado = '';
    this.caballosPase = [];
    this.comentariosPase = '';
    this.pases = [];
    this.caballosEnPases = [];
    this.entriesBilling = [];
    this.filtroConceptosEntrenador = {};
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.nombreEntrenadorBillingMatrix = '';
    this.formAutobilling = new UntypedFormGroup({
      concepto: new UntypedFormControl('', [Validators.required]),
      tipo: new UntypedFormControl('all', [Validators.required]),
      ipc: new UntypedFormControl('', [])
    });
    this.entriesAutobilling = [];
    this.tablaDetalles = {};
    this.filtro = false;
    this.entries = [];
    this.selectedEntries = [];
    this.selectedConcept = '';
    this.chargeNotes = '';
    this.saving = false;
  }

  ngOnInit() {
    this.iconRegistry.addSvgIcon('xlsx', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/xlsx.svg'));
    this.iconRegistry.addSvgIcon('fei', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/fei.svg'));
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getConcurso();
    this.getEntrenadores();
    this.getConceptos();
    this.getPruebas();
    this.getCaballosMatrix();
  }

  ngAfterViewInit(){
    this.tablaDetalles = $('#detalles').DataTable({
      dom: 'Bfrtip',
      buttons:[{
        extend: 'print',
        messageTop: function () {
          return $('#tableTitle').text();
        }
      },
      {
        text: 'Export to CSV',
        action: ( e, dt, node, config ) => {
          this.appService.arrayToCsv([...this.detallesConceptos.map(c => ({ 'Entry': c.num_caballo||'', 'Trainer': c.entrenador||'', 'Horse': c.caballo||'', 'Owner': c.owner ||'', 'Notes': c.referencia||'', 'Qty': this.numberPipe.transform(c.cantidad||''), 'Amount': this.currencyPipe.transform(c.monto||'') })), { 'Entry': '', 'Trainer': '', 'Horse': '', 'Notes': 'TOTAL: ', 'Qty': this.totalUnidadesConcepto||'', 'Amount': this.currencyPipe.transform(this.totalMontoConcepto||'') }], this.nombreConceptoSeleccionado);
        }
      }],
      language:{
        paginate:{
          previous:"<i class='mdi mdi-chevron-left'>",
          next:"<i class='mdi mdi-chevron-right'>"
        }
      },
      "pageLength": 50,
      drawCallback:function(){
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
      }
    });
  }

  public getConcurso(){
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error){
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
        }else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the show info.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getEntrenadores() {
    $('#loader').show();
    this.entrenadorService.getEntrenadores(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.entrenadores = response.entrenadores;
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the trainers list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  private getConceptos() {
    this.conceptoService.getConceptos(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.conceptos = response.conceptos;
          for(const concepto of response.conceptos){
            this.filtroConceptosEntrenador[concepto.id] = false;
          }
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the charges list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getPruebas() {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pruebasAutobilling = response.pruebas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  /**
   * Elimina un concepto de la base de datos solo si esté no se encuentra registrado en alguna transaccion.
   *
   * @param idConcepto ID del concepto que será eliminado
   * @param nombre nombre del concepto
   * @param monto monto monetario asignado al concepto
   * @param isUsed almacena el resultado de buscar el ID del concepto en alguna transacción.
   */
  public eliminarConcepto(idConcepto, nombre, monto, isUsed) {
    if(isUsed == null){
      const datos = {
        idConcepto: idConcepto,
        nombre: nombre,
        monto: monto,
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso
      };
      this.responsablesService.eliminarConcepto(datos).subscribe(
        response => {
          if (!response.error) {
            this.getConceptos();
            $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to remove the charge.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }else{
      $.NotificationApp.send("Error", "It has not been possible to remove the charge, because it is charged in some accounts", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public abrirFormularioEditarConcepto(index, accion){
    this.conceptos.forEach( concepto => {
      concepto.editar = 0;
    });
    this.conceptos[index].editar = accion;
    if(accion == 1){
      setTimeout(()=> {
        $('#nuevoconcepto').val(this.conceptos[index].nombre);
      }, 200);

    }
  }

  public editarConcepto(idConcepto){
    let nombre = $('#nuevoconcepto').val();
    let monto = $('#editar-monto').val();
    let online = $('#editar-online').is(":checked");
    let autobilling = $('#editar-auto').is(":checked");
    let feed = $('#editar-feed').is(":checked");
    let reservation = $('#editar-reservation').is(":checked");
    let salesTax = $('#editar-salesTax').is(":checked");

    if(nombre != ''){
      const datos = {
        idConcepto: idConcepto,
        nombre: nombre,
        monto: monto,
        online: online ? 1 : 0,
        autobilling: autobilling ? 1 : 0,
        feed: feed ? 1 : 0,
        reservation: reservation ? 1 : 0,
        salesTax: salesTax ? 1 : 0,
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso
      };
      this.responsablesService.editarConcepto(datos).subscribe(
        response => {
          if (!response.error) {
            this.getConceptos();
            $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to edit the charge.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }else{
      $.NotificationApp.send("Error", "It has not been possible to edit the charge, name field is necessary", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public agregarConcepto() {
    this.formConcepto.get('nombre').markAsDirty();
    this.formConcepto.get('monto').markAsDirty();
    this.formConcepto.get('online').markAsDirty();

    if (this.formConcepto.valid) {
      const datos = {
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso,
        nombre: this.formConcepto.get('nombre').value,
        monto: this.formConcepto.get('monto').value,
        online: this.formConcepto.get('online').value ? 1 : 0,
        autobilling: this.formConcepto.get('autobilling').value ? 1 : 0,
        feed: this.formConcepto.get('feed').value ? 1 : 0,
        reservation: this.formConcepto.get('reservation').value ? 1 : 0,
        salesTax: this.formConcepto.get('salesTax').value ? 1 : 0
      };

      this.responsablesService.agregarConcepto(datos).subscribe(
        response => {
          if (!response.error) {
            this.getConceptos();
            $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
            this.formConcepto.reset();
            this.formConcepto.get('nombre').setValue('');
            this.formConcepto.get('monto').setValue('');
            this.formConcepto.get('online').setValue(false);
            this.formConcepto.get('autobilling').setValue(false);
            this.formConcepto.get('feed').setValue(false);
            this.formConcepto.get('reservation').setValue(false);
            this.formConcepto.get('salesTax').setValue(false);
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to create the charge.", 'bottom-right', '#fa5c7c', 'error');
        }
      );
    }
  }

  public detalleConcepto(idConcepto, nombre) {
    this.idConceptoSeleccionado = idConcepto;
    this.nombreConceptoSeleccionado = nombre;
    //Limpiar tabla antes de consulta
    this.tablaDetalles.rows().remove().draw();
    this.conceptoService.detalleConcepto(this.idConcurso, idConcepto).subscribe(
      response => {
        this.totalMontoConcepto = 0;
        this.totalUnidadesConcepto = 0;
        this.detallesConceptos = response.detalles;
        this.detallesConceptos.forEach(
          concepto => {
            this.totalMontoConcepto += parseFloat(concepto.monto);
            this.totalUnidadesConcepto += parseFloat(concepto.cantidad);
          }
        );
        for(const concepto of this.detallesConceptos){
          this.tablaDetalles.row.add([concepto.num_caballo,concepto.entrenador,concepto.caballo,concepto.referencia,this.numberPipe.transform(concepto.cantidad),this.currencyPipe.transform(concepto.monto)]).draw(false);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the details", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public mostrarModalPaseDeSalida() {
    if (/*this.responsableSeleccionado*/1) {
      this.caballosPase = [];
      this.comentariosPase = '';
      $('#modalPaseDeSalida').modal('show');
    } else {
      $.NotificationApp.send("Error", "There is no payee selected", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public agregarQuitarCaballoPaseDeSalida(idCaballo, e) {
    const checked = e.currentTarget.checked;
    if (checked) { //Agergar caballo
      this.caballosPase.push(idCaballo);
    } else { //Quitar caballo
      const index = this.caballosPase.indexOf(idCaballo);
      this.caballosPase.splice(index, 1);
    }
  }

  public generarPaseDeSalida() {
    const caballos = JSON.stringify(this.caballosPase);
    const comentarios = this.comentariosPase;

    this.responsablesService.generarPaseDeSalida(this.idConcurso, /*this.responsableSeleccionado*/'', this.idUsuario, caballos, comentarios).subscribe(
      response => {
        if (!response.error) {
          $('#modalPaseDeSalida').modal('hide');
          //this.seleccionarResponsable();
          $.NotificationApp.send("Saved", "The exit pass has been successfully generated.", 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It was not possible to generate the exit pass.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public validarCaballoEnPase(idCaballo): boolean {
    return this.caballosEnPases.indexOf(idCaballo) > -1;
  }

  public mostrarPase(idPase) {
    window.open(`/pase/${idPase}`, '_blank')
    //this.router.navigate(['pase', idPase]);
  }

  public getCaballosMatrix(){
    this.entrenadorService.getCaballosMatrix(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.entriesBilling = response.entries.map(entry => {
            entry.conceptos = {};

            return entry;
          });
          this.entries = response.entries.filter(e => e.entry && e.entry.charAt(0) != 't').map(e => ({entry: e.entry, caballo: e.caballo, idEntrenador: e.idEntrenador}));
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the horses information.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getConceptoMatrix(idConcepto){
    //Si es para ocultar el campo no hacer la consulta a la bd
    if(this.filtroConceptosEntrenador[idConcepto]){
      this.filtroConceptosEntrenador[idConcepto] = !this.filtroConceptosEntrenador[idConcepto];
      return;
    }

    $('#loader').show();
    this.entrenadorService.getConceptoMatrix(idConcepto, this.idConcurso).subscribe(
      response => {
        if(!response.error){
          //Revisar si hay caballos que no esten en la matriz
          const ent = response.entries.filter(e => {
            const entry = this.entriesBilling.find(en => en.entry == e.entry);
            return !entry;
          }).map(e => ({ambito: '', caballo: (this.entrenadores.find(t => `t${t.idEntrenador}` == e.entry)||{}).nombre, conceptos: {}, entry: e.entry, idEntrenador: e.entry.charAt(0) == 't' ? e.entry.replace(/t/g,'') : '', qtyEntry: e.qtyEntry}));

          this.entriesBilling = [...this.entriesBilling, ...ent];

          this.entriesBilling.map(eb => {
            const entry = response.entries.find(e => e.entry == eb.entry);
            if(entry) eb.conceptos[idConcepto] = {
              qtyEntry: parseFloat((entry.qtyEntry).toFixed(2))
            }
            else eb.conceptos[idConcepto] = {
              qtyEntry: 0
            };

            return eb;
          });

          /*for(const entry of response.entries){
            const index = this.entriesBilling.findIndex(e => e.entry == entry.entry);
            if(index > -1){
              this.entriesBilling[index].conceptos[entry.id_concepto] = {
                qtyEntry: parseFloat((entry.qtyEntry).toFixed(2)),
                qtyEntrenador: entry.qtyEntrenador
              }
            }
          }*/
          //Map para poner 0 los caballos que no tengan resultado en el query
          /*this.entriesBilling.map(e => {
            if(!e.conceptos[idConcepto]){
              e.conceptos[idConcepto] = {
                qtyEntrenador: 0,
                qtyEntry: 0
              };
            }

            return e;
          });*/
          this.filtroConceptosEntrenador[idConcepto] = !this.filtroConceptosEntrenador[idConcepto];
          $('#loader').hide();
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the charge information.", 'bottom-right', '#ffbc00', 'error');
        console.log(error);
      }
    );
  }

  public filtroEntriesEntrenador(): Array<any>{
    const filtroEntrenador = this.entrenadorSeleccionado ? this.entriesBilling.filter(entry => {
      return entry.idEntrenador == this.entrenadorSeleccionado;
    }) : this.entriesBilling;

    const conceptosSeleccionados = [];
    //Si tiene filtro true filtrar
    for(let i in this.filtroConceptosEntrenador){
      if(this.filtroConceptosEntrenador[i])
        conceptosSeleccionados.push(i);
    }
    //conceptosSeleccionados

    const arrayFiltrado = this.filtro ? filtroEntrenador.filter(e => {
      for(const concepto of conceptosSeleccionados){
        if(e.conceptos[concepto]?.qtyEntry > 0){
          return true;
        }
      }

      return false;
    }) : filtroEntrenador;

    return arrayFiltrado;
  }

  public validate(e){
    const pattern = /[0-9-.t]/;
     const inputChar = String.fromCharCode(e.charCode);

     if (!pattern.test(inputChar) && e.charCode != 13) {
         // invalid character, prevent input
         e.preventDefault();
     }
  }

  public agregarCargoMatrix(e, idConcepto, entry, idEntrenador){
    const input = $(e.target);
    let isTrainer = false;
    //Validar siel cargo es para entrenador o entry

    let value:string = e.target.value;
    if(value.length > 0){
      if(value.indexOf('t') > -1){
        isTrainer = true;
        value = value.replace(/t/g, '');
      }
    // TODO: Cambiar a que cheque si el renglon es de un entrenador
      if (entry.indexOf('t') > -1) {
        isTrainer = true;
      }
      const cantidad = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      const indexConcepto = this.conceptos.findIndex(concepto => {
        return concepto.id == idConcepto;
      });
      const indexentriesBilling = this.entriesBilling.findIndex(e => e.entry == entry && e.idEntrenador == idEntrenador);
      const monto = parseFloat((cantidad * (!isNaN(parseFloat(this.conceptos[indexConcepto].monto)) ? parseFloat(this.conceptos[indexConcepto].monto) : 0)).toFixed(2));

      if(cantidad != 0){
        input.addClass('border-blue').removeClass('border-red');

        const cargo = {
          idConcepto,
          entry,
          idEntrenador: isTrainer ? idEntrenador : 'null',
          idUsuario: this.idUsuario,
          idConcurso: this.idConcurso,
          cantidad,
          monto,
          notas: 'Billing Matrix'
        };


        this.entrenadorService.agregarCargoMatrix(cargo).subscribe(
          response => {
            if (!response.error) {
              e.target.value = '';
              //Validar si el cargo gue a entrenador o a responsable y asignar al que corresponda
              if(isTrainer){
                this.entriesBilling[indexentriesBilling]['conceptos'][idConcepto]['qtyEntry'] += cantidad;
              } else{
                this.entriesBilling[indexentriesBilling]['conceptos'][idConcepto]['qtyEntry'] += cantidad;
              }
              input.removeClass('border-blue').removeClass('border-red');
            } else {
              input.addClass('border-red').removeClass('border-blue');
              console.log(response.message);
            }
          },
          error => {
            input.addClass('border-red').removeClass('border-blue');
            console.log(error);
          }
        );
      } else{
        e.target.value = '';
      }
    }
  }

  public cambioEntrenador(){
    if(this.entrenadorSeleccionado){
      const index = this.entrenadores.findIndex(entrenador => {
        return entrenador.idEntrenador == this.entrenadorSeleccionado;
      });
      if(index > -1){
        this.nombreEntrenadorBillingMatrix = this.entrenadores[index].nombre;
      }
    }
  }

  public totalConcepto(idConcepto): number{
    //this.filtroEntriesEntrenador();
    console.log(this.entrenadorSeleccionado);
    const caballos: Array<any> = this.entriesBilling.map(e => ({ ...e, qtyEntry: (e.conceptos[idConcepto]||{}).qtyEntry||0 })).filter(e => (this.entrenadorSeleccionado && e.idEntrenador == this.entrenadorSeleccionado) || !this.entrenadorSeleccionado);
    console.log(caballos);
    return caballos.reduce((a,b) => a + parseFloat(b.qtyEntry||'0'), 0);
  }

  public totalConceptoEntrenador(idConcepto): number{
    const caballos: Array<any> = this.filtroEntriesEntrenador();

    return caballos.reduce((a,b) => {
      return parseFloat((a + b.conceptos[idConcepto].qtyEntrenador).toFixed(2));
    }, 0);
  }

  public seleccionarTipoAutobilling(tipo){
    this.formAutobilling.get('tipo').setValue(tipo);
    if(tipo == 'all'){
      this.formAutobilling.get('ipc').setValue('');
    }
    this.filtroEntriesAutobilling();
  }

  public filtroEntriesAutobilling(){
    if((this.formAutobilling.get('tipo').value == 'class' && !this.formAutobilling.get('ipc').value) || !this.formAutobilling.get('concepto').value){
      this.entriesAutobilling = [];
      return;
    }

    $('#loader').show();
    this.entriesService.getEntriesAutobilling(this.formAutobilling.get('concepto').value, this.formAutobilling.get('ipc').value, this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.entriesAutobilling = response.entries;
          $('#loader').hide();
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the entries list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public limpiarAutobilling(){
    $('#formAutobilling').removeClass('was-validated');
    this.formAutobilling.setValue({
      concepto: '',
      tipo: 'all',
      ipc: ''
    });
    this.entriesAutobilling = [];
  }

  public applyAutobillingCharges(){
    //consulta del monto
    const index = this.conceptos.findIndex(c => c.id == this.formAutobilling.get('concepto').value);
    if(index == -1){
      console.log('Error: no se pudo encontrar el concepto');
      return;
    }
    const monto = this.conceptos[index].monto;

    //filter: filtra entries que tengan applyCharge en true (qty igual a 0)
    //map: da formato al arreglo para que cada elemento tenga los datos necesarios para agregar un cargo
    const cargos = this.entriesAutobilling.filter(e => e.applyCharge).map(e => {
      return {
        monto: monto,
        concepto: this.formAutobilling.get('concepto').value,
        entry: e.entry,
        cantidad: 1,
        notas: 'Autobilling'
      };
    });
    $('#loader').show();
    this.entriesService.addAutobillingCharge(cargos, this.idUsuario, this.idConcurso, this.formAutobilling.get('concepto').value).subscribe(
      response => {
        if(!response.error){
          this.limpiarAutobilling();
          $('#loader').hide();
          $.NotificationApp.send("Success", 'Charges have been added successfully.', 'bottom-right', '#0acf97', 'success');
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to add the charge.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public isTrainer(entry: string): boolean{
    return entry.charAt(0) == 't';
  }

  public filtrarBillingMatrix(): void{
    this.filtro = !this.filtro;
  }

  public conceptosBillingMatrix(): any[]{
    return this.conceptos.filter(c => !c.feed);
  }

  public addEntry(evt: any){
    const entry = evt.target.value;
    //Entry vacio
    if(!entry) return;
    //Validar que el entry exista en el arreglo
    const e = this.entries.find(e => e.entry == entry);
    if(!e){
      evt.target.value = '';
      $.NotificationApp.send("Error", `The entry ${entry} does not exist in this show.`, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }

    this.selectedEntries.push(e);
    evt.target.value = '';
  }

  public removeEntry(index: number){
    this.selectedEntries.splice(index, 1);
  }

  public isDuplicatedEntry(entry: string): boolean{
    return this.selectedEntries.filter((e: any) => e.entry == entry).length > 1;
  }

  public async addBillingItemToEntries(){
    if(!this.selectedConcept){
      $.NotificationApp.send("Error", 'You must select a charge to continue.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    if(this.selectedEntries.length == 0){
      $.NotificationApp.send("Error", 'You must select at least one entry to continue.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.saving = true;

    const concepto = this.conceptos.find(c => c.id == this.selectedConcept);
    const params = {
      concepto: concepto.id,
      cantidad: 1,
      monto: concepto.monto,
      notas: `Applied to multiple entries, ${this.chargeNotes||''}`,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso,
      entries: this.selectedEntries.map((e: any) => e.entry)
    };

    const response = await this.entriesService.addCharges(params);
    if(response.error){
      this.saving = false;
      $.NotificationApp.send('Error', response.message||'', 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message||'');
      return;
    }

    this.saving = false;
    this.selectedConcept = '';
    $('#multipleEntries-entry').val('');
    this.chargeNotes = '';
    this.selectedEntries = [];
    $.NotificationApp.send('Success', 'Charges added successfully.', 'bottom-right', '#0acf97', 'success');
  }
}
