import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
declare let $: any;

export const catchErrorMessage = (error: HttpErrorResponse) => {
// Return an observable with a user-facing error message.
  return throwError(() => new Error((error.error||{}).message||(error.message||(error.error||(error||'')))));
};

export const handleError = (error: string) => {
  $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error', 10000);
  return {
    error
  };
};
