import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ResultadoService } from '../services/resultado.service';

declare var $: any;
export interface Inscripciones {
}
@Component({
	selector: 'app-inscripciones-pendientes',
	templateUrl: './inscripciones-pendientes.component.html',
	styleUrls: ['./inscripciones-pendientes.component.css']
})
export class InscripcionesPendientesComponent implements OnInit {
	public token: string;
	private sessionData: any;
	public privilegios: number;
	public nombreConcurso: string;
	public idConcurso: string | boolean;
	public idUsuario: number;
	//public solicitudes: Array<any>;
	public solicitudesAccepted: Array<any>;
	public loading: boolean;
	public dataSource;
	public displayedColumns: string[] = ['Trainer', 'Horse', 'Date', 'Order ID'];
	public solicitudes: Map<string, any[]>;
	public hasSelectedEntries: boolean;
	public entriesSelected: any[];
	public entryNumber: string;
	public filtro: any;
	public orderBy: boolean = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private inscripcionesRemotasService: InscripcionesRemotasService,
		private resultadoService: ResultadoService,
	) {
		this.token = '';
		this.sessionData = {};
		this.privilegios = 0;
		this.idConcurso = '';
		this.idUsuario = 0;
		this.nombreConcurso = this.authService.getNombreConcurso();
		//this.solicitudes = [];
		this.loading = false;
		this.dataSource = new MatTableDataSource<Inscripciones>();
		this.solicitudes = new Map<string, any[]>();
		this.solicitudes.set('unpaid', []);
		this.solicitudes.set('paid', []);
		this.solicitudes.set('accepted', []);
		this.solicitudes.set('cancelled', []);
		this.hasSelectedEntries = false;
		this.entriesSelected = [];
		this.entryNumber = '';
		this.filtro = null;
	}
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	async ngOnInit() {
		if (!this.authService.isLoggedIn()) {
			this.authService.logOut();
			return;
		} else {
			this.token = this.authService.getAuthorizationToken();
			this.sessionData = this.authService.getSessionData(this.token);
			this.privilegios = this.sessionData.privilegios;
			this.idUsuario = this.sessionData.idUsuario;
		}
    //Ingate: 50
		if (this.authService.validarConcurso() && this.privilegios >= 50) {
			this.idConcurso = this.authService.validarConcurso();
		} else {
			this.router.navigate(['']);
			return;
		}
		await this.getSolicitudes(0);
		await this.getSolicitudes(1);
		await this.getSolicitudes(2, this.idConcurso || '');
		await this.getSolicitudes(5, this.idConcurso || '');
		$('#loader').hide();
		//this.getSolicitudesPendientes();
		//this.getSolicitudesAceptadas();
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	private async getSolicitudes(status: 0 | 1 | 2 | 5, showId?: string): Promise<void> {
		const type = ['unpaid', 'paid', 'accepted', '', '', 'cancelled'];

		return this.inscripcionesRemotasService.getSolicitudesPendientes(sessionStorage.getItem('club') || '', status, showId).toPromise().then(r => {
			if (r.error) {
				this.solicitudes.set(type[status], []);
				$('#loader').hide();
				$.NotificationApp.send("ERROR!", r.message, 'bottom-right', '#fa5c7c', 'error', 5000);
				return;
			}
			this.solicitudes.set(type[status], r.data.map(s => ({
				id: s.id,
				trainer: `${s.entrenador.trainerName} ${s.entrenador.trainerLastname}`,
				rider: `${s.jinete.riderName} ${s.jinete.riderLastName}`,
				email: s.email,
				caballo: s.caballo.horseName,
				horseUsef: s.caballo.horseUsef,
				created: s.created,
				show: s.concurso.fullname,
				entry: s.entry,
				data: [0, 1].includes(status) ? { ...s, cucarda: s.cucarda || s.entry } : null,
			})));
      console.log(this.solicitudes.get(type[status]));
			return;
		}, error => {
			this.solicitudes.set(type[status], []);
			$('#loader').hide();
			console.log(error);
			$.NotificationApp.send("ERROR!", "It has not been possible to query the pending requests.", 'bottom-right', '#fa5c7c', 'error', 5000);
			return;
		});
	}

	public verSolicitud(idSolicitud) {
		this.router.navigate(['/inscripciones-pendientes', idSolicitud]);
		return;
	}

	public fechaSafari(fecha: string) {
		return fecha.replace(/-/g, "/");
	}

	public sendSignatureMails() {
		this.loading = true;
		this.inscripcionesRemotasService.sendSignatureMails(this.idConcurso).subscribe(
			response => {
				if (!response.error) {
					this.loading = false;
				} else {
					this.loading = false;
					$.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
				}
			},
			error => {
				this.loading = false;
				console.log(error);
				$.NotificationApp.send("ERROR!", "It has not been possible to send the emials.", 'bottom-right', '#fa5c7c', 'error', 5000);
			}
		);
	}

	public printSignatureFormats() {
    const unsigned = this.solicitudes.get('unsigned')||[];
    if(!unsigned.length){
      $.NotificationApp.send("No entries to print", "There are no entries to print, please select the entries you want to print.", 'bottom-right', 'rgba(0,0,0,0.2)', 'info', 5000);
      return;
    }
		window.open(`/formato-inscripciones/${unsigned.map(s => s.id).join(',')}`, '_blank');
	}

	public checkPayment(retryTrace, orderId) {
		console.log('Checando pago con trace: ' + retryTrace);
		this.inscripcionesRemotasService.chaseInquiry(this.idConcurso, retryTrace).subscribe(
			response => {
				if (!response.error) {
					console.log(response['authorizationCode']);

					const pago = {
						pagado: true,
						metodo: 'tc',
						notas: response['txRefNum'],
						trasactionId: '',
						recibidoPor: '99'
					};

					// Validar si fue pagado
					if (response['approvalStatus'] == '1') {
						this.marcarPagado(orderId, pago, 1);
					}
				} else {
					$.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
				}

			},
			error => {
				console.log(error);
				$.NotificationApp.send("ERROR!", "It has not been possible to query the payment, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
			}
		)
	}

	public marcarPagado(idSolicitud, pago, estatus) {
		this.inscripcionesRemotasService.actualizarPagoSolicitud(idSolicitud, pago, estatus).subscribe(
			response => {
				if (!response.error) {
					$.NotificationApp.send("Success!", 'The payment has been registered in the application successfully, it will be reflected in the payee account once the application for registration is accepted.', 'bottom-right', '#07926b', 'success', 8000);
					//this.getSolicitudesPendientes();
					this.getSolicitudes(1);
				} else {
					$.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
					//this.procesandoPago = false;
				}
			},
			error => {
				console.log(error);
				$.NotificationApp.send("ERROR!", "It has not been possible to register the payment, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
				//this.procesandoPago = false;
			}
		);
	}

	public aceptarPago(event, idSolicitud) {
		event.stopPropagation();
		this.inscripcionesRemotasService.agregarPago(idSolicitud).subscribe(
			response => {
				if (!response.error) {
					$.NotificationApp.send("Success!", `Payment has been added successfully to the entry ${response.entry}`, 'bottom-right', '#3c763d', 'success', 5000);
				} else {
					$.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
				}
			},
			error => {
				console.log(error);
				$.NotificationApp.send("ERROR!", "It has not been possible to save the payment, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
			}
		);
	}

	public toogleSelection(id: number, selected: boolean, status: string) {
		const index = this.solicitudes.get(status).findIndex(s => s.id === id);
		if (index > -1) {
			this.solicitudes.get(status)[index].selected = selected;
		}
		this.hasSelectedEntries = !!this.solicitudes.get(status).find(s => s.selected || false);
		this.entriesSelected = this.solicitudes.get(status).filter(s => s.selected || false);
		if (this.hasSelectedEntries) {
			//Si hay entries seleccionadas, aplicar el filtro
			//Si aun no hay un filtro agregado, agregarlo
			if (!this.filtro) {
				const entry = this.solicitudes.get(status)[index];
				console.log(entry);
				this.filtro = {
					horseUsef: entry.horseUsef,
					horseName: entry.caballo,
				};
			} else {
				//Si ya hay un filtro, no se hace nada (si se quisiera actualizar el filtro, se podria hacer aqui)
			}
		} else {
			//Si no hay entries seleccionadas, limpiar el filtro
			this.filtro = null;
		}
	}

	public showAcceptModal() {
		this.entryNumber = '';
		$('#accept-entries-modal').modal('show');
	}

	public async acceptEntries(){
    this.loading = true;
		if(!this.entryNumber || isNaN(+this.entryNumber)){
      this.loading = false;
			$.NotificationApp.send("Invalid entry number", "Please select a valid entry number to continue.", 'bottom-right', 'rgba(0,0,0,0.2)', 'warning', 10000);
			return;
		}

		const errors = [];
    const acceptedEntries = [];
    for(const solicitud of this.entriesSelected){
      const { response, error } = await this.inscripcionesRemotasService.aceptarSolicitud(solicitud.data, this.entryNumber, this.idUsuario).toPromise().then(r => ({ response: r.error ? null : r, error: r.error ? r.message : null })).catch(e => ({ error: e, response: null }));
      if(error){
        console.log(error)
        errors.push(`${solicitud.caballo} - ${solicitud.show}: ${error}`);
        continue;
      }
      acceptedEntries.push(`${solicitud.caballo} - ${solicitud.show} has been accepted successfully.`);
    }

    if(errors.length){
      this.loading = false;
      $.NotificationApp.send("Error", errors, 'bottom-right', 'rgba(0,0,0,0.2)', 'warning', 30000);
      return;
    } else {
      this.loading = false;
			await this.getSolicitudes(1);
			await this.getSolicitudes(2, `${this.idConcurso}`);
      this.filtro = null;
			$('#accept-entries-modal').modal('hide');
			$.NotificationApp.send("Accepted!", acceptedEntries, 'bottom-right', '#0acf97', 'success', 30000);
      await this.resultadoService.guardarConcursoFirestore(this.idConcurso).toPromise();
			return;
    }
	}

	public reOrder(orderBy: string, status: string) {

		this.orderBy = !this.orderBy;

		let array = this.solicitudes.get(status);

		if (orderBy != 'created') {
			if (this.orderBy) {
				array.sort((a, b) => {
					if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
						return 1;
					}
					if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
						return -1;
					}
					return 0;
				});
			} else {
				array.sort((a, b) => {
					if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
						return 1;
					}
					if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
						return -1;
					}
					return 0;
				});
			}
		} else if(orderBy == 'created'){
			console.log('Ordering by date');

			if (this.orderBy) {
				array.sort((a, b) => {
					if (new Date(a[orderBy]) > new Date(b[orderBy])) {
						return 1;
					}
					if (new Date(a[orderBy]) < new Date(b[orderBy])) {
						return -1;
					}
					return 0;
				});
			} else {
				array.sort((a, b) => {
					if (new Date(a[orderBy]) < new Date(b[orderBy])) {
						return 1;
					}
					if (new Date(a[orderBy]) > new Date(b[orderBy])) {
						return -1;
					}
					return 0;
				});
			}

		}





	}

}
