import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Firestore, collection, doc, query, getDocs, where, writeBatch } from '@angular/fire/firestore';
import { environment } from './../../environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { ListaService } from './lista.service';

@Injectable({
  providedIn: 'root'
})
export class MlsjService {
  private api_key: string;

  constructor(private http: HttpClient, private authService: AuthService, private firestore: Firestore, private listaService: ListaService) {
    this.api_key = '7cc263a1-a490-4337-8302-151490495e56';
  }

  public async getClass(idConcurso: string){
    const pruebasRef = collection(this.firestore, 'concursos', idConcurso, 'pruebas');
    const q = query(pruebasRef, where('id_modalidad', '==', '71'));
    const snapshot = await getDocs(q);
    if(snapshot.empty){
      return '';
    }
    const [firstDoc] = snapshot.docs.map(d => ({ ...d.data(), id: d.id }));
    return firstDoc;
  }

  async getTeams(idConcurso): Promise<any> {
    const params = { idConcurso };
    const headers = {
      "x-api-key": this.api_key,
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.get(`${environment.api.nest.uri}teams/`, { headers: headers, params }));
  }

  public getRiders(ipc: string): Promise<any> {
    return this.listaService.getBinomios(ipc);
  }

  public async updateResultsFirebase(teams: TeamMlsj[], showId: string, ipc: string, prueba: any){
    const batch = writeBatch(this.firestore);
    batch.set(doc(this.firestore, 'concursos', showId, 'pruebas', ipc), prueba, { merge: true});
    for(const team of teams){
      batch.set(doc(this.firestore, 'results', `${team.ipc}_${team.id}`), team, { merge: true});
    }
    await batch.commit();
  }

  public getSpreadsheetData(APIKey: string, spreadsheetId: string, sheetName: string, cellRange: string): Promise<any> {
    const params = {
      key: APIKey
    };
    return firstValueFrom(this.http.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!${cellRange}`, { params }).pipe(map((res: any) => res.values)));
  }
}
