import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PantallaService } from '../services/pantalla.service';
import { PruebaService } from '../services/prueba.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import * as io from 'socket.io-client';
declare var $ :any;

@Component({
    selector: 'control-pantalla',
    templateUrl: 'control-pantalla.component.html',
    styleUrls: ['control-pantalla.component.css']
})

export class ControlPantallaComponent implements OnInit {
    public idConcurso: string | boolean;
    private idUsuario: string;
    private token: string;
    private sessionData: any;
    public privilegios: number;
    public nombreConcurso: string;
    private idPantalla: string;
    public pantalla: any;
    public prueba: any;
    public ipc: string;
    public pruebas: Array<any>;
    public categorias: Array<any>;
    private socket: SocketIOClient.Socket;

    constructor(
        private authService: AuthService,
        private pantallaService: PantallaService,
        private pruebaService: PruebaService,
        private router: Router,
        private route: ActivatedRoute,
        private db: AngularFirestore,
    ) {
        this.idConcurso = '';
        this.idUsuario = '';
        this.token = '';
        this.sessionData = {};
        this.privilegios = 0;
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.idPantalla = '';
        this.pantalla = {};
        this.prueba = {};
        this.ipc = '';
        this.pruebas = [];
        this.categorias = [];
        this.socket = io('https://api.ecuestre.digital:3000',{secure: true, reconnection: true});
    }

    ngOnInit() {
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
            this.privilegios = this.sessionData.privilegios;
        }
        if(this.authService.validarConcurso() && this.privilegios >= 300){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.route.params.subscribe(params => {
            this.idPantalla = params['idPantalla'];
        });
        //this.sockets();
        this.getPantalla();
        this.getPruebas();
    }

    public getPantalla(){
        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').doc(this.idPantalla).valueChanges().subscribe(
            (pantalla: any) => {
                $('#loader').hide();
                this.pantalla = {
                    pista: pantalla.pista,
                    ipc: pantalla.id_prueba,
                    pantalla: pantalla.pantalla,
                    categoria: pantalla.agrupador,
                    resultados: pantalla.resultados,
                    letreros: pantalla.letreros,
                };
                this.ipc = this.pantalla.ipc;
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'It was not possible to query the screen data.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );

        /*this.pantallaService.getPantalla(this.idPantalla).subscribe(
            response => {
                if(!response.error){
                    this.pantalla = response.pantalla;
                    console.log(this.pantalla);
                    this.ipc = this.pantalla.ipc;
                    if(this.ipc != '0'){
                        this.seleccionarPrueba();
                    }
                    this.getPruebas();
                } else{
                    $('#loader').hide();
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'It was not possible to query the screen data.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );*/
    }

    private getPruebas(){
        this.db.collection('pruebas').doc(`us_${this.idConcurso}`).collection('pruebas'/*, ref => ref.orderBy('inicio')*/).valueChanges().subscribe(
            pruebas => {
                this.pruebas = pruebas.map(p => {
                    return {
                        ipc: p.ipc,
                        numero: p.numero,
                        nombre: p.nombre,
                    };
                });
            },
            error => {
                $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public seleccionarPrueba(){
        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').doc(this.idPantalla).set({
            id_prueba: this.ipc
        }, { merge: true })
        .catch(error => {
            this.ipc = '';
            $.NotificationApp.send("Error", 'It was not possible to select the class.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        });
    }

    public claseCategoria(categoria): string{
        const clases: Array<string> = ['btn-outline-info', 'btn-outline-warning', 'btn-outline-danger', 'btn-outline-success', 'btn-outline-secondary', 'btn-outline-light', 'btn-outline-dark'];
        const index = this.categorias.findIndex(c => {
            return c.id == categoria;
        });

        if(index > -1){
            return `btn pantalla ${clases[index]}`;
        }

        return 'btn pantalla btn-outline-primary';
    }

    public seleccionarPantalla(pantalla){
        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').doc(this.idPantalla).set({
            pantalla: `${pantalla}`
        }, { merge: true })
        .catch(error => {
            this.ipc = '';
            $.NotificationApp.send("Error", 'It was not possible to select the screen.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        });
    }

    public seleccionarCategoria(categoria){
        $('#loader').show();
        this.pantallaService.seleccionarCategoria(this.idPantalla, categoria).subscribe(
            response => {
                $('#loader').hide();
                if(response.error){
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The category could not be selected.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public filtroResultados(resultados): string{
        if(resultados == 0){
            return '1 - 5';
        } else if(resultados == 1){
            return '6 - 10';
        } else if(resultados == 2){
            return '11 - 15'
        }

        return 'Error';
    }

    public scrollTablaResultados(accion){
        //$('#loader').show();
        let resultados = this.pantalla.resultados;

        if(accion == '+'){
            resultados++;
        } else if(accion == '-'){
            resultados--;
        }

        if(resultados < 0 || resultados > 2){
            resultados = 0;
        }

        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').doc(this.idPantalla).set({
            resultados: `${resultados}`
        }, { merge: true })
        .catch(error => {
            this.ipc = '';
            $.NotificationApp.send("Error", 'The results could not be changed.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        });
    }

    public toggleLetreros(letreros){
        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').doc(this.idPantalla).set({
            letreros: letreros
        }, { merge: true })
        .catch(error => {
            this.ipc = '';
            $.NotificationApp.send("Error", 'It has not been possible to hide / show the signs.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        });
    }

    private sockets(){
        this.socket.on('scroll-pantalla', data => {
            if(data.hasOwnProperty(this.idPantalla)){
                this.pantalla.resultados = data[this.idPantalla];
            }
        });
        this.socket.on('cambio-pantalla', data => {
            if(data.hasOwnProperty(this.idPantalla)){
                this.pantalla.pantalla = data[this.idPantalla];
            }
        });
        this.socket.on('categoria-pantalla', data => {
            if(data.hasOwnProperty(this.idPantalla)){
                this.pantalla.categoria = data[this.idPantalla];
            }
        });
        this.socket.on('letreros-pantalla', data => {
            if(data.hasOwnProperty(this.idPantalla)){
                this.pantalla.letreros = data[this.idPantalla];
            }
        });
        this.socket.on('prueba-pantalla', data => {
            if(data.hasOwnProperty(this.idPantalla)){
                this.ipc = data[this.idPantalla].ipc;
                this.categorias = data[this.idPantalla].categorias;
            }
        });
    }
}
