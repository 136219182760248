
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class PaisService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getPaises(): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}paises/`, {headers: headers});
  }
}
