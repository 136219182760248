<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 m-print-0 justify-content-center">
          <div class="col-11 col-print-12">
            <div class="row">
              <div class="card w-100">
                <div class="card-body p-print-0">
                  <div class="col-12 d-print-none">
                    <div class="row">
                      <div class="form-group mb-0">
                        <select class="form-control" (change)="seleccionarPrueba($event)">
                          <option value="" selected disabled>Select a Class</option>
                          <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} -
                            {{ prueba.nombre }}</option>
                        </select>
                      </div>
                      <div class="form-group mb-0 ml-auto">
                        <button *ngIf="(privilegios >= 200 && prueba.publicada) || privilegios >= 300"
                          class="btn btn-outline-danger btn-rounded" (click)="ocultarPublicacion()">
                          <i class=" mdi mdi-cancel"></i>
                          <span>unPublish (this Class)</span>
                        </button>
                        <button *ngIf="(privilegios >= 200 && !prueba.publicada) || privilegios >= 300"
                          class="btn btn-outline-danger btn-rounded" (click)="publicarIndividual()">
                          <i class=" mdi mdi-publish"></i>
                          <span>Publish (this Class)</span>
                        </button>
                        <button class="btn btn-outline-info btn-rounded mr-2 ml-5" (click)="sorteoEquilibrado()"
                          *ngIf="ipc && binomios.length > 0 && !prueba.publicada">
                          <i class="mdi mdi-scale-balance"></i>
                          <span>Auto Sort</span>
                        </button>
                        <!--button class="btn btn-outline-info btn-rounded mr-2" *ngIf="ipc && binomios.length > 0 && !prueba.publicada">
                                                    <i class="mdi mdi-sort-alphabetical"></i>
                                                    <span>Order A-Z</span>
                                                </button-->
                        <button class="btn btn-outline-success btn-rounded" onclick="print()"
                          *ngIf="ipc && binomios.length > 0">
                          <i class="mdi mdi-printer"></i>
                          <span>Print</span>
                        </button>
                        <button class="btn btn-outline-success btn-rounded" (click)="export_csv()"
                          *ngIf="ipc && binomios.length > 0">
                          <i class="mdi mdi-download"></i>
                          <span>Export</span>
                        </button>
                      </div>


                      <div class="col-12 mt-2 d-print-none">
                        <div class="form-group">
                          <mat-form-field class="example-chip-list mr-3" style="width: 50%;">
                            <mat-chip-list #chipList aria-label="Class selection">
                              <mat-chip *ngFor="let class of classes" [selectable]="true" [removable]="true"
                                (removed)="remove(class.ipc)">
                                {{class.name}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                              <input placeholder="Print multiple classes" [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                          </mat-form-field>
                          <button class="btn btn-info" *ngIf="classes.length > 0" (click)="printClasses()">
                            <i class="mr-1 mdi mdi-printer"></i>
                            Print
                          </button>
                        </div>
                        <div class="form-group row mb-0 ml-auto">
                          <div class="col-2">
                            <label class="my-auto mr-2">Horse info: </label><br>
                            <input style="float:right;" type="checkbox" id="infoCaballo" data-switch="success" (change)="showInfoCaballo($event)">
                            <label for="infoCaballo" [attr.data-on-label]="'Yes'" data-off-label="No"
                              class="my-auto"></label>
                          </div>
                          <div class="col-2">
                            <label class="my-auto mr-2">Rider country: </label><br>
                            <input type="checkbox" id="paisJinete" data-switch="success"
                              (change)="showPaisJinete($event)">
                            <label for="paisJinete" [attr.data-on-label]="'Yes'" data-off-label="No"
                              class="my-auto"></label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4 m-print-0" *ngIf="binomios.length > 0">
                    <div class="row border">
                      <div class="d-flex text-center align-items-center w-100">
                        <div style="flex: 1;">
                          <img [src]="prueba.header ? prueba.header : prueba.logoUrl" style="max-height: 90px;"
                            onerror="this.style.display='none'">
                        </div>
                        <div style="flex: 1;">
                          <h3><span>Class Sheet</span> <span *ngIf="prueba.publicada"
                              class="badge badge-success-lighten d-print-none">Published</span></h3>
                          <p>{{ nombreConcurso }}
                            <br><b>{{prueba.pista}}</b>
                            <br><b>{{ prueba.numero }}</b> {{ prueba.nombre }}
                          </p>
                        </div>
                        <div style="flex: 1;">
                          <img [src]="prueba.sponsorImage ? prueba.sponsorImage : prueba.header2"
                            style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'">
                        </div>
                      </div>
                      <div class="d-flex w-100">
                        <p class="p-3" style="flex: 1;">
                          <b>Date:</b> {{ prueba.inicio | date : 'd MMMM yyyy' | titlecase }}
                          <br><b>Designer:</b> {{ prueba.designer }}
                          <br *ngIf="prueba.premio > 0"><b *ngIf="prueba.premio > 0">Prize:</b> <span
                            *ngIf="prueba.premio > 0">{{ prueba.premio | currency }}</span>
                          <br><b>Judge:</b> {{ prueba.nombreJuez }}
                        </p>
                        <p class="p-3" style="flex: 1;">
                          <b>Article / Table:</b> {{ prueba.nombreModalidad }}
                          <br><b>Level:</b>
                          <span *ngIf="prueba.ambito == '1'">Regional</span>
                          <span *ngIf="prueba.ambito == '2'">National</span>
                          <span *ngIf="prueba.ambito == '3'">CSI</span>
                          <span *ngIf="prueba.ambito == '4'">CSI-Amateur</span>
                          <span *ngIf="prueba.ambito == '5'">FEI Ranking</span>
                          <br><b>Height:</b> {{ prueba.altura }}
                          <br><b>Total:</b> {{ binomios.length }}
                        </p>
                      </div>

                      <!-- Tabla para Nacionales USEF -->
                      <table id="USEF-National" class="table t-4 USEF-National" *ngIf="prueba.ambito < 3"
                        style="margin-top: 0px !important">
                        <thead>
                          <tr>
                            <th>
                              <p class="m-0">Entry</p>
                            </th>
                            <th>
                              <p class="m-0">Horse</p>
                            </th>
                            <th>
                              <p class="m-0">Rider</p>
                            </th>
                            <th>
                              <p class="m-0">Owner</p>
                            </th>
                            <th>
                              <p class="m-0">Trainer</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let binomio of binomios; let index = index"
                            (mouseover)="jineteSeleccionado=binomio.idJinete" (mouseout)="jineteSeleccionado=''"
                            [ngClass]="{'scratched': binomio.scratched, 'bg-danger text-white': jineteSeleccionado === binomio.idJinete}">
                            <td><b>{{binomio.cucarda}}</b></td>
                            <td>
                              <p class="m-0">{{ binomio.caballo }}
                                <span class="badge badge-secondary-lighten"
                                  style="vertical-align:text-top">{{binomio.heightCategory}}</span>

                              </p>
                              <p *ngIf="mostrarInfoCaballo" class="m-0 infoCaballo">{{ infoCaballo(binomio) }}</p>
                            </td>
                            <td>
                              <span *ngIf="mostrarPaisJinete && binomio.country !='-'"> ({{ binomio.country }}) </span>
                              {{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}<span
                                class="badge badge-pill border" style="font-size: .575rem;" *ngIf="binomio.categoria">{{
                                binomio.categoria.substring(0, 2) }}</span>
                            </td>
                            <td>{{ binomio.owner }}</td>
                            <td>{{ binomio.trainer }}</td>
                          </tr>
                        </tbody>
                      </table>

                      <!-- Tabla para CSI USA-->
                      <table id="USEF-CSI" class="table print mt-4 USEF-CSI" *ngIf="prueba.ambito == 3"
                        style="margin-top: 0px">
                        <thead>
                          <tr>
                            <!-- <th>
                              <p class="m-0 text-blue">Entry </p>
                            </th> -->
                            <th>
                              <p class="m-0 text-blue">Entry</p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Horse</p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Country </p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Name </p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Last Name</p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Owner</p>
                            </th>
                            <th>
                              <p class="m-0">Trainer</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="border-bottom: 2px solid darkgray;"
                            *ngFor="let binomio of binomios; let index = index"
                            (mouseover)="jineteSeleccionado=binomio.idJinete" (mouseout)="jineteSeleccionado=''"
                            [ngClass]="{'scratched': binomio.scratched, 'bg-danger text-white': jineteSeleccionado === binomio.idJinete}">
                            <!-- <td [attr.contenteditable]="!prueba.publicada ? 'true' : 'false'" class="d-print-none"
                              (blur)="cambiarEntrada($event, index)">{{ binomio.entrada }}</td> -->
                            <td class="d-none d-print-table-cell">{{ binomio.entradaPublicada }}</td>
                            <td>{{ binomio.cucarda }}</td>
                            <td>
                              <p class="m-0">{{ binomio.caballo }}</p>
                              <p *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2' ) && mostrarInfoCaballo"
                                class="m-0 infoCaballo">
                                {{ infoCaballo(binomio) }}</p>
                            </td>
                            <td>{{ binomio.representa }} <i *ngIf="binomio.remoto" class="mdi mdi-web"></i></td>
                            <td>{{ binomio.nombreJinete }}</td>
                            <td>{{ binomio.apellidosJinete }}</td>
                            <td>{{ binomio.owner }}</td>
                            <td *ngIf="prueba.categorias.length > 1">{{ binomio.siglasCategoria }}</td>
                            <td>{{ binomio.trainer }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="col-12 text-center">
                        <img [src]="prueba.footer" style="max-width: 100%; max-height: 90px;"
                          onerror="this.style.display='none'">
                      </div>
                      <p class="d-none d-print-block pl-2 mt-3">
                        Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
