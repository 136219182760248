import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

@Injectable()
export class PrintService {
  private _isPrinting: boolean;
  onprintstatechange: any;

  constructor(private router: Router) {
    this.isPrinting = false;
    this.onprintstatechange = null;
  }

  public get isPrinting(): boolean {
    return this._isPrinting;
  }
  public set isPrinting(value: boolean) {
    this._isPrinting = value;
    if(this.onprintstatechange){
      this.onprintstatechange(value);
    }
  }

  printDocument(documentName: string, nombreReporte, idConcurso, documentData: string[]) {
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', idConcurso, nombreReporte, documentName, idConcurso, documentData.join()]
      }}]);
  }

  printClasses(idConcurso, documentData: string[], mostrarInfoCaballo: boolean = false, mostrarPaisJinete: boolean = false){
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['orders-of-go', idConcurso, documentData.join(), mostrarInfoCaballo, mostrarPaisJinete]
      }}]);
  }

  printClassSheets(idConcurso, documentData: string[], mostrarInfoCaballo: boolean = false, mostrarPaisJinete: boolean = false){
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['class-sheets', idConcurso, documentData.join(), mostrarInfoCaballo, mostrarPaisJinete]
      }}]);
  }

  printChecks(idConcurso, documentData: string[]){
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print-checks-format', idConcurso, documentData.join()]
      }}]);
  }

  printDivisions(idConcurso, documentData: string[]){
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print-divisions-format', idConcurso, documentData.join()]
      }}]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    });
  }
}
