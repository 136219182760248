import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntriesService } from '../services/entries.service';
declare let $: any;

@Component({
  selector: 'app-federation-entry-agreement',
  templateUrl: './federation-entry-agreement.component.html',
  styleUrls: ['./federation-entry-agreement.component.css']
})
export class FederationEntryAgreementComponent implements OnInit {
  public idConcurso: string;
  public entry: string;
  public riderId: string;
  public show: any;
  public emergencyContact: any;
  public horse: any;
  public riders: any[];
  public owner: any;
  public trainer: any;
  public payee: any;
  public stablingInformation: any;
  public pruebas: any[];
  public cargos: any[];

  constructor(private entriesService: EntriesService, private route: ActivatedRoute) {
    this.idConcurso = this.route.snapshot.params.idConcurso;
    this.entry = this.route.snapshot.params.entry;
    this.riderId = this.route.snapshot.params.rider||'';
    this.show = {};
    this.emergencyContact = {};
    this.horse = {};
    this.riders = [];
    this.owner = {};
    this.trainer = {};
    this.payee = {};
    this.stablingInformation = {};
    this.pruebas = [];
    this.cargos = [];
  }

  ngOnInit() {
    this.getEntryInfo();
  }

  async getEntryInfo(){
    const response = await this.entriesService.federationEntryAgreement(this.idConcurso, this.entry, this.riderId).toPromise();
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.show = response.show;
    this.emergencyContact = response.emergencyContact;
    this.horse = response.horse;
    this.riders = response.riders;
    this.owner = response.owner;
    this.trainer = response.trainer;
    this.payee = response.payee;
    this.stablingInformation = response.stablingInformation;
    this.pruebas = response.pruebas;
    this.cargos = response.cargos;
    setTimeout(() => {
      window.print();
     // window.close();
    });
  }

  public totalCargos(): number {
    if (this.cargos) {
      return this.cargos.reduce((sum, cargo) => {
        return sum + cargo.total;
      }, 0);
    }

    return 10;
  }

  public numerosPruebas(pruebas: any[]): string {
    return pruebas.map(p => p.numero).join(', ');
  }
}
