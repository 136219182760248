import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ListaService } from '../services/lista.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResultadoService } from '../services/resultado.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AppService } from '../services/app.service';
declare var $: any;

@Component({
  selector: 'app-lista-editable',
  templateUrl: './lista-editable.component.html',
  styleUrls: ['./lista-editable.component.css']
})
export class ListaEditableComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public pruebas: Array<any>;
  public ipc: string;
  public binomios: Array<any>;

  constructor(
    private authService: AuthService,
    private pruebaService: PruebaService,
    private listaService: ListaService,
    private router: Router,
    private route: ActivatedRoute,
    private resultadoService: ResultadoService,
    private appService: AppService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.ipc = '';
    this.binomios = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
    $('#loader').hide();
  }

  private getPruebas(){
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
        response => {
            if(!response.error){
                this.pruebas = response.pruebas;
                $('#loader').hide();
            } else{
                $('#loader').hide();
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
            }
        },
        error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
        }
    );
  }

  public seleccionarPrueba(){
    $('#loader').show();

    this.listaService.getBinomios(this.ipc).then(
      response => {
        if(!response.error){
          $('#loader').hide();
          this.binomios = response.binomios.map(binomio => {
            binomio.entradaPublicada = binomio.entradaPublicada.indexOf('TMP') > -1 ? 'Ex' : binomio.entradaPublicada;

            return binomio;
          });
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be consulted.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public cambiarEntrada(e, ibpc){
    $('#loader').show();
    //Asignar su numero de entrada + .5 para que se posicione correctamnete
    const entradaNueva = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) /*- .5*/ : 0;
    const index = this.binomios.findIndex(b => b.ibpc == ibpc);
    this.binomios[index].entradaPublicada = entradaNueva;
    const binomios = this.binomios.sort((a, b) => {
      const entradaA = !isNaN(parseFloat(a.entradaPublicada)) ? parseFloat(a.entradaPublicada) : 0;
      const entradaB = !isNaN(parseFloat(b.entradaPublicada)) ? parseFloat(b.entradaPublicada) : 0;

      if(entradaA > entradaB) return 1;
      if(entradaA < entradaB) return -1;

      return 0;
    }).filter(b => {
      const entrada = !isNaN(parseFloat(b.entradaPublicada)) ? parseFloat(b.entradaPublicada) : 0;

      return entrada > 0;
    }).map((b, i) => {
      b.entradaPublicada = i+1;

      return b;
    }).map(b => {
      return {
        ibpc: b.ibpc,
        idBinomio: b.idBinomio,
        ipc: this.ipc,
        idConcurso: this.idConcurso,
        entrada: b.entradaPublicada,
        remoto: b.remoto ? 1 : 0
      };
    });

    /*let incrementar = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
    //Hacer un map para darle el formato necesario al arreglo y sumar uno a su entrada
    let binomios = this.binomios.map((b, i, a) => {
      let entrada = !isNaN(parseFloat(b.entradaPublicada)) ? parseFloat(b.entradaPublicada) : 0;
      if(entrada >= entradaNueva && (incrementar - entrada) > -1 && b.ibpc != ibpc){
        entrada++;
        incrementar++;
      }

      return {
        ibpc: b.ibpc,
        idBinomio: b.idBinomio,
        ipc: this.ipc,
        idConcurso: this.idConcurso,
        entrada: entrada,
        remoto: b.remoto ? 1 : 0
      };
    });

    const index = binomios.findIndex(b => b.ibpc == ibpc);
    binomios[index].entrada = entradaNueva;*/


    this.listaService.cambiarEntrada(binomios).subscribe(
      response => {
        if(!response.error){
          this.seleccionarPrueba();
          $.NotificationApp.send("Success", "The entry number has been successfully updated.", 'bottom-right', '#0acf97', 'success');
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to modify the entry number, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public guardarFirebase(): void{
    //Actualizar listas en la app
    this.appService.updateShow(this.idConcurso, this.ipc);
    /*$('#loader').show();
    this.resultadoService.guardarFirestore(this.ipc).subscribe(
      response => {
          if(response.error){
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
              $('#loader').hide();
          }else{
              $.NotificationApp.send("Success","The class has been synchronized", 'bottom-right', '#fa5c7c', 'success');
              $('#loader').hide();
          }
      },
      error => {
          $.NotificationApp.send("Error", 'The app has not been updated.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          $('#loader').hide();
      }
    );*/
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.binomios, event.previousIndex, event.currentIndex);
    this.binomios.map((b, i) => {
        b.entradaPublicada = `${i + 1}`;
        return b;
    });
    const binomios = this.binomios.map(b => {
      return {
        ibpc: b.ibpc,
        idBinomio: b.idBinomio,
        ipc: this.ipc,
        idConcurso: this.idConcurso,
        entrada: b.entradaPublicada,
        remoto: b.remoto ? 1 : 0
      };
    });

    this.listaService.cambiarEntrada(binomios).subscribe(
      response => {
        if(!response.error){
          this.seleccionarPrueba();
          $.NotificationApp.send("Success", "The entry number has been successfully updated.", 'bottom-right', '#0acf97', 'success');
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to modify the entry number, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
