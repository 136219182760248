import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
declare let $: any;

@Component({
  selector: 'app-trainer-reservations',
  templateUrl: './trainer-reservations.component.html',
  styleUrls: ['./trainer-reservations.component.css']
})
export class TrainerReservationsComponent implements OnInit, AfterViewInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public reservations: any[];

  constructor(private router: Router, private authService: AuthService, private inscripcionesRemotasService: InscripcionesRemotasService) {
    this.reservations = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getReservations();
  }

  ngAfterViewInit(): void {

  }

  private getReservations(): void{
    this.inscripcionesRemotasService.getTrainerReservations(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.reservations = response.reservations;
          console.log(this.reservations);
          $('#loader').hide();
        } else{
          console.log(response);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }
}
