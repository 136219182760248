import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PersonaService } from '../services/persona.service';
import { StripeService } from '../services/stripe.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-riders-duplicated',
  templateUrl: './riders-duplicated.component.html',
  styleUrls: ['./riders-duplicated.component.css']
})
export class RidersDuplicatedComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public personas: any[];
  public tablePersonas: any;
  public form: UntypedFormGroup;
  public personaSeleccionada: any;

  constructor(
    private personaService: PersonaService,
    private router: Router,
    private authService: AuthService,
    private stripeService: StripeService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.personas = [];
    this.tablePersonas = {};
    this.form = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      categoria: new UntypedFormControl('', []),
      amateur: new UntypedFormControl(true, []),
      email: new UntypedFormControl('', []),
      age: new UntypedFormControl('', []),
      nacionalidad: new UntypedFormControl('', []),
      dob: new UntypedFormControl('', []),
      telefono: new UntypedFormControl('', []),
      celular: new UntypedFormControl('', []),
      genero: new UntypedFormControl('', []),
      domicilio: new UntypedFormControl('', []),
      domicilio2: new UntypedFormControl('', []),
      ciudad: new UntypedFormControl('', []),
      estado: new UntypedFormControl('', []),
      postal: new UntypedFormControl('', []),
      pais: new UntypedFormControl('', []),
      rfc: new UntypedFormControl('', []),
      razon: new UntypedFormControl('', []),
      nota: new UntypedFormControl('', []),
      stripe_customer_id: new UntypedFormControl('', [])
    });
    this.personaSeleccionada = {};
  }

  ngAfterViewInit(): void {
    this.tablePersonas = $('#personas').DataTable({
      aaSorting: [],
      columnDefs: [
        {
          targets: [0],
          visible: false
        }
      ],
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
      'createdRow': function( row, data, dataIndex ) {
        $(row).attr('id', data[0]);
      },
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      }
    });
    this.getPersonasDupicadas();
    this.clickTable();
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
  }


  public getPersonasDupicadas(){
    firstValueFrom(this.personaService.getPersonasDupicadas(this.idConcurso)).then(
      response => {
        if(!response.error){
          this.personas = response.personas.map(p => {
            p.feis = p.feis.length == 0 ? [''] : p.feis;
            p.usefs = p.usefs.length == 0 ? [''] : p.usefs;
            p.categorias = p.categorias.length == 0 ? [''] : p.categorias;
            p.amateurs = p.amateurs.length == 0 ? [''] : p.amateurs;
            p.emails = p.emails.length == 0 ? [''] : p.emails;
            p.ages = p.ages.length == 0 ? [''] : p.ages;
            p.nacionalidades = p.nacionalidades.length == 0 ? [''] : p.nacionalidades;
            p.dobs = p.dobs.length == 0 ? [''] : p.dobs;
            p.telefonos = p.telefonos.length == 0 ? [''] : p.telefonos;
            p.celulares = p.celulares.length == 0 ? [''] : p.celulares;
            p.generos = p.generos.length == 0 ? [''] : p.generos;
            p.domicilios = p.domicilios.length == 0 ? [''] : p.domicilios;
            p.domicilios2 = p.domicilios2.length == 0 ? [''] : p.domicilios2;
            p.ciudades = p.ciudades.length == 0 ? [''] : p.ciudades;
            p.estados = p.estados.length == 0 ? [''] : p.estados;
            p.postales = p.postales.length == 0 ? [''] : p.postales;
            p.paises = p.paises.length == 0 ? [''] : p.paises;
            p.rfcs = p.rfcs.length == 0 ? [''] : p.rfcs;
            p.razones = p.razones.length == 0 ? [''] : p.razones;
            p.notas = p.notas.length == 0 ? [''] : p.notas;
            p.stripe_customer_ids = p.stripe_customer_ids.length == 0 ? [''] : p.stripe_customer_ids;

            return p;
          });
          console.log(response.personas);
          this.tablePersonas.clear().draw();
          this.tablePersonas.rows.add(this.personas.map(p => [p.ids.join('-'), p.name])).draw(false);
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private clickTable(){
    $('#personas tbody').on('click', 'tr', async (event) => {
      const ids = event.currentTarget.getAttribute('id');
      const index = this.personas.findIndex(p => p.ids.join('-') == ids);

      this.personaSeleccionada = this.personas[index];

      //Consulta metodos de pago stripe, para validar cual stripe_customer_id se debe de seleccionar
      const customerIds = (this.personaSeleccionada.stripe_customer_ids||[]).filter(customerId => customerId);
      let stripeCustomers = [];
      for(const customerId of customerIds){
        const { data, error } = await this.stripeService.getCustomerPaymentMethods(customerId, `${this.idConcurso}`);
        if(error) return;

        stripeCustomers.push({
          customerId,
          paymentMethods: data
        });
      }

      stripeCustomers = stripeCustomers.sort((a, b) => b.paymentMethods.length - a.paymentMethods.length);
      const [stripeCustomer]: any = stripeCustomers;
      let selectedCustomerId = this.personaSeleccionada.stripe_customer_ids[0];
      if(stripeCustomer && stripeCustomer.customerId){
        selectedCustomerId = stripeCustomer.customerId;
      }

      this.form.setValue({
        fei: this.personaSeleccionada.feis[0],
        usef: this.personaSeleccionada.usefs[0],
        categoria: this.personaSeleccionada.categorias[0],
        amateur: this.personaSeleccionada.amateurs.indexOf('1') > -1,
        email: this.personaSeleccionada.emails[0],
        age: this.personaSeleccionada.ages[0],
        nacionalidad: this.personaSeleccionada.nacionalidades[0],
        dob: this.personaSeleccionada.dobs[0],
        telefono: this.personaSeleccionada.telefonos[0],
        celular: this.personaSeleccionada.celulares[0],
        genero: this.personaSeleccionada.generos[0],
        domicilio: this.personaSeleccionada.domicilios[0],
        domicilio2: this.personaSeleccionada.domicilios2[0],
        ciudad: this.personaSeleccionada.ciudades[0],
        estado: this.personaSeleccionada.estados[0],
        postal: this.personaSeleccionada.postales[0],
        pais: this.personaSeleccionada.paises[0],
        rfc: this.personaSeleccionada.rfcs[0],
        razon: this.personaSeleccionada.razones[0],
        nota: this.personaSeleccionada.notas[0],
        stripe_customer_id: selectedCustomerId
      });
    });
  }

  public merge(){
    $('#loader').show();
    firstValueFrom(this.personaService.merge(this.personaSeleccionada.ids, this.form.value)).then(
      response => {
        if(!response.error){
          this.personaSeleccionada = {};
          $.NotificationApp.send("Success", 'People have merged successfully ', 'bottom-center', '#3c763d', 'success');
          this.getPersonasDupicadas();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to merge this person.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
