<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu class="navbar-custom" [concurso]="nombreConcurso" [idConcurso]="idConcurso"></menu>
      <div class="row justify-content-md-center margin-top-35 hidden-print" style="margin-bottom:15px;">
        <div class="col-8" style="background-color:#fafbfe;">
          <div class="card">
            <div class="card-header">
              <h3>Create announcement</h3>
            </div>
            <div class="card-body">
              <form #announcementForm="ngForm" (ngSubmit)="createAnnouncement()">
                <div class="row2">
                  <div class="form-group">
                    <label>Title</label>
                    <input type="text" placeholder="type the title of the announcement" class="form-control" (keyup)="enabledButton()" [formControl]="titleAnnouncements">
                  </div>
                </div>              
                <div class="row2">
                  <div class="form-group">
                    <label>Body</label>
                    <input type="text" placeholder="type the body of the announcement" class="form-control" (keyup)="enabledButton()" [formControl]="bodyAnnouncements">
                  </div>
                </div>
                <div class="row2">
                  <div class="form-group">
                    <button type="submit" class="btn btn-outline-success" id="submitButton" disabled>Create</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
