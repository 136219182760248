import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ContabilidadService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getTotalBolsaPremio(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/dato_total_concurso/`, params, {headers: headers});
  }

  public getFilasBolsaPremio(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/dato_total_concurso/rows.php`, params, {headers: headers});
  }

  public getFilasBolsaPremioPorSemana(idConcurso, semana, numero): Observable<any>{
    const params = JSON.stringify({ id: idConcurso, semana: semana, numero: numero });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/dato_total_concurso/rows_por_semana.php`, params, {headers: headers});
  }

  public getIngresos(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/ingresos/`, params, {headers: headers});
  }

  public getIngresosPorSemana(idConcurso, semana, numero): Observable<any>{
    const params = JSON.stringify({ id: idConcurso, semana: semana, numero: numero });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/ingresosPorSemana/`, params, {headers: headers});
  }

  public getPaymentReceived(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pagos/recibidos/`, params, {headers: headers});
  }

  public setPaymentReceivedNotes(destino): Observable<any>{
    const params = JSON.stringify(destino);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pagos/recibidos/update/`, params, {headers: headers});
  }

  public getPaymentToReceive(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pagos/por_recibir/`, params, {headers: headers});
  }

  public setPaymentReceived(destino): Observable<any>{
    const params = JSON.stringify(destino);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pagos/por_recibir/update/`, params, {headers: headers});
  }

  public getTotalPayments(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pagos/totales/`, params, {headers: headers});
  }

  public getAllExpenses(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/gastos/`, params, {headers: headers});
  }

  public getAllExpensesPorSemana(idConcurso, semana, numero): Observable<any>{
    const params = JSON.stringify({ id: idConcurso, semana: semana, numero: numero });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/gastosPorSemana/`, params, {headers: headers});
  }

  public setActionExpenses(destino): Observable<any>{
    const params = JSON.stringify(destino);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/gastos/add.php`, params, {headers: headers});
  }

  public getAllPrizesPerTest(idConcurso): Observable<any>{
    const params = JSON.stringify({ id: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/pruebas/`, params, {headers: headers});
  }

  public getDetalleMetodo(idConcurso, metodo): Observable<any>{
    const params = JSON.stringify({idConcurso, metodo});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}contabilidad/detalle-metodo/`, params, {headers: headers});
  }
}
