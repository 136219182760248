<title>Trainer reservations</title>
<div id="loader" style="display:block; opacity: 1; background: rgba(255, 255, 255, 0.4); z-index: 1051;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row justify-content-center">
                    <div class="col-10 mt-3">
                        <div class="card">
                            <div class="card-body p-2">
                                <table class="table table-sm table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Trainer</th>
                                            <th>Status</th>
                                            <th>Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let reservation of reservations" style="cursor: pointer;" [routerLink]="['/trainer-reservation', reservation.id]"
                                        routerLinkActive="router-link-active">
                                            <td>{{ reservation.id }}</td>
                                            <td>{{ reservation.trainer }}</td>
                                            <td>
                                                <span [ngSwitch]="reservation.status">
                                                    <span *ngSwitchCase="'0'">Pending</span>
                                                    <span *ngSwitchCase="'1'">Accepted</span>
                                                    <span *ngSwitchDefault>{{ reservation.status | date }}</span>
                                                </span>
                                            </td>
                                            <td>{{ reservation.created }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
