import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PruebaService } from '../services/prueba.service';
import { PrintService } from '../services/print.service';
import { ListaService } from '../services/lista.service';
import { EntriesService } from '../services/entries.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
declare let $: any;

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.css']
})
export class DivisionsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public form: FormGroup;
  public divisions: any[];
  public classes: any[];
  public idDivisionEliminar: string;
  public mergeDivision1: string;
  public mergeDivision2: string;
  public formMerge: FormGroup;
  public reining: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private pruebaService: PruebaService,
    private printService: PrintService,
    private listaService: ListaService,
    private entriesService: EntriesService,
    private database: AngularFirestore
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      cost: new FormControl('', [Validators.required]),
      type: new FormControl('hunters', [Validators.required]),
      charged: new FormControl('class-fees', [Validators.required]),
      section_code: new FormControl('', [])
    });
    this.divisions = [];
    this.classes = [];
    this.idDivisionEliminar = '';
    this.mergeDivision1 = '';
    this.mergeDivision2 = '';
    this.formMerge = new FormGroup({
      number: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required]),
      usefSectionCode: new FormControl('', [Validators.required]),
      keepClassNumbers: new FormControl(true, [Validators.required]),
      keepDivisions: new FormControl(false, [Validators.required]),
    });
    this.reining = sessionStorage.getItem('reining') == '1';
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getDivisions();
    //TODO: Eliminar
    this.getClasses();
  }

  public async getDivisions() {
    return this.pruebaService.getDivisions(this.idConcurso).toPromise().then(
      response => {
        if (!response.error) {
          this.divisions = response.divisiones.map(d => {
            d.created = d.created.replace(/-/g, '/');
            return d;
          });
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the divisions list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
    $('#loader').hide();
  }

  public async getClasses() {
    this.pruebaService.getPruebasList(this.idConcurso).toPromise().then(
      response => {
        if (!response.error) {
          this.classes = response.pruebas;
          console.log(this.classes);
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public async addDivision() {
    let divisionIndex = this.divisions.findIndex( d => this.form.get('code').value == d.code);
    if (this.form.valid && divisionIndex == -1) {
      $('#loader').show();
      let response = await this.pruebaService.addDivision(this.form.value, this.idConcurso).toPromise();
      if(response.error) {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        console.log(response.message);
        $('#loader').hide();
        return
      }
      response.division.pruebas = [];
      this.divisions.unshift(response.division);
      this.form.setValue({
        name: '',
        code: '',
        cost: '',
        charged: '',
        type: 'hunters',
        section_code: ''
      });
      $('form').removeClass('was-validated');
      $('#loader').hide();
    } else {
      if( divisionIndex != -1 ) {
        $.NotificationApp.send("Error", "This division number already exists.", 'bottom-right', '#fa5c7c', 'error');
      }
    }
  }

  public addClass(e, i): void {
    const num = e.target.value;
    if (num) {
      //TODO: Agregar tipo de prueba hunters o jumpers al filtro
      const prueba = this.classes.find(c => c.numero == num);
      if (prueba) {
        //TODO: Validar pruebas repetidas
        this.divisions[i].pruebas.push({
          ipc: prueba.ipc,
          numero: prueba.numero,
          nombre: prueba.nombre,
          altura: prueba.altura
        });
        e.target.value = '';
      } else {
        e.target.value = '';
        $.NotificationApp.send("Error", "There is no class with that number.", 'bottom-right', '#fa5c7c', 'error', 10000);
      }
    }
  }

  public removeClass(i, j) {
    this.divisions[i].pruebas.splice(j, 1);
  }

  public modalRemoveDivision(id) {
    this.idDivisionEliminar = id;
    $('#modal-eliminar-division').modal('show');
  }

  public deleteDivision() {
    this.pruebaService.deleteDivision(this.idDivisionEliminar).subscribe(
      response => {
        if (!response.error) {
          const index = this.divisions.findIndex(d => d.id == this.idDivisionEliminar);
          this.divisions.splice(index, 1);
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to delete the division.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public printDivisions(){
    //this.printService.printDivisions(this.idConcurso, this.divisions.map(d => d.id));
    this.printService.printDocument('print-divisions-format', 'Division', this.idConcurso, this.divisions.map(d => d.id));
  }

  public printDivisionCharts(){
    this.router.navigate(['/divisionCharts', this.divisions.map(d => d.id).join(',')]);
  }

  public async showMergeDivisions(){
    //Mostrar modal para seleccionar las divisiones a fusionar
    this.mergeDivision1 = '';
    this.mergeDivision2 = '';
    this.formMerge.setValue({
      number: '',
      name: '',
      height: '',
      usefSectionCode: '',
      keepClassNumbers: true,
      keepDivisions: false
    });
    $('#formMerge').removeClass('was-validated');
    $('#modal-merge-divisions').modal('show');
  }

  public getDivisionClasses(id): any[]{
    const division = this.divisions.find(d => d.id == id);
    if(!division) return [];
    return division.pruebas.map(p => this.classes.find(c => c.ipc == p.ipc)||p);
  }

  public getMergeDivisionClasses(): any[] {
    const division1 = [...this.divisions].find(d => d.id == this.mergeDivision1);
    const division2 = [...this.divisions].find(d => d.id == this.mergeDivision2);
    const mergedClasses = division1.pruebas.reduce((acc, c, i) => [...acc, {
      d1: this.classes.find(cl => cl.ipc == c.ipc)||c,
      d2: this.classes.find(cl => division2.pruebas[i] && cl.ipc == division2.pruebas[i].ipc)||(division2.pruebas[i]||{})
    }], []);
    const [lastNumber] = this.classes.map(c => parseInt(c.numero.replace(/[A-Za-z]|\s/g,''))).sort((a, b) => { if(a == b) return 0; return a < b ? 1 : -1; });
    return mergedClasses.map((c, i) => {
      const numero = this.formMerge.value.keepClassNumbers ? c.d1.numero : (lastNumber+i+1);
      const nombre = this.formMerge.value.name||(c.d1.nombre||c.d2.nombre);
      const altura = this.formMerge.value.height||(c.d1.altura||c.d2.altura);
      const usef_section  = this.formMerge.value.usefSectionCode||(c.d1.usef_section||c.d2.usef_section);
      return { ...c.d1, nombre, numero, altura, usef_section, ipc: null, ipcD1: c.d1.ipc, ipcD2: c.d2.ipc};
    });
  }

  public selectMergeDivision(id){
    if(!id) {
      this.formMerge.setValue({
        number: '',
        name: '',
        height: '',
        usefSectionCode: '',
        keepClassNumbers: true,
        keepDivisions: false
      });
      return;
    };
    const division1 = [...this.divisions].find(d => d.id == this.mergeDivision1);
    if(!division1){
      this.formMerge.setValue({
        number: '',
        name: '',
        height: '',
        usefSectionCode: '',
        keepClassNumbers: true,
        keepDivisions: false
      });
      return;
    }
    const usefSectionCode = division1.pruebas.map(p => (this.classes.find(c => c.ipc == p.ipc)||p)['usef_section']).filter(c => c && c != '0').reduce((p, c, i, a) => {
      return a.filter(v => c == v).length > a.filter(v => p == v).length ? c : p;
    }, '');
    const altura = division1.pruebas.map(p => (this.classes.find(c => c.ipc == p.ipc)||p)['altura']).filter(c => c && c != '0').reduce((p, c, i, a) => {
      return a.filter(v => c == v).length > a.filter(v => p == v).length ? c : p;
    }, '');
    this.formMerge.setValue({
      number: division1.code||this.formMerge.value.number,
      name: division1.name||this.formMerge.value.name,
      height: altura||this.formMerge.value.height,
      usefSectionCode: usefSectionCode||this.formMerge.value.usefSectionCode,
      keepClassNumbers: this.formMerge.value.keepClassNumbers,
      keepDivisions: this.formMerge.value.keepDivisions
    });
  }

  public async mergeDivisions(){
    $('#formMerge').addClass('was-validated');
    if(this.formMerge.valid && this.mergeDivision1 && this.mergeDivision2){
      this.loadingMergeDivisions();
      const batch = this.database.firestore.batch();
      const mergeDivision1 = this.divisions.find(d => d.id == this.mergeDivision1);
      const mergeDivision2 = this.divisions.find(d => d.id == this.mergeDivision2);
      const mergedDivision = {
        name: this.formMerge.value.name,
        code: this.formMerge.value.number,
        cost: mergeDivision1.cost||'',
        type: mergeDivision1.type||'',
        charged: mergeDivision1.charged_by||''
      };
      /** Create new division */
      const response = await this.pruebaService.addDivision(mergedDivision, this.idConcurso).toPromise();
      if(response.error){
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        console.log(response.message);
        this.loadingMergeDivisions(false);
        return;
      }
      let newDivision = response.division;
      /** Create new classes */
      const classes = this.getMergeDivisionClasses().map(c => this.pruebaService.createPrueba({
        altura: c.altura||'',
        categorias: c.categorias||[],
        costo: c.costo||'',
        designer: c.designer||'',//TODO: Cambiar por designer_id, el campo no esta en el listado de pruebas
        inicio: c.inicio||null,
        juez: c.juez||'',
        modalidad: c.modalidad||'',
        nombre: c.nombre||'',
        nombreModalidad: c.nombreModalidad||'',
        numero: `${c.numero||''}-m`,
        description: c.description||'',
        pista: c.pista||'',
        premio: c.premio||'0',
        video: c.video ? 1 : 0,
        youtube: c.youtube||'',
        imgPatrocinador: c.sponsorImage||'',
        fei_competition_id: c.competitionID||'',
        fei_event_id: c.eventId||'',
        usef_section: c.usef_section||'',
        hard_start: c.hard_start ? 1 : 0,
        overall: c.overall ? 1 : 0,
        croquis: c.croquis||'',
        cargosPrueba: c.charges||[],
        prize_distribution: c.id_prize_distribution||'',
        division: newDivision.id||'',
        height_unit: c.height_unit||''
      }, this.idConcurso).toPromise());
      let newClasses: any[] = await Promise.all(classes).then((values: any[]) => values.map((r) => {
        return {
          message: r.error ? r.message : null,
          ipc: r.ipc||null
        };
      })).catch(error => {
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        this.loadingMergeDivisions(false);
        return [{
          message: error,
          ipc: null
        }];
      });
      if(newClasses.filter(c => c.message).length > 0){
        $.NotificationApp.send("Error", newClasses.filter(c => c.message).map(c => c.message), 'bottom-right', '#fa5c7c', 'error');
        this.loadingMergeDivisions(false);
        return;
      }
      newClasses = this.getMergeDivisionClasses().map((v, i) => ({ ...v, ipc: newClasses[i].ipc, division: newDivision.id||'' }));
      /** Get entries from merged classes */
      for(const i in newClasses){
        newClasses[i].entries = [];
        if(newClasses[i].ipcD1){
          const entries = await this.listaService.getBinomios(newClasses[i].ipcD1);
          if(entries.error){
            $.NotificationApp.send("Error", entries.message, 'bottom-right', '#fa5c7c', 'error');
            this.loadingMergeDivisions(false);
            return;
          }
          newClasses[i].entries = entries.binomios;
        }
        if(newClasses[i].ipcD2){
          const entries2 = await this.listaService.getBinomios(newClasses[i].ipcD2);
          if(entries2.error){
            $.NotificationApp.send("Error", entries2.message, 'bottom-right', '#fa5c7c', 'error');
            this.loadingMergeDivisions(false);
            return;
          }
          newClasses[i].entries = [...newClasses[i].entries, ...entries2.binomios];
        }
        /** Remove duplicates */
        newClasses[i].entries = newClasses[i].entries.filter((e, i, a) => a.findIndex(b => b.idBinomio == e.idBinomio) == i);
      }
      /** Add entries to new classes */
      const inscripciones = newClasses.reduce((p, c, i, a) => {
        return [...p, ...c.entries.map(e => ({
          idBinomio: e.idBinomio||'',
          ipc: c.ipc,
          idUsuario: this.idUsuario,
          idConcurso: this.idConcurso,
          idCategoria: '0',
          entry: c.cucarda||''
        }))];
      }, []).map(e => this.entriesService.inscribir(e).toPromise());
      let newEntries: any[] = await Promise.all(inscripciones).then((values: any[]) => values.map((r) => {
        return {
          message: r.error ? r.message : null,
          accion: r.accion||null
        };
      })).catch(error => {
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        this.loadingMergeDivisions(false);
        return [{
          message: error,
          accion: null
        }];
      });
      if(newEntries.filter(c => c.message).length > 0){
        $.NotificationApp.send("Error", newEntries.filter(c => c.message).map(c => c.message), 'bottom-right', '#fa5c7c', 'error');
        this.loadingMergeDivisions(false);
        return;
      }
      /** Delete previous divisions */
      if(!this.formMerge.value.keepDivisions){
        /** Delete previous classes Division1 MySQL */
        const responseDeleteMergedDivision1 = await this.pruebaService.deleteDivision(this.mergeDivision1, true).toPromise();
        if(responseDeleteMergedDivision1.error){
          $.NotificationApp.send("Error", responseDeleteMergedDivision1.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(responseDeleteMergedDivision1.message);
          this.loadingMergeDivisions(false);
          return;
        }
        /** Delete previous classes Division2 MySQL */
        const responseDeleteMergedDivision2 = await this.pruebaService.deleteDivision(this.mergeDivision2, true).toPromise();
        if(responseDeleteMergedDivision2.error){
          $.NotificationApp.send("Error", responseDeleteMergedDivision2.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(responseDeleteMergedDivision2.message);
          this.loadingMergeDivisions(false);
          return;
        }

        //Delete previous classes Division1 & Division2 Firestore
        for(const prueba of [...mergeDivision1.pruebas, ...mergeDivision2.pruebas]){
          batch.delete(this.database.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${prueba.ipc}`).ref);
        }
        await batch.commit();
        this.loadingMergeDivisions(false);

        /** Check if keep class numbers and delete previos divisions are selected */
        if(this.formMerge.value.keepClassNumbers){
          //Actualzar numero de pruebas y quitar la -m temporal que se agregar al final del numero
          const newClassesUpdatePromises = newClasses.map(c => this.pruebaService.updatePrueba({
            altura: c.altura||'',
            categorias: c.categorias||[],
            costo: c.costo||'',
            designer: c.designer||'',//TODO: Cambiar por designer_id, el campo no esta en el listado de pruebas
            inicio: c.inicio||null,
            juez: c.juez||'',
            modalidad: c.modalidad||'',
            nombre: c.nombre||'',
            nombreModalidad: c.nombreModalidad||'',
            numero: `${c.numero||''}`.replace(/-m$/,''),
            description: c.description||'',
            pista: c.pista||'',
            premio: c.premio||'0',
            video: c.video||0,
            youtube: c.youtube||'',
            imgPatrocinador: c.sponsorImage||'',
            fei_competition_id: c.competitionID||'',
            fei_event_id: c.eventId||'',
            usef_section: c.usef_section||'',
            hard_start: c.hard_start ? 1 : 0,
            overall: c.overall ? 1 : 0,
            croquis: c.croquis||'',
            cargosPrueba: c.charges||[],
            prize_distribution: c.id_prize_distribution||'',
            division: c.division||'',
            height_unit: c.height_unit||''
          }, this.idConcurso).toPromise());

          let newClassesUpdated: any[] = await Promise.all(newClassesUpdatePromises).then((values: any[]) => values.map((r) => {
            return {
              message: r.error ? r.message : null
            };
          })).catch(error => {
            $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
            this.loadingMergeDivisions(false);
            return [{
              message: error
            }];
          });
          if(newClassesUpdated.filter(c => c.message).length > 0){
            $.NotificationApp.send("Error", newClassesUpdated.filter(c => c.message).map(c => c.message), 'bottom-right', '#fa5c7c', 'error');
            this.loadingMergeDivisions(false);
            return;
          }
        }
      }
      await this.getDivisions();
      await this.getClasses();
      this.loadingMergeDivisions(false);
      $('#modal-merge-divisions').modal('hide');
      $.NotificationApp.send("Merged", "Divisions were merged successfully.", 'bottom-right', '#06d5a1', 'success');
    }
  }

  public loadingMergeDivisions(loading = true){
    if(loading){
      this.formMerge.disable();
      $('#mergeDivision1').attr('disabled', 'disabled');
      $('#mergeDivision2').attr('disabled', 'disabled');
      $('#submit-merge').attr('disabled', 'disabled');
      $('#loader-merge').removeClass('d-none');
    } else{
      this.formMerge.enable();
      $('#mergeDivision1').removeAttr('disabled');
      $('#mergeDivision2').removeAttr('disabled');
      $('#submit-merge').removeAttr('disabled');
      $('#loader-merge').addClass('d-none');
    }
  }
}
