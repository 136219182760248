import { Component, OnInit } from '@angular/core';
import { PruebaService } from '../services/prueba.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ResultadoService } from '../services/resultado.service';

@Component({
  selector: 'app-resultados-firestore',
  templateUrl: './resultados-firestore.component.html',
  styleUrls: ['./resultados-firestore.component.css']
})
export class ResultadosFirestoreComponent implements OnInit {
  public pruebas: any[];

  constructor(private pruebaService: PruebaService, private db: AngularFirestore, private resultadoService: ResultadoService) {
    this.pruebas = [];
  }

  ngOnInit() {
    this.getPruebas();
  }

  private async getPruebas(){
    const pruebas = await this.pruebaService.getPruebasList(sessionStorage.concurso).toPromise().then(r => r.pruebas);
    const inscritos = await this.pruebaService.getInscritos(sessionStorage.concurso).toPromise().then(r => r.pruebas);
    for(const prueba of pruebas.filter(p => p.estatus_prueba == '2')){
      const resultados = await this.db.collection('resultados').doc(`us_${prueba.ipc}`).collection('resultados').get().toPromise().then(r => r.docs.map(d  => d.data()));
      if(resultados.length > (inscritos[prueba.ipc]||0)){
        //Consulta resultados de mysql
        const resultadosSQL: any[] = await this.resultadoService.getResultados(prueba.ipc).toPromise().then(r => r.resultados);
        //Validar si el resultado de firebase esta en mysql
        this.pruebas.push({ipc: parseInt(prueba.ipc), numero: prueba.numero, nombre: prueba.nombre, inscritosFS: resultados.length, inscritosSQL: inscritos[prueba.ipc]||0, diferencia: resultados.length - (inscritos[prueba.ipc]||0), diferencias: resultados.filter(r => resultadosSQL.findIndex(rs => rs.idBinomio == r.id_binomio.replace(/us_/g,'')) == -1)});
        console.log(this.pruebas);
      }
      
    }
  }

}
