<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <ng-container *ngTemplateOutlet="modalBorrarResultados"></ng-container>
      <ng-container *ngTemplateOutlet="modalSplitClass"></ng-container>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 m-print-0 justify-content-center">
          <div class="col-11 col-print-12">
            <div class="row">
              <div class="card w-100">
                <div class="card-body p-print-0">
                  <div class="col-12 d-print-none">
                    <div class="d-flex justify-content-between">
                      <div class="form-group mb-0">
                        <select class="form-control" (change)="seleccionarPrueba($event)">
                          <option value="" selected disabled>Select a Class</option>
                          <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                        </select>
                      </div>
                      <div class="form-group mb-0 ml-auto">
                        <button *ngIf="prueba.estatus_prueba == '0'" class="btn btn-info btn-sm mr-3" (click)="showSplitClassModal()">Split class</button>
                        <button *ngIf="prueba.publicada" class="btn btn-danger btn-sm mr-3" (click)="ocultarPublicacion()">
                          <i class=" mdi mdi-cancel"></i>
                          <span>unPublish (this Class)</span>
                        </button>
                        <button *ngIf="!prueba.publicada" class="btn btn-danger btn-sm mr-3" (click)="checkResultsOrder()">
                          <i class=" mdi mdi-publish"></i>
                          <span>Publish (this Class)</span>
                        </button>
                        <ng-container *ngIf="ipc && binomios.length > 0">
                          <button class="btn btn-info btn-sm mr-3" (click)="sorteoEquilibrado()" *ngIf="!prueba.publicada">
                            <i class="mdi mdi-scale-balance"></i>
                            <span>Auto Sort</span>
                          </button>
                          <button class="btn btn-success btn-sm mr-3" (click)="imprimirLista()">
                            <i class="mdi mdi-printer"></i>
                            <span>Print</span>
                          </button>
                          <button class="btn btn-success btn-sm mr-3" (click)="export_csv()">
                            <i class="mdi mdi-download"></i>
                            <span>Export</span>
                          </button>
                          <button class="btn btn-success btn-sm" (click)="guardarLista()">
                            <i class="mdi mdi-content-save"></i>
                            <span>Save</span>
                          </button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3 d-print-none">
                    <div class="form-group">
                      <mat-form-field class="example-chip-list mr-3" style="width: 50%;">
                        <mat-chip-list #chipList aria-label="Class selection">
                          <mat-chip *ngFor="let class of classes" [selectable]="true" [removable]="true" (removed)="remove(class.ipc)">
                            {{class.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Print multiple classes" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                      </mat-form-field>
                      <button class="btn btn-info" *ngIf="classes.length > 0" (click)="printClasses()">
                        <i class="mr-1 mdi mdi-printer"></i>
                        Print
                      </button>
                      <div class="form-group row mb-0 ml-auto" *ngIf="(ipc && binomios.length > 0) || classes.length > 0" style="margin-top:15px;">
                        <div class="col-2">
                          <label class="my-auto mr-2">Horse info: </label><br>
                          <input type="checkbox" id="infoCaballo" data-switch="success" (change)="showInfoCaballo($event)">
                          <label for="infoCaballo" [attr.data-on-label]="'Yes'" data-off-label="No" class="my-auto"></label>
                        </div>
                        <div class="col-2">
                          <label class="my-auto mr-2">Rider country: </label><br>
                          <input type="checkbox" id="paisJinete" data-switch="success"
                            (change)="showPaisJinete($event)">
                          <label for="paisJinete" [attr.data-on-label]="'Yes'" data-off-label="No"
                            class="my-auto"></label>
                        </div>
                        <div class="offset-6 col-2" *ngIf="ipc && binomios.length > 0">
                          <label class="my-auto mr-2">Time block </label><br>
                          <input type="number" min="0" class="form-control form-control-sm mr-2" placeholder="Minutes"
                            (change)="agregarReceso($event)" style="max-width: 160px;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4 m-print-0" *ngIf="ipc && !prueba.publicada">
                    <input type="text" class="form-control" placeholder="Add entry" (change)="addEntry($event)">
                  </div>
                  <div class="col-12 mt-4 m-print-0" *ngIf="binomios.length > 0">
                    <div class="row border">
                      <div class="d-flex text-center align-items-center w-100">
                        <div style="flex: 1;">
                          <img [src]="prueba.sponsorImage ? prueba.sponsorImage : prueba.header2" style="max-width: 100%; max-height: 90px; float:left; margin-left: 15px;" onerror="this.style.display='none'">
                        </div>
                        <div style="flex: 1;">
                          <h3><span>Order of Go</span> <span *ngIf="prueba.publicada" class="badge badge-success-lighten d-print-none">Published</span></h3>
                          <p>{{ nombreConcurso }}
                            <br><b>{{ prueba.numero }}</b> {{ prueba.nombre }}
                          </p>
                        </div>
                        <div style="flex: 1;">
                          <img [src]="prueba.header ? prueba.header : prueba.logoUrl"
                            style="max-height: 90px; float:right; margin-right:15px;"
                            onerror="this.style.display='none'">
                        </div>


                      </div>
                      <div class="d-flex w-100">
                        <p class="p-2 mb-0" style="flex: 1;">
                          <b>Date:</b> {{ prueba.inicio | date : 'd MMMM yyyy' | titlecase }}
                          <br><b>Designer:</b> {{ prueba.designer }}
                          <br *ngIf="prueba.premio > 0"><b *ngIf="prueba.premio > 0">Prize:</b> <span
                            *ngIf="prueba.premio > 0">{{ prueba.premio | currency }}</span>
                          <!-- <br><b>Judge:</b> {{ prueba.nombreJuez }} -->
                        </p>
                        <p class="p-2 mb-0" style="flex: 1;">
                          <b>Article / Table:</b> {{ prueba.nombreModalidad }}
                          <br><b>Level:</b>
                          <span *ngIf="prueba.ambito == '1'">Regional</span>
                          <span *ngIf="prueba.ambito == '2'">National</span>
                          <span *ngIf="prueba.ambito == '3'">CSI</span>
                          <span *ngIf="prueba.ambito == '4'">CSI-Amateur</span>
                          <span *ngIf="prueba.ambito == '5'">FEI Ranking</span>
                          <br><b>Height:</b> {{ prueba.altura }}
                          <br><b>Total:</b> {{ binomios.length }}
                        </p>
                      </div>

                      <!-- Tabla para Nacionales USEF -->
                      <table id="USEF-National" class="table t-4 USEF-National" *ngIf="prueba.ambito < 3"
                        style="margin-top: 0px !important">
                        <thead>
                          <tr>
                            <th>
                              <p class="m-0">Order</p>
                            </th>
                            <th>
                              <p class="m-0">Horse</p>
                            </th>
                            <th>
                              <p class="m-0">Rider</p>
                            </th>
                            <th>
                              <p class="m-0">Owner</p>
                            </th>
                            <th colspan="2">
                              <p class="m-0">Trainer</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="list-drag-drop" cdkDropList [cdkDropListData]="binomios" (cdkDropListDropped)="drop($event)">
                          <ng-container *ngFor="let binomio of binomios; let index = index">
                            <tr *ngIf="binomio.tipo == 'binomio'; else rowTiempo" (mouseover)="jineteSeleccionado=binomio.idJinete" (mouseup)="jineteSeleccionado=binomio.idJinete" (mouseout)="jineteSeleccionado=''" [ngClass]="{'scratched': binomio.scratched, 'repetido': validarRepetido(binomio.idJinete), 'bg-danger text-white': jineteSeleccionado === binomio.idJinete}" cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="prueba.publicada">
                              <td [attr.contenteditable]="!prueba.publicada ? 'true' : 'false'" class="d-print-none" (blur)="cambiarEntrada($event, index, binomio)"> {{ binomio.entrada }}</td>
                              <td class="d-none d-print-table-cell">{{ binomio.entradaPublicada }} </td>
                              <td>
                                <p class="m-0"><b>{{ binomio.cucarda }}</b> {{ binomio.caballo | uppercase }}
                                  <span class="badge badge-secondary-lighten" style="vertical-align:text-top">{{binomio.height_category}}</span>
                                </p>
                                <div *ngIf="mostrarInfoCaballo" class="m-0 infoCaballo" [ngClass]="{'d-print-none': !mostrarInfoCaballo}">
                                  {{ infoCaballo(binomio) }}
                                </div>
                              </td>
                              <td>
                                <span *ngIf="mostrarPaisJinete && binomio.country !='-'"> ({{ binomio.country }})
                                </span>
                                {{ binomio.nombreJinete | titlecase }} {{ binomio.apellidosJinete | titlecase}} <span
                                  class="badge badge-pill border" style="font-size: .575rem;"
                                  *ngIf="binomio.categoria">{{
                                  binomio.categoria.substring(0, 2) }}</span>
                              </td>
                              <td>{{ binomio.owner | titlecase}}</td>
                              <td>{{ binomio.trainer | titlecase}}</td>
                              <td>
                                <a *ngIf="!prueba.publicada" href="javascript:void(0)" [ngClass]="{'disabled': validated}" class="mr-1 d-print-none"
                                (click)="desinscribir(binomio)">
                                <span class="badge badge-danger badge-pill">drop</span>
                              </a>
                              </td>
                            </tr>
                            <ng-template #rowTiempo>
                              <tr [ngClass]="{'row-drag-drop': !prueba.publicada}" class="d-print-none" cdkDrag
                                cdkDragLockAxis="y" [cdkDragDisabled]="prueba.publicada">
                                <td colspan="11">
                                  <div class="d-flex justify-content-center">
                                    <input type="text" class="form-control form-control-sm" style="max-width: 200px;"
                                      [value]="binomio.nombre" (change)="cambiarNombreReceso($event, binomio)">
                                    <h4 class="my-auto ml-2"> {{ binomio.minutos }} minutes.</h4>
                                    <a href="javascript:;" class="my-auto ml-2" style="font-size: 1.125rem;"
                                      (click)="eliminarReceso(index)"><i class="mdi mdi-trash-can-outline"></i></a>
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </ng-container>
                        </tbody>
                      </table>

                      <!-- Tabla para CSI USA-->
                      <table id="USEF-CSI" class="table print mt-1 USEF-CSI" *ngIf="prueba.ambito == 3"
                        style="margin-top: 0px; table-layout: fixed;">
                        <thead>
                          <tr>
                            <th style="width:40px">
                              <p class="m-0 text-blue"># </p>
                            </th>
                            <th style="width:60px">
                              <p class="m-0 text-blue">Back#</p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Horse</p>
                            </th>
                            <th style="width:70px">
                              <p class="m-0 text-blue">Country </p>
                            </th>
                            <th>
                              <p class="m-0 text-blue">Rider </p>

                            </th>
                            <th>
                              <p class="m-0 text-blue">Trainer </p>

                            </th>
                            <th>
                              <p class="m-0 text-blue">Owner </p>
                            </th>
                            <!-- <th style="width: 100px;">
                              <p class="m-0 text-blue">Notes</p>
                            </th> -->
                            <!-- <th *ngIf="prueba.fases == '2'" style="width: 100px;">
                              <p class="m-0 text-blue">Score 2</p>
                            </th> -->
                          </tr>
                        </thead>
                        <tbody class="list-drag-drop" cdkDropList [cdkDropListData]="binomios" (cdkDropListDropped)="drop($event)">
                          <ng-container *ngFor="let binomio of binomios; let index = index">
                            <tr *ngIf="binomio.tipo == 'binomio'; else rowTiempo" (mouseover)="jineteSeleccionado=binomio.idJinete" (mouseup)="jineteSeleccionado=binomio.idJinete" (mouseout)="jineteSeleccionado=''" [ngClass]="{'scratched': binomio.scratched, 'repetido': validarRepetido(binomio.idJinete), 'bg-danger text-white': jineteSeleccionado === binomio.idJinete}" cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="prueba.publicada">
                              <td [attr.contenteditable]="!prueba.publicada ? 'true' : 'false'" class="d-print-none" (blur)="cambiarEntrada($event, index, binomio)">{{ binomio.entrada }}</td>
                              <td class="d-none d-print-table-cell">{{ binomio.entradaPublicada }}</td>
                              <td style="text-align:center;">{{ binomio.cucarda }}</td>
                              <td>
                                <p class="m-0">{{ binomio.caballo }}</p>
                                <p *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2' ) && mostrarInfoCaballo" class="m-0 infoCaballo" [ngClass]="{'d-print-none': !mostrarInfoCaballo}">
                                  <span *ngIf="!prueba.hunters" style="line-height: 1.5em;">{{ infoCaballo(binomio) }}</span>
                                  <span *ngIf="prueba.hunters">{{ binomio.height_category }}</span>
                                </p>
                              </td>
                              <td style="vertical-align: top;"> <img src="{{binomio.bandera}}" alt="" width="25px"> {{ binomio.representa }} <i *ngIf="binomio.remoto" class="mdi mdi-web hidden-print"></i>
                              </td>
                              <td style="vertical-align: top;">{{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}
                                <!-- <span class="badge badge-pill border" style="font-size: .575rem;" *ngIf="binomio.categoria">{{ binomio.categoria.substring(0, 3) }}</span> -->
                              </td>
                              <td style="vertical-align: top;">{{ binomio.trainer }}</td>
                              <td style="vertical-align: top;">{{ binomio.owner }}</td>
                              <!-- <td></td> -->
                              <!-- <td *ngIf="prueba.fases == '2'"></td> -->
                            </tr>
                            <ng-template #rowTiempo>
                              <tr [ngClass]="{'row-drag-drop': !prueba.publicada}" class="d-print-none" cdkDrag
                                cdkDragLockAxis="y" [cdkDragDisabled]="prueba.publicada">
                                <td colspan="11">
                                  <div class="d-flex justify-content-center">
                                    <input type="text" class="form-control form-control-sm" style="max-width: 200px;"
                                      [value]="binomio.nombre" (change)="cambiarNombreReceso($event, binomio)">
                                    <h4 class="my-auto ml-2"> {{ binomio.minutos }} minutes.</h4>
                                    <a href="javascript:;" class="my-auto ml-2" style="font-size: 1.125rem;"
                                      (click)="eliminarReceso(index)"><i class="mdi mdi-trash-can-outline"></i></a>
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </ng-container>
                        </tbody>
                        <!--
                        <tbody>
                          <tr style="border-bottom: 2px solid darkgray;"
                            *ngFor="let binomio of binomios; let index = index"
                            (mouseover)="jineteSeleccionado=binomio.idJinete" (mouseout)="jineteSeleccionado=''"
                            [ngClass]="{'scratched': binomio.scratched, 'repetido': validarRepetido(binomio.idJinete), 'bg-danger text-white': jineteSeleccionado === binomio.idJinete}">
                            <td [attr.contenteditable]="!prueba.publicada ? 'true' : 'false'" class="d-print-none"
                              (blur)="cambiarEntrada($event, index, binomio)">{{ binomio.entrada }}</td>
                            <td class="d-none d-print-table-cell">{{ binomio.entradaPublicada }}</td>
                            <td>{{ binomio.cucarda }}</td>
                            <td>
                              <p class="m-0">{{ binomio.caballo }}</p>
                              <p *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2' ) && mostrarInfoCaballo" class="m-0 infoCaballo"
                                [ngClass]="{'d-print-none': !mostrarInfoCaballo}">
                                <span *ngIf="!prueba.hunters">{{ (infoCaballo(binomio).length>45)? (infoCaballo(binomio)
                                  | slice:0:45)+'...':(infoCaballo(binomio)) }}</span>
                                <span *ngIf="prueba.hunters">{{ binomio.height_category }}</span>
                              </p>
                            </td>
                            <td>{{ binomio.representa }} <i *ngIf="binomio.remoto" class="mdi mdi-web hidden-print"></i>
                            </td>
                            <td>{{ binomio.nombreJinete }}</td>
                            <td>{{ binomio.apellidosJinete }} <span class="badge badge-pill border"
                                style="font-size: .575rem;" *ngIf="binomio.categoria">{{ binomio.categoria.substring(0,
                                3) }}</span></td>
                            <td>{{ binomio.owner }}</td>
                            <td></td>
                            <td *ngIf="prueba.fases == '2'"></td>
                          </tr>
                        </tbody>
                        -->
                      </table>


                      <div class="col-12 text-center">
                        <img [src]="prueba.footer" style="max-width: 100%; max-height: 90px;"
                          onerror="this.style.display='none'">
                      </div>
                      <p class="d-none d-print-block pl-2 mt-3">
                        Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalBorrarResultados>
  <div class="modal fade" id="modal-borrar-resultados" tabindex="-1" role="dialog" aria-labelledby="modal-borrar-resultados-label" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h4 class="modal-title" id="modal-borrar-resultados-label">Override results</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body p-2">
          <div class="mb-3">
            Override results order?
          </div>
          <div class="mb-3 d-flex justify-content-center">
            <button type="button" class="btn btn-success mr-3" (click)="publicarIndividual(true)">Yes</button>
            <button type="button" class="btn btn-danger" (click)="publicarIndividual()">No</button>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<ng-template #modalSplitClass>
  <div class="modal fade" id="modal-split-class" tabindex="-1" role="dialog" aria-labelledby="modalSplitClassLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h4 class="modal-title" id="modalSplitClassLabel">Split class {{ prueba?.numero }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body p-2">
          <app-split-class [binomios]="binomios" [prueba]="prueba" (onSaved)="onSavedSections($event)"></app-split-class>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

