
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid" style="width: 100%; float: left; display: flex;">
        <div class="float-left col-12" style="padding: 0px;">
          <div class="float-left col-12" style="margin-top: 30px;">
            <div class="card">
              <div class="card-body padding-0">
                <div class="float-left col-12 padding-0" style="margin-top:30px;">
                  <div class="float-left col-12" *ngIf="infoCuenta.length > 0" style="margin-bottom: 20px;">
                    <div class="float-left col-2">
                      <img style="height:200px;" src="/assets/images/logo.png" alt="Foto de perfil" class="img-circle">
                    </div>
                    <div class="float-left col-10">
                      <div class="float-left col-12" *ngIf="infoCuenta.length > 0">
                          <div class="float-left col-1">
                            <button mat-button type="button" style="margin-top:10px;background-color: white;" class="btn btn-space" (click)="mostrarPanelAgregar()">
                              <img style="height:25px;margin-top:0px;" src="/assets/images/icons/edit.svg" alt="Editar perfil">
                            </button>
                          </div>
                          <div class="float-left col-7" *ngIf="!edit_profile">
                            <mat-list role="list">
                              <mat-list-item role="listitem">
                                <div class="float-left col-4">
                                  Cuenta:
                                </div>
                                <div class="float-left col-8">
                                  <strong> {{ infoCuenta[0].nombre_cliente }} </strong>
                                </div>
                              </mat-list-item>
                              <mat-list-item role="listitem">
                                <div class="float-left col-4">
                                  Email:
                                </div>
                                <div class="float-left col-8">
                                  <strong> {{ infoCuenta[0].email }} </strong>
                                </div>
                              </mat-list-item>
                              <mat-list-item role="listitem">
                                <div class="float-left col-4">
                                  Teléfono:
                                </div>
                                <div class="float-left col-8">
                                  <strong> {{ infoCuenta[0].telefono }} </strong>
                                </div>
                              </mat-list-item>
                            </mat-list>
                          </div>
                          <div class="float-left col-7" *ngIf="edit_profile" style="margin-top:5px;">
                            <form #ClienteForm="ngForm" (ngSubmit)="onSubmitAgregarCliente()">
                              <div class="float-left col-12">
                                <div class="form-group">
                                  <div class="float-left col-4" style="margin-top:15px;padding-right:0px !important;">
                                    <label>
                                      <strong>Cuenta:</strong>
                                      <b style="color: red">*</b>:
                                    </label>
                                  </div>
                                  <div class="float-left col-8">
                                    <mat-form-field>
                                      <input matInput placeholder="Ingrese el nombre de la cuenta" [formControl]="validadorNombreCliente">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <div class="float-left col-12">
                                <div class="form-group">
                                  <div class="float-left col-4" style="margin-top:15px;padding-right:0px !important;">
                                    <label>
                                      <strong>Teléfono</strong>
                                    </label>
                                  </div>
                                  <div class="float-left col-8">
                                    <mat-form-field>
                                      <input matInput placeholder="Ingrese un número de teléfono" [formControl]="validadorTelefonoCliente">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <div class="float-left col-12">
                                <div class="form-group">
                                  <div class="float-left col-4" style="margin-top:15px;padding-right:0px !important;">
                                    <label>
                                      <strong>Email

                                      </strong>
                                      <b style="color: red">*</b>:
                                    </label>
                                  </div>
                                  <div class="float-left col-8">
                                    <mat-form-field>
                                      <input matInput placeholder="Ingrese un correo electrónico" [formControl]="validadorCorreoCliente">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <div class="float-left col-12" style="text-align: center;margin-bottom: 10px; padding-left: 0px !important;">
                                <button class="btn btn-default" style="margin-right: 1px" (click)="mostrarPanelAgregar()" type="button" i18n="@@botonCancelarCaballo">Cancelar</button>
                                <button class="btn" style="background-color: #00ca82; color:#ffffff" type="submit" i18n="@@botonAgregarCliente">Agregar</button>
                              </div>
                            </form>
                          </div>
                          <div class="float-left col-4">
                            <mat-list role="list">
                              <mat-list-item role="listitem">
                                <div class="col-8" style="padding:0px;">
                                    No. de videos comprados:
                                </div>
                                <div class="col-4">
                                  <strong>{{ infoCuenta[0].comprados }}</strong>
                                </div>
                              </mat-list-item>
                              <mat-list-item role="listitem">
                                <div class="col-8" style="padding:0px;">
                                    No. de videos entregados:
                                </div>
                                <div class="col-4">
                                  <strong> {{ infoCuenta[0].entregados }}</strong>
                                </div>
                              </mat-list-item>
                              <mat-list-item role="listitem">
                                <div class="col-8" style="padding:0px;">
                                    Saldo:
                                </div>
                                <div class="col-4">
                                  <b [style.color]="(infoCuenta[0].comprados - infoCuenta[0].entregados) > 0 ? 'green' : 'red'"> {{ infoCuenta[0].comprados - infoCuenta[0].entregados}} </b>
                                </div>
                              </mat-list-item>
                            </mat-list>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="float-left col-12">
                    <div class="float-left col-12" *ngIf="infoCuenta.length > 0" style="padding: 0px !important">
                      <div>
                        <span><h2>Paquetes contratados</h2></span>
                      </div>
                      <div class="widget widget-fullwidth widget-small table-responsive">
                        <table class="table table-fw-widget table-hover table-condensed">
                          <thead style="background-color:#3c4cb4;color:#FFFFFF">
                            <tr>
                              <th>Paquete</th>
                              <th>Fecha de la venta</th>
                              <th>Método de pago</th>
                              <th>Notas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-select placeholder="Selecciona un paquete" [(ngModel)]="paqueteVideosSeleccionado">
                                    <mat-option *ngFor="let paquete of paquetes" [value]="paquete.value">
                                      {{paquete.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td>
                              </td>
                              <td>
                                <mat-form-field>
                                  <mat-select placeholder="Selecciona un método de pago" [(ngModel)]="metodoPagoSeleccionado">
                                    <mat-option *ngFor="let metodo of metodosPago" [value]="metodo.value">
                                      <div *ngIf="metodo.name == 'Transferencia'">
                                        <img style="height:18px;" src="/assets/images/icons/bank.svg" alt="Transferencia">
                                        {{metodo.name}}
                                      </div>
                                      <div *ngIf="metodo.name == 'Efectivo'">
                                        <img style="height:18px;" src="/assets/images/icons/money.svg" alt="Efectivo">
                                        {{metodo.name}}
                                      </div>
                                      <div *ngIf="metodo.name == 'PayPal'">
                                        <img style="height:18px;" src="/assets/images/icons/paypal.svg" alt="PayPal">
                                      </div>
                                      <div *ngIf="metodo.name == 'Oxxo'">
                                        <img style="height:18px;" src="/assets/images/icons/OXXO_logo.svg" alt="Oxxo">
                                      </div>
                                      <div *ngIf="metodo.name == 'Cortesia'">
                                        <img style="height:18px;" src="/assets/images/icons/gift.svg" alt="Cortesia">
                                        {{metodo.name}}
                                      </div>
                                      <div *ngIf="metodo.name == 'Stripe'">
                                        <img style="height:18px;" src="/assets/images/icons/stripe.svg" alt="Stripe">
                                      </div>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td>
                                <div class="col-10 float-left" style="padding-left:0px !important;">
                                  <mat-form-field>
                                    <input matInput placeholder="Notas" [(ngModel)]="notas">
                                  </mat-form-field>
                                </div>
                                <div class="float-left col-2 float-left">
                                  <button mat-mini-fab style="background-color: #7accbe;" (click)="setPaquete()">
                                    <mat-icon>add_circle_outline</mat-icon>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <tr *ngFor="let paquete of paquetesContratados">
                              <td>{{ paquete.cantidad }} Videos</td>
                              <td>{{ paquete.fecha_venta | date:'EEEE, MMMM d, y' }}</td>
                              <td *ngIf="paquete.metodo == 'Transferencia'">
                                <img style="height:18px;" src="/assets/images/icons/bank.svg" alt="Transferencia">
                                {{ paquete.metodo}}
                              </td>
                              <td *ngIf="paquete.metodo == 'Efectivo'">
                                <img style="height:18px;" src="/assets/images/icons/money.svg" alt="Efectivo">
                                {{ paquete.metodo}}
                              </td>
                              <td *ngIf="paquete.metodo == 'PayPal'">
                                <img style="height:18px;" src="/assets/images/icons/paypal.svg" alt="PayPal">
                              </td>
                              <td *ngIf="paquete.metodo == 'Oxxo'">
                                <img style="height:18px;" src="/assets/images/icons/OXXO_logo.svg" alt="Oxxo">
                              </td>
                              <td *ngIf="paquete.metodo == 'Cortesia'">
                                <img style="height:18px;" src="/assets/images/icons/gift.svg" alt="Cortesia">
                                {{ paquete.metodo}}
                              </td>
                              <div *ngIf="paquete.metodo == 'Stripe'">
                                <img style="height:18px;" src="/assets/images/icons/stripe.svg" alt="Stripe">

                              </div>
                              <td *ngIf="paquete.metodo == null">No se cuenta con información respecto al método de pago</td>
                              <td>{{ paquete.notas }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="float-left col-12"  style="padding: 0px !important;">
                      <div>
                        <span><h2>Videos solicitados</h2></span>
                      </div>
                      <div class="widget widget-fullwidth widget-small table-responsive">
                        <div class="float-left col-12" style="margin-top:10px;">
                          <mat-form-field class="float-left col-12 col-lg-12 col-xs-12 col-sm-12 example-header">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar...">
                          </mat-form-field>
                        </div>
                        <div class="float-left col-12" style="padding: 0px !important;">
                            <mat-table #table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="Prueba" class="float-left col-3">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Prueba</mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                    <strong>{{ registro.numero_prueba }}.-</strong> {{ registro.prueba }} <br>
                                    {{ registro.inicio  | date:'EEEE, MMMM d, y'}} <br>
                                    {{ registro.concurso }}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Binomio" class="float-left col-3">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Binomio</mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                    <strong>{{ registro.jinete }}</strong> <br>
                                    {{ registro.caballo }}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Fase" class="float-left col-1">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Fase</mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                    {{ registro.fase }}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Entrada" class="float-left col-1">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">#Entrada</mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                      {{ registro.entrada }}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Entregado" class="float-left col-1">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Entregado</mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                      <strong *ngIf="registro.enviado == 1">Si</strong>
                                      <strong *ngIf="registro.enviado != 1">Pendiente</strong>
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Videos" class="float-left col-3">
                                  <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>
                                  <mat-cell *matCellDef="let registro">
                                      <a href="https://s3-us-west-1.amazonaws.com/videos.ecuestre.digital/{{registro.id_concurso}}-{{registro.id_prueba_concurso}}-{{registro.id_binomio}}-{{registro.fase}}.mp4" target="_blank">
                                        <div class="icon">
                                          <i class="mdi mdi-file-video"></i>
                                        </div>
                                      </a>
                                  </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                              </mat-table>
                              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
