import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ListaService } from '../services/lista.service';
import { ResultadoService } from '../services/resultado.service';
import { PrintService } from '../services/print.service';
import { AppService } from '../services/app.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { EntriesService } from '../services/entries.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { firstValueFrom } from 'rxjs';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'listas',
  templateUrl: 'listas.component.html',
  styleUrls: ['listas.component.css']
})

export class ListasComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public pruebas: Array<any>;
  public ipc: string;
  public prueba: any;
  public binomios: Array<any>;
  public fecha: Date;
  public jineteSeleccionado: string;
  public baseUrl: string;
  public mostrarInfoCaballo: boolean;
  public mostrarPaisJinete: boolean;
  public classes: any[];
  public hasResults: boolean;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private router: Router,
    private authService: AuthService,
    private pruebaService: PruebaService,
    private listaService: ListaService,
    private resultadoService: ResultadoService,
    private printService: PrintService,
    private appService: AppService,
    private entriesService: EntriesService
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.pruebas = [];
    this.ipc = '';
    this.prueba = {};
    this.binomios = [];
    this.fecha = new Date();
    this.baseUrl = window.location.hostname;
    this.mostrarInfoCaballo = false;
    this.mostrarPaisJinete = false;
    this.classes = [];
    this.hasResults = false;
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
  }

  private getPruebas() {
    return firstValueFrom(this.pruebaService.getPruebasList(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.pruebas = response.pruebas;
          if (this.ipc)
            return this.seleccionarPrueba({}, this.ipc);
          else
            $('#loader').hide();
          return;
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
         return;
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
       return;
      }
    );
  }

  public seleccionarPrueba(select, ipc: boolean | string = false) {
    this.binomios = [];
    this.hasResults = false;
    $('#loader').show();
    this.ipc = ipc ? ipc : select.currentTarget.value;
    const index = this.pruebas.findIndex(prueba => {
      return prueba.ipc == this.ipc;
    })
    this.prueba = this.pruebas[index];
    console.log(this.prueba);
    this.listaService.getBinomios(this.ipc).then(
      response => {
        console.log('response', response);
        if (!response.error) {
          this.binomios = response.binomios.map(b => {
            b.tipo = 'binomio'
            return b;
          });
          this.hasResults = response.resultados;
          var objs = JSON.parse(JSON.stringify(response.info.recesos));
          let i = 0;
          if (objs != null) {
            for (const receso of objs) {
              this.binomios.splice(objs[i]['indice'], 0, { tipo: 'tiempo', minutos: objs[i]['minutos'], nombre: objs[i]['nombre'], id: objs[i]['id'] || '' });
              i++;
            }
          }
          setTimeout(() => { $.App.init(); });
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        $('#loader').hide();
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be consulted.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public async showSplitClassModal() {
    $('#modal-split-class').modal('show');
  }

  public export_csv() {
    let headers = `"Horse Number","Horse Name","Rider Name","Owner(s)","Rider Country"\n`;
    let txt = this.binomios.map(binomio => `"${binomio.cucarda}","${binomio.caballo}","${binomio.nombreJinete.toUpperCase() + ' ' + binomio.apellidosJinete.toUpperCase()}","${binomio.owner.toUpperCase()}","${binomio.country.toUpperCase()}"`).join('\n');
    txt = headers + txt;
    const csvFile = new Blob([txt], { type: 'text/csv' });

    let a = document.createElement('a');
    a.download = this.prueba.numero + ' ' + this.prueba.nombre + '.csv';
    a.href = URL.createObjectURL(csvFile);
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public infoCaballo(binomio): string {
    let datosCaballo = [];
    if (binomio.dateOfBirth) {//Calcular la edad en años
      // Dar formato legible a la fecha de nacimiento
      datosCaballo.push(new Date(binomio.dateOfBirth).getFullYear().toString());
    }
    if (binomio.gender)
      datosCaballo.push(binomio.gender);
    if (binomio.colorCode)
      datosCaballo.push(binomio.colorCode);
    if (binomio.colorComplement)
      datosCaballo.push(binomio.colorComplement);
    if (binomio.countryOfBirth)
      datosCaballo.push(binomio.countryOfBirth);
    if (binomio.breed)
      datosCaballo.push(binomio.breed);

    if (binomio.sire)
      datosCaballo.push(binomio.sire);
    if (binomio.sireOfDam)
      datosCaballo.push('DS: ' + binomio.sireOfDam);

    return datosCaballo.join(' / ');
  }

  public showInfoCaballo(e) {
    if (e.target.checked) {
      this.mostrarInfoCaballo = true;
    } else {
      this.mostrarInfoCaballo = false;
    }
  }

  public showPaisJinete(e) {
    if (e.target.checked) {
      this.mostrarPaisJinete = true;
    } else {
      this.mostrarPaisJinete = false;
    }
  }

  public validarRepetido(idJinete) {
    const n = this.binomios.filter(binomio => {
      return binomio.idJinete === idJinete;
    }).length;

    return n > 1;
  }

  public shuffle(a): Array<any> {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  public sorteoEquilibrado() {
    $('#loader').show();
    let caballosPorJinete: Array<any> = []
    let jinetesVariosCaballos = [];
    let binomiosSortado = this.shuffle(this.binomios.concat([]));
    let binomiosOrdenadosNumCaballos = [];
    let posiciones: any = {};

    //Llenar json clave: idJinete, valor: numero de caballos del jinete
    for (const binomio of binomiosSortado) {
      const index = caballosPorJinete.findIndex(o => o.idJinete == binomio.idJinete);
      if (index === -1)
        caballosPorJinete.push({
          idJinete: binomio.idJinete,
          caballos: binomiosSortado.filter(b => b.idJinete === binomio.idJinete).length
        });
    }
    //Llenar arreglo con los idJinete de los jinetes que tienen mas de un caballo
    for (let idJinete in caballosPorJinete) {
      if (caballosPorJinete[idJinete] > 1) jinetesVariosCaballos.push(idJinete);
    }

    //Ordenar por jinete con mas caballos
    caballosPorJinete.sort((a, b) => {
      if (a.caballos > b.caballos) return -1;
      if (a.caballos < b.caballos) return 1;

      return 0;
    });

    //Ordenar lista de jinetes con mas caballos con el orden de los caballos ya sorteado
    for (const o of caballosPorJinete) {
      for (const binomio of binomiosSortado) {
        if (o.idJinete == binomio.idJinete)
          binomiosOrdenadosNumCaballos.push(binomio);
      }
    }

    for (const binomio of binomiosOrdenadosNumCaballos) {
      const cantidadCaballos = binomiosSortado.filter(b => b.idJinete === binomio.idJinete).length;
      const separacion = Math.round(this.binomios.length / cantidadCaballos);
      let min = 1;
      let max = this.binomios.length;
      let contadorApariciones = 1;
      let intentos = 0;
      let ultimaPosicion: any = 0;

      if (cantidadCaballos > 1) {
        for (let pos in posiciones) {
          if (posiciones[pos].idJinete == binomio.idJinete) {
            ultimaPosicion = pos;
            contadorApariciones++;
          }
        }

        if (contadorApariciones == 1) {
          min = Math.round(((contadorApariciones - 1) * separacion) + 1);
          max = Math.round(contadorApariciones * separacion);
        } else if (contadorApariciones > 1) {
          min = Math.round(parseInt(ultimaPosicion) + separacion / 1.5);
          max = Math.round(contadorApariciones * separacion);
        }

        if (min < 1) {
          min = 1;
        }

        if (max > this.binomios.length) {
          max = this.binomios.length;
        }
      }

      do {
        if (intentos > separacion) {
          min--;
          max++;
        }

        if (min < 1) {
          min = 1;
        }

        if (max > this.binomios.length) {
          max = this.binomios.length;
        }

        var aleatorio = Math.floor(Math.random() * (max - min + 1) + min);
        intentos++;
      } while (posiciones[aleatorio]);

      posiciones[aleatorio] = binomio;
    }

    let binomiosTmp = [];
    //Convertir json de binomios en array
    for (let i in posiciones) {
      binomiosTmp.push(posiciones[i]);
    }

    //Reasignar numero de entrada segun su posicion
    binomiosTmp = binomiosTmp.map((b, i) => {
      b.entrada = i + 1;
      return b;
    });
    //Cargar en la vista la lista ya sorteada
    this.binomios = binomiosTmp;
    $('#loader').hide();
    //this.guardarLista();
  }

  public cambiarEntrada(e: any, index: number, binomio) {
    const entrada = parseInt(e.currentTarget.textContent.trim());
    if (isNaN(entrada)) {
      e.currentTarget.textContent = binomio.entrada;
      return;
    }
    e.currentTarget.textContent = entrada;
    binomio.entrada = `${entrada}`;

    this.binomios.filter(b => b.tipo == 'binomio').sort((a, b) => {
      if (parseInt(a.entrada) == parseInt(b.entrada)) return 0;
      return parseInt(a.entrada) > parseInt(b.entrada) ? 1 : -1;
    });
  }

  public agregarReceso(e): void {
    const minutos = parseFloat(e.target.value || '0');
    if (minutos > 0) {
      this.binomios.unshift({
        tipo: 'tiempo',
        nombre: '',
        minutos,
        id: Math.random().toString(36).substring(7)
      });
      //this.asignarHoraDeInicioLista({ target: { value: 70 / 60 } });
      e.target.value = '';
    } else {
      e.target.value = '';
    }
  }

  public guardarLista(): void {
    $('#loader').show();
    let recesos = this.binomios.map((b, i) => ({ tipo: b.tipo, minutos: b.minutos, nombre: b.nombre, indice: i, id: b.id || '' })).filter(b => b.tipo == 'tiempo');
    this.listaService.guardarLista(this.binomios.filter(b => b.tipo == 'binomio'), this.ipc, recesos).subscribe(
      response => {
        if (!response.error) {
          this.appService.updateShow(this.idConcurso, this.ipc);
          $.NotificationApp.send("Saved", "The list has been saved correctly.", 'bottom-right', '#fa5c7c', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        $('#loader').hide();
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be saved.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public checkResultsOrder(){
    if(this.hasResults){
      $('#modal-borrar-resultados').modal('show');
    } else{
      this.publicarIndividual();
    }
  }

  public publicarIndividual(overrideResultsOrder: boolean = false) {
    $('#modal-borrar-resultados').modal('hide');
    this.listaService.publicarIndividual(this.ipc, this.idUsuario, this.idConcurso, overrideResultsOrder).subscribe(
      response => {
        if (!response.error) {
          this.getPruebas();
          $.NotificationApp.send("Success", response.message, 'bottom-right', '#fa5c7c', 'success');
          //Eliminar todos los binomios de la prueba
          this.appService.deleteEntriesAndUpdate(this.idConcurso, this.ipc);
          //Actualizar listas en la app
          /*this.resultadoService.guardarFirestore(this.ipc).subscribe(
              response => {
                  if(response.error){
                      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                      console.log(response.message);
                  }else{
                      $.NotificationApp.send("Success","This class was published", 'bottom-right', '#fa5c7c', 'success');
                  }
              },
              error => {
                  $.NotificationApp.send("Error", 'The app has not been updated.', 'bottom-right', '#fa5c7c', 'error');
                  console.log(error);
              }
          );*/
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be published.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public ocultarPublicacion() {
    this.listaService.revertirPublicacion(this.ipc, this.idUsuario, this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.getPruebas();
          $.NotificationApp.send("Reverted", response.message, 'bottom-right', '#fa5c7c', 'success');
          //Actualizar listas en la app
          this.appService.updateShow(this.idConcurso, this.ipc);
          /*this.resultadoService.guardarConcursoFirestore(this.idConcurso).subscribe(
              response => {
                  if(response.error){
                      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                      console.log(response.message);
                  }else{
                      $.NotificationApp.send("Reverted","This class was reverted", 'bottom-right', '#fa5c7c', 'success');
                  }
              },
              error => {
                  $.NotificationApp.send("Error", 'The app has not been updated.', 'bottom-right', '#fa5c7c', 'error');
                  console.log(error);
              }
          );*/
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be reverted.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public publicar() {
    $('#loader').show();
    this.listaService.publicar(this.idConcurso, this.idUsuario, this.nombreConcurso).subscribe(
      response => {
        if (!response.error) {
          this.getPruebas();
          $.NotificationApp.send("Saved", response.message, 'bottom-right', '#fa5c7c', 'success');
          //Actualizar listas en la app
          this.resultadoService.guardarConcursoFirestore(this.idConcurso).subscribe(
            response => {
              if (response.error) {
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }
            },
            error => {
              $.NotificationApp.send("Error", 'The app has not been updated.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
          );
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry lists could not be published.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public imprimirLista(): void {
    setTimeout(() => { print(); }, 0);
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      //Consultar el id de la prueba con el numero que el usuario nos esta dando
      const index = this.pruebas.findIndex(p => p.numero == value)
      if (index > -1) {
        this.classes.push({ ipc: this.pruebas[index].ipc, name: value.trim() });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  public remove(ipc): void {
    const index = this.classes.findIndex(c => c.ipc == ipc);

    if (index >= 0) {
      this.classes.splice(index, 1);
    }
  }

  public printClasses(): void {
    this.printService.printClasses(this.idConcurso, this.classes.map(c => c.ipc), this.mostrarInfoCaballo, this.mostrarPaisJinete);
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.binomios, event.previousIndex, event.currentIndex);
    this.binomios.filter(b => b.tipo == 'binomio').map((b, i) => {
      b.entrada = `${i + 1}`;
      return b;
    });
    this.guardarLista();
  }

  public cambiarNombreReceso(e, r) {
    const nombre = e.target.value;
    r.nombre = nombre;
  }

  public eliminarReceso(index) {
    this.binomios.splice(index, 1);
    //this.asignarHoraDeInicioLista({ target: { value: this.segundosPorBinomio / 60 } });
  }

  public async addEntry(e){
    const entry = e.target.value;
    if(!entry) return;
    //Validar si el binomio ya esta en la prueba
    const consultaBinomio = this.binomios.find(b => b.cucarda == entry);
    if (consultaBinomio) {
      $.NotificationApp.send('Error', 'This entry is already in this class.', 'bottom-right', 'rgba(0,0,0,0.2)', 'error');
      $('#add-entry').val('');
      return;
    }
    const entryInfo = await this.entriesService.getEntryInfo(entry, this.idConcurso).toPromise();
    if (entryInfo.error) {
      $.NotificationApp.send('Error', entryInfo.message, 'bottom-right', 'rgba(0,0,0,0.2)', 'error');
      return;
    }
    if (!entryInfo.id_binomio) return;
    const bpc = {
      idBinomio: entryInfo.id_binomio,
      ipc: this.ipc,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso,
      idCategoria: '0',
      entry: entry
    };
    //Inscribir y recargar
    const addResponse = await this.entriesService.inscribir(bpc).toPromise();
    if (addResponse.error) {
      $.NotificationApp.send('Error', addResponse.message, 'bottom-right', 'rgba(0,0,0,0.2)', 'error');
      return;
    }
    this.seleccionarPrueba({}, this.ipc);
    e.target.value = '';
    $.NotificationApp.send("Added", addResponse.message, 'bottom-right', '#fa5c7c', 'success');
  }

  public desinscribir(binomio){
    const idBinomio = binomio.idBinomio;
    const ipc = this.ipc;
    const idUsuario = this.idUsuario;
    const idConcurso = this.idConcurso;
    this.entriesService.eliminarBinomioNP(idBinomio, ipc, idConcurso, idUsuario).subscribe(
      response => {
        this.seleccionarPrueba({}, this.ipc);
      },
      error => {
        $.NotificationApp.send("Error", 'The entry could not be removed.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  async onSavedSections(event: any) {
    console.log(event);
    $('#modal-split-class').modal('hide');
    await this.getPruebas();
    //TODO: Show success message
  }
}
