import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class EntrenadorService extends Api {

  constructor(private http: HttpClient){
    super();
  }

  public getEntrenadores(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/`, params, {headers: headers});
  }

  public addEntrenadores(entrenador, idConcurso): Observable<any>{
    entrenador.idConcurso = idConcurso;
    const params = JSON.stringify(entrenador);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/add/`, params, {headers: headers});
  }

  public updateEntrenadores(entrenador): Observable<any>{
    const params = JSON.stringify(entrenador);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/update/`, params, {headers: headers});
  }

  public getEntrenadoresFiltrados(filtro, idConcurso): Observable<any>{
    const params = JSON.stringify({ filtro, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/query/`, params, {headers: headers});
  }

  public getEntrenadoresPorConcurso(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/porConcurso/`, params, {headers: headers});
  }

  public getEntrenadorById(idConcurso, idEntrenador, nombreEntrenador): Observable<any>{
    const params = JSON.stringify({ idConcurso, idEntrenador, nombreEntrenador });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/byId/`, params, {headers: headers});
  }

  public getSaldoEntries(idConcurso, entries): Observable<any>{
    const params = JSON.stringify({ idConcurso, entries });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/saldoEntries/`, params, {headers: headers});
  }

  public agregarCargo(datos): Observable<any>{
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/agregarCargo/`, params, {headers: headers});
  }

  public agregarPago(trainerPayment: TrainerPayment): Observable<any>{
    const params = JSON.stringify(trainerPayment);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/agregarPago/`, params, {headers: headers});
  }

  public async agregarPagos(payments: TrainerPayment[], cardFees?: any[]): Promise<any>{
    const params = JSON.stringify({ payments, cardFees });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entrenadores/agregarPagos/`, params, {headers: headers}));
  }

  public agregarSplit(splits): Observable<any>{
    const params = JSON.stringify({ splits });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/agregarSplit/`, params, {headers: headers});
  }

  public anularSplit(datos): Observable<any>{
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/anularSplit/`, params, {headers: headers});
  }

  public getInfoResumen(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/resumen/`, params, {headers: headers});
  }

  public getCaballosMatrix(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/caballosMatrix/`, params, {headers: headers});
  }

  public getConceptoMatrix(idConcepto, idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcepto, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/conceptoMatrix/`, params, {headers: headers});
  }

  public agregarCargoMatrix(cargo): Observable<any>{
    const params = JSON.stringify({cargo});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/agregarCargoMatrix/`, params, {headers: headers});
  }

  public agregarPagoDividido(idConcurso, trainer, payment, pagosEntries, idUsuario): Observable<any>{
    const params = JSON.stringify({ idConcurso, trainer, payment, pagosEntries, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/pago-dividido/`, params, {headers: headers});
  }

  public balanceTransfer(traspaso): Observable<any>{
    const params = JSON.stringify({ traspaso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/balance-transfer/`, params, {headers: headers});
  }

  public validarCuenta(idConcurso, trainer, validated): Observable<any>{
    const params = JSON.stringify({ idConcurso, trainer, validated });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/validate-invoice/`, params, {headers: headers});
  }

  public cancelPayment(idTransaccion, metodo, monto, idUsuario, idConcurso, idEntrenador, motivo = ''): Observable<any>{
    const params = JSON.stringify({ idTransaccion, metodo, monto, idUsuario, idConcurso, idEntrenador, motivo });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/cancelPayment/`, params, {headers: headers});
  }

  public saveNotes(idConcurso, trainer, notes): Observable<any>{
    const params = JSON.stringify({ idConcurso, trainer, notes });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entrenadores/save-notes/`, params, {headers: headers});
  }
}
