<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row justify-content-center mt-4 m-print-0">
          
          <div class="col-lg-7 panel" [ngClass]="{'d-print-none': printSummary}">  
            <!-- Feed Orders List -->
            <div class="card d-print-none">
              <div class="card-header bg-dark text-white">
                Feed Orders List
                <div class="card-widgets">
                  <a href="javascript:void(0)" (click)="abrirPanelAgregar()"><i class="mdi mdi-plus-circle-outline"></i></a>
                </div>
              </div>
              <div class="card-body">
                <table class="table" id="orders">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Stall</th>
                      <th>Trainer</th>
                      <!-- <th>Entry</th> -->
                      <!-- <th>Total</th> -->
                      <th>Created</th>
                      <th>Delivered</th>
                      <th>Online</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody style="font-size: .9em;">

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="panel" [ngClass]="{'col-0': !mostrarPanelAgregar, 'col-lg-5': mostrarPanelAgregar}" >
            <!-- Individual Order -->
            <div class="card">
              <div class="card-header bg-dark text-white">
                <ng-container *ngIf="ordenSeleccionada">
                  Order {{ ordenSeleccionada }}
                </ng-container>
                <ng-container *ngIf="!ordenSeleccionada">
                  Add order
                </ng-container>
                <div class="card-widgets d-print-none">
                  <a href="javascript:void(0)" (click)="print($event, 'order')" title="Print">
                    <i class="mdi mdi-printer"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
              <form class="needs-validation" (ngSubmit)="saveOrder()" [formGroup]="form" novalidate>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Stall</label>
                      <input type="text" class="form-control" required formControlName="stall">
                    </div>
                  </div>
                  <div class="col-6" [ngClass]="{'d-print-none': !form.value.trainer}">
                    <div class="form-group">
                      <label>Trainer</label>
                      <input type="text" class="form-control" [matAutocomplete]="autocompleteTrainer" (keyup)="getPersonas($event)" formControlName="trainer" [attr.disabled]="form.get('entry').value ? '' : null">
                      <mat-autocomplete #autocompleteTrainer="matAutocomplete" [displayWith]="displayEntrenador.bind(this)" >
                          <mat-option *ngFor="let entrenador of entrenadores" [value]="entrenador.id_persona" class="fila-autocomplete">
                            <span class="badge badge-warning-lighten" style="font-size: .576rem;" *ngIf="!entrenador.inShow">Not in show</span>
                            {{ entrenador.fullname||'-' }}
                            </mat-option>
                      </mat-autocomplete>
                    </div>
                  </div>
                  <div class="col-6" [ngClass]="{'d-print-none': !form.value.entry}">
                    <div class="form-group">
                      <label>Entry</label>
                      <select class="form-control" formControlName="entry" [attr.disabled]="form.get('trainer').value ? '' : null">
                        <option value=""></option>
                        <option [value]="entry.entry" *ngFor="let entry of entries">{{ entry.entry }} - {{ entry.horse }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <label>Charges</label>
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th class="p-1">Concept</th>
                          <th class="p-1">Notes</th>
                          <th class="p-1">Qty</th>
                          <th class="p-1">Amount</th>
                          <th class="p-1 d-print-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="d-print-none">
                          <td class="p-1">
                            <select class="form-control" [(ngModel)]="concept" [ngModelOptions]="{standalone: true}" (change)="calcularCargo()" [disabled]="loadingSave">
                              <option value="" disabled></option>
                              <option [value]="concepto.id" *ngFor="let concepto of conceptos">{{ concepto.nombre }}</option>
                            </select>
                          </td>
                          <td class="p-1"><input type="text" class="form-control" [(ngModel)]="notes" [ngModelOptions]="{standalone: true}" [disabled]="loadingSave"></td>
                          <td class="p-1"><input type="number" class="form-control" [(ngModel)]="qty" [ngModelOptions]="{standalone: true}" [disabled]="loadingSave" (change)="calcularCargo()"></td>
                          <td class="p-1"><input type="number" class="form-control" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}" [disabled]="loadingSave" (change)="calcularCargo()"></td>
                          <td class="p-1">
                            <a href="javascript:void(0)" (click)="loadingSave ? null : addCharge()">
                              <i class="mdi mdi-plus-circle-outline" style="font-size: 1.75rem;"></i>
                            </a>
                          </td>
                        </tr>
                        <tr *ngFor="let charge of charges; let i = index">
                          <td>{{ charge.producto }}</td>
                          <td>{{ charge.notas }}</td>
                          <td>{{ charge.cantidad }}</td>
                          <td>{{ charge.linea }}</td>
                          <td class="d-print-none">
                            <div class="table-action text-center">
                              <a href="javascript: void(0);" class="action-icon" (click)="removeCharge(i)">
                                 <i class="mdi mdi-close-circle-outline"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right d-print-none">
                    <button type="button" class="btn btn-sm btn-info mr-3" (click)="markDelivered(ordenSeleccionada)">Mark as Delivered</button>
                    <button type="button" class="btn btn-sm btn-light mr-3" (click)="cerrarPanelAgregar()">Cancel</button>
                    <button type="submit" class="btn btn-sm btn-success" [disabled]="loadingSave">
                      <span class="loader" *ngIf="loadingSave"></span>
                      Save
                    </button>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div class="panel col-8" [ngClass]="{'d-print-none': !printSummary}" >
            <!-- Sales Summary -->
            <div class="card ">
              <a class="card-header bg-dark text-white" data-toggle="collapse" href="#collapseSummary" aria-expanded="true" aria-controls="collapseSummary">
                Sales Summary
                <div class="card-widgets d-print-none">
                  <a href="javascript:void(0)" (click)="print($event, 'summary')" title="Print">
                    <i class="mdi mdi-printer"></i>
                  </a>
                </div>
              </a>
              <div class="collapse show" id="collapseSummary">
                <div class="card-body p-1">

                  <table class="table summary mb-0">
                    <thead>
                      <tr>
                        <th>Concept</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of summaryOrders">
                        <td>{{ order.producto }}</td>
                        <td>{{ order.unitario | currency }}</td>
                        <td>{{ order.cantidad }}</td>
                        <td>{{ order.total | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
