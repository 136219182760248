<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="row justify-content-center px-3 mt-3">
    <div class="col-12 col-lg-8">
        <div class="card">
            <div class="card-header text-white bg-primary text-xs-center p-2">
                {{ prueba.numero }} - {{ prueba.nombre }}
                <div class="card-widgets">
                    <a href="javascript:;" (click)="getEntries()"><i class="mdi mdi-refresh"></i> Update</a>
                </div>
            </div>
            <div class="card-body p-2">
                <table class="table table-sm table-hover mb-0">
                    <thead>
                        <tr>
                            <th>Entry</th>
                            <th>Rider</th>
                            <th>Horse</th>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody *ngFor="let entry of entries">
                        <tr>
                            <td><input class="form-control form-control-sm text-center" min="1" type="number" [value]="entry.entradaPublicada" style="width: 4rem;" onclick="this.select()" (change)="cambiarEntrada($event, entry.ibpc)"></td>
                            <td [ngClass]="{'scratched': entry.scratched}">{{ entry.nombreJinete }} {{ entry.apellidosJinete }}</td>
                            <td [ngClass]="{'scratched': entry.scratched}">{{ entry.caballo }}</td>
                            <td class="table-action">
                                <ng-container *ngIf="!entry.scratched">
                                    <a href="javascript: void(0);" class="btn btn-danger text-white" (click)="scratch(entry.ibpc, entry.idBinomio)"> Scratch</a>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>