import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { ListaService } from '../../services/lista.service';
import { AuthService } from '../../services/auth.service';
import { ConcursoService } from '../../services/concurso.service';
import { MantenimientoService } from '../../services/mantenimiento.service'
import { JineteService } from '../..//services/jinete.service';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'mantenimiento-clubes',
  templateUrl: './mantenimiento-clubes.component.html',
  styleUrls: ['./mantenimiento-clubes.component.css']
})

export class MantenimientoClubesComponent implements OnInit {
  public idConcurso: string | boolean;
  public nombreConcurso: string;
  public logoClub: string;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public header: string;
  public caballosJSON: any;
  public jinetesJSON: any;
  constructor(
    private authService: AuthService,
    private listaService: ListaService,
    private mantenimientoService: MantenimientoService,
    private router: Router,
    private concursoService: ConcursoService
  ) {
    this.jinetesJSON = [];
    this.caballosJSON = [];
    this.token = '';
    this.logoClub = '';
    this.sessionData = {};
    this.header = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if(this.authService.validarConcurso()){
      this.idConcurso = this.authService.validarConcurso();
    } else{
      this.router.navigate(['']);
      return;
    }
    this.getJinetesJson();
    //this.getCaballosJSON();
  }

  public getJinetesJson(){
    this.mantenimientoService.getJinetesJSON().subscribe(data => {
      this.jinetesJSON = JSON.parse(data._body)
      $.NotificationApp.send("SUCCESS!", 'We already have the information of all the riders.', 'bottom-right', '#06d5a1', 'success');
    });
  }

  public getCaballosJSON(){
    this.mantenimientoService.getCaballosJSON().subscribe(data => {
      this.caballosJSON = JSON.parse(data._body);
      $.NotificationApp.send("SUCCESS!", 'We already have the information of all the horses.', 'bottom-right', '#06d5a1', 'success');
      console.log(this.caballosJSON);
    });
  }

  public updateClubes(){
    let jsonLength = Object.keys(this.jinetesJSON).length;
    let contador = 0;
    this.jinetesJSON.forEach(element => {
      this.mantenimientoService.updateClubes(element.nombre_corto, element.nombre_club).subscribe(
        response => {
          console.log(response);
          contador++;
          if(contador == jsonLength){
            $.NotificationApp.send("SUCCESS!", 'Data correction is finished.', 'bottom-right', '#06d5a1', 'success');
          }
        },
        error => {
          console.log(error);
        }
      );

    });


  }

}
