import { Component, OnInit } from '@angular/core';
import { PruebaService } from './../services/prueba.service';

@Component({
  selector: 'app-pruebas-paddock',
  templateUrl: './pruebas-paddock.component.html',
  styleUrls: ['./pruebas-paddock.component.css']
})
export class PruebasPaddockComponent implements OnInit {
  public pistas: any[];

  constructor(private pruebaService: PruebaService) {
    this.pistas = [];
  }

  ngOnInit() {
    this.getPruebas();
  }

  private getPruebas(): void{
    this.pruebaService.getPruebasList(410).subscribe(//Id de consurso fijo por el momento
      response => {
        if(!response.error){
          response.pruebas.forEach(p => {
            const index = this.pistas.findIndex(e => e.nombre == p.pista);
            if(index == -1){
              this.pistas.push({
                nombre: p.pista,
                pruebas: [p]
              });
            } else{
              this.pistas[index].pruebas.push(p);
            }
          });
          this.pistas = this.pistas.sort((a,b) => {
            if(a.nombre < b.nombre) return -1;
            if(a.nombre > b.nombre) return 1;
            return 1;
          });
          console.log(this.pistas);
        } else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public fechaApple(fecha: string): string{
    return fecha.replace(/-/g, '/');
  }
}
