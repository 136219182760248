import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as LogRocket from 'logrocket';

const packageJson = require('../package.json'); // Adjust the path as necessary
const version = packageJson.version;

console.log('Version: ', version);

LogRocket.init('shjn82/equestrian-digital');

Sentry.init({
  dsn: "https://7bd4ce1e70ddb92036c636ddaad3ccdc@o4507624615116800.ingest.us.sentry.io/4507624619704320",
  release: version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        'https://myapi.ushja.org',
        'https://api.usef.org/api',
        'https://api.equestrian.digital',
        'https://nest.equestrian.digital/v1'

      ],
    }),
    Sentry.httpClientIntegration()
  ],
  sendDefaultPii: true,
  beforeSend(event) {
    return event;
  },
  //Environment
  environment: environment.production ? "production" : "development",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.equestrian\.digital/, /^https:\/\/nest\.equestrian\.digital\/v1/],
  // Session Replayapi.equestrian.digital/
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
