import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PruebaService } from '../services/prueba.service';
declare let $: any;

@Component({
  selector: 'app-prize-distributions',
  templateUrl: './prize-distributions.component.html',
  styleUrls: ['./prize-distributions.component.css']
})
export class PrizeDistributionsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public monto: any;
  public montos: number[];
  public totalDistribucion: number;
  public form: UntypedFormGroup;
  public distribuciones: any[];
  public distribucionSeleccionada: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private pruebaService: PruebaService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.monto = '';
    this.montos = [];
    this.totalDistribucion = 0;
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('percentage', [Validators.required])
    });
    this.distribuciones = [];
    this.distribucionSeleccionada = '';
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPrizeDistributions();
  }

  private getPrizeDistributions(){
    this.pruebaService.getPrizeDistributions(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.distribuciones = [];
          for(let distribucion of response.distribuciones){
            if(distribucion.tipo == 'percentage'){
              distribucion.distribucion = distribucion.distribucion.map(d => d*100);
              this.distribuciones.push(distribucion);
            } else{
              this.distribuciones.push(distribucion);
            }
          }
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public mostrarModalAgregarDistribucion(): void{
    this.distribucionSeleccionada = '';
    this.montos = [];
    this.monto = '';
    this.form.setValue({
      name: '',
      type: 'percentage'
    });
    $('#modal-distribucion').modal('show');
  }

  public mostrarModalEditarDistribucion(distribucion): void{
    this.distribucionSeleccionada = distribucion.id;
    this.montos = distribucion.distribucion;
    this.monto = '';
    this.totalDistribucion = this.montos.reduce((sum, current) => sum + current, 0);
    this.form.setValue({
      name: distribucion.nombre,
      type: distribucion.tipo
    });
    $('#modal-distribucion').modal('show');
  }

  public agregarMonto(): void{
    const monto = isNaN(parseFloat(this.monto||'0')) ? 0 : parseFloat(this.monto||'0');
    if(monto > 0){
      this.montos.push(monto);
      this.monto = '';
    } else{
      this.monto = '';
    }
    this.totalDistribucion = this.montos.reduce((sum, current) => sum + current, 0);
  }

  public borrarMonto(i): void{
    this.montos.splice(i, 1);
    this.totalDistribucion = this.montos.reduce((sum, current) => sum + current, 0);
  }

  public guardarDistribucion(): void{
    if(this.form.valid && this.montos.length > 0){
      $('#loader').show();
      const montos = this.form.value.type == 'amount' ? this.montos : this.montos.map(m => {
        return m/100;
      });
      if(this.distribucionSeleccionada){
        this.pruebaService.editDistribution(this.distribucionSeleccionada, this.form.value.name, this.form.value.type, montos).subscribe(
          response => {
            if(!response.error){
              this.getPrizeDistributions();
              $('#modal-distribucion').modal('hide');
            } else{
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
              $('#loader').hide();
            }
          },
          error => {
            $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
            $('#loader').hide();
          }
        );
      } else{
        this.pruebaService.addDistribution(this.form.value.name, this.form.value.type, montos, this.idConcurso).subscribe(
          response => {
            if(!response.error){
              this.getPrizeDistributions();
              $('#modal-distribucion').modal('hide');
            } else{
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
              $('#loader').hide();
            }
          },
          error => {
            $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
            $('#loader').hide();
          }
        );
      }
    }
  }

  public editarMonto(e, i): void{
    const monto = parseFloat(e.target.value||'0');
    this.montos[i] = monto;
    this.totalDistribucion = this.montos.reduce((sum, current) => sum + current, 0);
  }
}
