import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { StripeService } from './../../services/stripe.service';
import { firstValueFrom } from 'rxjs';
declare var Stripe: any;
declare var $: any;

@Component({
  selector: 'app-stripe-add-card',
  templateUrl: './stripe-add-card.component.html',
  styleUrls: ['./stripe-add-card.component.css']
})

export class StripeAddCardComponent implements OnInit, OnDestroy, OnChanges{
  @Input() private customers: any[];
  @Output() public onFinished: EventEmitter<any> = new EventEmitter<any>();
  private publickey: string;
  private stripe: any;
  private showId: string;
  public customersPaymentsMethods: any[];
  public elements: any;
  public showCardForm: boolean;
  public customerSelected: string;

  constructor(private stripeService: StripeService) {
    this.customers = [];
    this.publickey = sessionStorage.getItem('public_key')||'';
    this.stripe = this.publickey ? Stripe(this.publickey) : null;
    this.showId = sessionStorage.getItem('concurso')||'';
    this.customersPaymentsMethods = [];
    this.elements = this.stripe ? this.stripe.elements() : null;
    this.showCardForm = false;
    this.customerSelected = '';
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // al pasar del Home a Entries, el valor de currentValue es undefined
    if(changes && changes.customers.currentValue && changes.customers.currentValue.length){
      this.getPaymentMethods(...this.customers);
    }
  }

  private async getPaymentMethods(...customers: any){
    const customerIds: any[] = [...new Set(customers.map(c => c.id))].filter(id => id !== '');
    const promises = customerIds.map((customerId: any) => this.stripeService.getCustomerPaymentMethods(customerId, this.showId).then((result: any) => {
      if(result.error) return result;
      return { ...(customers.find(c => c.id === customerId)||{}), paymentMethods: result.data||[] };
    }));

    const response = await Promise.all(promises);
    const errors = response.filter(r => r.error).map(r => r.error);
    console.log('getPaymentMethods', response||'responseNull', errors||'errorsNull');

    if(errors.length){
      $.NotificationApp.send("Error", errors, 'bottom-right', '#fa5c7c', 'error', 15000);
      return;
    }
    this.customersPaymentsMethods = response;
  }

  public async createPaymentMethod(){
    if(!this.customerSelected){
      $.NotificationApp.send("Alert", "Please select a stripe customer.", 'bottom-right', '#fa5c7c', 'warning', 5000);
      return;
    }
    const cardholderName: any = document.getElementById('cardholder-name');
    //const resultContainer: any = document.getElementById('card-result');
    const cardNumberElement = this.elements.getElement('cardNumber');

    const options = {
      name: cardholderName.value||'',
      currency: 'usd',
    };

    const { token, error } = await this.stripe.createToken(cardNumberElement, options);
    if(error){
      $.NotificationApp.send(error.code, error.message, 'bottom-right', '#fa5c7c', 'error', 30000);
      return;
    }
    //Setup Intent
    const response = await this.createSetupIntent(token.id);
    if(response.error){
      $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error', 30000);
      return;
    }

    const clientSecret = response.setupIntent.client_secret;
    const paymentMethod = response.setupIntent.payment_method;

    if(response.setupIntent.status !== 'succeeded'){
      //Handle error
    }

    // Confirm the card setup
    this.hideAddPaymentMethod();
    await this.getPaymentMethods(...this.customers);
    this.onFinished.emit(this.customersPaymentsMethods);
  }

  public async showAddPaymentMethod(){
    if(this.showCardForm){
      this.hideAddPaymentMethod();
      return;
    }
    const [firstCustomer] = this.customers;
    if(firstCustomer) this.customerSelected = firstCustomer.id||'';
    this.showCardForm = true;
    const cardNumberElement = this.elements.create('cardNumber', {
      classes: {
        base: 'StripeElement form-control form-control-sm',
      }
      //disabled: true,
    });
    const cardCvvElement = this.elements.create('cardCvc', {
      classes: {
        base: 'StripeElement form-control form-control-sm',
      }
      //placeholder: 'Card number',
      //disabled: true,
    });
    const cardExpiryElement = this.elements.create('cardExpiry', {
      classes: {
        base: 'StripeElement form-control form-control-sm',
      }
      //placeholder: 'Card number',
      //disabled: true,
    });
    cardNumberElement.on('change', (event) => {
      // show validation to customer
      const displayError = document.getElementById('card-number-errors');
      if (event.error) {
        displayError.classList.remove('d-none');
        displayError.textContent = event.error.message;
      } else if(event.complete){
        displayError.classList.add('d-none');
        displayError.textContent = '';
      } else {
        displayError.classList.add('d-none');
        displayError.textContent = '';
      }
    });
    cardExpiryElement.on('change', (event) => {
      // show validation to customer
      const displayError = document.getElementById('card-expiry-errors');
      if (event.error) {
        displayError.classList.remove('d-none');
        displayError.textContent = event.error.message;
      } else if(event.complete){
        displayError.classList.add('d-none');
        displayError.textContent = '';
      } else {
        displayError.classList.add('d-none');
        displayError.textContent = '';
      }
    });
    cardCvvElement.on('change', (event) => {
      // show validation to customer
      const displayError = document.getElementById('card-cvv-errors');
      if (event.error) {
        displayError.classList.remove('d-none');
        displayError.textContent = event.error.message;
      } else if(event.complete){
        displayError.classList.add('d-none');
        displayError.textContent = '';
      } else {
        displayError.classList.add('d-none');
        displayError.textContent = '';
      }
    });
    setTimeout(() => {
      cardNumberElement.mount('#card-number');
      cardExpiryElement.mount('#card-expiry');
      cardCvvElement.mount('#card-cvv');
    });
  }

  public hideAddPaymentMethod(){
    this.showCardForm = false;
    const cardNumberElement = this.elements.getElement('cardNumber');
    const cardExpiryElement = this.elements.getElement('cardExpiry');
    const cardCvvElement = this.elements.getElement('cardCvc');
    cardNumberElement.destroy();
    cardExpiryElement.destroy();
    cardCvvElement.destroy();
  }

  public async createSetupIntent(paymentMethod: string){
    if(!this.customerSelected){
      $.NotificationApp.send("Alert", "Please select a stripe customer.", 'bottom-right', '#fa5c7c', 'warning', 5000);
      return;
    }
    return firstValueFrom(this.stripeService.createSetupIntent(this.showId, this.customerSelected, paymentMethod)).then(r => ({ setupIntent: r.setupIntent, error: null })).catch((reason: any) => ({ error: reason.error.text||'', setupIntent: null }));
  }
}
