<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">

        <!-- Modal de confirmacion para borrar prueba -->
        <div class="modal fade" id="modal-eliminar-division" tabindex="-1" role="dialog" aria-labelledby="eliminarDivisionLabel" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header px-2 py-1">
                <h4 class="modal-title" id="eliminarDivisionLabel">Delete division</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body p-2">
                Are you sure to delete this division?
              </div>
              <div class="modal-footer px-2 py-1">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary btn-sm" (click)="deleteDivision()">Accept</button>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN Modal de confirmacion para borrar prueba -->

        <!-- Modal para fusionar divisiones -->
        <div class="modal fade" id="modal-merge-divisions" tabindex="-1" aria-labelledby="mergeDivisionsModalLabel" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-full-width">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="mergeDivisionsModalTitle">Merge Divisions</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" tabindex="-1">×</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                      <div class="col-4">
                        <label>Division 1</label>
                        <select class="form-control" id="mergeDivision1" [(ngModel)]="mergeDivision1" (change)="selectMergeDivision(mergeDivision1)">
                          <option value="">Select division</option>
                          <ng-container *ngFor="let division of divisions;">
                            <option [value]="division.id" *ngIf="division.id != mergeDivision2">{{ division.code }} - {{ division.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <div class="col-4">
                        <label>Division 2</label>
                        <select class="form-control" id="mergeDivision2" [(ngModel)]="mergeDivision2">
                          <option value="">Select division</option>
                          <ng-container *ngFor="let division of divisions;">
                            <option [value]="division.id" *ngIf="division.id != mergeDivision1">{{ division.code }} - {{ division.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <div class="col-4">
                        <label>New Division</label>
                        <ng-container *ngIf="mergeDivision1 && mergeDivision2">
                          <form class="needs-validation" [formGroup]="formMerge" id="formMerge" (ngSubmit)="mergeDivisions()" novalidate>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group">
                                  <label>Division Number</label>
                                  <input type="text" class="form-control" placeholder="Division Number" formControlName="number" required>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label>Division Name</label>
                                  <input type="text" class="form-control" placeholder="Name" formControlName="name" required>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label>Height</label>
                                  <input type="text" class="form-control" placeholder="Height" formControlName="height" required>
                                </div>
                              </div>
                              <ng-container *ngIf="!reining">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>USEF Section Code</label>
                                    <input type="text" class="form-control" placeholder="USEF Section Code" formControlName="usefSectionCode" required>
                                  </div>
                                </div>
                              </ng-container>
                              <div class="col-6">
                                <label>Keep class numbers</label><br>
                                <input type="checkbox" id="keep-class-numbers" data-switch="success" formControlName="keepClassNumbers"/>
                                <label for="keep-class-numbers" [attr.data-on-label]="'Yes'" data-off-label="No"></label>
                              </div>
                              <div class="col-6">
                                <label>Keep original divisions and entries</label><br>
                                <input type="checkbox" id="keep-divisions" data-switch="success" formControlName="keepDivisions"/>
                                <label for="keep-divisions" [attr.data-on-label]="'Yes'" data-off-label="No"></label>
                              </div>
                            </div>
                            <div class="col-12 text-center mb-3">
                              <button type="submit" id="submit-merge" class="btn btn-success"><span class="loader d-none" id="loader-merge"></span> Merge</button>
                            </div>
                          </form>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <ng-container *ngIf="mergeDivision1">
                          <table class="table table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col"><b>Class Number</b></th>
                                <th scope="col"><b>Class Name</b></th>
                                <ng-container *ngIf="!reining">
                                  <th scope="col"><b>USEF Section Code</b></th>
                                </ng-container>
                                <th scope="col"><b>Height</b></th>
                                <th scope="col"><b>Entries</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let class of getDivisionClasses(mergeDivision1)">
                                <tr [ngClass]="{'text-danger': !formMerge.value.keepDivisions}">
                                  <th><b>{{ class.numero }}</b></th>
                                  <td scope="row">{{ class.nombre }}</td>
                                  <ng-container *ngIf="!reining">
                                    <td>{{ class.usef_section }}</td>
                                  </ng-container>
                                  <td>{{ class.altura }}{{ class.height_unit }}</td>
                                  <td>-</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </ng-container>
                      </div>
                      <div class="col-4">
                        <ng-container *ngIf="mergeDivision2">
                          <table class="table table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col"><b>Class Number</b></th>
                                <th scope="col"><b>Class Name</b></th>
                                <ng-container *ngIf="!reining">
                                  <th scope="col"><b>USEF Section Code</b></th>
                                </ng-container>
                                <th scope="col"><b>Height</b></th>
                                <th scope="col"><b>Entries</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let class of getDivisionClasses(mergeDivision2)">
                                <tr [ngClass]="{'text-danger': !formMerge.value.keepDivisions}">
                                  <th scope="row"><b>{{ class.numero }}</b></th>
                                  <td>{{ class.nombre }}</td>
                                  <ng-container *ngIf="!reining">
                                    <td>{{ class.usef_section }}</td>
                                  </ng-container>
                                  <td>{{ class.altura }}{{ class.height_unit }}</td>
                                  <td>-</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </ng-container>
                      </div>
                      <div class="col-4">
                        <ng-container *ngIf="mergeDivision1 && mergeDivision2">
                          <table class="table table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col"><b>Class Number</b></th>
                                <th scope="col"><b>Class Name</b></th>
                                <ng-container *ngIf="!reining">
                                  <th scope="col"><b>USEF Section Code</b></th>
                                </ng-container>
                                <th scope="col"><b>Height</b></th>
                                <th scope="col"><b>Entries</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let class of getMergeDivisionClasses()">
                                <tr class="text-success">
                                  <th scope="row"><b>{{ class.numero }}</b></th>
                                  <td>{{ class.nombre }}</td>
                                  <ng-container *ngIf="!reining">
                                    <td>{{ class.usef_section }}</td>
                                  </ng-container>
                                  <td>{{ class.altura }}{{ class.height_unit }}</td>
                                  <td>-</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </ng-container>
                      </div>
                    </div>
                </div>
              </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div>
        <!-- FIN Modal para fusionar diviones -->

        <div class="row m-print-0 w-100">
          <div class="col-12 mt-3">
            <div class="card">
              <div class="card-body p-2">
                <form [formGroup]="form" (ngSubmit)="addDivision()" class="needs-validation" novalidate>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label>Division Number</label>
                        <input type="text" class="form-control" formControlName="code" required>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="name" required>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Cost</label>
                        <input type="number" class="form-control" formControlName="cost" required>
                      </div>
                    </div>
                    <!--div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="form-group">
                                                <label>Discount</label>
                                                <input type="number" class="form-control" formControlName="discount">
                                            </div>
                                        </div-->

                    <div class="col-3">
                      <div class="form-group">
                        <label>Type</label>
                        <select class="form-control" formControlName="type">
                          <option value="hunters">Hunters</option>
                          <option value="jumpers">Jumpers</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label>Charge by</label>
                        <select class="form-control" formControlName="charged">
                          <option value="class-fees">Class Fees</option>
                          <option value="division-fee">Division Fee</option>
                          <option value="division-discount">Division Discount</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label>Section Code</label>
                        <input type="text" class="form-control" formControlName="section_code">
                      </div>
                    </div>
                    <div class="col-3 text-right">
                      <label></label>
                      <div class="form-group text-sm-left text-center mb-0">
                        <button class="btn btn-success">Add division</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12 text-right mb-2">
            <button class="btn btn-primary mr-3" (click)="showMergeDivisions()"><i class="mdi mdi-merge"></i> Merge Divisions</button>
            <a class="btn btn-primary text-white mr-3" (click)="printDivisionCharts()"><i class="mdi mdi-printer"></i> Print Division Charts</a>
            <button type="button" class="btn btn-primary" (click)="printDivisions()"><i class="mdi mdi-printer"></i> Print Divisions</button>
          </div>
          <div class="col-12">
            <div class="card mb-1">
              <div class="card-header p-0 d-flex" style="color: #000000; background: white">
                <div class="px-2 py-1" style="width: 15%;"><b>Division Number</b></div>
                <div class="px-2 py-1" style="width: 30%;"><b>Name</b></div>
                <div class="px-2 py-1" style="width: 15%;"><b>Cost</b></div>
                <div class="px-2 py-1" style="width: 15%;"><b>Charge by</b></div>
                <div class="px-2 py-1" style="width: 10%;"><b>Type</b></div>
                <div class="px-2 py-1" style="width: 10%;"><b>Created</b></div>
                <div class="px-2 py-1" style="width: 3%;"><b></b></div>
                <div class="px-2 py-1" style="width: 3%;"><b></b></div>
                <div class="px-2 py-1" style="width: 3%;"><b></b></div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngFor="let division of divisions; let i = index;">
            <div [id]="'d'+division.id" class="custom-accordion mb-1">
              <div class="card mb-0">
                <div class="card-header p-0" id="headingOne">
                  <a class="custom-accordion-title d-flex p-0 collapsed" data-toggle="collapse"
                    [href]="'#dc'+division.id" aria-expanded="false" [attr.aria-controls]="'dc'+division.id">
                    <div class="my-auto px-2 py-1" style="width: 15%;">{{ division.code }}</div>
                    <div class="my-auto px-2 py-1" style="width: 30%;">{{ division.name }}</div>
                    <div class="my-auto px-2 py-1" style="width: 15%;">{{ division.cost | currency }}</div>
                    <div class="my-auto px-2 py-1" style="width: 15%;">{{ division.charged_by | titlecase }}</div>
                    <div class="my-auto px-2 py-1" style="width: 10%;">{{ division.type | titlecase }}</div>
                    <div class="my-auto px-2 py-1" style="width: 10%;">{{ division.created | date }}</div>
                    <div class="my-auto px-2 py-1" style="width: 3%;">
                      <a href="javascript: void(0);" class="action-icon" [routerLink]="['/division', division.id]"> <i
                          class="mdi mdi-pencil"></i></a>
                    </div>
                    <div class="my-auto px-2 py-1" style="width: 3%;">
                      <a href="javascript: void(0);" class="action-icon" (click)="modalRemoveDivision(division.id)"> <i
                          class="mdi mdi-delete"></i></a>
                    </div>
                    <div class="my-auto px-2 py-1" style="width: 3%;"><i
                        class="mdi mdi-chevron-down accordion-arrow"></i></div>
                  </a>
                </div>
                <div [id]="'dc'+division.id" class="collapse" aria-labelledby="headingOne"
                  [attr.data-parent]="'#d'+division.id" style="">
                  <div class="card-body p-0">
                    <div class="w-100 text-left p-1">
                      <button type="button" class="btn btn-success btn-sm"
                        [routerLink]="['/divisionCharts/', division.id]">Champ. Chart</button>
                    </div>
                    <div class="w-100 text-right p-1">
                      <button type="button" class="btn btn-success btn-sm"
                        [routerLink]="['/division', division.id, 'addClass']">Add class</button>
                    </div>
                    <ul class="list-group">
                      <li class="list-group-item py-1 px-2 justify-content-between d-flex"
                        *ngFor="let prueba of division.pruebas; let j = index;">
                        <span><b>{{ prueba.numero }}</b> {{ prueba.nombre }}</span>
                        <span>
                          <span class="mr-3" [ngClass]="{'visible': prueba.sponsorImage}" style="opacity: 0;">
                            <i class="mdi mdi-image"></i>
                            <small>Sponsor Banner</small>
                          </span>
                          <span [ngClass]="{'visible': prueba.croquis}" style="opacity: 0;">
                            <i class="mdi mdi-image"></i>
                            <small>Course Design</small>
                          </span>
                        </span>
                        <!--a href="javascript:;" (click)="removeClass(i, j)"><i class="mdi mdi-close"></i></a-->
                      </li>
                    </ul>
                  </div>
                </div>
              </div> <!-- end card-->
            </div>
          </div>
          <!--div class="col-3" *ngFor="let division of divisions; let i = index;">
                        <div class="card">
                            <div class="card-body p-2">
                                <div class="card-widgets text-right" style="float: unset;">
                                    <a href="javascript:;" class="mr-1" (click)="division.editar = !division.editar"><i class="mdi mdi-pencil-outline"></i></a>
                                    <a href="javascript:;" (click)="deleteDivision(division.id, i)"><i class="mdi mdi-delete"></i></a>
                                </div>
                                <div class="row">
                                    <ng-container *ngIf="!division.editar;else formEditDivision">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <p class="mb-0"><b>Name:</b> {{ division.name }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <p class="mb-0"><b>Code:</b> {{ division.code }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <p class="mb-0"><b>Cost:</b> {{ division.cost | currency }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <p class="mb-0"><b>Discount:</b> {{ division.discount | currency }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <p class="mb-0"><b>Type:</b> {{ division.type | titlecase }}</p>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #formEditDivision>
                                        <div class="col-12 mt-1">
                                            <div class="form-group d-flex">
                                                <label class="my-auto mr-2">Name:</label>
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="division.name">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group d-flex">
                                                <label class="my-auto mr-2">Code:</label>
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="division.code">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group d-flex">
                                                <label class="my-auto mr-2">Cost:</label>
                                                <input type="number" class="form-control form-control-sm" [(ngModel)]="division.cost">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group d-flex">
                                                <label class="my-auto mr-2">Discount:</label>
                                                <input type="number" class="form-control form-control-sm" [(ngModel)]="division.discount">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group d-flex">
                                                <label class="my-auto mr-2">Type:</label>
                                                <select class="form-control form-control-sm" [(ngModel)]="division.type">
                                                    <option value="hunters">Hunters</option>
                                                    <option value="jumpers">Jumpers</option>
                                                </select>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Add a class" (change)="addClass($event, i)">
                                        </div>
                                        <ul class="list-group">
                                            <li class="list-group-item py-1 px-2 justify-content-between d-flex" *ngFor="let prueba of division.pruebas; let j = index;">
                                                <span><b>{{ prueba.numero }}</b> {{ prueba.nombre }}</span>
                                                <a href="javascript:;" (click)="removeClass(i, j)"><i class="mdi mdi-close"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div-->
          <!--div class="col-12">
                        <div class="card">
                            <div class="card-body p-2">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Division Number</th>
                                            <th>Type</th>
                                            <th>Created</th>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let division of divisions; let i = index;">
                                            <td>{{ division.name }}</td>
                                            <td>{{ division.code }}</td>
                                            <td>{{ division.type | titlecase }}</td>
                                            <td>{{ division.created | date }}</td>
                                            <td class="table-action">
                                                <a href="javascript: void(0);" class="action-icon" [routerLink]="['/division', division.id]"> <i class="mdi mdi-pencil"></i></a>
                                            </td>
                                            <td class="table-action">
                                                <a href="javascript: void(0);" class="action-icon" (click)="deleteDivision(division.id, i)"> <i class="mdi mdi-delete"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div-->
        </div>
      </div>
    </div>
  </div>
</div>
