<div class="content">
    <ng-container *ngFor="let division$ of divisions; let i = index">
        <ng-container *ngIf="(division$ | async)?.division as division">
            <div class="division row">
                <div class="col-12">
                    <p class="text-center" ><b>{{ division.name }}</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p><b>Division Number: </b>{{ division.code }}</p>
                    <p><b>Cost: </b>{{ division.cost | currency }}</p>
                </div>
                <div class="col-6">
                    <p><b>Type: </b>{{ division.type | titlecase }}</p>
                    <p><b>Charge by: </b>{{ division.charged_by | titlecase }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Class</th>
                                <th>Section Code</th>
                                <th>Table</th>
                                <th>Height</th>
                                <th>Unit</th>
                                <th>Prize</th>
                                <th>PM Dist.</th>
                                <th>Cost</th>
                                <th>Additional Charges</th>
                                <th>Sponsor Banner</th>
                                <th>Course Design</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prueba of division.pruebas">
                                <td>{{ prueba.numero }}</td>
                                <td>{{ prueba.nombre }}</td>
                                <td>{{ prueba.section }}</td>
                                <td>{{ prueba.modalidad }}</td>
                                <td>{{ prueba.altura }}</td>
                                <td>{{ prueba.height_unit }}</td>
                                <td>{{ prueba.premio | currency:'USD':'symbol':'0.0-0' }}</td>
                                <td>{{ prueba.distribucion }}</td>
                                <td>{{ prueba.costo | currency:'USD':'symbol':'0.0-0' }}</td>
                                <td>{{ prueba.charges }}</td>
                                <td><i class="mdi mdi-image" *ngIf="prueba.sponsorImage"></i></td>
                                <td><i class="mdi mdi-image" *ngIf="prueba.croquis"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--div class="poweredBy d-none d-print-block mb-4">Powered by EquestrianDigital <img src="assets/images/logo.png"></div-->
        </ng-container>
    </ng-container>

</div>
