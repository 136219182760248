import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CaballoService } from '../services/caballo.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-horses-duplicated',
  templateUrl: './horses-duplicated.component.html',
  styleUrls: ['./horses-duplicated.component.css']
})
export class HorsesDuplicatedComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public caballos: any[];
  public tableCaballos: any;
  public form: UntypedFormGroup;
  public caballoSeleccionado: any;

  constructor(
    private caballoService: CaballoService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.caballos = [];
    this.tableCaballos = {};
    this.form = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      nsba: new UntypedFormControl('', []),
      owner: new UntypedFormControl('', []),
      chip: new UntypedFormControl('', []),
      country: new UntypedFormControl('', []),
      color: new UntypedFormControl('', []),
      complement: new UntypedFormControl('', []),
      breed: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      date: new UntypedFormControl('', []),
      sire: new UntypedFormControl('', []),
      dam: new UntypedFormControl('', []),
      height: new UntypedFormControl('', []),
      category: new UntypedFormControl('', []),
      nota: new UntypedFormControl('', [])
    });
    this.caballoSeleccionado = {};
  }

  ngAfterViewInit(): void {
    this.tableCaballos = $('#caballos').DataTable({
      aaSorting: [],
      columnDefs: [
        {
          targets: [0],
          visible: false
        }
      ],
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
      'createdRow': function( row, data, dataIndex ) {
        $(row).attr('id', data[0]);
      },
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      }
    });
    this.getCaballosDupicados();
    this.clickTable();
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
  }


  public getCaballosDupicados(){
    this.caballoService.getCaballosDupicados(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.caballos = response.caballos.map(c => {
            c.feis = c.feis.length == 0 ? [''] : c.feis;
            c.usefs = c.usefs.length == 0 ? [''] : c.usefs;
            c.nsbas = c.nsbas.length == 0 ? [''] : c.nsbas;
            c.owners = c.owners.length == 0 ? [''] : c.owners;
            c.chips = c.chips.length == 0 ? [''] : c.chips;
            c.countries = c.countries.length == 0 ? [''] : c.countries;
            c.colors = c.colors.length == 0 ? [''] : c.colors;
            c.complements = c.complements.length == 0 ? [''] : c.complements;
            c.breeds = c.breeds.length == 0 ? [''] : c.breeds;
            c.genders = c.genders.length == 0 ? [''] : c.genders;
            c.dates = c.dates.length == 0 ? [''] : c.dates;
            c.sires = c.sires.length == 0 ? [''] : c.sires;
            c.dams = c.dams.length == 0 ? [''] : c.dams;
            c.heights = c.heights.length == 0 ? [''] : c.heights;
            c.categories = c.categories.length == 0 ? [''] : c.categories;
            c.notas = c.notas.length == 0 ? [''] : c.notas;

            return c;
          });
          this.tableCaballos.clear().draw();
          this.tableCaballos.rows.add(this.caballos.map(p => [p.ids.join('-'), p.name])).draw(false);
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private clickTable(){
    $('#caballos tbody').on('click', 'tr', (event) => {
      const ids = event.currentTarget.getAttribute('id');
      const index = this.caballos.findIndex(p => p.ids.join('-') == ids);

      this.caballoSeleccionado = this.caballos[index];
      this.form.setValue({
        fei: this.caballoSeleccionado.feis[0],
        usef: this.caballoSeleccionado.usefs[0],
        nsba: this.caballoSeleccionado.nsbas[0],
        owner: this.caballoSeleccionado.owners[0],
        chip: this.caballoSeleccionado.chips[0],
        country: this.caballoSeleccionado.countries[0],
        color: this.caballoSeleccionado.colors[0],
        complement: this.caballoSeleccionado.complements[0],
        breed: this.caballoSeleccionado.breeds[0],
        gender: this.caballoSeleccionado.genders[0],
        date: this.caballoSeleccionado.dates[0],
        sire: this.caballoSeleccionado.sires[0],
        dam: this.caballoSeleccionado.dams[0],
        height: this.caballoSeleccionado.heights[0],
        category: this.caballoSeleccionado.categories[0],
        nota: this.caballoSeleccionado.notas[0]
      });
    });
  }

  public merge(){
    $('#loader').show();
    this.caballoService.merge(this.caballoSeleccionado.ids, this.form.value).subscribe(
      response => {
        if(!response.error){
          this.caballoSeleccionado = {};
          $.NotificationApp.send("Success", 'Horses have merged successfully ', 'bottom-center', '#3c763d', 'success');
          this.getCaballosDupicados();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to merge this horse.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
