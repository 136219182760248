
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PruebaService } from './prueba.service';
import { AngularFirestore, DocumentData, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
// import * as firebase from 'firebase';

declare let $: any;

@Injectable()
export class AppService extends Api {
  private timezones: any;

  constructor(private http: HttpClient, private pruebaService: PruebaService, private db: AngularFirestore) {
    super();
    this.timezones = {
      'America/New_York_DST': 'GMT -0400',
      'America/New_York': 'GMT -0400',
      'America/Chicago': 'GMT -0500',
      'America/Phoenix': 'GMT -0700',
      'America/Denver': 'GMT -0600',
      'America/Los_Angeles_DST': 'GMT -0700',
      'America/Los_Angeles': 'GMT -0800'
    };
  }

  public async updateClasses(idConcurso: any = '', ipc: any = '')/*: Observable<any>*/ {
    // TODO: Cambiar a api nueva api/v2 y las pruebas que devuelva mapearlas aqui al formato que usamos
    const params = {};
    if (idConcurso) { params['c'] = idConcurso; }
    if (ipc) { params['ipc'] = ipc; }
    const concursosRequest = await firstValueFrom(this.http.get<any>(`${environment.api.uri}actualizar/pruebas/index2.php`, { params }));
    if (concursosRequest.error) {
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    for (const concurso of concursosRequest.concursos) {
      const timezone = concurso.timezone;
      const batchPistas = this.db.firestore.batch();
      const batchPruebas = this.db.firestore.batch();
      let banderaPistas = false;
      // Consulta de pistas en firestore
      const pistasFS = await firstValueFrom(this.db.collection('concursos')
        .doc(`us_${concurso.concurso}`)
        .collection('pruebas', ref => ref.where('tipo', '==', 'pista')).get())
        .then<firebase.firestore.DocumentReference[]>(r => r.docs.map(p => p.ref));
      for (const pistaFS of pistasFS) {
        // banderaPistas = true;
        // batchPistas.delete(pistaFS);
      }
      for (const pista of concurso.pistas) {
        if (!pista.docId) { continue; }
        banderaPistas = true;
        batchPistas.set(this.db.collection('concursos')
          .doc(`us_${concurso.concurso}`)
          .collection('pruebas').doc(pista.docId).ref, pista, { merge: true });
      }
      for (const prueba of concurso.pruebas) {
        if (prueba.fecha && prueba.fecha !== '0000-00-00 00:00:00' && prueba.inicio && prueba.inicio !== '0000-00-00 00:00:00') {
          const classDate = new Date(
            `${prueba.fecha.replace(/-/g, '/')} ${this.timezones[timezone] ? this.timezones[timezone] : ''}`.trim()
          );
          prueba.fecha = firebase.firestore.Timestamp.fromDate(classDate);

          prueba.inicio = firebase.firestore.Timestamp
            .fromDate(new Date(
              `${prueba.inicio.replace(/-/g, '/')} ${this.timezones[timezone] ? this.timezones[timezone] : ''}`.trim())
            );
        } else {
          console.log('No inicio for class: ', prueba.numero, prueba.inicio);
        }
        batchPruebas.set(
          this.db.collection('concursos')
            .doc(`us_${concurso.concurso}`)
            .collection('pruebas').doc(prueba.ipc).ref, prueba, { merge: true });
      }
      if (!ipc) {// Actualizar el arreglo de días solo si no se especifica el ipc (se actualizan todas las pruebas)
        batchPruebas.set(this.db.collection('concursos').doc(`us_${concurso.concurso}`).ref, { dias: concurso.dias }, { merge: true });
      }

      const promises = [];
      if (banderaPistas) {
        promises.push(batchPistas.commit());
      }
      promises.push(batchPruebas.commit());

      Promise.all(promises).then(v => {
        $.NotificationApp.send('Updated', 'All classes were updated', 'bottom-right', '#06d5a1', 'success');
      });
    }
  }

  public async updateClass(idConcurso, ipc) {
    this.updateClasses(idConcurso, ipc);
  }

  public async updateShow(idConcurso, ipc: any = false) {
    console.log('updateShow', idConcurso, ipc);
    const params = { id: idConcurso };
    if (ipc) { params['ipc'] = ipc; }
    const binomiosRequest = await firstValueFrom(
      this.http.get<any>(`${environment.api.uri}actualizar/resultados/index3.php`,
        { params })).catch(r => ({ error: r }));
    if (binomiosRequest.error) {
      console.log(binomiosRequest.error);
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    let batch = this.db.firestore.batch();
    let actualizar = false;
    let i = 0;

    // Binomios
    console.log('Binomios', binomiosRequest.binomios);
    for (const binomio of binomiosRequest.binomios) {
      actualizar = true;
      batch.set(this.db.collection('resultados')
        .doc(`us_${binomio.ipc}`)
        .collection('resultados')
        .doc(`${binomio.id_binomio}`)
        .ref, binomio, { merge: true });
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
      const binomioNuevo = this.parseNewInterface(binomio);
      batch.set(this.db.collection('results')
        .doc(`us_${binomio.ipc}_${binomio.id_binomio.replace('us_', '')}`)
        .ref, binomioNuevo, { merge: true });
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
    }
    console.log('Recesos', binomiosRequest.recesos);
    // Recesos
    for (const receso of binomiosRequest.recesos) {
      actualizar = true;
      batch.set(this.db.collection('resultados')
        .doc(`us_${receso.ipc}`)
        .collection('resultados')
        .doc(`${receso.id}`)
        .ref, receso, { merge: true });
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
    }
    console.log('Participantes en prueba', binomiosRequest.inscritosCalificados);
    // Participantes en prueba
    for (const participantes of binomiosRequest.inscritosCalificados) {
      batch.set(this.db.collection('concursos')
        .doc(`us_${idConcurso}`)
        .collection('pruebas')
        .doc(`${participantes.ipc}`)
        .ref, participantes, { merge: true });
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
    }
    console.log('batch', batch);
    await batch.commit().catch(r => {
      console.log('commit', r);
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
    });
    return;
  }

  private parseNewInterface(binomio): any {
    if (binomio.inicio && binomio.inicio !== '0000-00-00 00:00:00') {
      binomio.inicio = firebase.firestore.Timestamp.fromDate(new Date(`${binomio.inicio.replace(/-/g, '/')} ${this.timezones[binomio.timezone] ? this.timezones[binomio.timezone] : ''}`.trim()));
    }

    const b: any = { ...binomio };
    b.accumulated = binomio.acumulado || '';
    b.classDate = binomio.inicio || (new Date());
    b.className = binomio.nombrePrueba || '';
    b.classified = binomio.clasificado === '1';
    b.currentRank = binomio.rankTabla || `${binomio.posicion}`;
    b.entryNumber = binomio.cucarda || '';
    b.faults = binomio.faltas || '';
    // TODO: definir de donde va a salir esta info
    b.faultsDetail = '';
    b.horseId = binomio.id_caballo || '';
    b.horseInfo = binomio.datosCaballo || '';
    b.horseName = binomio.caballo || '';
    b.id = binomio.id_binomio;
    b.inRing = binomio.en_pista === '1';
    b.ipc = `us_${binomio.ipc}`;
    b.owner = binomio.owner || '';
    b.order = binomio.order || 0;
    b.previous = (binomio.anterior || '0') === '1';
    b.prize = binomio.premio || '';
    b.rank = binomio.posicion || '';
    b.result1 = binomio.result1 || '-';
    b.result2 = binomio.result2 || '';
    b.result3 = binomio.result3 || '';
    b.riderAcronym = binomio.siglas || '';
    b.riderBadge = binomio.logo_url || '';
    b.riderBadgeDark = binomio.logoDark || '';
    b.riderCategory = binomio.categoria || '';
    b.riderId = binomio.id_jinete || '';
    b.riderName = binomio.jinete || '';
    b.riderPhoto = binomio.foto || '';
    b.riderShortName = binomio.jinetePantalla || '';
    b.showName = sessionStorage.nombreConcurso || '';
    b.startTime = binomio.startTime || '';
    b.time = binomio.tiempo || '';
    b.video1 = binomio.bloqueo1 !== '1';
    b.video2 = binomio.bloqueo2 !== '1';
    b.video3 = false;
    b.video1Url = binomio.video_app || '';
    b.video2Url = binomio.video_app_2 || '';
    b.video3Url = '';
    b.onlyOtomi = false;

    return b;
  }

  public async updateShows(idConcurso = '') {
    const params = idConcurso ? { c: idConcurso } : {};
    // TODO: Cambiar a api nueva api/v2 y las pruebas que devuelva mapearlas aqui al formato que usamos
    const concursosRequest = await firstValueFrom(this.http.get<any>(`${environment.api.uri}actualizar/concursos/index2.php`, { params }));
    if (concursosRequest.error) {
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const batchConcursosActivos = this.db.firestore.batch();
    const batchConcursosInactivos = this.db.firestore.batch();
    let banderaConcursosInactivos = false;
    let banderaConcursosActivos = false;
    for (const concurso of concursosRequest.concursosActivos) {
      banderaConcursosActivos = true;
      if (concurso.inicio !== '0000-00-00 00:00:00' && concurso.fin !== '0000-00-00 00:00:00') {
        concurso.inicio = firebase.firestore.Timestamp.fromDate(new Date(concurso.inicio.replace(/-/g, '/')));
        concurso.fin = firebase.firestore.Timestamp.fromDate(new Date(concurso.fin.replace(/-/g, '/')));
      }
      batchConcursosActivos.set(this.db.collection('concursos').doc(concurso.id).ref, concurso, { merge: true });
    }
    for (const concurso of concursosRequest.concursosInactivos) {
      banderaConcursosInactivos = true;
      batchConcursosInactivos.delete(this.db.collection('concursos').doc(concurso).ref);
    }

    const promises = [];
    if (banderaConcursosActivos) {
      promises.push(batchConcursosActivos.commit());
    }
    if (banderaConcursosInactivos) {
      promises.push(batchConcursosInactivos.commit());
    }

    Promise.all(promises).then(v => {
      $.NotificationApp.send('Updated', 'All shows were updated', 'bottom-right', '#06d5a1', 'success');
    });
  }

  public async updateShowFirestore(idConcurso) {
    this.updateShows(idConcurso);
    // return this.http.get(`${environment.api.uri}actualizar/concursos/byId.php?c=${idConcurso}`);
  }

  public async updateMasterList(idConcurso) {
    const params = {};
    if (idConcurso) {
      params['c'] = idConcurso;
    }
    const binomiosRequest = await firstValueFrom(
      this.http.get<any>(`${environment.api.uri}actualizar/masterList/index2.php?c=${idConcurso}`));
    if (binomiosRequest.error) {
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const binomiosFS = await firstValueFrom(
      this.db.collection('concursos')
        .doc(`us_${idConcurso}`)
        .collection('masterlist').get()).then<firebase.firestore.DocumentReference[]>( r => r.docs.map(p => p.ref));
    const masterListFS = await firstValueFrom(
      this.db.collection('masterLists',
          queryFn => queryFn.where('show', '==', `us_${idConcurso}`))
        .get()).then<firebase.firestore.DocumentReference[]>(r => r.docs.map(p => p.ref));
    // Eliminar todos los binomios que esten en firebase antes de sincronizar
    let batch = this.db.firestore.batch();
    let i = 0;
    const promises = [];
    let actualizar = false;

    binomiosFS.forEach((binomioFS: any, index) => {
      actualizar = true;
      batch.delete(binomioFS);
      i++;
      if (i % 500 === 0 || index === binomiosFS.length - 1) {
        promises.push(batch.commit().then(e => console.log(`Deleted ${index + 1} documents`)).catch(e => console.log(e)));
        batch = this.db.firestore.batch();
      }
    });

    masterListFS.forEach((binomioFS: any, index) => {
      actualizar = true;
      batch.delete(binomioFS);
      i++;
      if (i % 500 === 0 || index === masterListFS.length - 1) {
        promises.push(batch.commit().then(e => console.log(`Deleted ${index + 1} documents`)).catch(e => console.log(e)));
        batch = this.db.firestore.batch();
      }
    });

    let actualizarBinomios = false;
    binomiosRequest.binomios.forEach((binomio, index) => {
      actualizarBinomios = true;
      batch.set(
        this.db
          .collection('concursos')
          .doc(`us_${idConcurso}`)
          .collection('masterlist')
          .doc(`${binomio.id_binomio}`)
          .ref,
        binomio,
        { merge: true });
      i++;
      batch.set(
        this.db
          .collection('masterLists')
          .doc(`us_${idConcurso}_${binomio.id_binomio}`)
          .ref,
        {
          entry: binomio.dorsal,
          horseName: binomio.caballo,
          riderName: binomio.jinete,
          show: `us_${idConcurso}`
        },
        { merge: true });
      i++;
      if (i % 500 === 0 || index === binomiosRequest.binomios.length - 1) {
        promises.push(batch.commit().then(e => console.log(`Uploaded ${index + 1} documents`)).catch(e => console.log(e)));
        batch = this.db.firestore.batch();
      }
    });

    await Promise.all(promises).then(
      r => {
        $.NotificationApp.send('Updated', 'Master List were updated', 'bottom-right', '#06d5a1', 'success');
      }
    );

    const binomiosFD = [];
    if (binomiosRequest.error) {
      $.NotificationApp.send('Error', 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
  }

  public createAnnouncement(body, title): Observable<any> {
    const params: string = JSON.stringify({ body, title });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}actualizar/create-announcements/`, params, { headers: headers });
  }

  public async deleteEntriesAndUpdate(idConcurso, ipc) {
    let batch = this.db.firestore.batch();
    let borrarEntries = false;
    let i = 0;
    const entries = await firstValueFrom(
      this.db.collection('resultados')
      .doc(`us_${ipc}`)
      .collection('resultados')
      .get()).then(r => r.docs.map(d => d.ref));
    const entriesResults = await firstValueFrom(
      this.db.collection('results', queryFn => queryFn
        .where('ipc', '==', `us_${ipc}`)).get()).then(r => r.docs.map(d => d.ref));

    for (const entry of entries) {
      borrarEntries = true;
      batch.delete(entry);
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
    }

    for (const entry of entriesResults) {
      borrarEntries = true;
      batch.delete(entry);
      if (++i === 450) {
        await batch.commit();
        i = 0;
        batch = this.db.firestore.batch();
      }
    }

    if (borrarEntries) {
      await batch.commit();
    }
    this.updateShow(idConcurso, ipc);
  }

  public async downloadAsExcel(sheets: { [name: string]: any[] }, fileName: string = 'file') {
    const wb = {
      SheetNames: Object.keys(sheets),
      Sheets: Object.entries(sheets)
        .map<[string, XLSX.WorkSheet]>(([n, r]) => [n, XLSX.utils.json_to_sheet(r)]).reduce((pv, [k, v]) => ({ ...pv, [k]: v }), {})
    };
    const wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    const wbout = XLSX.write(wb, wopts);
    saveAs(new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}), `${fileName}.xlsx`);
  }

  public async arrayToCsv(array: any[], fileName: string = 'file') {
    const [el] = array;
    const keys = Object.keys(el);
    const headers = keys.map(h => `"${h}"`).join(',');
    const rows = [headers, ...array.map(row => keys.map(k => `"${row[k]}"`).join(','))].join('\n');

    saveAs(new Blob([rows], { type: 'text/csv' }), `${fileName}.csv`);
  }

  private s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }
    return buf;
  }

  public updateTeamLogos(riders, feis, idConcurso): Promise<any> {
    const params = JSON.stringify({ riders, feis, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(
      `${environment.api.nest.uri}mlsj/updateTeamLogos`,
      params, { headers: headers }))
      .catch(reason => ({
        error: true, message:
          (reason.error || {}).text
          || (((reason.error
            || {}).error
            || {}).message
            || (reason.message || (reason.error || (reason || 'Error during request.')))) }));
  }
}
