
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid" style="width: 100%; float: left; display: flex;">
        <div class="col-md-12 margin-top-35" style="padding:0px;">
          <div class="card">
            <div class="card-body padding-0">
              <div class="col-md-12" style="margin-top:30px;">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Contabilidad(venta de videos)
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="widget widget-fullwidth widget-small table-responsive">
                      <table class="table table-fw-widget table-hover table-condensed">
                        <thead>
                          <tr>
                            <th>Método de pago</th>
                            <th>Paquete</th>
                            <th>Paquetes vendidos</th>
                            <th>Ventas totales</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let venta of balanceVentaPaquetes">
                            <td *ngIf="venta.metodo == null">Sin método de pago asignado</td>
                            <td *ngIf="venta.metodo != null">{{ venta.metodo }}</td>
                            <td *ngIf="venta.cantidad == '1'">{{ venta.cantidad }} Video ({{ venta.monto | currency }})</td>
                            <td *ngIf="venta.cantidad != '1'">{{ venta.cantidad }} Videos ({{ venta.monto | currency }})</td>
                            <td>{{ venta.paquetes_vendidos }}</td>
                            <td>{{ venta.ventas_totales | currency }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="col-md-12">
                <div class="col-md-12" style="margin-top: 10px;">
                  <div class="widget widget-fullwidth widget-small table-responsive">
                    <table class="table table-fw-widget table-hover table-condensed">
                      <thead>
                        <tr>
                          <th>Paquete comprado</th>
                          <th>Pago</th>
                          <th>Fecha</th>
                          <th>Info</th>
                          <th>Cuenta</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let pago of ventasSinClienteAsignado">
                          <td *ngIf="pago.cantidad != '1'">{{ pago.cantidad }} Videos</td>
                          <td *ngIf="pago.cantidad == '1'">{{ pago.cantidad }} Video</td>
                          <td>{{ pago.monto | currency }} <br> {{ pago.metodo}} </td>
                          <td>{{ pago.fecha_venta | date:'EEEE, MMMM d, y' }}</td>
                          <td>{{ pago.notas }}</td>
                          <td>
                            <input type="text" placeholder="Ingresa el nombre del cliente" class="form-control" [matAutocomplete]="autocompleteCliente" (keyup)="getResponsablesVideos($event)">
                            <mat-autocomplete #autocompleteCliente="matAutocomplete" [displayWith]="displayResponsableCuenta"> 
                              <mat-option *ngFor="let cliente of cuentasDeVideos" [value]="cliente" class="fila-autocomplete" (click)="setClienteVideo(cliente.id,pago.id)">
                                {{ (cliente.nombre_cliente) ? cliente.nombre_cliente : '-' }}
                              </mat-option>
                            </mat-autocomplete>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
