import { environment } from './../../environments/environment';

export abstract class Api {
    protected url: string;
    protected api_key: string;
    protected test: boolean;
    protected sr: boolean;
    
    constructor(){
        this.url = 'https://api.equestrian.digital/';
        //this.url = 'http://localhost:8000/';
        this.api_key = '7cc263a1-a490-4337-8302-151490495e56';
        this.test = !environment.production;
        this.sr = sessionStorage.getItem('sr') === '377';
    }
}
