<!-- Loader FEI -->
<div id="loader-fei">
    <div class="sk-cube-grid" >
        <svg width="168.75053mm" height="50.237492mm" viewBox="0 0 597.93496 178.00686" id="FEI-svg" sodipodi:docname="drawing.svg">
            <path id="fei-picto"
            d="m 217.744,20.586 6.203,-14.78 -90.265,0 0,70.734 c 0,5.674 -1.319,10.82 -7.918,12.008 l 91.98,0 6.203,-14.78 -64.532,0 0,-20.058 51.863,0 5.411,-13.725 -57.274,0 0,-19.399 58.329,0 z m 15.176,67.962 25.734,0 0,-82.742 -25.734,0 0,82.742 z M 104.913,70.733 c -5.674,-4.223 -10.161,-8.182 -9.765,-14.78 -3.035,4.882 -4.091,10.557 -4.091,14.12 0,10.425 6.466,17.287 8.313,18.475 l 17.552,0 c -9.369,-3.035 -3.167,-11.217 -12.009,-17.815 z m 23.094,-22.698 c 0,-2.507 -2.111,-4.355 -4.486,-4.355 -2.508,0 -4.488,1.848 -4.488,4.355 0,2.508 1.98,4.487 4.488,4.487 2.375,0 4.486,-1.979 4.486,-4.487 z m -32.859,7.654 7.918,-13.725 -43.072,0 C 74.165,25.059 96.043,17.301 122.861,21.378 l 8.973,-15.572 -23.225,0 c -35.098,0 -63.603,11.393 -80.156,36.158 l -20.535,0 L 0,55.689 l 21.087,0 c -3.993,9.495 -6.643,20.422 -7.759,32.859 l 29.297,0 c 1.042,-12.473 3.986,-23.492 8.556,-32.859 l 43.967,0 z m 181.396,-42.832 0,-4.501 -1.95,4.501 -1.141,0 -1.891,-4.441 0,4.441 -1.468,0 0,-7.051 1.911,0 2.067,4.908 2.067,-4.908 1.796,0 0,7.051 -1.391,0 z m -9.832,-5.737 0,5.737 -1.468,0 0,-5.737 -2.163,0 0,-1.314 5.795,0 0,1.314 -2.164,0 z"
            inkscape:connector-curvature="0" style="fill:#4a0d53;" stroke="#4a0d53" class="path" />
        </svg>
    </div>
    <div style="position: fixed; left: 50%; top: 52%; z-index: 2; font-size:4em; color:#4a0d53">
        <i class="mdi mdi-spin mdi-loading"></i>
    </div>
</div>

<!-- Loader ED -->
<div id="loader" style="display:none; opacity: 1; background: rgba(255, 255, 255, 0.4); z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>

<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <form>
                                    <div id="progressbarwizard">

                                        <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
                                            <li class="nav-item">
                                                <a href="#account-2" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2 active show">
                                                    <i class="mdi mdi-flag-variant mr-1"></i>
                                                    <span class="d-none d-sm-inline">Shows</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#profile-tab-2" data-toggle="tab" id="step2" class="nav-link rounded-0 pt-2 pb-2" [ngClass]="showEntries ? 'enabled' : 'disabled'">
                                                    <i class="mdi mdi-format-list-numbered mr-1"></i>
                                                    <span class="d-none d-sm-inline">Entries</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#finish-2" data-toggle="tab" id="step3" class="nav-link rounded-0 pt-2 pb-2" [ngClass]="saving ? 'enabled' : 'disabled'">
                                                    <i class="mdi mdi-checkbox-marked-circle-outline mr-1"></i>
                                                    <span class="d-none d-sm-inline">Finish</span>
                                                </a>
                                            </li>
                                        </ul>
                                    
                                        <div class="tab-content b-0 mb-0">
                                    
                                            <div id="bar" class="progress mb-3" style="height: 7px;">
                                                <div class="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style="width: 33.3333%;"></div>
                                            </div>
                                    
                                            <div class="tab-pane active show" id="account-2">
                                                <div class="row">
                                                    <div class="col-12" *ngIf="error">
                                                        <h3 class="text-center">Please verify that the FEI Show ID id correct in <a href="javascript:void(0)" routerLink="/show-info" routerLinkActive="router-link-active" >Show Info</a></h3>
                                                    </div>
                                                    <!--div class="col-6" *ngFor="let concurso of concursos">
                                                        <div class="form-group mb-3">
                                                            <div class="custom-control custom-radio">
                                                                <input type="radio" [id]="concurso.show_code" name="show" class="custom-control-input" [value]="concurso" (change)='getPruebasFei(concurso.show_code)'>
                                                                <label class="custom-control-label" [for]="concurso.show_code">
                                                                    <div>{{ concurso.venue_name }} </div>
                                                                    <div style='font-weight: normal;'><i class="dripicons-calendar"></i> {{ concurso.show_date_start | date: 'MMM d' }} - {{ concurso.show_date_end  | date}}</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div-->
                                                </div> <!-- end row -->
                                            </div>

                                            <div class="tab-pane" id="profile-tab-2">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <h4 class="header-title mt-0 mb-3">Show Entries</h4>
                                                                <p class="text-muted font-13">
                                                                    This is the latest data in the FEI platform.
                                                                </p>
                                                                <hr>
                                                                <p class="text-muted font-13">
                                                                    Please make sure the information is correct and click next to import this information into the system.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div class="table-responsive-sm">
                                                                    <table class="table table-striped table-hover table-borderless table-centered table-sm mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Horse</th>
                                                                                <th>Rider</th>
                                                                                <th>Country</th>
                                                                                <th>Event</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let entry of entries; let index = index;">
                                                                                <td>{{ index + 1 }}</td>
                                                                                <td [ngClass]="{'text-danger': caballosFaltantes.indexOf(entry.horse.fei) > -1}">{{ entry.horse.horsename }} ({{ entry.horse.fei }})</td>
                                                                                <td [ngClass]="{'text-danger': jinetesFaltantes.indexOf(entry.rider.fei) > -1}">{{ entry.rider.name }} {{ entry.rider.lastname }} ({{ entry.rider.fei }})</td>
                                                                                <td>{{ entry.rider.country }}</td>
                                                                                <td>{{ entry.event }}</td>
                                                                                <td><span class="badge badge-success">Confirmed entry</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <ul class="list-inline mb-0 wizard text-center">
                                                    <li class="list-inline-item">
                                                        <a href="javascript:void(0)" class="btn btn-info" style='color:white' (click)='validate()' [hidden]="validado">
                                                            <i class="mdi mdi-check mr-1"></i>
                                                            <span>Validate</span>
                                                        </a>
                                                    </li>
                                                    <li class="list-inline-item float-right">
                                                        <button class="btn btn-success" (click)='saveEntries()' [hidden]="!validado">
                                                            <i class="mdi mdi-thumb-up mr-1"></i>
                                                            <span>Import</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="tab-pane" id="finish-2">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="text-center">
                                                            <h2 class="mt-0"><i class="mdi mdi-check-all"></i></h2>
                                                            <h3 class="mt-0">Thank you !</h3>

                                                            <p class="w-75 mb-2 mx-auto">All entries for this shows have been synchronized and imported.</p>
                                                        </div>
                                                    </div> <!-- end col -->
                                                </div> <!-- end row -->
                                            </div>
                                        </div> <!-- tab-content -->
                                    </div> <!-- end #progressbarwizard-->
                                </form>

                            </div> <!-- end card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
