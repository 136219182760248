<div id="loader" style="display:block; opacity: 1; background: rgba(255, 255, 255, 0.4); z-index: 1051;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row justify-content-center">
                    <div class="col-10 mt-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8">
                                        <p><b>Reservation:</b> {{ id }}</p>
                                        <p><b>Trainer:</b> {{ name }}</p>
                                    </div>
                                    <div class="col-4 text-right">
                                        <span [ngSwitch]="status">
                                            <span *ngSwitchCase="'0'">Pending</span>
                                            <span *ngSwitchCase="'1'">Accepted</span>
                                            <span *ngSwitchDefault>{{ status }}</span>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <table class="table table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Concept</th>
                                                    <th>Qty</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cargo of cargos">
                                                    <td>{{ cargo.nombre }}</td>
                                                    <td>{{ cargo.qty }}</td>
                                                    <td>{{ cargo.total }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 mt-3 text-center">
                                        <button class="btn btn-success" type="button" *ngIf="status == '0'" (click)="aceptarReservacion()">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
