import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { UntypedFormControl, Validators, Form } from '@angular/forms';

declare var jquery:any;
declare var $: any;

@Component({
  selector: 'crear-anuncio',
  templateUrl: './crear-anuncio.component.html',
  styleUrls: ['./crear-anuncio.component.css']
})
export class CrearAnuncioComponent implements OnInit, AfterViewInit {
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  public siglasClubConcurso: string;
  public baseUrl: string;
  public titleAnnouncements: UntypedFormControl;
  public bodyAnnouncements: UntypedFormControl;
  public createdAnnoucements: UntypedFormControl;
  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService
  ) {
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.idConcurso = '';
    this.siglasClubConcurso = "chls"; // TODO: Traer dinamicamente
    this.baseUrl = window.location.hostname;
    this.titleAnnouncements = new UntypedFormControl( '', [] );
    this.bodyAnnouncements = new UntypedFormControl('', []);
    this.createdAnnoucements = new UntypedFormControl('', []);
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 200){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
  }

  ngAfterViewInit(){

  }

  public enabledButton(){
    let body = this.bodyAnnouncements.value;
    let title = this.titleAnnouncements.value;
    if(body != '' && title != ''){
      $("#submitButton").removeAttr('disabled');
    }else{
      $("#submitButton").prop('disabled', true);
    }
  }

  public createAnnouncement(){
    let body = this.bodyAnnouncements.value;
    let title = this.titleAnnouncements.value;
    if(body != '' && title != ''){
      this.appService.createAnnouncement(body, title).subscribe(
        response => {
          $.NotificationApp.send("Success!", "The announcement was published.",'bottom-right', '#06d5a1', 'success');
          this.bodyAnnouncements.reset();
          this.titleAnnouncements.reset();
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
