import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterResultados'
})

export class FilterResultadosPipe implements PipeTransform{
    transform(value: Array<any>, query: number): Array<any>{
        if(query > 0){
            return value.filter(v => {
                return v.categoria == query.toString();
            });
        }

        return value;
    }
}