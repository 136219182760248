<ng-container *ngFor="let entry$ of entryDetails;">
  <div class="entry" *ngIf="entry$ | async as entry">
    <div class="row justify-content-center" style="padding: 10px 0;">
      <div class="col-4">
        <div class="mr-3">
          <p class="mb-0">
            <b style="vertical-align: middle;">{{ entry.entry }} - {{entry.horseName}}</b>
          </p>
          <p class="mb-0"></p>
        </div>
      </div>
      <div class="col-4 text-left"></div>
      <div class="col-4 text-right">
        <div style="color:black; font-weight: bold;font-size: 1rem;">Balance: {{ entry.saldo | currency }}</div>
        <span style="line-height: 19px" class="text-panel visible-print-block">{{ date | date: 'dd/MM/yyyy hh:mm a'
          }}</span>
      </div>
    </div>
    <!-- Encabezado Personas -->
    <div class="row justify-content-center" style="padding: 10px 0;">
      <div class="col-5">
        <div class="mr-3">
          <p class="mb-1">Payee: <b class="mr-3">{{entry.payee}}</b></p>
          <p class="mb-1">{{ entry.payeeAddress }}</p>
          <p class="mb-1">{{ entry.payeeAddress2 }}</p>
        </div>
      </div>
      <div class="col-4 text-left">
        <ng-container *ngIf="entry?.entrenadores?.length > 0">
          <p class="mb-1">Trainer: <b>{{ entry?.entrenadores[0]?.name }}</b></p>
          <p class="mb-1">{{ entry.entrenadores[0]?.address }}</p>
          <p class="mb-1">{{ entry.entrenadores[0]?.address2 }}</p>
          <p class="mb-1">{{ entry.entrenadores[0]?.trainerPhone }}</p>
        </ng-container>
      </div>
      <div class="col-3 text-right">
        <p class="mb-0">Owner: <b>{{ entry.owner }}</b></p>
        <p class="mb-1">{{ entry.ownerAddress }}</p>
        <p class="mb-1">{{ entry.ownerAddress2 }}</p>
      </div>
    </div>
    <!-- Encabezado Inscripciones -->
    <div class="card m-0 d-print-none">
      <div class="card-body text-panel p-0">
        <div class="card-header" style="color: #ffffff !important; background: rgb(65, 65, 65); padding: 0.25rem 1rem">
          <h3 class="m-0" style="width: fit-content; display: contents;">
            <span>Riders & Classes</span>
          </h3>
          <span class="d-print-none" style="float: right;"></span>
        </div>
      </div>
    </div>
    <!-- Contenido Inscripciones-->
    <div class="card-body text-panel p-print-0 d-print-none">
      <table class="table table-hover table-sm table-condensed m-print-0">
        <thead style="display:none;">
          <tr>
            <th><b>Rider</b></th>
            <th><b>Notes</b></th>
          </tr>
        </thead>
        <tbody>
          <tr class="page-break" *ngFor="let binomio of entry.binomios">
            <td>
              {{ binomio.jinete }}
              <br>
              <img *ngIf="!binomio.suspended" class="d-print-none" style="max-width: 20px;" src="assets/images/icons/success.svg">
              <img *ngIf="binomio.suspended" class="d-print-none" data-toggle="tooltip" [title]="binomio?.suspendedReason" style="max-width: 20px;" src="assets/images/icons/warning.svg">
              <img *ngIf="!binomio.ushja" class="ml-1 d-print-none" style="max-width: 40px;" src="assets/images/icons/ushja_green.png">
              <img *ngIf="binomio.ushja" class="ml-1 d-print-none" data-toggle="tooltip" [title]="binomio?.ushjaReason" style="max-width: 40px;" src="assets/images/icons/ushja_red.png">
              <img *ngIf="!binomio.usef" class="ml-1 d-print-none" style="max-width: 25px;" src="assets/images/icons/usef_green.png">
              <img *ngIf="binomio.usef" class="ml-1 d-print-none" data-toggle="tooltip" [title]="binomio?.usefReason" style="max-width: 25px;" src="assets/images/icons/usef_red.png">
            </td>
            <td>
              <div style="margin-bottom: 7px;" *ngFor="let inscripcion of binomio.inscripciones">
                <span class="circulo">{{ inscripcion.nombre }}</span>
                <span class="cat-prueba" *ngIf="inscripcion.multicosto">{{ inscripcion.siglas }}</span>
                <mat-icon style="font-size: 16px; height: 16px; width: 16px;vertical-align: middle">event</mat-icon>
                {{ inscripcion.inicio | date: 'EEEEEE dd MMM yyyy' | titlecase }}
                <b [ngClass]="{scratched: inscripcion.scratched}">{{ inscripcion.costo | currency }}</b>
                <span *ngIf="(inscripcion.scratched || inscripcion.extemporaneo) && !inscripcion.anularMulta"
                  style="display: inline-table;">
                  <span class="label" [ngClass]="{'label-danger': inscripcion.scratched, 'label-blue': inscripcion.extemporaneo}" data-toggle="tooltip" [title]="inscripcion.scratched ? 'Scratcheado' : 'Extemporaneo'">{{inscripcion.multa | currency }}</span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Encabezado class fees -->
    <div class="card m-0">
      <div class="card-body text-panel p-0">
        <div class="card-header" style="color: #ffffff !important; background: rgb(65, 65, 65); padding: 0.25rem 1rem">
          <h3 class="m-0" style="width: fit-content; display: contents;">
            <span>Class & Division Fees</span>
            <span style="float: right;">{{ entry.totalInscripciones | currency }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- Contenido Inscripciones-->
    <div class="card-body text-panel p-print-0">
      <table class="table table-hover table-sm table-condensed m-print-0">
        <thead>
          <tr>
            <th>Reference</th>
            <th style="text-align: right;">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr class="page-break" *ngFor="let cargo of entry.cargosInscripciones">
            <td>{{cargo.referencia}}</td>
            <td style="text-align: right;">{{cargo.costo | currency}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--  Charges -->
    <div class="row">
      <div class="col-12">
        <div class="card-header bg-danger" style="color: #ffffff !important; padding: 0.25rem 1rem">
          <h3 style="margin: 0px">
            <span>Charges</span>
            <span style="float:right">{{ entry.totalCargos | currency }}</span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <thead>
              <tr>
                <th>Concept</th>
                <th>Notes</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <!--tr [ngClass]="{'d-print-none': entry.totalInscripciones == 0}">
                  <td colspan="3">Class Entries</td>
                  <td>{{ entry.totalInscripciones | currency }}</td>
                </tr-->
              <ng-container *ngFor="let cargo of entry.cargos; let index = index">
                <tr [ngClass]="{'d-print-none': cargo.monto == 0}">
                  <td>{{ cargo.nombre }}</td>
                  <td>{{ cargo.referencia }}</td>
                  <td>{{ cargo.cantidad }}</td>
                  <td>{{ cargo.monto | currency }}</td>
                </tr>
                <ng-container *ngIf="cargo.mostrarDetalle">
                  <tr *ngFor="let detalleCargo of cargo.detalle" [ngClass]="{'d-print-none': detalleCargo.monto == 0}">
                    <td>{{ detalleCargo.referencia }}</td>
                    <td>{{ detalleCargo.cantidad }}</td>
                    <td>{{ detalleCargo.monto | currency }}</td>
                    <td></td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Feed Orders -->
      <div class="col-3 d-print-none">
        <div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1rem">
          <h3 style="margin: 0px">
            <span>Feed orders summary</span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <thead>
              <tr>
                <th>Concept</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of entry.orders">
                <td>{{ order.producto }}</td>
                <td>{{ order.cantidad }}</td>
                <td>{{ order.total | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Splits -->
    <ng-container *ngIf="entry.totalSplits > 0">
      <div class="card-header bg-info" style="color: #ffffff !important; padding: 0.25rem 1rem">
        <h3 class="m-0">
          <span>Splits</span>
          <span style="float:right">{{ entry.totalSplits | currency }}</span>
        </h3>
      </div>
      <div class="card-body text-panel p-print-0">
        <table class="table table-hover table-condensed m-print-0" *ngIf="entry.splits.length > 0">
          <thead>
            <tr>
              <th>Trainer</th>
              <th>Notes</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let split of entry.splits" [ngClass]="{'d-print-none': split.anulado}">
              <td>{{ split.entrenador }}</td>
              <td>{{ split.notas }}</td>
              <td [ngClass]="{scratched: split.anulado}">{{ split.monto | currency }}</td>
              <td>{{ split.fecha | date: 'EEEEEE dd MMM yyyy' | titlecase }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <!-- Pagos -->
    <div class="card-header bg-success" style="color: #ffffff !important; padding: 0.25rem 1rem">
      <h3 style="margin: 0px">
        <span>Payments</span>
        <span style="float:right">{{ entry.totalPagos | currency }}</span>
      </h3>
    </div>
    <div class="card-body text-panel p-print-0">
      <table class="table table-hover table-condensed m-print-0">
        <thead>
          <tr>
            <th>Method</th>
            <th><span class="d-print-none">Notes</span></th>
            <th><span class="d-print-none">Date</span></th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pago of entry.pagos" [ngClass]="{'d-print-none': pago.anulado}">
            <td>{{ traducirMetodoDePago(pago.metodo) }}</td>
            <td><span class="d-print-none">{{ pago.referencia }}</span></td>
            <td><span class="d-print-none">{{ pago.fecha_pago | date: 'EEEEEE dd MMM yyyy' | titlecase }}</span></td>
            <td [ngClass]="{scratched: pago.anulado}">{{ pago.monto | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Saldo -->
    <div class="card-header bg-light" style="padding: 0.25rem 1rem">
      <h3 style="margin: 0px; text-align:center">
        <span>Balance:{{entry.saldo | currency }}</span>
      </h3>
    </div>
    <!-- Prizes -->
    <!-- Premios -->
    <div class="row justify-content-center mt-2">
      <div class="col-6" *ngIf="entry.premiosAplicados?.length > 0">
        <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
          <h3 style="margin: 0px">
            <span>Applied Prizes</span>
            <span style="float:right">{{ entry.totalPremiosAplicados | currency }}</span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <thead>
              <tr>
                <th>Prize</th>
                <th>Class</th>
                <th>Rider</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let premio of entry.premiosAplicados">
                <td>{{ premio.nombre }}</td>
                <td>{{ premio.nombrePrueba }}</td>
                <td>{{ premio.jinete }}</td>
                <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                <td *ngIf="premio.monto == 0"> - </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="text-center">
                  <!-- Prize money is not applied, please check the Payee and
                  provide a W-9 form. -->
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="col-6" *ngIf="entry.premios?.length > 0">
        <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
          <h3 style="margin: 0px">
            <span>Unapplied Prizes</span>
            <span style="float:right">{{ entry.totalPremios | currency }}</span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <thead>
              <tr>
                <th>Prize</th>
                <th>Class</th>
                <th>Rider</th>
                <th>Amount</th>
                <th *ngIf="entry.championshipSeries">Championship Series</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let premio of entry.premios">
                <td>{{ premio.nombre }}</td>
                <td>{{ premio.nombrePrueba }}</td>
                <td>{{ premio.jinete }}</td>
                <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                <td *ngIf="premio.monto == 0"> - </td>
                <td *ngIf="entry.championshipSeries">
                  <img *ngIf="premio.id_prueba == '2'" style="max-height: 20px;" src="https://firebasestorage.googleapis.com/v0/b/srjt/o/championshipSeries.png?alt=media&token=d0cc7397-3317-4a66-878a-233f3a5f064d">
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="text-center">
                  <!-- Prize money is not applied, please check the Payee and
                  provide a W-9 form. -->
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="col-6" *ngIf="entry.premiosChampionshipSeries?.length > 0">
        <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
          <h3 style="margin: 0px">
            <span>Championship Series Prizes</span>
            <span style="float:right">{{ entry.totalPremiosChampionshipSeries | currency }}</span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <thead>
              <tr>
                <th>Prize</th>
                <th>Class</th>
                <th>Rider</th>
                <th>Amount</th>
                <!--th *ngIf="entry.championshipSeries">Championship Series</th-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let premio of entry.premiosChampionshipSeries">
                <td>{{ premio.nombre }}</td>
                <td>{{ premio.nombrePrueba }}</td>
                <td>{{ premio.jinete }}</td>
                <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                <td *ngIf="premio.monto == 0"> - </td>
                <!--td *ngIf="entry.championshipSeries">
                  <img *ngIf="premio.id_prueba == '2'" style="max-height: 20px;" src="https://firebasestorage.googleapis.com/v0/b/srjt/o/championshipSeries.png?alt=media&token=d0cc7397-3317-4a66-878a-233f3a5f064d">
                </td-->
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="text-center">
                  <!-- Prize money is not applied, please check the Payee and
                  provide a W-9 form. -->
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="col-6" *ngIf="entry.resultados?.length > 0">
        <div class="card-header"
          style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: rgb(65, 65, 65);">
          <h3 style="margin: 0px">
            <span>Results</span>
            <span style="float:right"></span>
          </h3>
        </div>
        <div class="card-body text-panel p-print-0">
          <table class="table table-hover table-condensed m-print-0">
            <tbody>
              <tr *ngFor="let resultado of entry.resultados">
                <td><span class="circulo">{{ resultado.numero_prueba }}</span></td>
                <td>{{ resultado.nombre_prueba }}</td>
                <td>{{ resultado.jinete }}</td>
                <td>
                  <span style="color:#FFBC00">
                    <i class="mdi mdi-trophy"></i>{{ resultado.posicion }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-6">
        <app-check-form [entry]="entry.entry" *ngIf="entry.entry" [hideForm]="true"></app-check-form>
      </div>
    </div>

    <!-- Invoice Closing Footer -->
    <div class="row mt-3 mb-1">
      <div class="col-12 text-center">
        <img [src]="entry.footer" style="max-height: 120px;" onerror="this.style.display='none'"
          onload="this.style.display='-webkit-inline-box'">
      </div>
    </div>

    <div class="poweredBy mb-3">Powered by EquestrianDigital <img src="assets/images/logo.png"></div>

    <!--div>
      {{ (entryDetails[i] | async) | json }}
    </div-->
  </div>
</ng-container>
