import { Component, OnInit } from '@angular/core';
import { CaballoService } from '../../services/caballo.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'limpiarBDCaballos',
    templateUrl: 'limpiarBDCaballos.component.html'
})

export class LimpiarBDCaballos implements OnInit{
    public nombreConcurso: string;
    public idConcurso: string | boolean;
    public feiDuplicados;
    public caballosPorFEI;
    public caballosPorFEM;
    public caballoSeleccionado: any;
    public nombreCaballoSeleccionado: any;
    public propietarioCaballoSeleccionado: any;
    public caballoEliminado: any;
    public nombreCaballoEliminado: any;
    public propietarioCaballoEliminado: any;
    

    constructor(
        private limpiarCaballos: CaballoService,
        private authService: AuthService,
        private router: Router
    ){
        this.nombreConcurso = "";
        this.idConcurso = "";
        this.feiDuplicados = [];
        this.caballosPorFEI = [];
        this.caballosPorFEM = [];
        this.caballoSeleccionado = {};
        this.nombreCaballoSeleccionado = '';
        this.propietarioCaballoSeleccionado = '';
        this.caballoEliminado = {};
        this.nombreCaballoEliminado = '';
        this.propietarioCaballoEliminado = '';
    }

    ngOnInit(){
        if (this.authService.validarConcurso()) {
            this.idConcurso = this.authService.validarConcurso();
        } else {
            this.router.navigate(['']);
            return;
        }
        this.traerCaballos();
    }

    private traerCaballos(){
        this.limpiarCaballos.getFEIDuplicados(this.idConcurso).subscribe(
            response => {
                this.feiDuplicados = response.feiduplicados;
            },
            error => {}
        )
    }

    public traerCaballosPorFEI(fei){
        this.caballoSeleccionado = {};
        this.caballoEliminado = {};
        this.limpiarCaballos.getCaballosPorFEI(fei, this.idConcurso).subscribe(
            response => {
                this.caballosPorFEI = response.caballos;
            },
            error => {}
        )
    }

    public seleccionarCaballoAConservar(idcaballo,nombre, propietario){
        this.caballoSeleccionado = idcaballo;
        this.nombreCaballoSeleccionado = nombre;
        this.propietarioCaballoSeleccionado = propietario;
        if(this.caballoEliminado == this.caballoSeleccionado){
            this.caballoEliminado = '';
            this.nombreCaballoEliminado = '';
            this.propietarioCaballoEliminado = '';
        }
    }

    public seleccionarCaballoABorrar(caballo, nombre, propietario){
        this.caballoEliminado = caballo;
        this.nombreCaballoEliminado = nombre;
        this.propietarioCaballoEliminado = propietario;
        if(this.caballoEliminado == this.caballoSeleccionado){
            this.caballoSeleccionado = '';
            this.nombreCaballoSeleccionado = '';
            this.propietarioCaballoSeleccionado = '';
        }
    }

    public mergeCaballos(viejo, nuevo){
        //this.caballoEliminado = {};
        //this.caballoSeleccionado = {};
        this.limpiarCaballos.mergeCaballos(viejo,nuevo).subscribe(
            response => {
                console.log("Borrado: " + viejo);
                console.log("Seleccionado: " + nuevo);
            },
            error => {
                console.log("Error:");
            } 
        )
    }
}