
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class SerieService extends Api {;

    constructor(private http: HttpClient) {
        super();
    }

    public getSeries(idConcurso): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}series/?c=${idConcurso}`, {headers: headers});
    }

    public getTipos(): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}series/tipos/`, {headers: headers});
    }

    public createSerie(serie): Observable<any>{
        const params = JSON.stringify({ serie });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}series/add/`, params, {headers: headers});
    }

    public updateSerie(serie): Observable<any>{
        const params = JSON.stringify({ serie });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}series/update/`, params, {headers: headers});
    }
}
