import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CaballoService } from '../services/caballo.service';
import { AuthService } from '../services/auth.service';
import { PersonaService } from '../services/persona.service';
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { firstValueFrom } from 'rxjs';
declare var $: any;
export interface Caballos {
}
@Component({
  selector: 'directorio-caballos',
  templateUrl: './directorio-caballos.component.html',
  styleUrls: ['./directorio-caballos.component.css']
})

export class DirectorioCaballosComponent implements OnInit, AfterViewInit {
  private idUsuario: string;
  public idConcurso: string | boolean;
  public nombreConcurso: string;
  private token: string;
  private sessionData: any;
  public caballoSeleccionado: string;
  public horses: any[];
  public form: UntypedFormGroup;
  public mostrarPanel: boolean;
  public owners: any[];
  public cargando: boolean;
  public dataSource;
  public displayedColumns: string[];
  public reining: boolean;

  constructor(
    private caballoService: CaballoService,
    private authService: AuthService,
    private personaService: PersonaService,
    private router: Router
  ) {
    this.idUsuario = '';
    this.idConcurso = '';
    this.token = '';
    this.sessionData = {};
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.caballoSeleccionado = '';
    this.horses = [];
    this.form = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      ushja: new UntypedFormControl('', []),
      nrha: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required]),
      chip: new UntypedFormControl('', []),
      owner: new UntypedFormControl('', []),
      //ownerUsef: new FormControl('', []),
      country: new UntypedFormControl('', []),
      color: new UntypedFormControl('', []),
      colorComplement: new UntypedFormControl('', []),
      breed: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      dateOfBirth: new UntypedFormControl('', []),
      sire: new UntypedFormControl('', []),
      dam: new UntypedFormControl('', []),
      suspensionStatus: new UntypedFormControl('', []),
      height: new UntypedFormControl('', []),
      height_category: new UntypedFormControl('', []),
      notes: new UntypedFormControl('', [])
    });
    this.mostrarPanel = false;
    this.owners = [];
    this.cargando = true;
    this.dataSource = new MatTableDataSource<Caballos>();
    this.reining = sessionStorage.getItem('reining') == '1';
    this.displayedColumns = this.reining ? ['Name', 'Nrha', 'Owner', 'Edit'] : ['Name', 'Usef', 'Fei', 'Owner', 'Edit'];
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getHorses();
  }

  public getHorses() {
    this.caballoService.getHorses(this.idConcurso).subscribe(
      response => {
        this.cargando = false;
        if (!response.error) {
          const horses: any[] = response.caballos.map(h => {
            return [h.name, h.usef_id, h.fei, h.nrha||'', h.owner, `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon" horse="${h.id_caballo}"> <i class="mdi mdi-pencil"></i></a></div>`];
          });
          this.horses = response.caballos;
          this.dataSource.data = response.caballos;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        this.cargando = false;
        $.NotificationApp.send("Error", "It has not been possible to query the horses list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filterPredicate = (data: Caballos, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    }
    this.dataSource.filter = filterValue;
  }

  public selectHorse(idCaballo: string) {
    this.cargando = true;
    this.caballoSeleccionado = idCaballo;
    $('#editHorse').removeClass('was-validated');
    this.mostrarPanel = true;
    //Consultar datos de caballo
    this.caballoService.getInfo(idCaballo).subscribe(
      response => {
        if (!response.error) {
          this.owners = [{
            id_persona: response.info.owner_id,
            fullname: response.info.owner_name
          }];
          console.log(response.info);
          this.form.setValue({
            fei: response.info.fei,
            usef: response.info.usef_id,
            ushja: response.info.ushja_id,
            nrha: response.info.nrha,
            name: response.info.name,
            chip: response.info.chip_id,
            owner: response.info.owner_id,
            //ownerUsef: response.info.ownerUsef,
            country: response.info.country_of_birth,
            color: response.info.color,
            colorComplement: response.info.color_complement,
            breed: response.info.breed,
            gender: response.info.gender,
            dateOfBirth: response.info.date_of_birth,
            sire: response.info.sire,
            dam: response.info.dam,
            suspensionStatus: response.info.suspensionStatus,
            height: response.info.height,
            height_category: response.info.height_category,
            notes: response.info.notes
          });
          this.cargando = false;
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        this.cargando = false;
        $.NotificationApp.send("Error", "It has not been possible to query the info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public panelAgregar() {
    $("#fei-horse").focus();
    this.caballoSeleccionado = '';
    this.mostrarPanel = true;
    this.owners = [];
    $('#editHorse').removeClass('was-validated');
    this.form.setValue({
      fei: '',
      usef: '',
      ushja: '',
      nrha: '',
      name: '',
      chip: '',
      owner: '',
      //ownerUsef: '',
      country: '',
      color: '',
      colorComplement: '',
      breed: '',
      gender: '',
      dateOfBirth: '',
      sire: '',
      dam: '',
      suspensionStatus: '',
      height: '',
      height_category: '',
      notes: ''
    });
  }

  public editHorse() {
    //Validar que el valor de owner sea tomado desde la lista
    const indexOwner = this.owners.findIndex(p => p.id_persona == this.form.get('owner').value);
    if (indexOwner == -1) {
      this.form.get('owner').setValue('');
    }

    if (this.form.valid) {
      this.cargando = true;
      //editar caballo
      if (this.caballoSeleccionado) {
        firstValueFrom(this.caballoService.editHorse(this.form.value, this.caballoSeleccionado, this.idConcurso, this.idUsuario)).then(
          response => {
            if (!response.error) {
              this.caballoSeleccionado = '';
              this.mostrarPanel = false;
              this.getHorses();
            } else {
              this.cargando = false;
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
          }).catch(error => {
          this.cargando = false;
          $.NotificationApp.send("Error", "It has not been possible to edit the horse.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        });
      } else { //Agregar caballo
        firstValueFrom(this.caballoService.addCaballo(this.form.value, this.idConcurso, this.idUsuario)).then(
          response => {
            if (!response.error) {
              this.mostrarPanel = false;
              this.getHorses();
            } else {
              this.cargando = false;
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
        }).catch(error => {
          this.cargando = false;
          $.NotificationApp.send("Error", "It has not been possible to add the horse.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        });
      }
    }
  }

  public getHorseFeiInfo() {
    const fei = this.form.get('fei').value.toUpperCase();
    if (fei) {
      $('#loader-fei').show();
      this.caballoService.getFeiInfo(fei).subscribe(
        response => {
          if (!response.error) {
            $('#loader-fei').hide();
            const caballo = response.caballo;
            this.form.get('name').setValue(caballo.name ? caballo.name : this.form.get('name').value);
            this.form.get('chip').setValue(caballo.chipId ? caballo.chipId : this.form.get('chip').value);
            this.form.get('country').setValue(caballo.countryOfOrigin ? caballo.countryOfOrigin : this.form.get('country').value);
            this.form.get('color').setValue(caballo.color ? caballo.color : this.form.get('color').value);
            this.form.get('breed').setValue(caballo.breed ? caballo.breed : this.form.get('breed').value);
            this.form.get('gender').setValue(caballo.gender ? caballo.gender : this.form.get('gender').value);
            this.form.get('dateOfBirth').setValue(caballo.dateOfBirth ? caballo.dateOfBirth : this.form.get('dateOfBirth').value);
            this.form.get('sire').setValue(caballo.sire ? caballo.sire : this.form.get('sire').value);
            this.form.get('dam').setValue(caballo.dam ? caballo.dam : this.form.get('dam').value);
            $.NotificationApp.send("Success", "" , 'bottom-right', '#00b581', 'success');
            //this.form.get('owner').setValue(caballo.owner ? caballo.owner : this.form.get('owner').value);
          } else {
            $('#loader-fei').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader-fei').hide();
          console.log(error);
          $.NotificationApp.send("Something didn´t work...", "We are having problems performing this request. Please try again or notify this error", 'bottom-right', '#fa5c7c', 'error');
        }
      );
    } else {
      $.NotificationApp.send("Invalid FEI Number.", "The FEI number is invalid or the input is empty. Please double check the number and try again", 'bottom-right', '#ffbc00', 'warning',7000);
    }
  }

  public getHorseUsefData() {
    const usef = this.form.get('usef').value;
    if(usef){
      $('#loader-usef').show();
      this.caballoService.getUsefData(usef, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            $('#loader-usef').hide();
            const caballo = response.usefData;
            this.form.get('name').setValue(caballo.name ? caballo.name : this.form.get('name').value);
            this.form.get('chip').setValue(caballo.chip ? caballo.chip : this.form.get('chip').value);
            this.form.get('country').setValue(caballo.countryOfOrigin ? caballo.countryOfOrigin : this.form.get('country').value);
            this.form.get('color').setValue(caballo.color ? caballo.color : this.form.get('color').value);
            this.form.get('breed').setValue(caballo.breed ? caballo.breed : this.form.get('breed').value);
            this.form.get('gender').setValue(caballo.gender ? caballo.gender : this.form.get('gender').value);
            this.form.get('dateOfBirth').setValue(caballo.dateofBirth ? caballo.dateofBirth : this.form.get('dateOfBirth').value);
            this.form.get('sire').setValue(caballo.sire ? caballo.sire : this.form.get('sire').value);
            this.form.get('dam').setValue(caballo.dam ? caballo.dam : this.form.get('dam').value);
            //this.form.get('ownerUsef').setValue(caballo.usefOwner ? caballo.usefOwner : this.form.get('ownerUsef').value);
            this.owners = [{
              id_persona: caballo.owner.id,
              fullname: caballo.owner.name
            }];
            this.form.get('owner').setValue(caballo.owner.id ? caballo.owner.id : this.form.get('owner').value);
            this.form.get('fei').setValue(caballo.fei ? caballo.fei : this.form.get('fei').value);
            this.form.get('height').setValue(caballo.height ? caballo.height : this.form.get('height').value);
            this.form.get('suspensionStatus').setValue(caballo.suspensionStatus ? caballo.suspensionStatus : this.form.get('suspensionStatus').value);
          } else {
            $('#loader-usef').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader-usef').hide();
          console.log(error);
          $.NotificationApp.send("Error", "It has not been possible to perform the query.", 'bottom-right', '#fa5c7c', 'error');
        }
      );
    } else {
      $.NotificationApp.send("Invalid Usef ID.", "The Usef ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async getHorseNrhaData() {
    this.cargando = true;
    const nrha = this.form.value.nrha;
    if(!nrha){
      this.cargando = false;
      $.NotificationApp.send("Invalid NRHA ID.", "The Nrha ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    const response = await this.caballoService.getNrhaData(nrha).toPromise().then(r => r||{}, r => r||{});
    if(response.error){
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#ffbc00', 'warning');
      this.cargando = false;
      return;
    }
    const horseName = response.horseName||'';
    const foalDate = response.foalDate||'';
    const ownerMemberNumber = response.ownerMemberNumber||'';
    const ownerName = (response.ownerName||'').trim();
    const gender = (response.sex||'').trim();
    const damName = (response.damName||'').trim();
    const sireName = (response.sireName||'').trim();
    this.form.patchValue({
      name: horseName||'',
      dateOfBirth: foalDate||'',
      gender,
      dam: damName||'',
      sire: sireName||''
    });
    this.cargando = false;
  }

  public getPersonas(e) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            this.owners = response.personas;
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the owners, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.owners = [];
    }
  }

  public displayOwner(idPersona): string {
    const owner = this.owners.find(p => p.id_persona == idPersona);

    return owner ? owner.fullname : idPersona;
  }
}

