<!-- Loader -->
<ng-container *ngTemplateOutlet="loaderED"></ng-container>
<!-- Loader fei -->
<ng-container *ngTemplateOutlet="loaderFEI"></ng-container>
<!-- Loader USEF -->
<ng-container *ngTemplateOutlet="loaderUsef"></ng-container>
<div class="wrapper entries">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <!-- Modal traspaso -->
    <ng-container *ngTemplateOutlet="modalTraspaso"></ng-container>
    <!-- Modal inscripciones -->
    <ng-container *ngTemplateOutlet="modalAddDrops"></ng-container>
    <!-- Modal rider swap -->
    <ng-container *ngTemplateOutlet="modalRiderSwap"></ng-container>
    <!-- Modal agregar binomio -->
    <ng-container *ngTemplateOutlet="modalAddCombination"></ng-container>
    <!-- Modal editar jinete / entrenador -->
    <ng-container *ngTemplateOutlet="modalEditRider"></ng-container>
    <!-- Modal cambiar entrenador -->
    <ng-container *ngTemplateOutlet="modalChangeTrainer"></ng-container>
    <!-- Modal editar entry -->
    <ng-container *ngTemplateOutlet="modalEditEntry"></ng-container>
    <!-- Modal cambiar dueño -->
    <ng-container *ngTemplateOutlet="modalChangeOwner"></ng-container>
    <!-- Modal editar dueño -->
    <ng-container *ngTemplateOutlet="modalEditOwner"></ng-container>
    <!-- Modal cambiar numero de entry -->
    <ng-container *ngTemplateOutlet="modalEditEntryNumber"></ng-container>
    <!-- Modal cambiar caballo -->
    <ng-container *ngTemplateOutlet="modalEditHorse"></ng-container>
    <!-- Modal anular multa -->
    <ng-container *ngTemplateOutlet="modalAnularMulta"></ng-container>
    <!-- Motivo anular multa -->
    <ng-container *ngTemplateOutlet="modalMotivoAnularMulta"></ng-container>
    <!-- Modal pago con tarjeta (paysafe) -->
    <!--ng-container *ngTemplateOutlet="modalPaysafe"></ng-container-->
    <!-- Modal pago con tarjeta (chase) -->
    <ng-container *ngTemplateOutlet="modalCardPayment"></ng-container>
    <!-- Modal pago cheque -->
    <ng-container *ngTemplateOutlet="modalCheckPayment"></ng-container>
    <!-- Modal logs -->
    <ng-container *ngTemplateOutlet="modalLog"></ng-container>
    <!-- Modal motivo de anulacion traspaso -->
    <ng-container *ngTemplateOutlet="modalMotivoAnulacionTraspaso"></ng-container>
    <!-- Modal productos -->
    <ng-container *ngTemplateOutlet="modalProductos"></ng-container>
    <!-- Modal Stripe -->
    <ng-container *ngTemplateOutlet="modalStripePayment"></ng-container>
    <div class="content">
      <menu [concurso]="concurso?.name" [idConcurso]="concurso?.id" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 m-print-0">
          <!-- Panel entries -->
          <div class="panel d-print-none" [ngClass]="{'col-4': panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner') || panel.get('horse') || pantalla == 1, 'col-6': !(panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner') || panel.get('horse') || pantalla == 1)}">
            <div class="card">
              <div class="card-header bg-dark text-white">
                Entries
                <div class="card-widgets">
                  <a href="javascript:void(0)" (click)="showAddEntry()"><i class="mdi mdi-plus-circle-outline"></i></a>
                </div>
              </div>
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-8">
                    <div class="form-group mb-3">
                      <input type="text" [(ngModel)]="searchPerson" class="form-control"
                        (keyup)="applyFilter($event.target.value)" placeholder="Search Horse/Person/Back #">
                    </div>
                  </div>
                </div>
                <table class="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>Entry</th>
                      <th>Horse</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let entry of entries">
                      <td>{{ entry?.entry }}</td>
                      <td>{{ entry?.horse }}</td>
                      <td class="table-action">
                        <a href="javascript: void(0);" class="action-icon" (click)="selectEntry(entry?.entry)"> <i
                            class="mdi mdi-magnify"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <app-pagination [current]="current" [total]="total" [totalItems]="totalItems" (goTo)="onGoTo($event)"
                  (next)="onNext($event)" (previous)="onPrevious($event)"></app-pagination>
              </div>
            </div>
          </div>
          <!-- Panel add entries -->
          <div class="panel"
            [ngClass]="{'col-4': panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner') || panel.get('horse'), 'col-6': !(panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner') || panel.get('horse')), 'col-0': pantalla == 1}">
            <div class="card">
              <div class="card-header bg-dark text-white">
                Add Entry
              </div>
              <div class="card-body p-2">
                <form [formGroup]="entryForm" id="entryForm" (ngSubmit)="addEntry($event)" class="needs-validation"
                  novalidate>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label>Entry</label>
                        <input class="form-control" type="text" formControlName="entry" id="input-entry" required>
                        <div class="invalid-feedback">
                          Please provide an entry.
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Horse</label>
                        <div class="input-group">
                          <input class="form-control" type="text" formControlName="horse" required
                            [matAutocomplete]="autocompleteCaballo" (keyup)="getCaballos($event)">
                          <mat-autocomplete #autocompleteCaballo="matAutocomplete"
                            [displayWith]="displayCaballo.bind(this)">
                            <mat-option *ngFor="let caballo of caballos" [value]="caballo.id_caballo"
                              class="fila-autocomplete caballo" style="height:5em; line-height: 2em"
                              (click)="seleccionarCaballo(caballo)">
                              <span id="horseName" style="font-weight: bold;">{{ caballo.fullname||'-' }}</span>
                              <div id="owner" style="font-size: .8em; line-height: .8em">
                                {{caballo.owner}}
                              </div>
                              <div>
                                <span id="USEF">
                                  <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                                  caballo.usef }}
                                </span>
                                <span *ngIf="caballo.fei" id="FEI">
                                  <img src="/assets/images/icons/fei.svg" alt="FEI: "
                                    style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{
                                  caballo.fei }}
                                </span>
                              </div>

                            </mat-option>
                          </mat-autocomplete>
                          <div class="input-group-append" *ngIf="add.get('horse')">
                            <button type="button" class="btn btn-icon btn-success" (click)="mostrarPanelHorse()"><i
                                class="mdi mdi-plus"></i></button>
                          </div>
                          <div class="invalid-feedback">
                            Please choose a horse.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Owner</label>
                        <div class="input-group">
                          <input type="text" class="form-control" [matAutocomplete]="autocompleteOwnerEntry"
                            (keyup)="getPersonas($event, 'owner')" formControlName="owner">
                          <mat-autocomplete #autocompleteOwnerEntry="matAutocomplete"
                            [displayWith]="displayOwner.bind(this)">
                            <mat-option *ngFor="let owner of owners" [value]="owner.id_persona"
                              class="fila-autocomplete" style="height:5em; line-height: 2em">
                              <img [src]="owner.flag" style="height:.9em" />
                              <span id="horseName" style="font-weight: bold;">{{ owner.fullname||'-' }}</span>
                              <div>
                                <span id="USEF">
                                  <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{ owner.usef }}
                                </span>
                                <span *ngIf="owner.fei" id="FEI">
                                  <img src="/assets/images/icons/fei.svg" alt="FEI: "
                                    style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ owner.fei }}
                                </span>
                              </div>
                            </mat-option>
                          </mat-autocomplete>
                          <div class="input-group-append" *ngIf="add.get('owner')">
                            <button type="button" class="btn btn-icon btn-success" (click)="mostrarPanelPersona('owner')"><i class="mdi mdi-plus"></i></button>
                          </div>
                          <div class="invalid-feedback">
                            Please choose a owner.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Payee</label>
                        <div class="input-group">
                          <input class="form-control" type="text" formControlName="payee" required [matAutocomplete]="autocompletePayee" (keyup)="getPersonas($event, 'payee')">
                          <mat-autocomplete #autocompletePayee="matAutocomplete" [displayWith]="displayResponsable.bind(this)">
                            <mat-option *ngFor="let responsable of responsables" [value]="responsable.id_persona"
                              class="fila-autocomplete" style="height:5em; line-height: 2em">
                              <img [src]="responsable.flag" style="height:.9em" />
                              <span id="horseName" style="font-weight: bold;">{{ responsable.fullname||'-' }}</span>
                              <div>
                                <span id="USEF">
                                  <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                                  responsable.usef }}
                                </span>
                                <span *ngIf="responsable.fei" id="FEI">
                                  <img src="/assets/images/icons/fei.svg" alt="FEI: "
                                    style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{
                                  responsable.fei }}
                                </span>
                              </div>
                            </mat-option>
                          </mat-autocomplete>
                          <div class="input-group-append" *ngIf="add.get('payee')">
                            <button type="button" class="btn btn-icon btn-success"
                              (click)="mostrarPanelPersona('payee')"><i class="mdi mdi-plus"></i></button>
                          </div>
                          <div class="invalid-feedback">
                            Please choose a payee.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Rider</label>
                        <div class="input-group">
                          <input class="form-control" type="text" formControlName="rider"
                            [matAutocomplete]="autocompleteRider" (keyup)="getPersonas($event, 'rider')"
                            style="margin-bottom: 10px;">
                          <mat-autocomplete #autocompleteRider="matAutocomplete"
                            [displayWith]="displayJinete.bind(this)"
                            (optionSelected)="riderSelected($event.option.value)">
                            <mat-option *ngFor="let jinete of jinetes" [value]="jinete.id_persona"
                              class="fila-autocomplete" style="height:5em; line-height: 2em">
                              <img [src]="jinete.flag" style="height:.9em" />
                              <span id="horseName" style="font-weight: bold;">{{ jinete.fullname||'-' }}</span>
                              <div>
                                <span id="USEF">
                                  <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                                  jinete.usef }}
                                </span>
                                <span *ngIf="jinete.fei" id="FEI">
                                  <img src="/assets/images/icons/fei.svg" alt="FEI: "
                                    style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{
                                  jinete.fei }}
                                </span>
                              </div>
                            </mat-option>
                          </mat-autocomplete>
                          <div class="input-group-append" *ngIf="add.get('rider')">
                            <button type="button" class="btn btn-icon btn-success"
                              (click)="mostrarPanelPersona('rider')"><i class="mdi mdi-plus"></i></button>
                          </div>
                          <div class="col-12">
                            <span *ngFor="let rider of newEntryRiders"
                              style="border: 1px solid darkgrey; font-size:12px; min-width: 80%; padding:5px;"
                              class="badge badge-primary badge-pill mr-1">
                              {{ rider.fullname }}
                              <a href="javascript:;" class="text-white" (click)="removeRider(rider.id)"
                                style="float:right; padding-right:5px;">
                                <i class=" mdi mdi-close"></i></a>
                            </span>
                          </div>
                          <div class="invalid-feedback">
                            Please choose a rider.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Trainer</label>
                        <div class="input-group">
                          <input class="form-control" type="text" formControlName="trainer" required
                            [matAutocomplete]="autocompleteTrainer" (keyup)="getPersonas($event, 'trainer')">
                          <mat-autocomplete #autocompleteTrainer="matAutocomplete"
                            [displayWith]="displayEntrenador.bind(this)">
                            <mat-option *ngFor="let entrenador of entrenadores" [value]="entrenador.id_persona"
                              class="fila-autocomplete" style="height:5em; line-height: 2em">
                              <img src="{{entrenador.flag}}" style="height:.9em" />
                              <span id="horseName" style="font-weight: bold;">{{ (entrenador.fullname) ?
                                entrenador.fullname : '-' }}</span>
                              <div>
                                <span id="USEF">
                                  <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                                  entrenador.usef }}
                                </span>
                                <span *ngIf="entrenador.fei" id="FEI">
                                  <img src="/assets/images/icons/fei.svg" alt="FEI: "
                                    style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{
                                  entrenador.fei }}
                                </span>
                              </div>
                            </mat-option>
                          </mat-autocomplete>
                          <div class="input-group-append" *ngIf="add.get('trainer')">
                            <button type="button" class="btn btn-icon btn-success"
                              (click)="mostrarPanelPersona('trainer')"><i class="mdi mdi-plus"></i></button>
                          </div>
                          <div class="invalid-feedback">
                            Please choose a trainer.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mt-3">
                      <button type="submit" class="btn btn-info">Add</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Panel add person -->
          <div class="panel"
            [ngClass]="{'col-4': panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner'), 'col-0': !(panel.get('rider') || panel.get('payee') || panel.get('trainer') || panel.get('owner')) || pantalla == 1}">
            <div class="card">
              <div class="card-header bg-dark text-white" style="white-space: nowrap;">
                Add Person
                <div class="card-widgets">
                  <a href="javascript:void(0)" (click)="cerrarPaneles()"><i class="mdi mdi-close"></i></a>
                </div>
              </div>
              <div class="card-body">
                <form id="ownerForm" [formGroup]="personForm" (ngSubmit)="agregarPersona()" class="needs-validation"
                  novalidate>
                  <div class="row">
                    <!-- Campos FEI y USEF -->
                    <ng-container *ngIf="!reining">
                      <div class="col-6">
                        <div class="form-group">
                          <label># FEI</label>
                          <input type="text" class="form-control" style="text-transform: uppercase;"
                            formControlName="fei">
                        </div>
                      </div>
                      <div class="col-6">
                        <button style="margin-top: 27px; height: 37.39px;color:#4a0d53" type="button" mat-raised-button
                          (click)="getPersonFeiInfo()">
                          <img src="assets/images/icons/fei.svg" alt="fei-logo" class="fei-logo">
                          <!--mat-icon style="margin-top: -7px; margin-right: 8px;height: 30px;width: 30px;" [svgIcon]="'fei-logo'"></mat-icon-->
                          CONNECT
                        </button>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label># USEF</label>
                          <input type="text" class="form-control" formControlName="usef">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="custom-control custom-checkbox mr-3" style="margin-top: 37px;">
                            <input type="checkbox" class="custom-control-input" id="organization"
                              [(ngModel)]="organization" [ngModelOptions]="{standalone: true}">
                            <label class="custom-control-label" for="organization">Org</label>
                          </div>
                          <button style="margin-top: 27px; height: 37.39px;color:#113978" type="button"
                            mat-raised-button (click)="getPersonUsefData()">
                            <img src="assets/images/icons/usef.svg" alt="usef-logo" class="usef-logo">
                            <!--mat-icon style="margin-top: -3px; margin-right: 8px;height: 30px;width: 30px;" [svgIcon]="'usef-logo'"></mat-icon-->
                            CONNECT
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Campo NRHA -->
                    <ng-container *ngIf="reining">
                      <div class="col-12">
                        <div class="form-group">
                          <label># NRHA</label>
                          <input type="text" class="form-control" style="text-transform: uppercase;"
                            formControlName="nrha">
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="name" required>
                        <div class="invalid-feedback">
                          Please provide a name.
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Lastname</label>
                        <input type="text" class="form-control" formControlName="lastname">
                        <div class="invalid-feedback">
                          Please provide a lastname.
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Amateur Pro Status</label>
                        <input type="text" class="form-control" formControlName="amateurProStatus">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>W9</label>
                        <input type="number" min="1900" max="2099" step="1" class="form-control" formControlName="w9">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox mr-3" style="margin-top: 37px;">
                          <input type="checkbox" class="custom-control-input" id="amateur" formControlName="amateur">
                          <label class="custom-control-label" for="amateur">Amateur</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" formControlName="email">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Age</label>
                        <input type="number" class="form-control" formControlName="age">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Nationality</label>
                        <input type="text" class="form-control" formControlName="nationality"
                          [matAutocomplete]="autocompleteNacionalidad">
                        <mat-autocomplete #autocompleteNacionalidad="matAutocomplete"
                          [displayWith]="displayPais.bind(this)">
                          <mat-option *ngFor="let pais of paises | filterPais : queryPais" [value]="pais.id">
                            <img [src]="pais.image" aria-hidden height="25" style="vertical-align:middle;"
                              onerror="this.src='assets/images/icons/sin-bandera.jpg'">
                            <span>{{ (pais.name) ? pais.name : '-' }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Date of birth</label>
                        <input type="text" class="form-control" placeholder="YYYY-MM-DD" data-toggle="input-mask"
                          data-mask-format="0000-00-00" maxlength="10" formControlName="dateOfBirth">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Telephone</label>
                        <input type="text" class="form-control" formControlName="telephone">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Cell Phone</label>
                        <input type="text" class="form-control" formControlName="cellPhone">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Gender</label>
                        <select class="form-control" formControlName="gender">
                          <option value="">-- choose an option --</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div id="accordion-address" class="custom-accordion mb-4">

                        <div class="card mb-0">
                          <div class="card-header py-0 px-2" id="heading-usef-address">
                            <h5 class="m-0">
                              <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse"
                                href="#collapse-usef-address" aria-expanded="false"
                                aria-controls="collapse-usef-address">
                                Usef Address <span class="float-right"><i
                                    class="mdi mdi-chevron-down accordion-arrow"></i></span>
                              </a>
                            </h5>
                          </div>
                          <div id="collapse-usef-address" class="collapse" aria-labelledby="heading-usef-address"
                            data-parent="#accordion-address">
                            <div class="card-body px-2 py-1" formGroupName="usefAddress">
                              <div class="row">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" class="form-control" formControlName="address">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address 2</label>
                                    <input type="text" class="form-control" formControlName="address2">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control" formControlName="city">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>State</label>
                                    <input type="text" class="form-control" formControlName="state">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Zip</label>
                                    <input type="text" class="form-control" formControlName="zip">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Country</label>
                                    <input type="text" class="form-control" formControlName="country">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> <!-- end card-->

                        <div class="card mb-0">
                          <div class="card-header py-0 px-2" id="heading-tax-address">
                            <h5 class="m-0">
                              <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse"
                                href="#collapse-tax-address" aria-expanded="false" aria-controls="collapse-tax-address">
                                Tax Address <span class="float-right"><i
                                    class="mdi mdi-chevron-down accordion-arrow"></i></span>
                              </a>
                            </h5>
                          </div>
                          <div id="collapse-tax-address" class="collapse" aria-labelledby="heading-tax-address"
                            data-parent="#accordion-address">
                            <div class="card-body px-2 py-1" formGroupName="taxAddress">
                              <div class="row">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" class="form-control" formControlName="address">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address 2</label>
                                    <input type="text" class="form-control" formControlName="address2">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control" formControlName="city">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>State</label>
                                    <input type="text" class="form-control" formControlName="state">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Zip</label>
                                    <input type="text" class="form-control" formControlName="zip">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Country</label>
                                    <input type="text" class="form-control" formControlName="country">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> <!-- end card-->

                        <div class="card mb-0">
                          <div class="card-header py-0 px-2" id="heading-mail-address">
                            <h5 class="m-0">
                              <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse"
                                href="#collapse-mail-address" aria-expanded="false"
                                aria-controls="collapse-mail-address">
                                Mail Address <span class="float-right"><i
                                    class="mdi mdi-chevron-down accordion-arrow"></i></span>
                              </a>
                            </h5>
                          </div>
                          <div id="collapse-mail-address" class="collapse" aria-labelledby="heading-mail-address"
                            data-parent="#accordion-address">
                            <div class="card-body px-2 py-1" formGroupName="mailAddress">
                              <div class="row">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" class="form-control" formControlName="address">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Address 2</label>
                                    <input type="text" class="form-control" formControlName="address2">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control" formControlName="city">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>State</label>
                                    <input type="text" class="form-control" formControlName="state">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Zip</label>
                                    <input type="text" class="form-control" formControlName="zip">
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label>Country</label>
                                    <input type="text" class="form-control" formControlName="country">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> <!-- end card-->
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label>TAX ID</label>
                        <input type="text" class="form-control" formControlName="taxId">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>TAX Name</label>
                        <input type="text" class="form-control" formControlName="taxName">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>Notes</label>
                        <textarea class="form-control" rows="3" formControlName="notas"
                          style="resize: none;"></textarea>
                      </div>
                    </div>
                    <div class="col-12 mt-3 text-center">
                      <button type="button" class="btn btn-light mr-4" (click)="cerrarPaneles()">Cancel</button>
                      <button type="submit" class="btn btn-success">Save</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Panel add horse -->
          <div class="panel" [ngClass]="{'col-4': panel.get('horse'), 'col-0': !panel.get('horse') || pantalla == 1}">
            <div class="card">
              <div class="card-header bg-dark text-white" style="white-space: nowrap;">
                Add Horse
                <div class="card-widgets">
                  <a href="javascript:void(0)" (click)="cerrarPaneles()"><i class="mdi mdi-close"></i></a>
                </div>
              </div>
              <div class="card-body p-2">
              </div>
            </div>
          </div>
          <!-- Entry Invoice -->
          <div class="panel p-print-0 w-print-100 m-0" [ngClass]="{'col-0': pantalla != 1, 'col-8': pantalla == 1}">
            <div class="card">
              <div class="card-header bg-dark text-white px-2 py-1 d-flex justify-content-between d-print-none"
                style="white-space: nowrap;">
                <h4 class="my-auto">Entry invoice</h4>
                <div class="card-widgets my-auto">
                  <a href="javascript:void(0)" (click)="mostrarLog()" class="mr-2" title="Log"><i
                      class="mdi mdi-file-document-outline"></i></a>
                  <a href="javascript:void(0)" (click)="imprimir()" title="Print"><i class="mdi mdi-printer"></i></a>
                </div>
              </div>
              <div class="card-body p-print-0">
                <!-- Notes -->
                <div class="row">
                  <div class="col-12 text-left">
                    <!-- Editar notas -->
                    <div
                      class="alert alert-warning alert-dismissible fade show mt-2 text-left ng-star-inserted w-100 d-print-none"
                      role="alert" *ngIf="!editarNotas; else addNotes">
                      <div class="close px-1" style="cursor: auto;">
                        <button type="button" class="notas"
                          style="background-color: transparent; border: 0; -webkit-appearance: none; cursor: pointer;"
                          (click)="saveNotes('')">
                          <span aria-hidden="true">×</span>
                        </button>
                        <button type="button" class="notas"
                          style="background-color: transparent; border: 0; -webkit-appearance: none; cursor: pointer;"
                          (click)="editarNotas = true;">
                          <span aria-hidden="true"><i class="mdi mdi-pencil"></i></span>
                        </button>
                      </div>
                      <i class="mdi mdi-lightbulb-on-outline"></i>
                      <i>Notes: {{ notes }}</i>
                    </div>
                    <!-- Agregar notas -->
                    <ng-template #addNotes>
                      <textarea name="" id="" cols="30" rows="3" (change)="saveNotes($event.target.value)"
                        placeholder="Add notes..." class="form-control d-print-none"
                        style="width: calc(100% - 15px); resize: none;">{{ notes }}</textarea>
                    </ng-template>
                  </div>
                </div>
                <!-- Headers -->
                <div class="row justify-content-between d-none d-print-flex">
                  <div class="col">
                  </div>
                  <div class="col">
                    <ng-container *ngIf="!loading?.get('show');else cardPlaceholder">
                      <h2>{{ concurso?.name }}</h2>
                      <h4 class="text-center"><span>Entry Invoice</span></h4>
                    </ng-container>
                  </div>
                  <div class="col text-right">
                    <ng-container *ngIf="!loading?.get('show');else cardPlaceholder">
                      <img [src]="concurso?.header" style="max-height: 90px;" onerror="this.style.display='none'"
                        onload="this.style.display='-webkit-inline-box'">
                    </ng-container>
                  </div>
                </div>
                <!-- Entry Info -->
                <div class="row justify-content-center" style="padding: 10px 0;">
                  <div class="col">
                    <!-- Horse data -->
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <div class="mr-3">
                        <p class="mb-0">
                          <b style="vertical-align: middle;">
                            <a *ngIf="idUsuario == 381 || privilegios >= 200" href="javascript:void(0)"
                              [ngClass]="{'disabled': validated}" (click)="validated ? null : showEditEntryNumber()">
                              <i class="mdi mdi-tag-outline"></i>
                            </a>
                            <!-- Entry Number -->
                            {{ entrySeleccionado }} - {{ horse?.name }}
                            <a href="javascript: void(0);" [ngClass]="{'disabled': validated}"
                              class="edit ml-1 d-print-none"
                              (click)="validated ? null : showEditEntry(horse?.id, horse?.name, payee?.id, payee?.fullname)">
                              <i class="mdi mdi-swap-horizontal"></i>
                            </a>
                            <a [href]="'/federation-entry-agreement/'+concurso?.id+'/'+entrySeleccionado"
                              class="d-print-none ml-2" target="_blank" title="Federation Entry Agreement">
                              <i class="mdi mdi-signature-freehand"></i>
                            </a>
                            <a href="javascript: void(0);" [ngClass]="{'disabled': validated}"
                              class="edit ml-1 d-print-none" (click)="validated ? null : openCloseHorseForm()">
                              <i class="mdi mdi-pencil"></i>
                            </a>
                          </b>
                        </p>
                        <!-- Horse suspension status -->
                        <ng-container *ngIf="!reining">
                          <!-- Horse Suspension Status -->
                          <ng-container
                            *ngTemplateOutlet="suspensionStatus; context: { $implicit: this, entity: horse }"></ng-container>
                        </ng-container>
                        <p class="mb-0" *ngIf="horse?.height">Height: <b> {{ horse?.height }}</b></p>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col text-center">
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <div class="custom-control custom-checkbox  hidden-print">
                        <input type="checkbox" class="custom-control-input" id="validated" name="validated"
                          [(ngModel)]="validated" (change)="validarCuenta($event)">
                        <label class="custom-control-label " for="validated">Checked</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Balance -->
                  <div class="col text-right">
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <div style="color:black; font-weight: bold;font-size: 1rem;">Balance: {{ balance | currency }}
                      </div>
                      <span style="line-height: 19px" class="text-panel visible-print-block">{{ currentDate | date: 'dd/MM/yyyy hh:mm a' }}</span>
                    </ng-container>
                  </div>
                </div>
                <!-- Payee/Trainer/Owner -->
                <div class="row justify-content-center" style="padding: 10px 0;">
                  <!-- Payee -->
                  <div class="col-4">
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <p class="mb-0"> Payee: <b class="mr-2">{{ payee?.fullname }}</b>
                        <a href="javascript: void(0);" [ngClass]="{'disabled': validated}" class="edit d-print-none" (click)="validated ? null : showEditEntry(horse?.id, horse?.name, payee?.id, payee?.fullname)">
                          <i class="mdi mdi-swap-horizontal"></i>
                        </a>
                      </p>
                      <ng-container *ngTemplateOutlet="addressTemplate; context: { $implicit: this, entity: payee, tipo: 'payee' }"></ng-container>
                    </ng-container>
                  </div>
                  <!-- Trainer -->
                  <div class="col-4 text-left">
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <p class="mb-0">Trainer: <b>{{ trainer?.fullname }} </b>
                        <a href="javascript: void(0);" [ngClass]="{'disabled': validated}" class="edit ml-2 d-print-none" (click)="validated ? null : showchangeTrainer(trainer?.fullname, trainer?.id)">
                          <i class="mdi mdi-swap-horizontal"></i>
                        </a>
                      </p>
                      <!-- Trainer suspension status -->
                      <ng-container *ngIf="!reining">
                        <ng-container *ngTemplateOutlet="suspensionStatus; context: { $implicit: this, entity: trainer }"></ng-container>
                      </ng-container>
                      <ng-container *ngTemplateOutlet="addressTemplate; context: { $implicit: this, entity: trainer, tipo: 'trainer' }"></ng-container>
                    </ng-container>
                  </div>
                  <!-- Owner -->
                  <div class="col-4 text-left">
                    <ng-container *ngIf="!loading?.get('info');else cardPlaceholder">
                      <p class="mb-0">Owner: <b>{{ owner?.fullname }}</b><a href="javascript: void(0);" [ngClass]="{'disabled': validated}" class="edit ml-2 d-print-none" (click)="validated ? null : showchangeOwner(owner?.fullname, owner?.id)">
                          <i class="mdi mdi-swap-horizontal"></i></a></p>
                      <!-- Owner suspension status -->
                      <ng-container *ngIf="!reining">
                        <ng-container *ngTemplateOutlet="suspensionStatus; context: { $implicit: this, entity: owner }"></ng-container>
                      </ng-container>
                      <ng-container *ngTemplateOutlet="addressTemplate; context: { $implicit: this, entity: owner, tipo: 'owner' }"></ng-container>
                    </ng-container>
                  </div>
                </div>
                <!-- Entries/Charges/Orders/Cards/Splits/Payments/Prizes/Results -->
                <div class="row">
                  <!-- Inscripciones -->
                  <div class="col-12">
                    <div class="card mb-2 d-print-none">
                      <div class="card-header px-2 py-1 text-white d-flex justify-content-between"style="background: rgb(65, 65, 65);">
                        <h4 class="my-auto">
                          <span>Riders & Classes</span>
                        </h4>
                        <div class="card-widgets my-auto">
                          <a href="javascript:void(0)" data-toggle="tooltip" title="Add rider" [ngClass]="{'disabled': validated}" (click)="validated ? null : showAddCombination()"><i class="mdi mdi-plus-circle-outline"></i></a>
                        </div>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('riders_clases');else cardPlaceholder">
                        <table class="table table-hover table-sm table-condensed mb-0">
                          <tbody>
                            <tr class="page-break" *ngFor="let rider of binomios">
                              <td>
                                <a href="javascript: void(0);" [ngClass]="{'disabled': validated}" class="edit d-print-none" (click)="validated ? null : showEditPerson(rider?.id, 'rider')" data-toggle="tooltip" title="Edit Rider">
                                  <i class="mdi mdi-pencil"></i>
                                </a>
                                {{ rider?.fullname }} <span *ngIf="rider?.amateur||false" class="badge badge-warning">Jr/Am</span>
                                <span *ngIf="rider?.professional||false" class="badge badge-warning">Pro</span>
                                <a href="javascript: void(0);" style="vertical-align: middle;" [ngClass]="{'disabled': validated}" class="ml-2 edit d-print-none" (click)="validated ? null : showEditRiderTrainer(rider?.id_binomio, rider?.id, rider?.fullname, rider?.trainer?.id, rider?.trainer?.fullname)">
                                  <i class="mdi mdi-swap-horizontal"></i>
                                </a>
                                <a href="javascript:void(0)" [ngClass]="{'disabled': validated}" class="d-print-none ml-2" (click)="validated ? null : showAddDrops(rider?.id_binomio, rider?.inscripciones)">
                                  <span class="badge badge-success badge-pill">add/drops</span>
                                </a>
                                <ng-container *ngIf="rider?.email">
                                  <br>
                                  <span>{{ rider?.email }} </span>
                                </ng-container>
                                <!-- Rider Usef -->
                                <ng-container *ngIf="!reining">
                                  <br>
                                  <!-- Rider Suspension Status -->
                                  <ng-container *ngTemplateOutlet="suspensionStatus; context: { $implicit: this, entity: rider }"></ng-container>
                                </ng-container>
                                <!-- Rider FEI -->
                                <ng-container *ngIf="rider?.fei">
                                  <br>
                                  <span>FEI ID: {{ rider?.fei }} </span>
                                </ng-container>
                                <!-- Rider Address -->
                                 <ng-container *ngTemplateOutlet="addressTemplate; context: { $implicit: this, entity: rider, tipo: 'rider' }"></ng-container>
                                <br>
                              </td>
                              <td>
                                <ng-container *ngIf="rider?.inscripciones?.length == 0 && riders?.length > 1">
                                  <a href="javascript:void(0)" class="d-print-none ml-2"
                                    (click)="removeRiderFromEntry(rider?.id_binomio)">
                                    <span class="badge badge-danger badge-pill">remove</span>
                                  </a>
                                </ng-container>
                                <div style="margin-bottom: 5px;" *ngFor="let inscripcion of rider?.inscripciones">
                                  <span class="circulo"
                                    [ngClass]="{'bg-danger': validarPruebaRepetida(inscripcion?.ipc)}">{{
                                    inscripcion?.numero||'-' }}</span>
                                  <span *ngIf="inscripcion?.estatus_prueba == 2; else waiting" style="color:#FFBC00">
                                    <i class="mdi mdi-trophy"></i>{{inscripcion?.rank}}
                                  </span>
                                  <ng-template #waiting>
                                    <span>&nbsp;&nbsp;<i class="mdi mdi-timer-sand"></i>&nbsp;</span>
                                  </ng-template>
                                  <i class="mdi mdi-calendar" style="font-size: 16px;"></i>
                                  {{ inscripcion?.inicio | date: 'EEEEEE dd MMM yyyy' | titlecase }}
                                  <b [ngClass]="{scratched: inscripcion?.scratched}">

                                  </b>
                                  <a href="javascript:void(0)" [ngClass]="{'disabled': validated}"
                                    class="mr-1 d-print-none" style="font-size: 1rem;"
                                    (click)="validated ? null : riderSwapModal(inscripcion?.ibpc)" title="Rider Swap">
                                    <i class="mdi mdi-swap-horizontal"></i>
                                  </a>
                                  <a href="javascript:void(0)" [ngClass]="{'disabled': validated}"
                                    class="mr-1 d-print-none" *ngIf="!inscripcion?.scratched"
                                    (click)="validated ? null : desinscribir(rider?.id_binomio, inscripcion?.ipc, '0')">
                                    <span class="badge badge-danger badge-pill">drop</span>
                                  </a>
                                  <a href="javascript:void(0)" [ngClass]="{'disabled': validated}" class="d-print-none"
                                    *ngIf="(inscripcion?.estatus == 'DNS' || inscripcion?.estatus == 'WD') && inscripcion?.estatus_prueba != '1'"
                                    style="font-size: 1rem;"
                                    (click)="validated ? null : eliminarBinomio(rider?.id_binomio, inscripcion?.ipc)"
                                    title="Delete">
                                    <i class="mdi mdi-close-circle-outline"></i>
                                  </a>
                                </div>
                              </td>
                              <td style="text-align:center" class="total-binomio">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Cargos inscripciones -->
                  <div class="col-12">
                    <div class="card mb-2">
                      <div class="card-header bg-dark text-white px-2 py-1 d-flex justify-content-between">
                        <h4 class="my-auto">Class & Division Fees</h4>
                        <span class="my-auto">
                          <a href="javascript:;" class="text-white mr-2" data-toggle="tooltip" title="Reload"
                            (click)="recalcularDivisiones()"><i class="mdi mdi-refresh"></i></a>
                          <b>{{ total_class_fees | currency }}</b>
                        </span>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('charges');else cardPlaceholder">
                        <table class="table table-hover table-sm table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Reference</th>
                              <th style="text-align: right;">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let cargo of class_fees; let index = index">
                              <td>{{ cargo?.referencia }}</td>
                              <td style="text-align: right;">{{cargo.monto_pagar | currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Cargos -->
                  <div class="col-12">
                    <div class="card mb-2">
                      <div class="card-header bg-danger text-white px-2 py-1 d-flex justify-content-between">
                        <h4 class="my-auto">
                          <span>Charges</span>
                        </h4>
                        <span class="my-auto">
                          <a href="javascript:void(0)" data-toggle="tooltip" title="Balance Transfer"
                            [ngClass]="{'disabled': validated}" class="text-white mr-2 d-print-none"
                            (click)="validated ? null : mostrarModalTraspaso()">
                            <i class="mdi mdi-bank-transfer mdi-18px"></i>
                          </a>
                          <a href="javascript:void(0)" data-toggle="tooltip" title="Merch Store"
                            class="text-white mr-2 d-print-none" (click)="mostrarModalProductos()">
                            <i class="mdi mdi-cash-register mdi-18px"></i>
                          </a>
                          <a href="javascript:void(0)" data-toggle="tooltip" title="Remove selected charges"
                            [ngClass]="{'d-none': validated || !cargosSeleccionados.length}"
                            class="text-white mr-2 d-print-none"
                            (click)="validated || !cargosSeleccionados.length ? null : deleteSelectedCharges()">
                            <i class="mdi mdi-trash-can-outline mdi-18px"></i>
                          </a>
                          <b>{{ total_charges | currency }}</b>
                        </span>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('charges');else cardPlaceholder">
                        <table class="table table-hover table-sm table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Concept</th>
                              <th>Notes</th>
                              <th>Qty</th>
                              <th class="text-right">Amount</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="d-print-none" [formGroup]="formCargo">
                              <td style="vertical-align: middle;">
                                <select class="form-control form-control-sm" (change)="calcularCargo()"
                                  formControlName="concepto"
                                  [ngClass]="{'no-valido': formCargo.get('concepto').invalid && (formCargo.get('concepto').touched || formCargo.get('concepto').dirty)}">
                                  <option value="" selected disabled></option>
                                  <option *ngFor="let concepto of conceptos" [value]="concepto.id">{{ concepto.nombre }}
                                  </option>
                                </select>
                              </td>
                              <td style="vertical-align: middle;"><input class="form-control form-control-sm"
                                  type="text" formControlName="notas"></td>
                              <td style="vertical-align: middle;">
                                <input min="0" class="form-control form-control-sm" type="number"
                                  formControlName="cantidad" (change)="calcularCargo()"
                                  [ngClass]="{'no-valido': formCargo.get('cantidad').invalid && (formCargo.get('cantidad').touched || formCargo.get('cantidad').dirty)}">
                              </td>
                              <td style="vertical-align: middle; text-align: right;">
                                <input class="form-control form-control-sm" type="number" formControlName="monto"
                                  [ngClass]="{'no-valido': formCargo.get('monto').invalid && (formCargo.get('monto').touched || formCargo.get('monto').dirty)}">
                              </td>
                              <td class="table-action text-center" style="vertical-align: middle;">
                                <a href="javascript: void(0);" class="action-icon text-danger" style="font-size: 2rem;"
                                  (click)="agregarCargo()">
                                  <i class="mdi mdi-plus-circle-outline"></i>
                                </a>
                              </td>
                            </tr>
                            <ng-container *ngFor="let charge of charges; let index = index">
                              <tr [ngClass]="{'d-print-none': charge?.monto_pagar == 0}">
                                <td>{{ charge?.concepto }}</td>
                                <td></td>
                                <td>{{ charge?.cantidad | number : '1.0-2'}}</td>
                                <td class="text-right">{{ charge?.monto_pagar | currency }}</td>
                                <td class="table-action">
                                  <a href="javascript:;" class="table-action" (click)="showChargeDetails(charge)">
                                    <i class="mdi"
                                      [ngClass]="{'mdi-chevron-down': !charge?.show, 'mdi-chevron-up': charge?.show}"></i>
                                  </a>
                                </td>
                              </tr>
                              <ng-container *ngIf="charge.show">
                                <tr *ngFor="let detail of charge.details"
                                  [ngClass]="{'d-print-none': detail?.monto_pagar == 0}">
                                  <td class="d-flex">
                                    <div class="custom-control custom-checkbox d-print-none">
                                      <input type="checkbox" class="custom-control-input" [id]="'c'+detail?.id"
                                        (change)="selectCharge(detail, $any($event).target.checked)">
                                      <label class="custom-control-label" [for]="'c'+detail?.id"
                                        style="height: 1em;"></label>
                                    </div>
                                    {{ detail?.concepto?.nombre }}
                                  </td>
                                  <td>{{ detail?.referencia }}</td>
                                  <td>{{ detail?.quantity }}</td>
                                  <td class="text-right">{{ detail?.monto_pagar | currency }}</td>
                                  <td class="table-action d-print-none">
                                    <a href="javascript:;" class="table-action" data-toggle="tooltip"
                                      title="Remove charge" [ngClass]="{'disabled': validated}"
                                      (click)="eliminarCargo(detail?.id, detail?.monto_pagar)">
                                      <i class="mdi mdi-close"></i>
                                    </a>
                                  </td>
                                  <td style="text-align: right;">{{ detail?.monto | currency }}</td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!--div class="col-12">
                    <button type="button" class="btn btn-info" (click)="showPaysafeModal()">Paysafe</button>
                  </div-->
                  <!-- Feed orders summary -->
                  <div class="col-4 d-print-none">
                    <div class="card mb-2">
                      <div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1rem">
                        <h4 style="margin: 0px; line-height: 1.65rem;white-space: nowrap;">
                          <span>Feed orders summary</span>
                        </h4>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading.get('orders');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <th>Concept</th>
                            <th>Qty</th>
                            <th>Total</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let charge of orders_summary">
                              <td>{{ charge?.producto }}</td>
                              <td>{{ charge?.cantidad }}</td>
                              <td>{{ charge?.total | currency }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Cards - Opcion para agregar tarjeta -->
                  <div class="col-8 d-print-none">
                    <ng-container *ngIf="!loading.get('stripe_customers');else cardPlaceholder">
                      <app-stripe-add-card [customers]="stripeCustomers"></app-stripe-add-card>
                    </ng-container>
                  </div>
                  <!-- Splits -->
                  <div class="col-12">
                    <div class="card mb-2">
                      <div class="card-header bg-info" style="color: #ffffff !important; padding: 0.25rem 1rem">
                        <h3 class="m-0">
                          <span>Splits</span>
                          <span style="float:right">{{ total_splits | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('splits');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Trainer</th>
                              <th>Notes</th>
                              <th>Date</th>
                              <th style="text-align: right;">Amount</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let split of splits" [ngClass]="{'d-print-none': split.anulado}">
                              <td>{{ split.entrenador }}</td>
                              <td>{{ split.notas }}</td>
                              <td>{{ split.fecha | date: 'EEEEEE dd MMM yyyy' | titlecase }}</td>
                              <td [ngClass]="{scratched: split.anulado}" style="text-align: right;">{{ split.monto |
                                currency }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Pagos -->
                  <div class="col-12">
                    <div class="card mb-2">
                      <div class="card-header bg-success" style="color: #ffffff !important; padding: 0.25rem 1rem">
                        <h3 style="margin: 0px">
                          <span>Payments</span>
                          <span style="float:right">{{ totalPagos | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('splits');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Method</th>
                              <th><span class="d-print-none">Notes</span></th>
                              <!--th>Date</th-->
                              <th>Amount</th>
                              <th class="d-print-none"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="d-print-none" [formGroup]="formPago">
                              <td style="vertical-align: middle;">
                                <select class="form-control input-s" formControlName="metodo"
                                  [ngClass]="{'no-valido': formPago.get('metodo').invalid && (formPago.get('metodo').touched || formPago.get('metodo').dirty)}">
                                  <option value="" selected disabled></option>
                                  <option value="Tc">Credit Card</option>
                                  <option value="Cheque">Check</option>
                                  <option value="Deposito">Bank Deposit</option>
                                  <option value="Efectivo">Cash</option>
                                  <option value="Transferencia">Wire Transfer</option>
                                  <option value="Reembolso">Refund</option>
                                  <option value="Cortesia">Courtesy</option>
                                  <option value="Intercambio">Exchange</option>
                                  <option value="CC PrePayment">CC PrePayment</option>
                                  <option value="PrizeMoney">Prize Money</option>
                                </select>
                              </td>
                              <td style="vertical-align: middle;">
                                <input class="form-control input-s" type="text" formControlName="referencia"
                                  [ngClass]="{'no-valido': formPago.get('referencia').invalid && (formPago.get('referencia').touched || formPago.get('referencia').dirty)}">
                              </td>
                              <!--td style="vertical-align: middle;">
                                <input class="form-control input-s" min="0" type="date" formControlName="fecha" [ngClass]="{'no-valido': formPago.get('fecha').invalid && (formPago.get('fecha').touched || formPago.get('fecha').dirty)}">
                              </td-->
                              <td style="vertical-align: middle;">
                                <input class="form-control input-s" type="number" formControlName="monto"
                                  [ngClass]="{'no-valido': formPago.get('monto').invalid && (formPago.get('monto').touched || formPago.get('monto').dirty)}"
                                  [min]="formPago.get('metodo').value === 'PrizeMoney' ? null : 0">
                              </td>
                              <td class="table-action text-center" style="vertical-align: middle;">
                                <a href="javascript: void(0);" class="action-icon"
                                  style="font-size: 2rem; color: #0acf97!important;" (click)="agregarPago()">
                                  <i class="mdi mdi-plus-circle-outline"></i>
                                </a>
                              </td>
                            </tr>
                            <tr *ngFor="let pago of pagos; index as i" [ngClass]="{'d-print-none': pago.anulado}">
                              <td>{{ traducirMetodoDePago(pago.metodo) }}</td>
                              <td>
                                <ng-container *ngIf="pago.metodo == 'Tc'">
                                  <p *ngIf="pago.paymentDetails && pago.paymentDetails.paymentMethod.card" class="m-0">
                                    <span [ngSwitch]="pago.paymentDetails.paymentMethod.card.brand">
                                      <span *ngSwitchCase="'amex'"><img src="assets/img/card_brands/amex.png" alt="amex"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'discover'"><img src="assets/img/card_brands/discover.png"
                                          alt="discover" style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'jcb'"><img src="assets/img/card_brands/jcb.png" alt="jcb"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'mastercard_debit'"><img
                                          src="assets/img/card_brands/mastercard_debit.png" alt="mastercard_debit"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'mastercard_prepaid'"><img
                                          src="assets/img/card_brands/mastercard_prepaid.png" alt="mastercard_prepaid"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'mastercard'"><img
                                          src="assets/img/card_brands/mastercard.png" alt="mastercard"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'unionpay'"><img src="assets/img/card_brands/unionpay.png"
                                          alt="unionpay" style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'visa_debit'"><img
                                          src="assets/img/card_brands/visa_debit.png" alt="visa_debit"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchCase="'visa'"><img src="assets/img/card_brands/visa.png" alt="visa"
                                          style="max-width: 2rem; max-height: 2rem;"></span>
                                      <span *ngSwitchDefault><i class="mdi mdi-credit-card"></i></span>
                                    </span>
                                    <span class="mx-1">•⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠ </span>{{
                                    pago.paymentDetails.paymentMethod.card.last4 }}
                                  </p>
                                  <p class="m-0">
                                    <i class="mdi mdi-calendar"></i>{{ pago.fecha_pago }}
                                  </p>
                                </ng-container>

                                <span class="d-print-none">{{ pago.referencia }}</span>
                                <div *ngIf="pago.moreInfo">{{pago.moreInfo}}</div>

                              </td>
                              <!--td>{{ pago.fecha_pago | date: 'EEEEEE dd MMM yyyy' | titlecase }}</td-->
                              <td [ngClass]="{scratched: pago.anulado}">
                                <ng-container *ngIf="pago.metodo == 'Reembolso'">-</ng-container>
                                {{ pago.monto | currency }}
                              </td>
                              <td class="d-print-none">
                                <button *ngIf="!pago.anulado" mat-icon-button [disabled]="validated"
                                  (click)="anularPago(pago.id, pago.monto, pago.metodo)">
                                  <mat-icon>remove_circle_outline</mat-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Balance -->
                  <div class="col-12">
                    <div class="card mb-2">
                      <div class="card-header bg-light" style="padding: 0.25rem 1rem">
                        <h2 style="margin: 0px; text-align:center; color: black;">
                          <span *ngIf="!loading.get('info')">Balance: {{balance | currency }}</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <!-- Premios -->
                  <!-- Premios Aplicados -->
                  <div class="col-6">
                    <div class="card mb-2">
                      <div class="card-header"
                        style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Applied Prizes</span>
                          <span style="float:right">{{ totalPremiosAplicados | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('prizes');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th class="text-center d-print-none">UnApply</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of premiosAplicados">
                              <td>{{ premio.nombre }}</td>
                              <td class="text-center d-print-none">
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" [id]="'prize'+premio.id"
                                    (change)="applyPrize($event, premio)"
                                    [attr.checked]="premio.pagarAparte ? 'checked' : null">
                                  <label class="custom-control-label" style="margin-bottom: 15px !important;"
                                    [for]="'prize'+premio.id"></label>
                                </div>
                              </td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Premios Sin Aplicar -->
                  <div class="col-6">
                    <div class="card mb-2">
                      <div class="card-header"
                        style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Unapplied Prizes</span>
                          <span style="float:right">{{ totalPremios | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('prizes');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th class="text-center d-print-none">UnApply</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                              <th *ngIf="championshipSeries">Championship Series</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of premios">
                              <td>{{ premio.nombre }}</td>
                              <td class="text-center d-print-none">
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" [id]="'prize'+premio.id"
                                    (change)="applyPrize($event, premio)"
                                    [attr.checked]="premio.pagarAparte ? 'checked' : null">
                                  <label class="custom-control-label" style="margin-bottom: 15px !important;"
                                    [for]="'prize'+premio.id"></label>
                                </div>
                              </td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                              <td *ngIf="championshipSeries">
                                <img *ngIf="premio.id_prueba == '2'" style="max-height: 20px;"
                                  src="https://firebasestorage.googleapis.com/v0/b/srjt/o/championshipSeries.png?alt=media&token=d0cc7397-3317-4a66-878a-233f3a5f064d">
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="5" class="text-center" *ngIf="!aplicarPremios">
                                <!-- Prize money is not applied, please check the Payee and
                                provide a W-9 form. -->
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Premios Championship Series -->
                  <div class="col-6" *ngIf="totalPremiosChampionshipSeries">
                    <div class="card mb-2">
                      <div class="card-header"
                        style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Championship Series Prizes</span>
                          <span style="float:right">{{ totalPremiosChampionshipSeries | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('prizes');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th class="text-center d-print-none">UnApply</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of premiosChampionshipSeries">
                              <td>{{ premio.nombre }}</td>
                              <td class="text-center d-print-none">
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" [id]="'prize'+premio.id"
                                    (change)="applyPrize($event, premio)"
                                    [attr.checked]="premio.pagarAparte ? 'checked' : null">
                                  <label class="custom-control-label" style="margin-bottom: 15px !important;"
                                    [for]="'prize'+premio.id"></label>
                                </div>
                              </td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="5" class="text-center" *ngIf="!aplicarPremios">
                                <!-- Prize money is not applied, please check the Payee and provide a W-9 form. -->
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Resultados -->
                  <div class="col-6">
                    <div class="card mb-2">
                      <div class="card-header"
                        style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: rgb(65, 65, 65);">
                        <h3 style="margin: 0px">
                          <span>Results</span>
                          <span style="float:right"></span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-0" *ngIf="!loading?.get('results');else cardPlaceholder">
                        <table class="table table-hover table-condensed mb-0">
                          <tbody>
                            <tr *ngFor="let resultado of resultados">
                              <td><span class="circulo">{{ resultado.numero_prueba }}</span></td>
                              <td>{{ resultado.nombre_prueba }}</td>
                              <td>{{ resultado.jinete }}</td>
                              <td>
                                <span style="color:#FFBC00">
                                  <i class="mdi mdi-trophy"></i>{{ resultado.posicion }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Fin Resultados -->
                </div>
                <!-- Footer -->
                <div class="row d-none d-print-flex mt-3 mb-1">
                  <div class="col-12 text-center">
                    <img [src]="concurso?.footer" style="max-height: 120px;" onerror="this.style.display='none'"
                      onload="this.style.display='-webkit-inline-box'">
                  </div>
                </div>
                <!-- Invoice Footer -->
                <div class="row d-none d-print-flex mt-3 mb-1">
                  <div class="col-12 text-center">
                    <img [src]="concurso?.invoice_footer" style="max-height: 120px;" onerror="this.style.display='none'"
                      onload="this.style.display='-webkit-inline-box'">
                  </div>
                </div>
                <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img
                    src="assets/images/logo.png"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loader ED -->
<ng-template #loaderED>
  <div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
    <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
</ng-template>
<!-- Loader fei -->
<ng-template #loaderFEI>
  <div id="loader-fei">
    <div class="sk-cube-grid">
      <!-- Created with Inkscape (http://www.inkscape.org/) -->
      <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="168.75053mm" height="50.237492mm"
        viewBox="0 0 597.93496 178.00686" id="FEI-svg" version="1.1" inkscape:version="0.91 r13725"
        sodipodi:docname="drawing.svg">
        <path id="fei-picto"
          d="m 217.744,20.586 6.203,-14.78 -90.265,0 0,70.734 c 0,5.674 -1.319,10.82 -7.918,12.008 l 91.98,0 6.203,-14.78 -64.532,0 0,-20.058 51.863,0 5.411,-13.725 -57.274,0 0,-19.399 58.329,0 z m 15.176,67.962 25.734,0 0,-82.742 -25.734,0 0,82.742 z M 104.913,70.733 c -5.674,-4.223 -10.161,-8.182 -9.765,-14.78 -3.035,4.882 -4.091,10.557 -4.091,14.12 0,10.425 6.466,17.287 8.313,18.475 l 17.552,0 c -9.369,-3.035 -3.167,-11.217 -12.009,-17.815 z m 23.094,-22.698 c 0,-2.507 -2.111,-4.355 -4.486,-4.355 -2.508,0 -4.488,1.848 -4.488,4.355 0,2.508 1.98,4.487 4.488,4.487 2.375,0 4.486,-1.979 4.486,-4.487 z m -32.859,7.654 7.918,-13.725 -43.072,0 C 74.165,25.059 96.043,17.301 122.861,21.378 l 8.973,-15.572 -23.225,0 c -35.098,0 -63.603,11.393 -80.156,36.158 l -20.535,0 L 0,55.689 l 21.087,0 c -3.993,9.495 -6.643,20.422 -7.759,32.859 l 29.297,0 c 1.042,-12.473 3.986,-23.492 8.556,-32.859 l 43.967,0 z m 181.396,-42.832 0,-4.501 -1.95,4.501 -1.141,0 -1.891,-4.441 0,4.441 -1.468,0 0,-7.051 1.911,0 2.067,4.908 2.067,-4.908 1.796,0 0,7.051 -1.391,0 z m -9.832,-5.737 0,5.737 -1.468,0 0,-5.737 -2.163,0 0,-1.314 5.795,0 0,1.314 -2.164,0 z"
          inkscape:connector-curvature="0" style="fill:#4a0d53;" stroke="#4a0d53" class="path" />
      </svg>
    </div>
  </div>
</ng-template>
<!-- Loader USEF -->
<ng-template #loaderUsef>
  <div id="loader-usef">
    <div class="sk-cube-grid">
      <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg version="1.1" id="US_Equestrian" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="136.98px"
        viewBox="0 0 150 136.98" style="enable-background:new 0 0 150 136.98;" xml:space="preserve">
        <path class="path" stroke="#14377D" style="fill:#14377D;" d="M111.22,25.41c-3.13-7.34-18.59-17.24-18.59-17.24c8.25,9.64,8.51,9.21,13.07,18.33
          c0,6.3,4.13,9.03,4.13,9.03c5.56,6.43,15.9,14.46,17.81,23.1c1.69,7.56-5.73,14.72-13.2,12.94c6.69,1.61,18.41,1.48,20.11-7.51
          c2.13-11.34-16.42-26.28-22.8-34.44C111.74,25.93,111.22,25.41,111.22,25.41z M92.76,53.77c-4.47-1.17-16.24-4.47-16.72-10.21
          c0.26,3,2.69,15.11,22.93,16.89c0,0,11.16,0.78,12.68,5.3C108.7,57.15,100.27,55.72,92.76,53.77z M73.39,116.39
          c-0.52-9.16-1.22-18.37-4-27.14c-4.16-13.07-13.09-24.23-23.93-32.97c-29.24,47.92,27.62,59.9,27.62,59.9
          c-59.92-7.06-52.36-40.57-34.99-65.28c-4.61-3.05-9.42-5.69-14.26-7.9C10.29,36.79-4.18,29.05,1.12,12.12
          c-3.67,15.07,23.54,26.32,40.73,33.75c4.45-5.63,9.24-10.6,13.56-14.42C76.52,12.77,102.57,0,102.57,0
          c-25.99,19.47-42.6,35.83-52.59,49.54c1.19,0.59,2.22,1.15,3.04,1.66C75.78,65.54,84.12,92.2,73.39,116.39z" />
        <path class="path-text" stroke="#DA1A32" style="fill:#DA1A32;" d="M86.98,80.95h7.77v22.02c0,4.82,2.95,7.56,7.25,7.56c4.26,0,7.25-2.74,7.25-7.56V80.95h7.73v22.02
          c0,9.47-6.25,14.11-14.98,14.11s-15.03-4.6-15.03-14.11V80.95L86.98,80.95z M125.81,107.19c3.13,1.87,6.91,3.21,10.47,3.21
          c3.82,0,5.78-1.56,5.78-3.87c0-6.64-19.2-2.17-19.2-15.63c0-5.82,4.82-10.55,13.03-10.55c4.65,0,8.82,1.13,12.77,3l-2.82,6.04
          c-3.13-1.56-6.43-2.35-9.6-2.35c-3.39,0-5.43,1.61-5.43,3.73c0,6.56,19.2,1.91,19.2,15.63c0,5.99-4.56,10.64-13.68,10.64
          c-5.34,0-10.77-1.78-14.16-3.91L125.81,107.19z" />
        <path class="path-text" stroke="#14377D" style="fill:#14377D;" d="M25.7,123.34h9.6v2.48h-6.78v2.91h6.04v2.21h-6.04v3.08h6.82v2.48H25.7V123.34z M44.16,123.12
          c4,0,6.82,2.82,6.82,6.78c0,1.69-0.48,3.13-1.39,4.3l1.48,1.26l-1.43,1.52l-1.56-1.39c-1.09,0.74-2.43,1.13-3.95,1.13
          c-4,0-6.82-2.87-6.82-6.86C37.34,125.95,40.21,123.12,44.16,123.12z M45.46,130.55l2.08,1.87c0.39-0.69,0.61-1.56,0.61-2.52
          c0-2.65-1.61-4.39-3.95-4.39c-2.3,0-3.95,1.74-3.95,4.39c0,2.69,1.65,4.43,3.95,4.43c0.74,0,1.43-0.17,2.04-0.56l-2.08-1.78
          L45.46,130.55z M53.59,123.34h2.87v8.16c0,1.78,1.09,2.82,2.69,2.82c1.56,0,2.69-1,2.69-2.82v-8.16h2.87v8.16
          c0,3.52-2.3,5.21-5.56,5.21c-3.21,0-5.56-1.69-5.56-5.21C53.59,131.51,53.59,123.34,53.59,123.34z M67.66,123.34h9.6v2.48h-6.73
          v2.91h6.04v2.21h-6.04v3.08h6.82v2.48h-9.68V123.34z M80.82,133.07c1.17,0.69,2.56,1.17,3.87,1.17c1.39,0,2.13-0.56,2.13-1.43
          c0-2.48-7.12-0.83-7.12-5.78c0-2.17,1.78-3.91,4.82-3.91c1.74,0,3.26,0.43,4.73,1.13l-1.04,2.26c-1.17-0.56-2.39-0.87-3.56-0.87
          c-1.26,0-2,0.61-2,1.39c0,2.43,7.12,0.69,7.12,5.78c0,2.21-1.69,3.95-5.08,3.95c-1.95,0-4-0.65-5.26-1.43L80.82,133.07z
          M95.67,125.86h-4.34v-2.52h11.55v2.52h-4.34v10.64h-2.82v-10.64H95.67z M105.09,123.34h5.78c3.78,0,5.08,2.08,5.08,4.17
          c0,1.56-0.78,2.95-2.39,3.65l2.52,5.34H113l-2.04-4.91h-3v4.91h-2.87V123.34z M110.83,129.51c1.52,0,2.26-0.83,2.26-1.95
          c0-1.13-0.74-1.95-2.21-1.95h-2.91v3.91H110.83z M118.73,123.34h2.87v13.16h-2.87V123.34z M128.37,123.34h3.21l4.78,13.16h-3.04
          l-0.83-2.48h-4.99l-0.83,2.48h-3.08L128.37,123.34z M128.2,131.85h3.56l-1.78-5.34h-0.04L128.2,131.85z M138.45,123.34h2.69
          l5.86,8.69v-8.69h2.69v13.16H147l-5.86-8.64v8.64h-2.69V123.34z" />
      </svg>

    </div>
  </div>
</ng-template>
<!-- Modal Traspaso -->
<ng-template #modalTraspaso>
  <div id="modal-traspaso" tabindex="-1" role="dialog" class="modal fade colored-header success">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-danger">
          <h4 class="modal-title">Balance transfer</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" (ngSubmit)="balanceTransfer()" [formGroup]="formTraspaso" novalidate>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label><b>From show</b></label>
                  <p>{{ nombreConcurso }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>From entry</b></label>
                  <p>{{ entrySeleccionado }} - {{ horse?.name }}</p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label><b>To show</b></label>
                  <select class="form-control" formControlName="concurso" (change)="consultarEntrenadoresTraspaso();"
                    required>
                    <option [value]="concurso.id" *ngFor="let concurso of concursos">{{ concurso.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" *ngIf="entrySeleccionado">
                  <label><b>To trainer</b></label>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="entrenador"
                      [matAutocomplete]="autocompleteTrainer" (keyup)="getPersonas($event, 'trainer')"
                      [attr.disabled]="formTraspaso.value.entry ? '' : null">
                    <mat-autocomplete #autocompleteTrainer="matAutocomplete"
                      [displayWith]="displayEntrenador.bind(this)">
                      <mat-option *ngFor="let entrenador of trainersBalanceTransfer" [value]="entrenador.idEntrenador"
                        class="fila-autocomplete" style="height:5em; line-height: 2em">
                        <img [src]="entrenador?.flag" style="height:.9em" />
                        <span id="trainerName" style="font-weight: bold;">{{ entrenador?.nombre||'-' }}</span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            entrenador?.usef }}
                          </span>
                          <span *ngIf="entrenador?.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ entrenador?.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>To entry</b></label>
                  <span class="clearfix"></span>
                  <input type="text" class="form-control" data-provide="typeahead" id="entryTraspaso"
                    (keyup)="searchEntryTraspaso($any($event).target.value)">
                  <!--select class="form-control" formControlName="entry" [attr.disabled]="formTraspaso.value.entrenador ? '' : null">
                    <option value=""></option>
                    <option [value]="entry.entry" *ngFor="let entry of entriesTraspaso">{{ entry.entry }} - {{ entry.horse }}</option>
                  </select-->
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Notes</b></label>
                  <input class="form-control input-s" type="text" formControlName="notas">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Date</b></label>
                  <input class="form-control input-s" min="0" type="date" formControlName="fecha" required>

                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Amount</b></label>
                  <input class="form-control input-s" type="number" formControlName="monto" required>
                </div>
              </div>
              <div class="col-12 text-right mt-3">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close mr-3">Cancel</button>
                <button type="submit" class="btn btn-danger md-close">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal AddDrops -->
<ng-template #modalAddDrops>
  <div id="addDrops" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editEntryLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editEntryLabel">Add/Drops: {{ entrySeleccionado }} - {{ nombreCaballo}}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-chip-list #classList aria-label="Class selection">
                  <mat-chip *ngFor="let class of inscritosChip"
                    [ngClass]="{'chip-inscrito': !class.extemporaneo, 'chip-extemporaneo': class.extemporaneo}"
                    [selectable]="true" [removable]="true" (removed)="inscribir(class.ipc, 0)">
                    {{class.numero}}
                    <mat-icon *ngIf="class.estatus_prueba != '1'" matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input onfocus="var val=this.value; this.value=''; this.value= val;" id="input-pruebas"
                    placeholder="Add class..." [matChipInputFor]="classList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
                    (matChipInputTokenEnd)="addClass($event)">
                </mat-chip-list>
              </mat-form-field>
            </div>
            <div class="col-12" *ngFor="let dia of pruebasFiltradas">
              <div [id]="'accordion'+dia.key" class="custom-accordion">
                <div class="card mb-0">
                  <div class="card-header py-0 px-1" [id]="dia.key">
                    <h5 class="m-0">
                      <a class="text-dark d-block pt-2 pb-2" data-toggle="collapse" href="#collapse{{dia.key}}"
                        aria-expanded="true" [attr.aria-controls]="'collapse'+dia.key">
                        {{ dia.key | date : 'EEEE, dd LLLL yyyy' }} <span class="float-right"><i
                            class="mdi mdi-chevron-down accordion-arrow"></i></span>
                      </a>
                    </h5>
                  </div>
                  <div id="collapse{{dia.key}}" class="collapse show" [attr.aria-labelledby]="dia.key"
                    [attr.data-parent]="'#accordion'+dia.key">
                    <div class="card-body p-0">
                      <table class="mb-0  table-sm table-striped table">
                        <tbody>
                          <tr *ngFor="let prueba of dia.value">
                            <td><b>{{ prueba.numero }}</b></td>
                            <td>{{ prueba.prueba }}</td>
                            <td>Height: {{ prueba.altura }}</td>
                            <td class="table-action">
                              <a *ngIf="prueba.estatus != '1'" href="javascript: void(0);" class="action-icon"
                                title="Drop" (click)="inscribir(prueba.ipc, 0)">
                                <i class="mdi mdi-delete"></i>
                              </a>
                              <a *ngIf="prueba.estatus == '1'" href="javascript: void(0);" class="action-icon"
                                title="Live class" style="cursor:default !important;">
                                <i class="mdi mdi-delete"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>
<!-- Modal RiderSwap -->
<ng-template #modalRiderSwap>
  <div id="riderSwapModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="riderSwapModalLabel"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="riderSwapModalLabel">Rider swap</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" (ngSubmit)="riderSwap()" [formGroup]="formRiderSwap" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Rider</label>
                  <input class="form-control" type="text" [matAutocomplete]="autocompleteRiderSwap"
                    (keyup)="getPersonas($event, 'rider')" required formControlName="rider">
                  <mat-autocomplete #autocompleteRiderSwap="matAutocomplete" [displayWith]="displayJinete.bind(this)">
                    <mat-option *ngFor="let jinete of jinetes" [value]="jinete.id_persona" class="fila-autocomplete">
                      {{ (jinete.fullname) ? jinete.fullname : '-' }}
                    </mat-option>
                  </mat-autocomplete>
                  <div class="invalid-feedback">
                    Please choose a rider.
                  </div>
                </div>
              </div>
              <div class="col-12 text-right">
                <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>
<!-- Modal AddCombination -->
<ng-template #modalAddCombination>
  <div id="addCombination" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editEntryLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header px-2 py-1">
          <h4 class="modal-title" id="editEntryLabel">Add Rider</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" id="addRiderTrainerForm" (ngSubmit)="addCombination()"
            [formGroup]="addRiderTrainerForm" novalidate>
            <div class="mb-3">
              <label>Rider</label>
              <input id="addCombinationInput" type="text" class="form-control form-control-sm"
                [matAutocomplete]="autocompleteEditRider" (keyup)="getPersonas($event, 'rider')" formControlName="rider"
                required>
              <mat-autocomplete #autocompleteEditRider="matAutocomplete" [displayWith]="displayJinete.bind(this)">
                <mat-option *ngFor="let jinete of jinetes" [value]="jinete.id_persona" class="fila-autocomplete"
                  style="height:5em; line-height: 2em;">
                  {{ (jinete.fullname) ? jinete.fullname : '-' }}
                </mat-option>
              </mat-autocomplete>
              <div class="invalid-feedback">
                Please choose a rider.
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-sm btn-light mr-3" data-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-sm btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal EditRider -->
<ng-template #modalEditRider>
  <div id="editRider" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editEntryLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editEntryLabel">Edit Rider / Trainer</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" id="editRiderTrainerForm" (ngSubmit)="editRider()"
            [formGroup]="editRiderTrainerForm" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Rider</label>
                  <div class="input-group">
                    <input type="text" class="form-control" [matAutocomplete]="autocompleteEditRider"
                      (keyup)="getPersonas($event, 'rider')" formControlName="rider" required>
                    <mat-autocomplete #autocompleteEditRider="matAutocomplete" [displayWith]="displayJinete.bind(this)">
                      <mat-option *ngFor="let jinete of jinetes" [value]="jinete.id_persona" class="fila-autocomplete"
                        style="height:5em; line-height: 2em;">
                        <img [src]="jinete.flag" style="height:.9em" />
                        <span id="riderName" style="font-weight: bold;">{{ jinete.fullname ?? '-' }}</span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            jinete.usef }}
                          </span>
                          <span *ngIf="jinete.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ jinete.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="add.get('rider')">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddPerson('rider')">
                        <i class="mdi mdi-plus"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Please choose a rider.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Trainer</label>
                  <div class="input-group">
                    <input type="text" class="form-control" [matAutocomplete]="autocompleteEditTrainer"
                      (keyup)="getPersonas($event, 'trainer')" formControlName="trainer" required>
                    <mat-autocomplete #autocompleteEditTrainer="matAutocomplete"
                      [displayWith]="displayEntrenador.bind(this)">
                      <mat-option *ngFor="let entrenador of entrenadores" [value]="entrenador.id_persona"
                        class="fila-autocomplete" style="height:5em; line-height: 2em">
                        <img [src]="entrenador.flag" style="height:.9em" />
                        <span id="horseName" style="font-weight: bold;">{{ entrenador.fullname||'-' }}</span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            entrenador.usef }}
                          </span>
                          <span *ngIf="entrenador.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ entrenador.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="add.get('trainer')">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddPerson('trainer')">
                        <i class="mdi mdi-plus"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Please choose a trainer.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="form-group">
                  <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal ChangeTrainer -->
<ng-template #modalChangeTrainer>
  <div id="changeTrainer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="changeTrainerLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="changeTrainerLabel">Change Trainer</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" id="changeTrainerForm" (ngSubmit)="changeTrainer()" [formGroup]="changeTrainerForm" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Trainer</label>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="trainer" required [matAutocomplete]="autocompleteTrainer" (keyup)="getPersonas($event, 'trainer')">
                    <mat-autocomplete #autocompleteTrainer="matAutocomplete" [displayWith]="displayEntrenador.bind(this)">
                      <mat-option *ngFor="let entrenador of entrenadores" [value]="entrenador.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em;">
                        <img [src]="entrenador?.flag" style="height:.9em" />
                        <span id="trainerName" style="font-weight: bold;">{{ entrenador.fullname ?? '-' }}</span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            entrenador.usef }}
                          </span>
                          <span *ngIf="entrenador.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ entrenador.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="add.get('trainer')">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddPerson('trainer')"><i
                          class="mdi mdi-plus"></i></button>
                    </div>
                    <div class="invalid-feedback">
                      Please choose a trainer.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="form-group">
                  <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal EditEntry -->
<ng-template #modalEditEntry>
  <div id="editEntry" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editEntryLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editEntryLabel">Edit Horse / Payee</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" id="editEntryForm" (ngSubmit)="editEntry()" [formGroup]="editEntryForm"
            novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Horse</label>
                  <div class="input-group">
                    <input type="text" class="form-control" [matAutocomplete]="autocompleteEditCaballo"
                      (keyup)="getCaballos($event)" formControlName="horse" required>
                    <mat-autocomplete #autocompleteEditCaballo="matAutocomplete"
                      [displayWith]="displayCaballo.bind(this)">
                      <mat-option *ngFor="let caballo of caballos" [value]="caballo.id_caballo"
                        class="fila-autocomplete" style="height:5em; line-height:2em;">
                        <img src="" style="height:.9em" />
                        <span id="horseName" style="font-weight:900;">
                          {{ horse?.name||'-' }}
                        </span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">
                            {{ horse?.usef?.id }}
                          </span>
                          <span *ngIf="horse?.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                            {{ horse?.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="addHorse">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddHorse()"><i
                          class="mdi mdi-plus"></i></button>
                    </div>
                    <div class="invalid-feedback">
                      Please choose a horse.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Payee</label>
                  <div style="display:flex">
                    <input type="text" class="form-control" [matAutocomplete]="autocompleteEditPayee"
                      (keyup)="getPersonas($event, 'payee')" formControlName="payee" required>
                    <mat-autocomplete autoActiveFirstOption #autocompleteEditPayee="matAutocomplete"
                      [displayWith]="displayResponsable.bind(this)">
                      <mat-option *ngFor="let responsable of responsables" style="height:5em; line-height:2em;"
                        [value]="responsable.id_persona" class="fila-autocomplete">
                        <span id="payeeName" style="font-weight:900;">{{ responsable.fullname ?? '-' }}</span>
                        <div>
                          <span id="USEF">
                            <img [src]="usefLogo.Base" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            responsable.usef }}
                          </span>
                          <span *ngIf="responsable.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                            {{ responsable.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="add.get('payee')">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddPerson('payee')"><i
                          class="mdi mdi-plus"></i></button>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Please choose a payee.
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="form-group">
                  <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal ChangeOwner -->
<ng-template #modalChangeOwner>
  <div id="changeOwner" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="changeOwnerLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="changeOwnerLabel">Change Owner</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" id="changeOwnerForm" (ngSubmit)="changeOwner()" [formGroup]="changeOwnerForm"
            novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Owner</label>
                  <div class="input-group">
                    <input type="text" class="form-control" [matAutocomplete]="autocompleteOwnerEntry"
                      (keyup)="getPersonas($event, 'owner')" formControlName="owner">
                    <mat-autocomplete #autocompleteOwnerEntry="matAutocomplete" [displayWith]="displayOwner.bind(this)">
                      <mat-option *ngFor="let owner of owners" [value]="owner.id_persona" class="fila-autocomplete"
                        style="height:5em; line-height: 2em">
                        <img [src]="owner.flag" style="height:.9em" />
                        <span id="ownerName" style="font-weight: bold;">{{ owner?.fullname ?? '-' }}</span>
                        <div>
                          <span id="USEF">
                            <img src="/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">{{
                            owner?.usef }}
                          </span>
                          <span *ngIf="owner.fei" id="FEI">
                            <img src="/assets/images/icons/fei.svg" alt="FEI: "
                              style="height: 1em; padding-left: 10px; padding-bottom: 5px;">{{ owner.fei }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append" *ngIf="add.get('owner')">
                      <button type="button" class="btn btn-icon btn-success" (click)="mostrarAddPerson('owner')"><i
                          class="mdi mdi-plus"></i></button>
                    </div>
                    <div class="invalid-feedback">
                      Please choose a owner.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <div class="form-group">
                  <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal EditOwner -->
<ng-template #modalEditOwner>
  <div id="editOwner" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editOwnerLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editOwnerLabel">Edit/Add Person</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form id="personForm" [formGroup]="personForm" (ngSubmit)="agregarPersona()" class="needs-validation"
            novalidate>
            <div class="row">
              <!-- Campos FEI, USEF y USHJA -->
              <ng-container *ngIf="!reining">
                <div class="col-6">
                  <div class="form-group">
                    <label># FEI</label>
                    <input type="text" class="form-control" style="text-transform: uppercase;" formControlName="fei">
                  </div>
                </div>
                <div class="col-6">
                  <button style="margin-top: 27px; height: 37.39px;color:#4a0d53" type="button" mat-raised-button
                    (click)="getPersonFeiInfo()">
                    <img src="assets/images/icons/fei.svg" alt="fei-logo" class="fei-logo">
                    <!--mat-icon style="margin-top: -7px; margin-right: 8px;height: 30px;width: 30px;" [svgIcon]="'fei-logo'"></mat-icon-->
                    CONNECT
                  </button>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label># USEF</label>
                    <input type="text" class="form-control" formControlName="usef">
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="custom-control custom-checkbox mr-3" style="margin-top: 37px;">
                      <input type="checkbox" class="custom-control-input" id="organization" [(ngModel)]="organization"
                        [ngModelOptions]="{standalone: true}">
                      <label class="custom-control-label" for="organization">Org</label>
                    </div>
                    <button style="margin-top: 27px; height: 37.39px;color:#113978" type="button" mat-raised-button
                      (click)="getPersonUsefData()">
                      <img src="assets/images/icons/usef.svg" alt="usef-logo" class="usef-logo">
                      <!--mat-icon style="margin-top: -3px; margin-right: 8px;height: 30px;width: 30px;" [svgIcon]="'usef-logo'"></mat-icon-->
                      CONNECT
                    </button>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label># USHJA</label>
                    <input type="text" class="form-control" formControlName="ushja">
                  </div>
                </div>
              </ng-container>
              <!-- Campo NRHA -->
              <ng-container *ngIf="reining">
                <div class="col-6">
                  <div class="form-group">
                    <label># NRHA</label>
                    <input type="text" class="form-control" style="text-transform: uppercase;" formControlName="nrha">
                  </div>
                </div>
                <div class="col-6">
                  <button style="margin-top: 27px; height: 37.39px;color:#113978" type="button" mat-raised-button
                    (click)="getPersonNrhaData()">
                    <img src="assets/images/NRHA.png" alt="NRHA logo" class="mr-1" style="max-height: 1rem;">CONNECT
                  </button>
                </div>
              </ng-container>
              <div class="col-6">
                <div class="form-group">
                  <label>Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="name" required>
                  <div class="invalid-feedback">
                    Please provide a name.
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Lastname</label>
                  <input type="text" class="form-control" formControlName="lastname">
                  <div class="invalid-feedback">
                    Please provide a lastname.
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Amateur Pro Status</label>
                  <input type="text" class="form-control" formControlName="amateurProStatus">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>W9</label>
                  <input type="number" min="1900" max="2099" step="1" class="form-control" formControlName="w9">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="custom-control custom-checkbox mr-3" style="margin-top: 37px;">
                    <input type="checkbox" class="custom-control-input" id="amateur" formControlName="amateur">
                    <label class="custom-control-label" for="amateur">Amateur</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control" formControlName="email">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Age</label>
                  <input type="number" class="form-control" formControlName="age">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Nationality</label>
                  <input type="text" class="form-control" formControlName="nationality"
                    [matAutocomplete]="autocompleteNacionalidad">
                  <mat-autocomplete #autocompleteNacionalidad="matAutocomplete" [displayWith]="displayPais.bind(this)">
                    <mat-option *ngFor="let pais of paises | filterPais : queryPais" [value]="pais.id">
                      <img [src]="pais.image" aria-hidden height="25" style="vertical-align:middle;"
                        onerror="this.src='assets/images/icons/sin-bandera.jpg'">
                      <span>{{ (pais.name) ? pais.name : '-' }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Date of birth</label>
                  <input type="text" class="form-control" placeholder="YYYY-MM-DD" data-toggle="input-mask"
                    data-mask-format="0000-00-00" maxlength="10" formControlName="dateOfBirth">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Telephone</label>
                  <input type="text" class="form-control" formControlName="telephone">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Cell Phone</label>
                  <input type="text" class="form-control" formControlName="cellPhone">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Gender</label>
                  <select class="form-control" formControlName="gender">
                    <option value="">-- choose an option --</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div class="col-6" *ngIf="privilegios == 300">
                <div class="form-group">
                  <label>Stripe customer id</label>
                  <input type="text" class="form-control" formControlName="stripeCustomerId">
                </div>
              </div>
              <div class="col-12">
                <div id="accordion-address" class="custom-accordion mb-4">
                  <div class="card mb-0">
                    <div class="card-header py-0 px-2" id="heading-usef-address">
                      <h5 class="m-0">
                        <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse" href="#collapse-usef-address" aria-expanded="false" aria-controls="collapse-usef-address">
                          Usef Address <span class="float-right"><i  class="mdi mdi-chevron-down accordion-arrow"></i></span>
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-usef-address" class="collapse" aria-labelledby="heading-usef-address" data-parent="#accordion-address">
                      <div class="card-body px-2 py-1" formGroupName="usefAddress">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address</label>
                              <input type="text" class="form-control" formControlName="address">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address 2</label>
                              <input type="text" class="form-control" formControlName="address2">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>City</label>
                              <input type="text" class="form-control" formControlName="city">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>
                              <input type="text" class="form-control" formControlName="state">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Zip</label>
                              <input type="text" class="form-control" formControlName="zip">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Country</label>
                              <input type="text" class="form-control" formControlName="country">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> <!-- end card-->
                  <div class="card mb-0">
                    <div class="card-header py-0 px-2" id="heading-tax-address">
                      <h5 class="m-0">
                        <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse"
                          href="#collapse-tax-address" aria-expanded="false" aria-controls="collapse-tax-address">
                          Tax Address <span class="float-right"><i
                              class="mdi mdi-chevron-down accordion-arrow"></i></span>
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-tax-address" class="collapse" aria-labelledby="heading-tax-address"
                      data-parent="#accordion-address">
                      <div class="card-body px-2 py-1" formGroupName="taxAddress">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address</label>
                              <input type="text" class="form-control" formControlName="address">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address 2</label>
                              <input type="text" class="form-control" formControlName="address2">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>City</label>
                              <input type="text" class="form-control" formControlName="city">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>
                              <input type="text" class="form-control" formControlName="state">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Zip</label>
                              <input type="text" class="form-control" formControlName="zip">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Country</label>
                              <input type="text" class="form-control" formControlName="country">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> <!-- end card-->
                  <div class="card mb-0">
                    <div class="card-header py-0 px-2" id="heading-mail-address">
                      <h5 class="m-0">
                        <a class="custom-accordion-title d-block py-2 collapsed" data-toggle="collapse"
                          href="#collapse-mail-address" aria-expanded="false" aria-controls="collapse-mail-address">
                          Mail Address <span class="float-right"><i
                              class="mdi mdi-chevron-down accordion-arrow"></i></span>
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-mail-address" class="collapse" aria-labelledby="heading-mail-address"
                      data-parent="#accordion-address">
                      <div class="card-body px-2 py-1" formGroupName="mailAddress">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address</label>
                              <input type="text" class="form-control" formControlName="address">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Address 2</label>
                              <input type="text" class="form-control" formControlName="address2">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>City</label>
                              <input type="text" class="form-control" formControlName="city">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>
                              <input type="text" class="form-control" formControlName="state">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Zip</label>
                              <input type="text" class="form-control" formControlName="zip">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Country</label>
                              <input type="text" class="form-control" formControlName="country">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> <!-- end card-->
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>TAX ID</label>
                  <input type="text" class="form-control" formControlName="taxId">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>TAX Name</label>
                  <input type="text" class="form-control" formControlName="taxName">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Notes</label>
                  <textarea class="form-control" rows="3" formControlName="notas" style="resize: none;"></textarea>
                </div>
              </div>
              <div class="col-12 mt-3 text-center">
                <button type="button" class="btn btn-light mr-4" data-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-success">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal EditEntryNumber -->
<ng-template #modalEditEntryNumber>
  <div id="editEntryNumber" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editEntryNumberLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editHorseLabel">Edit Entry Number</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-bottom: 25px; border-bottom: 1px solid #dddddd;">
            <div class="col-12">
              <div class="form-group">
                <label>Entry number</label>
                <input type="text" id="entry" class="form-control" (keyup)="nuevoEntryInput($event)"
                  value="{{ entrySeleccionado }}">
              </div>
            </div>
            <div class="col-3">
              <button class="btn btn-success" style="margin-bottom: 15px; height: 37.39px;" type="btn button"
                mat-raised-button (click)="changeEntryNumber()">
                Change Entry Number
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal EditHorse -->
<ng-template #modalEditHorse>
  <div id="editHorse" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editHorseLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" *ngIf="actions.showHorseForm">
        <div class="modal-header">
          <h4 class="modal-title" id="editHorseLabel">Edit/Add Horse</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <app-form-horse [horse_id]="horse?.id" [showId]="concurso.id" (onCreate)="addHorse($event)" (onUpdate)="editHorse($event)" (onCancel)="closeHorseModal()"></app-form-horse>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- Modal AnularMulta -->
<ng-template #modalAnularMulta>
  <div id="modal-anular-multa" tabindex="-1" role="dialog" class="modal fade in"
    style="display: none; padding-right: 17px; z-index: 1070;">
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 5px;">
        <div class="modal-header bg-danger" style="color: #fff;padding: 20px 15px;border-radius: 5px 5px 0 0;">
          <h3 class="modal-title">Cancel Penalty</h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            <i style="color: #ffffff;" class=" mdi mdi-close-box-outline"></i>
          </button>
        </div>
        <div class="modal-body">
          <textarea class="form-control" name="motivo" cols="15" rows="5" style="resize: none;" [formControl]="motivo"
            [ngClass]="{'no-valido': motivo.invalid && (motivo.touched || motivo.dirty)}"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-default">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="anularMulta()">Accept</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal MotivoAnularMulta -->
<ng-template #modalMotivoAnularMulta>
  <div id="motivoAnularMulta" tabindex="-1" role="dialog" class="modal fade in"
    style="display: none; padding-right: 17px; z-index: 1070;">
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 5px;">
        <div class="modal-header bg-danger" style="color: #fff;padding: 20px 15px;border-radius: 5px 5px 0 0;">
          <h3 class="modal-title">Reason</h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            <i style="color: #ffffff;" class=" mdi mdi-close-box-outline"></i>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ mensajeMotivo }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-primary">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Paysafe -->
<ng-template #modalPaysafe>
<div class="modal fade" id="modal-paysafe" tabindex="-1" role="dialog" aria-labelledby="cardPaysafeModalTitle" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="cardPaysafeModalTitle">Credit/Debit Card Payment</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" tabindex="-1">×</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="show.get('paysafe-form')">
              <app-paysafe></app-paysafe>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>

<!-- Modal CardPayment -->
<ng-template #modalCardPayment>
  <div class="modal fade" id="cardPaymentModal" tabindex="-1" role="dialog" aria-labelledby="cardPaymentModalTitle" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="cardPaymentModalTitle">Credit/Debit Card Payment</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" tabindex="-1">×</button>
        </div>
        <div class="modal-body">
          <form [formGroup]="cardForm" class="needs-validation" (ngSubmit)="addCreditCardPayment()" autocomplete="off" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Notes</label>
                  <input class="form-control input-s" type="text" [(ngModel)]="notasTarjeta"
                    [ngModelOptions]="{standalone: true}" (change)="actualizarNotasTarjeta()">
                </div>
                <div class="form-group">
                  <label>Cards</label>
                  <select class="form-control select2" data-toggle="select2" id="select-tarjeta">
                    <option value="" selected>-- Select a card --</option>
                    <optgroup label="Chase Bank">
                      <option [value]="card.customerRefNum" *ngFor="let card of cards">
                        •⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠  •⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠  •⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠  {{ card.ccAccountNum }} - {{
                        fechaExp(card.ccExp) }} - {{ card.customerName }}
                      </option>
                    </optgroup>
                  </select>
                </div>
                <div class="form-group text-center">
                  <b>OR</b>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend" tabindex="-1">
                      <span class="input-group-text" style="background: transparent; border-right: 0;padding: 0 .9rem;"
                        tabindex="-1"><i class="mdi mdi-credit-card" tabindex="-1"></i></span>
                    </div>
                    <input id="cardNumber" formControlName="number" type="text" placeholder="Card number" class="form-control" style="border-left: 0; padding-left: 0; border-right: 0;" data-toggle="input-mask" data-mask-format="9999 9999 9999 9990" minlength="18" required tabindex="0">
                    <div class="input-group-append">
                      <input formControlName="date" type="text" placeholder="MM/YYYY" class="form-control"
                        style="border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
                        data-toggle="input-mask" data-mask-format="00/0000" minlength="7" required>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errorTarjeta }}
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="cardForm.value.save">
                  <label>Name</label>
                  <input type="text" formControlName="name" class="form-control">
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" formControlName="save" id="save-card" type="checkbox">
                    <label class="custom-control-label" for="save-card">Save this card</label>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center mt-3">
                <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-primary">Pay {{ formPago.value.monto * 1.0325 | currency
                  }}</button>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal CheckPayment -->
<ng-template #modalCheckPayment>
  <div class="modal fade" id="checkPaymentModal" tabindex="-1" role="dialog" aria-labelledby="checkPaymentModalTitle"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="checkPaymentModalTitle">Check Payment</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" tabindex="-1">×</button>
        </div>
        <div class="modal-body">
          <form [formGroup]="cardForm" class="needs-validation" (ngSubmit)="addPayment()" autocomplete="off" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Check numbers</label>
                  <input type="number" id="numero-cheque" class="form-control col-12"
                    (change)="checkNumberPayment($event)" />
                </div>
              </div>
              <div class="col-12 text-center mt-3">
                <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-primary">Pay</button>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal Log -->
<ng-template #modalLog>
  <div id="logModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="logModalLabel"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="logModalLabel">Log entry {{ entrySeleccionado }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <table class="table table-striped table-sm mb-0">
            <thead>
              <tr>
                <th>Event</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="entrySeleccionado && entryInfo as eInfo">
                <tr *ngFor="let log of eInfo.logs">
                  <th>{{ log.evento }}</th>
                  <th>{{ log.fecha | date : 'MMM dd yyyy H:mm' }}</th>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal MotivoAnulacionTraspaso -->
<ng-template #modalMotivoAnulacionTraspaso>
  <div id="motivoAnulacionTraspaso" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="motivoAnulacionTraspasoLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="motivoAnulacionTraspasoLabel">Reason</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <textarea style="resize: none;" cols="30" rows="4" class="form-control"
            [(ngModel)]="motivoAnulacionTraspaso"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="anularTraspaso()">Accept</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal Productos -->
<ng-template #modalProductos>
  <div class="modal fade" id="modal-productos" tabindex="-1" role="dialog" aria-labelledby="storeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="storeModalLabel">Merch Store</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <table class="table table-sm mb-0">
                <tbody>
                  <tr>
                    <td>
                      <select name="productos" id="productos" class="form-control" (change)="changeTotal()"
                        [(ngModel)]="selectedProduct" required>
                        <option value=""></option>
                        <option [value]="product.id" *ngFor="let product of products" [disabled]="product.qty == '0'">
                          {{ product.name }} ({{ product.qty }})</option>
                      </select>
                    </td>
                    <td><input type="number" class="form-control" min="1" (change)="changeTotal()"
                        [(ngModel)]="selectedQty" required></td>
                    <td><input type="number" class="form-control" readonly [(ngModel)]="selectedTotal"></td>
                    <td class="table-action text-center">
                      <a href="javascript: void(0);" class="action-icon" (click)="addProduct()"> <i
                          class="mdi mdi-plus-circle-outline"></i></a>
                    </td>
                  </tr>
                  <tr *ngFor="let product of selectedProducts">
                    <td>{{ product.name }}</td>
                    <td>{{ product.qty }}</td>
                    <td>{{ product.price | currency }}</td>
                    <td class="table-action">
                      <a href="javascript: void(0);" class="action-icon" (click)="removeProduct(product.id)"> <i
                          class="mdi mdi-close"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="saveProductCharges()">Save</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Modal StripePayment -->
<ng-template #modalStripePayment>
  <div class="modal fade" id="cardPaymentStripeModal" tabindex="-1" role="dialog"
    aria-labelledby="creditPAymentStripeLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="creditPAymentStripeLabel">Credit/Debit Card Payment</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <!--Componente de pago stripe -->
              <ng-container *ngIf="show.get('stripe-form')">
                <app-stripe-payment-form [customers]="stripeCustomers" [showId]="concurso?.id" [amount]="totalPayment" [metadata]="stripeMetadata" [description]="stripeDescription" [defaultCardHolder]="defaultCardHolder" (onError)="handleError($event)" (onConfirmPayment)="handleSubmit($event)"></app-stripe-payment-form>
              </ng-container>
            </div>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<!-- Iconos suspension status -->
<ng-template #suspensionStatus let-entity="entity">
  <p class="mb-0">
    <img class="ml-1 d-print-none" data-toggle="tooltip" [title]="entity?.usef?.suspension_reason"
      style="max-width: 30px;" [src]="((entity?.usef?.suspended) ? usefLogo.Error : usefLogo.Ok)||usefLogo.Base">
    <span style="line-height: 30px;"><b> {{ entity?.usef?.id }}</b></span>
    <a href="javascript:;" class="ml-2" data-toggle="tooltip" title="Update suspension status"
      [ngClass]="{'disabled': entity?.usef?.loading}"
      (click)="entity?.usef?.entity_type == 'H' ? getHorseUsefSuspensionInfo() : getPersonUsefSuspensionInfo(entity)">
      <i class="mdi mdi-sync" [ngClass]="{'mdi-spin': entity?.usef?.loading}"></i>
    </a>
  </p>
  <p class="mb-0">
    <img class="ml-1 d-print-none" data-toggle="tooltip" [title]="entity?.ushja?.suspension_reason"
      style="max-width: 30px;" [src]="(entity?.ushja?.suspended ? ushjaLogo.Error : ushjaLogo.Ok)||ushjaLogo.Base">
    <span style="line-height: 30px;"><b> {{ entity?.ushja?.id }}</b></span>
    <a href="javascript:;" class="ml-2" data-toggle="tooltip" title="Update suspension status"
      [ngClass]="{'disabled': entity?.ushja?.loading}"
      (click)="entity?.ushja?.entity_type == 'H' ? getHorseUshjaSuspensionInfo() : getPersonUshjaSuspensionInfo(entity)">
      <i class="mdi mdi-sync" [ngClass]="{'mdi-spin': entity?.ushja?.loading}"></i>
    </a>
  </p>
</ng-template>

<!-- Domiciilio personas -->
<ng-template #addressTemplate let-entity="entity" let-tipo="tipo">
  <p class="mb-0">{{ entity?.address?.tax?.address }} <a href="javascript: void(0);" *ngIf="entity?.id" [ngClass]="{'disabled': validated}" class="edit ml-2 d-print-none" (click)="validated ? null : showEditPerson(entity?.id, 'entity')"> <i  class="mdi mdi-pencil"></i></a></p>
  <p class="mb-0" *ngIf="entity?.address?.tax?.address2">{{ entity?.address?.tax?.address2 }} </p>
  <p class="mb-0">{{ entity?.address?.tax?.city }}, {{ entity?.address?.tax?.state }} </p>
  <p class="mb-0">{{ entity?.address?.tax?.zip }}</p>
  <p class="mb-0">{{ entity?.address?.tax?.city }}</p>
  <ng-container *ngIf="tipo == 'payee'">
    <p class="mb-0 d-print-none" *ngIf="entity?.w9"><b>W9:</b> {{ entity?.w9 }}</p>
    <p class="mb-0 d-print-none" *ngIf="entity?.notes"><b>Notes:</b> {{ entity?.notes }}</p>
  </ng-container>
</ng-template>

<ng-template #cardPlaceholder>
  <div class="card-body text-panel p-print-0">
    <p class="placeholder-glow">
      <span class="placeholder col-6"></span>
      <span class="placeholder w-75"></span>
      <span class="placeholder" style="width: 25%;"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder w-75"></span>
      <span class="placeholder" style="width: 25%;"></span>
    </p>
  </div>
</ng-template>
