import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PersonaService } from './../services/persona.service';
import { CheckService } from './../services/check.service'; 
declare let $:any;

@Component({
  selector: 'app-check-form',
  templateUrl: './check-form.component.html',
  styleUrls: ['./check-form.component.css']
})
export class CheckFormComponent implements OnInit, OnChanges {
  //Variable opcional en caso de contar con el id de entrenador: Si se manda este parametro no se muestra en la tabla
  @Input() trainer?: string;
  //Variable opcional en caso de contar con el numero de entry: Si se manda este parametro no se muestra en la tabla
  @Input() entry?: string;
  //Variable opcional en caso de contar con el arreglo de entries
  @Input() entries?: any[];//{entry, nombre}
  //Variable opcional en caso de requerir ocultar el formulario de agregar cheque
  @Input() hideForm?: boolean;
  //Este evento se emite despues de consultar y agregar un cheque, envia el checke al componente padre y se obtiene la info sin necesidad de recargar
  @Output() newCheckEvent = new EventEmitter<Check>();
  private idConcurso: string;
  public form: UntypedFormGroup;
  public isEntry: boolean;
  public payees: any[];
  public trainers: any[];
  public checks: any[];


  constructor(private personaService: PersonaService, private checkService: CheckService) {
    this.idConcurso = sessionStorage.concurso||'';
    this.form = new UntypedFormGroup({
      number: new UntypedFormControl('', [Validators.required]),
      entry: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', [Validators.required]),
      show: new UntypedFormControl(this.idConcurso, [Validators.required]),
      notes: new UntypedFormControl('', []),
      payee: new UntypedFormControl('', []),
      amount: new UntypedFormControl('', [Validators.required, Validators.min(1)])
    });
    this.isEntry = false;
    this.payees = [];
    this.trainers = [];
    this.checks = [];
  }

  //Listener de cambios de valor de parametros recibidos en el componente
  ngOnChanges(changes: SimpleChanges): void {
    //Si se detecta un cambio de entrenador o de entry hay que reiniciar el componente
    if(changes.trainer || changes.entry){
      //Limpiar todas las variables igual que en el constructor
      this.idConcurso = sessionStorage.concurso||'';
      this.form = new UntypedFormGroup({
        number: new UntypedFormControl('', [Validators.required]),
        entry: new UntypedFormControl(this.entry||'', [Validators.required]),
        trainer: new UntypedFormControl(this.trainer||'', [Validators.required]),
        show: new UntypedFormControl(this.idConcurso, [Validators.required]),
        notes: new UntypedFormControl('', []),
        payee: new UntypedFormControl('', []),
        amount: new UntypedFormControl('', [Validators.required, Validators.min(1)])
      });
      this.isEntry = !!this.entry;
      this.payees = [];
      this.trainers = [];
      this.checks = [];
      //Consulta los cheques del nuevo entrenador o entry
      this.getChecks();
    }
  }

  ngOnInit() {
    //Valida si se esta recibiendo id de entrenador o entry y asigna el valor al formulario
    /*if(this.trainer) this.form.get('trainer').setValue(this.trainer);
    if(this.entry){
      this.isEntry = true;
      this.form.get('entry').setValue(this.entry);
    } 
    this.getChecks();*/
  }

  getChecks(){
    this.checkService.getChecks(this.idConcurso, this.entry, this.trainer).subscribe(
      response => {
        if(!response.error){
          this.checks = response.checks.map(c => {
            this.newCheckEvent.emit(c);
            return c;
          });

        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the checks list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  async submit(){
    console.log(this.form.errors);
    console.log(this.form.valid);
    //Si todos los campos requeridos se llenaron
    if(this.form.valid){
      const { response, error } = await this.checkService.save(this.form.value).toPromise().then(r => ({ response: r, error: r.message||'' })).catch(e => ({response: null, error: e}));
      if(error){
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
      }
      this.form.reset({
        number: '',
        entry: this.entry||'',
        trainer: this.trainer||'',
        show: this.idConcurso,
        notes: '',
        payee: '',
        amount: ''
      });
      this.checks.push(response.check);
      this.newCheckEvent.emit(response.check);
    }
  }

  public displayTrainer(idPersona): string{
    const persona = this.trainers.find(p => p.id_persona == idPersona);

    return persona ? persona.fullname : idPersona;
  }

  public displayPayee(idPersona): string{
    const persona = this.payees.find(p => p.id_persona == idPersona);

    return persona ? persona.fullname : idPersona;
  }

  public getPersonas(e, entidad) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            switch (entidad) {
              case 'payee':
                this.payees = response.personas;
                break;
              case 'trainer':
                this.trainers = response.personas;
                break;
            }
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the people, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      switch (entidad) {
        case 'payee':
          this.payees = [];
          break;
        case 'trainer':
          this.trainers = [];
          break;
      }
    }
  }
}

interface Check{
  id?: string;
  number: string;
  entry?: string;
  trainer?: string;
  show: string;
  notes?: string;
  payee?: string;
  amount: string;
}