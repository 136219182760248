<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <!--INICIO DE TABLA DE Binomios-->
        <div class="row margin-top-35 m-print-0">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="row d-print-none" style="margin-bottom: 50px;">
                  <div class="form-group mb-0">
                    <select class="form-control hidden-print float-left" [(ngModel)]="idEntrenador" (change)="getListasEntrenador()" [ngModelOptions]="{standalone: true}">
                      <option value="Selecciona a un entrenador" selected disabled></option>
                      <option *ngFor="let entrenador of entrenadoresConcurso" [value]="entrenador.idEntrenador">{{ entrenador.nombre }}</option>
                    </select>
                  </div>
                  <div class="form-group mb-0 ml-auto">
                    <button *ngIf="displayButtonAD" class="btn btn-outline-success btn-rounded hidden-print" (click)="printAddsDrops()">
                      <i class="mdi mdi-printer"></i>
                      Print Adds/Drops
                    </button>
                    <button *ngIf="displayButtonAD" class="btn btn-outline-success btn-rounded hidden-print" (click)="printTrainerList()">
                      <i class="mdi mdi-printer"></i>
                      Print Trainer lists
                    </button>
                  </div>
                </div>
                <div class="panel panel-info" style="margin-bottom:50px;" id="adds-drops" *ngIf="idEntrenador">
                  <div class="row">
                    <div class="col-sm-3" id="logoClub" style="float: left; width:16%">
                      <p style="font-size: large;">{{ trainerName }}</p>
                      <p style="font-size: large;">{{ trainerEmail }}</p>
                      <br>
                      <p style="font-size: large;">{{ date }}</p>
                    </div>
                    <div class="col-sm-6" style="float: left; width:66.6%">
                      <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
                      <h4 style="text-align: center;">
                        <b>Trainer Adds / Drops</b>
                      </h4>
                    </div>
                    <div class="col-sm-3" id="logoClub" style="float: right; width:16%"><img style="max-width:75%; max-height: 100px; float: right"
                        [src]="logoClub" />
                    </div>
                  </div>
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th style="width: 35%; font-size: large; background: #d0d0d0; color: black;">Horse/Rider(s)</th>
                        <th style="width: 20%; font-size: large; background: #d0d0d0; color: black; ">Currently in</th>
                        <th colspan="2" style="width: 45%; font-size: large; background: #d0d0d0; color: black;">Changes</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: large;">
                      <ng-container *ngFor="let horse of jsonToArray(adds_drops); let i = index; let last = last;">
                        <tr class="last-rider">
                          <td>{{ horse }}</td>
                          <td></td>
                          <td colspan="2"></td>
                        </tr>
                        <ng-container *ngFor="let rider of jsonToArray(adds_drops[horse]);">
                          <tr >
                            <td style="padding-left: 100px;">{{ rider }}</td>
                            <td>
                              <button class="btn btn-secondary" style="margin-right: 10px;padding-left: 2px;padding-right: 2px;" *ngFor="let class of adds_drops[horse][rider]; let i = index;"> {{ class }} </button>
                            </td>
                            <td>
                              <p style="border-bottom: 1px solid #ccc!important;">Add</p>
                              <p style="border-bottom: 1px solid #ccc!important;">Add</p>

                            </td>
                            <td>
                              <p style="border-bottom: 1px solid #ccc!important;">Drop</p>
                              <p style="border-bottom: 1px solid #ccc!important;">Drop</p>

                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="panel panel-info" id="listas-entrenador">
                  <div class="row">
                    <div class="col-sm-2" id="logoClub" style="float: left; width:16%"><img style="max-width:75%; max-height: 100px; float: left"
                        [src]="logoClub" /> </div>
                    <div class="col-sm-8" style="float: left; width:66.6%">
                      <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
                      <h4 style="text-align: center;">
                        <b>{{tituloReporteEN}}</b>
                      </h4>
                    </div>
                    <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%"><img style="max-width:75%; max-height: 100px;  float: right"
                        [src]="header" /></div>
                  </div>
                  <mat-accordion>
                    <mat-expansion-panel *ngFor="let prize of testPrizes; let index = index" (opened)="prize.panelOpenState = true"
                      (closed)="prize.panelOpenState = false" [ngClass]="{'d-print-none': !prize.panelOpenState}">
                      <mat-expansion-panel-header>
                        <mat-panel-title style="float:left; width: 40% !important;">
                          {{prize.fecha_prueba}}
                        </mat-panel-title>
                        <mat-panel-description style="float:left; width: 40% !important;" >
                          <span style="float:right;">Entries</span>: <strong>{{prize.inscritos}}</strong>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <div class="col" *ngFor="let pista of prize.pistas;let index = index">
                          <div class="card">
                            <h6 class="card-header" style="padding: 0.75rem .5rem;">{{ pista.numero_pista }}</h6>
                            <div class="card-body p-0">
                              <table class="table table-hover">
                                <thead>
                                  <tr style="font-size: .75rem; background-color: #313a46!important;" class="white-font">
                                    <th style="padding: .5rem !important;">Class</th>
                                    <th style="padding: .5rem !important;">Entries</th>
                                    <th style="padding: .5rem !important;">Start Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let p of pista.pistas; let i = index">
                                    <tr id="{{pista.numero_pista}}-{{p.prueba}}" style="    font-size: 17px;">
                                      <td> {{ p.prueba }} - {{ p.nombre}}</td>
                                      <td> {{ p.cantidad }}</td>
                                      <td><span *ngIf="p.hard_start == 1">{{p.horario }}</span></td>
                                    </tr>
                                    <ng-container *ngIf="binomiosPorEntrenador[prize.fecha_prueba]">
                                      <ng-container *ngIf="binomiosPorEntrenador[prize.fecha_prueba][pista.numero_pista]">
                                        <ng-container *ngIf="binomiosPorEntrenador[prize.fecha_prueba][pista.numero_pista][p.prueba]">
                                          <tr *ngFor="let prueba of binomiosPorEntrenador[prize.fecha_prueba][pista.numero_pista][p.prueba]; let ind = index;">
                                            <td style="
                                                      border-bottom: 1px solid #c3c3c3;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;"
                                                      [style.padding-left]="(prueba.entrada > 9) ? '42px': '50px'"
                                                      [style.background]="(ind%2 == 0) ? 'rgb(216, 216, 216)': '#ffffff'" colspan="3"
                                                      >
                                              <strong *ngIf="prueba.publicado == 1">{{ prueba.entrada }}</strong> - <strong>{{ prueba.jinete}}</strong>
                                              <p style="margin-bottom: 0px;" [style.padding-left]="(prueba.entrada > 9) ? '25px': '18px'">{{prueba.caballo}}</p>
                                            </td>
                                          </tr>
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>

                </div>
              </div> <!-- end card-->
            </div> <!-- end card-body-->
          </div> <!-- end card-->
        </div> <!-- end col-->
      </div>
    </div>
  </div>
</div>

