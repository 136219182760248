export const environment = {
  production: true,
  version: require('../../package.json').version,
  firebase: {
    apiKey: 'AIzaSyBFhMU1VwH3l1Hf4yGoQE6Qm3SEY9fIO0Y',
    authDomain: 'ecuestredigital.firebaseapp.com',
    databaseURL: 'https://ecuestredigital.firebaseio.com',
    projectId: 'ecuestredigital',
    storageBucket: 'ecuestredigital',
    messagingSenderId: '581815221685'
  },
  api: {
    uri: 'https://api.equestrian.digital/',
    key: '7cc263a1-a490-4337-8302-151490495e56',
    nest: {
      uri: 'https://nest.equestrian.digital/v1/'
    }
  },
  reiningClubs: ['563']
};
