import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ConcursoService extends Api {

    constructor(private http: HttpClient) {
        super();
    }

    public getConcursos(idConcurso:any = ''): Observable<any>{
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/`, params, {headers: headers});
    }

    public create(concurso): Observable<any>{
        const params = JSON.stringify(concurso);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/crear/`,params,{headers: headers});
    }

    public getConcurso(idConcurso): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}concursos/getById/?c=${idConcurso}`, {headers: headers});
    }

    public guardarCampo(nombreCampo, tipoCampo, valor, idConcurso): Observable<any>{
        const params = JSON.stringify({ nombreCampo, tipoCampo, valor, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/guardarCampo/`,params,{headers: headers});
    }

    public getSemanas(idConcurso): Observable<any>{
        const params = JSON.stringify({idConcurso});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/semanas/`,params,{headers: headers});
    }

    public getDias(idConcurso): Observable<any>{
        const params = JSON.stringify({idConcurso});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/dias/`,params,{headers: headers});
    }

    public getConcursosFei(): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}fei/get_shows.php`,{headers: headers});
    }

    public saveFeiShow(show, idConcurso): Observable<any>{
        const params = JSON.stringify({ show, idConcurso });
        const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}fei/save_show.php`, params, {headers: headers});
    }

    public getPistas(idConcurso): Observable<any>{
        const params = JSON.stringify({ idConcurso });
        const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/pistas/`, params, {headers: headers});
    }

    public copy(from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting): Observable<any>{
        const params = JSON.stringify({ from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting });
        const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/copy/`, params, {headers: headers});
    }
}
