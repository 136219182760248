<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 justify-content-center m-print-0">
          <div class="col-10">
            <div class="card">
              <div class="card-body">
                <form class="needs-validation" (ngSubmit)="copyEntries()" [formGroup]="form" novalidate>
                  <div class="row">
                    <div class="col-4">
                      <select class="form-control" formControlName="idConcurso" required>
                        <option value="" disabled>Select a show</option>
                        <option [value]="concurso.id" *ngFor="let concurso of concursos">{{ concurso.nombre }} ({{concurso.inicio | date}})</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a show.
                      </div>
                    </div>
                    <div class="col-2">
                      <input type="text" class="form-control" placeholder="Entry Prefix" (change)="entryPrefix($event)">
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-success">Copy</button>
                    </div>
                    <div class="col-6 mt-4">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th>Copy?</th>
                            <th>Entry</th>
                            <th>Trainer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let entry of entries; let i = index">
                            <td>
                                <div>
                                    <input type="checkbox" id="switch{{i}}" data-switch="success" [(ngModel)]="entries[i].copy" [ngModelOptions]="{standalone: true}">
                                    <label for="switch{{i}}" [attr.data-on-label]="'Yes'" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </td>
                            <td>{{ entry.entry }} - {{ entry.horse }}</td>
                            <td>{{ entry.trainers }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-6 mt-4">
                      <table class="table validados table-striped table-sm">
                        <thead>
                          <tr>
                            <th>Entry</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let entry of entriesValidados" [ngClass]="{'bg-danger text-white': entry.error}">
                            <td>{{ entry.entry }} - {{ entry.horse }}</td>
                            <td>{{ entry.message }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>