import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges{
  @Input() current: number = 0;
  @Input() total: number = 0;
  @Input() totalItems: number = 0;

  @Output() goTo: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();

  public pages: number[] = [];
  public from: number = 0;
  public to: number = 0;

  constructor() {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.current && changes.current.currentValue) || (changes.total && changes.total.currentValue) || (changes.totalItems && changes.totalItems.currentValue)) {
      this.pages = this.getPages(this.current, this.total);
      this.from = Math.max(1, this.current * 10 - 9);
      this.to = Math.min(this.totalItems, this.current * 10);
    }
  }

  public onGoTo(page: number): void {
    this.goTo.emit(page);
  }

  public onNext(): void {
    this.next.emit(this.current);
  }

  public onPrevious(): void {
    this.previous.next(this.current);
  }

  private getPages(current: number, total: number): number[] {
    if (total <= 5) {
      return [...Array(total).keys()].map(x => ++x)
    }

    if (current > 3) {
      console.log('current > 3', current);
      console.log('total -2', total - 2);
      if (current >= total - 2) {
        console.log('current >= total - 2', current, total);
        return [total - 4, total - 3, total - 2, total - 1, total]
      } else {
        return [current-2, current - 1, current, current + 1, current + 2]
      }
    }

    return [1, 2, 3, 4, 5];
  }
}
