import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { EntriesService } from '../services/entries.service';
declare var $ :any;

@Component({
  selector: 'app-validar-master-list',
  templateUrl: './validar-master-list.component.html',
  styleUrls: ['./validar-master-list.component.css']
})
export class ValidarMasterListComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public concursos: any[];
  public enabledEntries: boolean;
  public entries: any[];
  public jinetes: any[];
  public binomios: any[];
  public notAccepted: any[];
  public error: boolean;
  public drops: any[];

  constructor(private router: Router, private authService: AuthService, private concursoService: ConcursoService, private entriesService: EntriesService) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.concursos = [];
    this.enabledEntries = false;
    this.entries = [];
    this.jinetes = [];
    this.binomios = [];
    this.notAccepted = [];
    this.error = false;
    this.drops = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getConcursosFei();
  }

  private getShowInfo(): void{
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          if(response.concurso.idOficial){
            const index = this.concursos.findIndex(c => c.show_code == response.concurso.idOficial);
            if(index > -1){
              this.getEntriesFei(response.concurso.idOficial);
            } else{
              this.error = true;
              $('#loader-fei').hide();
            }
          } else{
            this.error = true;
            $('#loader-fei').hide();
          }
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader-fei').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the show info.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  private getConcursosFei(): void{
    $('#loader-fei').show();
    this.concursoService.getConcursosFei().subscribe(
      response => {
        this.concursos = response;
        this.getShowInfo();
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the shows list.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public getEntriesFei(idConcurso): void{
    this.enabledEntries = true;
    this.entries = [];
    this.notAccepted = [];
    //$('#loader-fei').show();
    this.entriesService.getEntriesFei(idConcurso).subscribe(
      response => {
        this.jinetes = response.map(e => e.rider);
        for(const entry of response){
          for(const horse of entry.horses){
            if(horse.status == 'H-ACCEP'){
              this.entries.push({
                horse,
                rider: entry.rider,
                status: horse.status,
                event: entry.event,
                eventCode: entry.eventCode,
                enEd: false
              });
            } else{
              this.notAccepted.push({
                horse,
                rider: entry.rider,
                status: horse.status,
                event: entry.event,
                eventCode: entry.eventCode,
                enEd: false
              });
            }
          }
        }
        this.binomiosMasterList();
        this.desinscribirCanceladosFei();
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the entries list.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public binomiosMasterList(){
    let entries = {};
    this.entries.forEach(e => {
      if(entries[`${e.rider.fei}-${e.horse.fei}`]){
        entries[`${e.rider.fei}-${e.horse.fei}`]['eventCode'] += `,${e.eventCode}`;
      } else{
        entries[`${e.rider.fei}-${e.horse.fei}`] = e;
      }
    });
    this.entriesService.marcarBinomiosEnMasterList(this.idConcurso, entries).subscribe(
      response => {
        if(!response.error){
          this.entries = response.entries.filter(e => !e.enEd);
          this.binomios = response.binomios.filter(b => !b.enFei);
          let element: HTMLElement = document.getElementById('step2') as HTMLElement;
          element.click();
          $('#loader-fei').hide();
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader-fei').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the entries list.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public desinscribirCanceladosFei(): void{
    //TODO: Filtrar cancelados, substituidos ademas de withdrawn
    const notAccepted = this.notAccepted.filter(e => e.status == 'H-WITHD').map(e => {
      return {
        feiHorse: e.horse.fei,
        feiRider: e.rider.fei,
        event: e.event
      };
    });

    this.entriesService.desinscribirCanceladosFei(this.idConcurso, notAccepted, this.idUsuario).subscribe(
      response => {
        if(!response.error){
          this.drops = response.drops;
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to drop the not accepted entries.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }
}
