import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { BehaviorSubject, Observable, Subject, catchError, first, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchErrorMessage, handleError } from '.';
import { ADD_AUTH_HEADER } from '../http-interceptors';

@Injectable()
export class UsefService extends Api {
  public USEFAPI: string;
  public USHJA: string;
  public avance: BehaviorSubject<number>;
  public $avance: Observable<number>;

  constructor(private http: HttpClient) {
    super();
    this.USEFAPI = "595ff1dad4c3459c8dcfdb80e10c4a17:Ynx4KWpOR3ElChRjsAF3QXpcYp1pC9M0f50kHp2BJkk=";
    this.USHJA = "60f9079d-8d45-40a6-be45-498b1917586a:9ec1c622-95d1-404d-be94-e1e2bd809ca0";
    this.avance = new BehaviorSubject<number>(0);
    this.$avance = this.avance.asObservable();
  }

  //USEF HORSE
  public getUSEFhorseInfo(Id: number): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}caballos/usef/${Id}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  /**
   * Retrieve USEF horse suspension information
   * @param Id Usef Id
   * @returns Array of horses updated with suspension information
   */
  public async getUSEFhorseSuspensionInfo(Id: number): Promise<any> {
    if(!Id){
      return [
        {
          id_caballo: null,
          usef_suspended: 1,
          usef_suspension_reason: 'Invalid USEF ID / USEF ID is not present.',
          usef_suspension_status: 'YES',
          usef_suspension_updated: null,
        }
      ];
    }
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}caballos/usef/suspension/${Id}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  //USHJA HORSE
  public getUSHJAhorseInfo(Id: number): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}caballos/ushja/${Id}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  /**
   * Retrieve USHJA horse suspension information
   * @param Id Usef Id
   * @returns Array of horses updated with suspension information
   */
  public async getUSHJAhorseSuspensionInfo(USEFId: number): Promise<any> {
    if(!USEFId){
      return [
        {
          id_caballo: null,
          ushjaId: '',
          ushja_suspended: 1,
          ushja_suspension_reason: 'Invalid Ushja ID / Ushja ID is not present.',
          ushja_suspension_status: 'YES',
          ushja_suspension_updated: null
        }
      ];
    }
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}caballos/ushja/suspension/${USEFId}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  //USEF PERSON
  public getUSEFpersonInfo(Id, entity_type: string = 'I'): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}personas/usef/${Id}/${entity_type}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  //USJHA PERSON
  public getUSHJApersonSuspensionInfo(USEFId): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}personas/ushja/suspension/${USEFId}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  //USEF PERSON
  public getUSEFpersonSuspensionInfo(Id, entity_type: string = 'I'): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}personas/usef/suspension/${Id}/${entity_type}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  //USJHA PERSON
  public getUSHJApersonInfo(USEFId): Promise<any> {
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}personas/ushja/${USEFId}`,{ ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public async getUSHJApersonSuspensionInfoBulk(ids: number[]){
    let processed = 0;
    this.avance.next(0);
    const promises = ids.map(id => this.getUSHJApersonSuspensionInfo(id).then(r => {
      this.avance.next((++processed*100)/ids.length);
      return r.data;
    }));
    return Promise.all(promises).then(r => {
      this.avance.next(100);
      return r;
    });
  }

  public async getUSHJAHorseSuspensionInfoBulk(ids: number[]): Promise<any> {
    let processed = 0;
    this.avance.next(0);
    const promises = ids.map(id => this.getUSHJAhorseSuspensionInfo(id).then(r => {
      this.avance.next((++processed*100)/ids.length);
      return r.data;
    }));
    return Promise.all(promises).then(r => {
      this.avance.next(100);
      return r;
    });
  }
}
